import { gql } from '@apollo/client'

/**
 * mutations.js
 * Exports all GraphQL mutations used by the app.
 * Since we use the ApolloClient React lib, most of these
 * are gql objects.
 */

export const Mutations = {
    EMPLOYEE: gql`
        mutation updateEmployee($employeeToUpdate: EmployeeInput!) {
            updateEmployee(employeeToUpdate: $employeeToUpdate) {
                id
            }
        }
    `,
    END_OF_LEASE: gql`
        mutation updateEndOfLease($endOfLeaseToUpdate: EndOfLeaseInput!) {
            updateEndOfLease(endOfLeaseToUpdate: $endOfLeaseToUpdate) {
                id
                end_of_lease_choice_fk
            }
        }
    `,
    UPDATE_QUOTATION: gql`
        mutation updateQuotation($quotationToUpdate: QuotationInput!) {
            updateQuotation(quotationToUpdate: $quotationToUpdate) {
                id
                hidden
            }
        }
    `,
    CREATE_QUOTATION: gql`
        mutation createQuotation($quotationToCreate: QuotationInput!) {
            createQuotation(quotationToCreate: $quotationToCreate) {
                id
            }
        }
    `,
    CREATE_QUOTATION_FIELD: gql`
        mutation createQuotationField($quotationFieldToCreate: QuotationFieldInput!) {
            createQuotationField(quotationFieldToCreate: $quotationFieldToCreate) {
                id
                field_fk
                value
                quotation_fk
            }
        }
    `
}
