import { useRecoilState } from 'recoil'
import flattendeep from 'lodash.flattendeep'
import uniqWith from 'lodash.uniqwith'
import isEqual from 'lodash.isequal'

import useDeviceDetection from '../../../hooks/useDeviceDetection'
import { userProfileState } from '../../../store/store'
import theme from '../../../theme/theme'
import { Blogpost } from '../../../types'
import { MUIComponents } from '../../muiComponents'
import StyledComponents from '../../StyledComponents'
import { ImagesList } from '../images/ImagesList'

// TODO: Make this more generic -> Implement when we are going to use the carousel on places that are not blogposts
export const SmallScreenCarousel = (props: { items: Blogpost[] }) => {
    const [userProfile] = useRecoilState(userProfileState)

    const [devices] = useDeviceDetection()

    return (
        <MUIComponents.Styling.Paper style={{ height: '100%' }} elevation={5}>
            <MUIComponents.Typography
                variant="h5"
                style={{
                    textAlign: 'left',
                    padding: '10px 20px 0 20px',
                    color: theme.palette.primary.main,
                    ...((devices.isMob || devices.isSmallerThanMob) && {
                        ...StyledComponents.helper.truncatedText
                    })
                }}
            >
                Tips & Tricks
            </MUIComponents.Typography>
            {props.items.length > 0 && (
                <ImagesList
                    orientation="vertical"
                    imageWidth="75%"
                    padding
                    margin
                    listItems={uniqWith(
                        flattendeep(props.items.map((item) => item.listItems)),
                        (a, b) => isEqual(a, b)
                    )}
                />
            )}
        </MUIComponents.Styling.Paper>
    )
}
