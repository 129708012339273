import MuiPagination, { PaginationProps as MuiPaginationProps } from '@mui/material/Pagination'
import MuiPaginationItem, {
    PaginationItemProps as MuiPaginationItemProps
} from '@mui/material/PaginationItem'
import styled from 'styled-components'

import { noop } from '../../utils/utils'

const StyledPaginationItem = styled(MuiPaginationItem)`
    background-color: ${(props) => props.selected && props.theme.palette.primary.main} !important;
    color: ${(props) => props.selected && props.theme.palette.primary.contrastText} !important;
`

// you can declare custom props in here
export interface PaginationProps extends MuiPaginationProps {
    // You can customise the pagination items like this
    // components: { previous: Icons.ArrowBackIcon, next: Icons.ArrowForwardIcon, first: Icons.First, last: Icons.last }
    // first = first page icon
    // last = last page icon
    // previous = navigate before icon
    // next = navigate next icon
    components?: MuiPaginationItemProps['components']
}

export const Pagination = ({
    shape = 'rounded',
    count = 0,
    page = 1,
    onChange = noop,
    ...props
}: PaginationProps) => (
    <MuiPagination
        count={count}
        page={page}
        onChange={onChange}
        shape={shape}
        renderItem={(item) => (
            <StyledPaginationItem
                {...(props.components && { components: props.components })}
                {...item}
                disabled={props.hideNextButton && (item?.page ?? 0) > page}
            />
        )}
        {...props}
    />
)

// Example of this component with props:
{
    /* <MUIComponents.Pagination
                    count={pagination.count}
                    page={pagination.page}
                    onChange={(e, page) => {
                        setPagination({ ...pagination, page })
                    }}
                    disabled={loading}
                    style={{
                        width: 'fit-content',
                        margin: 'auto',
                        marginBottom: 15,
                        marginTop: 15
                    }}
                    shape="rounded"
                    hideNextButton={
                        (pagination.page ?? 0) > 1 &&
                        ((searchValue ? filteredCalculations : pageCalculations) ?? [])?.length <
                            (pagination.size ?? 10)
                    }
                    hidePrevButton={pagination.page === 1}
    /> */
}
