import MuiMenu, { MenuProps as MuiMenuProps } from '@mui/material/Menu'
import { CSSProperties } from 'styled-components'

import { MUIComponents } from '.'
import { noop } from '../../utils/utils'

interface MenuProps extends MuiMenuProps {
    // you can declare custom props in here
    style?: CSSProperties
    menuItems?: { label: string; value: string }[]
    itemClick?: (item: { label: string; value: string }) => void
}

export const Menu = ({
    style = {},
    keepMounted = true,
    anchorOrigin = { vertical: 'bottom', horizontal: 'left' },
    transformOrigin = { vertical: 'top', horizontal: 'left' },
    menuItems = [],
    itemClick = noop,
    ...props
}: MenuProps) => (
    <MuiMenu
        transformOrigin={transformOrigin}
        keepMounted={keepMounted}
        anchorOrigin={anchorOrigin}
        style={style}
        {...props}
    >
        {menuItems.map((item) => (
            <MUIComponents.MenuItem key={item.label} onClick={() => itemClick(item)}>
                <MUIComponents.Typography>{item.label}</MUIComponents.Typography>
            </MUIComponents.MenuItem>
        ))}
    </MuiMenu>
)

// Example of this component with props:
{
    /* <MUIComponents.Menu
    id="basic-menu"
    anchorEl={anchorEl}
    open={open}
    onClose={() => {
        return
    }}
    menuItems={menuActions.map((action) => ({
        label: action.label,
        value: action.value
    }))}
    itemClick={() => {
        return
    }}
/> */
}
