import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { MUIComponents } from '../../muiComponents'
import Containers from '../../containers'
import theme from '../../../theme/theme'
import StyledComponents from '../../StyledComponents'
import useDeviceDetection from '../../../hooks/useDeviceDetection'

export const ConfirmationRegister = () => {
    const { t } = useTranslation()

    // hooks
    const navigate = useNavigate()
    const [devices] = useDeviceDetection()

    return (
        <StyledComponents.Wrapper>
            <MUIComponents.Styling.Grid
                container
                sx={{
                    height: devices.isWeb ? 'calc(100vh - 20vh)' : 'calc(100vh - 10vh)',
                    marginTop: devices.isWeb ? '19vh' : '9vh'
                }}
                columns={15}
                spacing={1}
            >
                <MUIComponents.Styling.Grid item xs={devices.isWeb ? 4.5 : 1.5} />

                {devices.isWeb && (
                    <MUIComponents.Styling.Grid
                        item
                        component={MUIComponents.Styling.Paper}
                        xs={2}
                        elevation={6}
                        sx={{
                            maxHeight: '75%',
                            padding: 'unset !important',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center center',
                            backgroundImage: "url('/img/login-design.png')",
                            backgroundSize: 'cover',
                            minHeight: '550px'
                        }}
                    />
                )}

                <MUIComponents.Styling.Grid
                    item
                    xs={devices.isWeb ? 4 : 12}
                    component={MUIComponents.Styling.Paper}
                    elevation={6}
                    sx={{
                        maxHeight: '75%',
                        position: 'relative',
                        height: '100%',
                        boxShadow: devices.isMob || devices.isSmallerThanMob ? 'unset' : undefined,
                        minHeight: '550px'
                    }}
                >
                    <MUIComponents.Styling.Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            ...((devices.isTablet || devices.isWeb) && { my: 3, mx: 3 })
                        }}
                    >
                        <MUIComponents.Typography
                            sx={{ color: theme.palette.primary.main }}
                            variant="h5"
                        >
                            {t('register.confirmationTitle')}
                        </MUIComponents.Typography>
                    </MUIComponents.Styling.Box>
                    <MUIComponents.Styling.Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            height:
                                devices.isMob || devices.isSmallerThanMob
                                    ? '100%'
                                    : 'calc(100% - 100px)',
                            ...((devices.isTablet || devices.isWeb) && { my: 3, mx: 3 })
                        }}
                    >
                        <MUIComponents.Typography
                            variant="subtitle1"
                            style={{ color: 'rgba(0, 0, 0, 0.87)' }}
                        >
                            {t('register.confirmationSubTitle')}
                        </MUIComponents.Typography>
                        <MUIComponents.Typography style={{ color: 'rgba(0, 0, 0, 0.87)' }}>
                            {t('register.confirmationDescription')}
                        </MUIComponents.Typography>

                        <StyledComponents.ItemNavigation
                            onClick={() => {
                                navigate('/login')
                            }}
                            sx={{
                                justifyContent: 'center',
                                ...(devices.heightIsSmallerThan850 && {
                                    paddingBottom: 10
                                }),
                                ...(devices.isWeb &&
                                    !devices.heightIsSmallerThan850 && {
                                        bottom: 0,
                                        position: 'absolute',
                                        left: '10%'
                                    })
                            }}
                        >
                            {t('register.confirmationLogin')}
                        </StyledComponents.ItemNavigation>
                    </MUIComponents.Styling.Box>

                    {(devices.isMob || devices.isSmallerThanMob) && (
                        <Containers.Copyright
                            isMob={devices.isMob || devices.isSmallerThanMob}
                            isSmallerThanMob={devices.isSmallerThanMob}
                            sx={{
                                color: 'rgb(128,128,128)',
                                margin: 'auto 5px',
                                fontSize: 'small'
                            }}
                        />
                    )}
                </MUIComponents.Styling.Grid>

                <MUIComponents.Styling.Grid item xs={3} elevation={6} />

                {(devices.isTablet || devices.isWeb) && (
                    <MUIComponents.Styling.Grid
                        item
                        xs={20}
                        elevation={6}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                            marginTop: 'auto'
                        }}
                        id="copyright"
                    >
                        <Containers.Copyright
                            isMob={devices.isMob}
                            isTablet={devices.isTablet}
                            sx={{
                                color: 'rgb(128,128,128)',
                                margin: devices.isTablet ? 'auto 5px' : 'auto 25px'
                            }}
                        />
                    </MUIComponents.Styling.Grid>
                )}
            </MUIComponents.Styling.Grid>
        </StyledComponents.Wrapper>
    )
}
