import { CSSProperties } from 'styled-components'

import { MUIComponents } from '../../muiComponents'
import Containers from '../../containers'
import * as GQLTypes from '../../../types/GQLTypes'

const border = '1px solid #D8D8D8'

const commonStyling: CSSProperties = {
    gridRow: '1/7',
    minWidth: 0,
    borderRight: border,
    overflow: 'auto'
}

export const Content = ({
    orderId,
    fileTypes,
    quotation
}: {
    orderId: GQLTypes.Order['id']
    fileTypes: string[]
    quotation: GQLTypes.Maybe<GQLTypes.Quotation> | undefined
}) => (
    <>
        <MUIComponents.Styling.Box
            sx={{
                display: 'grid',
                gridAutoFlow: 'row',
                gridTemplateColumns: 'repeat(12, 1fr)',
                gridTemplateRows: 'repeat(6, 1fr)',
                width: '100%',
                height: '100%'
            }}
        >
            <MUIComponents.Styling.Box
                sx={{
                    gridColumn: '1/4',
                    position: 'relative',
                    ...commonStyling
                }}
            >
                <Containers.Info.BasisInfo quotation={quotation} sourceComponent="Calculations" />
            </MUIComponents.Styling.Box>
            <MUIComponents.Styling.Box
                sx={{
                    gridColumn: '4/7',
                    borderLeft: border,
                    ...commonStyling
                }}
            >
                <Containers.Info.Calculation quotation={quotation} />
            </MUIComponents.Styling.Box>
            <MUIComponents.Styling.Box
                sx={{
                    gridColumn: '7/10',
                    borderLeft: border,
                    ...commonStyling
                }}
            >
                <Containers.Info.Details quotation={quotation} />
            </MUIComponents.Styling.Box>
            <MUIComponents.Styling.Box sx={{ gridColumn: '10/12', ...commonStyling }}>
                <Containers.Info.Documents
                    fileTypes={fileTypes}
                    orderId={orderId}
                    quotationId={quotation?.id}
                />
            </MUIComponents.Styling.Box>
        </MUIComponents.Styling.Box>
    </>
)
