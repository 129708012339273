import styled, { css } from 'styled-components'
import { useRecoilState } from 'recoil'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'

import { MUIComponents } from '../../muiComponents'
import Containers from '../../containers'
import { alertObjState, userProfileState } from '../../../store/store'
import { getBlogPosts } from './utils'
import useDeviceDetection from '../../../hooks/useDeviceDetection'
import { DeviceParameters, Blogpost, Paragraphs } from '../../../types'
import StyledComponents from '../../StyledComponents'

// TODO: content als HTML bewaren en ingeven in de content hier beneden

export const BlogPosts = () => {
    const [, setAlertObject] = useRecoilState(alertObjState)
    const [blogpostContent, setBlogpostContent] = useState<Blogpost>()
    const { blogId } = useParams()
    const [userProfile] = useRecoilState(userProfileState)

    const getPosts = async () => {
        let language: string = userProfile?.language as string

        if (!language) {
            language = navigator?.languages[1] as string // languagecode [1]
        }

        const posts = await getBlogPosts(language)
        const post = posts?.find((post) => post?.key === blogId)
        setBlogpostContent(post)
    }

    useEffect(() => {
        getPosts()
    }, [blogId])

    // hooks
    const [devices] = useDeviceDetection()

    return (
        <Main
            isMob={devices.isMob}
            isTablet={devices.isTablet}
            isSmallerThanMob={devices.isSmallerThanMob}
            isSmallerThen170Width={devices.isSmallerThen170Width}
        >
            <MUIComponents.Styling.Grid
                container
                sx={{
                    marginLeft: 'unset',
                    position: 'relative',
                    marginTop: '140px',
                    width: '100%',
                    boxShadow:
                        '0px 6px 6px -3px rgb(0 0 0 / 20%), 0px 10px 14px 1px rgb(0 0 0 / 14%), 0px 4px 18px 3px rgb(0 0 0 / 12%)'
                }}
                component={MUIComponents.Styling.Paper}
                columns={15}
                spacing={1}
            >
                <Image
                    isMob={devices.isMob}
                    isTablet={devices.isTablet}
                    isSmallerThanMob={devices.isSmallerThanMob}
                    isSmallerThen170Width={devices.isSmallerThen170Width}
                    src={blogpostContent?.bannerImg}
                    style={{ objectFit: 'contain' }}
                />
                <Content
                    isMob={devices.isMob}
                    isTablet={devices.isTablet}
                    isSmallerThanMob={devices.isSmallerThanMob}
                    isSmallerThen170Width={devices.isSmallerThen170Width}
                >
                    <MUIComponents.Typography
                        variant="subtitle1"
                        style={{
                            fontSize: '14px',
                            lineHeight: '16px',
                            color: '#7B7B7B',
                            marginTop: '15px'
                        }}
                    >
                        {blogpostContent?.subtitle}
                    </MUIComponents.Typography>
                    <MUIComponents.Typography
                        variant="h4"
                        style={{
                            fontWeight: 'bold',
                            color: 'rgba(0, 0, 0, 0.87)',
                            ...StyledComponents.helper.breakupText
                        }}
                    >
                        {blogpostContent?.title}
                    </MUIComponents.Typography>
                    {blogpostContent?.paragraphs?.map((paragraph: Paragraphs) => (
                        <>
                            {paragraph?.title}
                            {paragraph?.content}
                        </>
                    ))}
                    {blogpostContent?.listItems && (
                        <ImagesListWrapper>
                            <Containers.ImagesList
                                listItems={blogpostContent?.listItems}
                                orientation={
                                    devices.isMob || devices.isSmallerThanMob
                                        ? 'vertical'
                                        : 'horizontal'
                                }
                                margin={true}
                            />
                        </ImagesListWrapper>
                    )}
                </Content>
            </MUIComponents.Styling.Grid>

            <Containers.Copyright
                sx={{ color: 'rgb(128,128,128)', margin: 'auto 25px' }}
                paperStyling={{ margin: '10px 0px', width: 'unset' }}
                isMob={devices.isMob || devices.isSmallerThanMob}
                isSmallerThanMob={devices.isSmallerThanMob}
            />
        </Main>
    )
}

const Main = styled.div<DeviceParameters>`
    margin: 0 100px;

    ${(props) =>
        props.isTablet &&
        css`
            margin: 0 50px;
        `}

    ${(props) =>
        props.isMob &&
        css`
            margin: 0 25px;
        `}

    ${(props) =>
        (props.isSmallerThanMob || props.isSmallerThen170Width) &&
        css`
            margin: 0 10px;
        `}
`

const Image = styled.img<DeviceParameters>`
    margin-top: -60px !important;
    border-radius: 10px;
    display: flex;
    margin: auto;
    width: 75%;
    max-width: 60%;
    max-height: 400px;
    min-width: 75px;
    min-height: 40px;
    box-shadow: 0px 6px 6px -3px rgb(0 0 0 / 20%), 0px 10px 14px 1px rgb(0 0 0 / 14%),
        0px 4px 18px 3px rgb(0 0 0 / 12%);

    ${(props) =>
        (props.isMob || props.isTablet) &&
        css`
            width: 60%;
        `}

    ${(props) =>
        props.isSmallerThanMob &&
        css`
            width: 60%;
        `}

    ${(props) =>
        props.isSmallerThen170Width &&
        css`
            width: 60%;
        `};
`

const Content = styled.div<DeviceParameters>`
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 25px;
    display: flex;
    flex-direction: column;
    width: 50%;

    ${(props) =>
        (props.isMob || props.isTablet) &&
        css`
            width: 90%;
        `}

    ${(props) =>
        props.isSmallerThanMob &&
        css`
            width: 90%;
        `}

    ${(props) =>
        props.isSmallerThen170Width &&
        css`
            width: 90%;
        `};
`

const ImagesListWrapper = styled.div`
    margin-top: 50px;
`
