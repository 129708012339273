import { MUIComponents } from '../../muiComponents'
import StyledComponents from '../../StyledComponents'
import { Item } from './Details'

export const Label = (item: Item) => (
    <MUIComponents.Typography
        sx={{
            fontSize: 'small',
            color: 'rgba(0,0,0,0.6)',
            textAlign: 'left',
            ...StyledComponents.helper.truncatedText
        }}
        variant="body2"
    >
        {item.label}
    </MUIComponents.Typography>
)
