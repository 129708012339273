/**
 * apiUrls.js
 * Provides global access to urls for the gateway
 */

// Bugsnag
const bugsnagApiKey = ''

const apiGatewayDevelopment = 'https://b2bike3-gateway-2c7rx35x.ew.gateway.dev'
const apiGatewayStaging = 'https://b2bike3-gateway-staging-2c7rx35x.ew.gateway.dev'
const apiGatewayProduction = 'https://b2bike3-gateway-production-2c7rx35x.ew.gateway.dev'

const handleUrls = (apiGateway: string) => ({
    // graphql API
    graphql: `${apiGateway}/graphql`,
    // cloud function
    handlePasswordReset: `${apiGateway}/handlePasswordReset`,
    // main gateway url
    main: apiGateway,
    // workflow
    register: `${apiGateway}/register`,
    // cloud function
    registerCompanyCode: `${apiGateway}/registerCompanyCode`,
    // cloud function
    fetchFromDrive: `${apiGateway}/fetchFromDrive`,
    // cloud function
    createCalculationSheet: `${apiGateway}/createCalculationSheet`,
    // cloud function
    generateDocuments: `${apiGateway}/generateDocuments`,
    // cloud function
    generateMyServiceDocuments: `${apiGateway}/generateDocuments`
})

const handleWorkflows = (workflowName: string) => ({
    [workflowName]: {
        development: `${workflowName}-development`,
        staging: `${workflowName}-staging`,
        production: `${workflowName}-production`
    }
})

const workflowNames = ['register', 'registerCompanyCode']

export default {
    bugsnag: {
        apiKey: bugsnagApiKey
    },
    // all workflows with for each a distinction for the different env's
    // IMPORTANT: When creating a workflow, we are going to create 1 for each env with the following name
    // (test is the workflow name): `test-development`, `test-staging`, `test-production`
    // When you added these to the GCP, make sure that you add the workflow name in the array workflowNames
    workflows: workflowNames.reduce<{
        [key: string]: { development: string; staging: string; production: string }
    }>((next, number) => ({ ...next, ...handleWorkflows(number) }), {}),
    // all API URL's for development
    development: handleUrls(apiGatewayDevelopment),
    // all API URL's for staging
    staging: handleUrls(apiGatewayStaging),
    // all API URL's for production
    production: handleUrls(apiGatewayProduction)
}
