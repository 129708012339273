import styled from 'styled-components'

import { Maybe } from '../../../types/GQLTypes'
import { MUIComponents } from '../../muiComponents'

export const InfoFields = ({
    fields = [],
    title
}: {
    fields: { label: string; value: Maybe<string> | undefined }[]
    title?: string
}) => {
    const getValue = (value: Maybe<string> | undefined) => {
        if (value) return value

        return '/'
    }

    return (
        <Main>
            {title && (
                <MUIComponents.Typography sx={titleStyling}>{title}</MUIComponents.Typography>
            )}
            <MUIComponents.Styling.Divider />
            {fields.map((field, index) => (
                <div key={index}>
                    <Wrapper>
                        <Label isValue={false}>{field.label}</Label>
                        <Label isValue>{getValue(field.value)}</Label>
                    </Wrapper>
                    <MUIComponents.Styling.Divider />
                </div>
            ))}
        </Main>
    )
}

const Main = styled.div`
    margin-bottom: 15px;
    width: 100%;
`

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
`

const Label = styled.div<{ isValue: boolean }>`
    display: flex;
    align-self: center;
    width: 80%;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: ${(props) => (props.isValue ? 'right' : 'left')};
`

const titleStyling = {
    boxSizing: 'border-box',
    lineHeight: '40px',
    listStyle: 'none',
    color: 'rgba(0, 0, 0, 0.6)',
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: 500,
    fontSize: '0.875rem',
    paddingLeft: '16px',
    paddingRight: '16px',
    position: 'sticky',
    top: 0,
    zIndex: 1,
    backgroundColor: '#fff'
}
