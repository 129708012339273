import { MUIComponents } from '../../muiComponents'

export const BackgroundImage = () => (
    <MUIComponents.Styling.Grid
        item
        component={MUIComponents.Styling.Paper}
        xs={2}
        elevation={6}
        sx={{
            maxHeight: '75%',
            padding: 'unset !important',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            backgroundImage: "url('/img/login-design.png')",
            backgroundSize: 'cover',
            minHeight: '550px'
        }}
    />
)
