import MuiDialog, { DialogProps as MuiDialogProps } from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { CSSProperties } from 'styled-components'

import { MUIComponents } from '.'
import useDeviceDetection from '../../hooks/useDeviceDetection'

interface DialogProps extends MuiDialogProps {
    // you can declare custom props in here
    cancelClick?: React.MouseEventHandler<HTMLButtonElement>
    confirmClick?: React.MouseEventHandler<HTMLButtonElement>
    title?: string
    content?: string
    cancelButtonText?: string
    confirmButtonText?: string
    contentComponent?: JSX.Element
    actionsContent?: JSX.Element
    contentStyle?: CSSProperties
    actionsStyle?: CSSProperties
}

// TODO: made prop title optional
// TODO: updated responsive layout with the hook useDeviceDetection
// TODO: updated DialogContent sx prop
export const Dialog = ({
    open,
    onClose,
    cancelClick,
    confirmClick,
    title,
    content,
    cancelButtonText,
    confirmButtonText,
    fullScreen,
    contentStyle = {},
    actionsStyle = {},
    contentComponent,
    actionsContent,
    children,
    ...props
}: DialogProps) => {
    const theme = useTheme()
    const fullScreenResponsive = fullScreen ? useMediaQuery(theme.breakpoints.down('md')) : false
    const [devices] = useDeviceDetection()

    const dialogActions = () => {
        if (actionsContent) return actionsContent

        if (!cancelButtonText && !confirmButtonText) return undefined

        return (
            <>
                {cancelButtonText && (
                    <MUIComponents.Buttons.Button onClick={cancelClick}>
                        {cancelButtonText}
                    </MUIComponents.Buttons.Button>
                )}

                {confirmButtonText && (
                    <MUIComponents.Buttons.Button onClick={confirmClick}>
                        {confirmButtonText}
                    </MUIComponents.Buttons.Button>
                )}
            </>
        )
    }

    const checkForSmallScreen = devices.isMob || devices.isTablet || devices.isSmallerThanMob

    return (
        <MuiDialog
            fullScreen={fullScreenResponsive}
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            {...props}
            {...(checkForSmallScreen && {
                sx: {
                    '& .MuiDialog-paper': {
                        width: '100%',
                        height: '100%',
                        margin: 'unset',
                        maxHeight: 'unset',
                        maxWidth: 'unset'
                    }
                }
            })}
        >
            {children ? (
                children
            ) : (
                <>
                    {title && (
                        <DialogTitle
                            id="alert-dialog-title"
                            sx={{ fontFamily: contentStyle?.['fontFamily'] }}
                        >
                            {title}
                        </DialogTitle>
                    )}
                    <DialogContent
                        sx={{
                            ...contentStyle,
                            ...(checkForSmallScreen && {
                                overflowX: 'hidden',
                                overflowY: 'auto'
                            })
                        }}
                    >
                        {contentComponent}
                        {content && (
                            <DialogContentText id="alert-dialog-description">
                                {content}
                            </DialogContentText>
                        )}
                    </DialogContent>
                    <DialogActions sx={actionsStyle}>{dialogActions()}</DialogActions>
                </>
            )}
        </MuiDialog>
    )
}

// Example of this component with props:
{
    /* <MUIComponents.Dialog
                open={passwordForgotDialogVisible}
                actionsContent={forgotPasswordActionsContent}
                onClose={() => {
                    setPasswordForgotDialogVisible(false)
                }}
                title="E-mail adres om de email voor je wachtwoord te veranderen te laten verzenden"
                contentComponent={
                    <MUIComponents.TextFields.TextField
                        id="email"
                        label="E-mailadres"
                        name="email"
                        autoFocus={false}
                        onBlur={(e) => {
                            const isEmailValid = validateEmail(e.target.value)

                            if (!isEmailValid) {
                                setPasswordEmailError({ error: true, valid: isEmailValid })
                            } else {
                                setPasswordEmailError({
                                    error: false,
                                    valid: isEmailValid
                                })
                                setPasswordEmail(e.target.value)
                            }
                        }}
                        error={passwordEmailError.error}
                        {...(passwordEmailError.error && {
                            helperText: !passwordEmailError.valid
                                ? 'Het ingevulde E-mailadres is geen geldig E-mailadres.'
                                : 'Het invullen van uw E-mailadres is verplicht.'
                        })}
                    />
                }
                fullScreen
            /> */
}
