import { gql } from '@apollo/client'

/**
 * queries.js
 * Exports all GraphQL queries used by the app.
 * Since we use the ApolloClient React lib, most of these
 * are gql objects.
 */

export const Queries = {
    DEALERS: gql`
        query getDealers {
            dealers(page: 0, size: 999) {
                id
                name
                street
                zip_code
                city
                tel
                email
                language
                active
                latitude
                longitude
                bikeBrands {
                    id
                    code
                    name
                }
            }
        }
    `,
    BIKE_BRANDS: gql`
        query getBikeBrands {
            bikeBrands(page: 0, size: 999) {
                id
                code
                name
            }
        }
    `,
    BIKE_TYPES: gql`
        query getBikeTypes {
            bikeTypes {
                id
                code
                name
            }
        }
    `,
    COMPANY_DRIVE_INFO_BY_EMPLOYEE_ID: gql`
        query getEmployee($id: ID!) {
            employee(id: $id) {
                employeeStatute {
                    id
                    code
                    name
                }
                company {
                    id
                    calculation_sheet
                    folder_id
                    name
                }
                id
                first_name
                last_name
                name
            }
        }
    `,
    EMPLOYEE: gql`
        query getEmployee($id: ID!) {
            employee(id: $id) {
                id
                employee_title_fk
                language_fk
                employee_statute_fk
                company_fk
                employee_number
                first_name
                last_name
                email
                tel
                date_of_birth
                street
                house_number
                zip_code
                city
                drivers_license_date
                national_register
                custom_properties
                company {
                    id
                    info
                    name
                    logo_url
                    custom_employee_properties
                    employee_required_fields
                    employeeStatutes {
                        id
                        code
                        name
                    }
                }
            }
        }
    `,
    EMPLOYEE_QUOTATIONS_AND_ORDER_DETAIL_CONTRACT: gql`
        query getQuotation($id: ID!, $page: Int, $size: Int, $filter: OrderLogFilter) {
            quotation(id: $id) {
                quotation_fields {
                    value
                    field_fk
                    field {
                        id
                        name
                        index
                        field_type {
                            id
                            name
                            code
                            dataType {
                                id
                                name
                                code
                            }
                        }
                        field_values {
                            id
                            value
                        }
                    }
                }
                order {
                    id
                    orderStatus {
                        id
                        name
                        code
                        index
                    }
                    dealStatus {
                        id
                        name
                    }
                    bike {
                        id
                        model
                        license_number
                        frame_number
                        sticker_number
                        accu_number
                        control_number
                        bikeType {
                            id
                            name
                            code
                        }
                        bikeBrand {
                            id
                            name
                        }
                    }
                    orderDeal {
                        id
                        custom_fields {
                            value
                            definition {
                                id
                                label
                            }
                        }
                    }
                    confirmed_order {
                        id
                        order_fk
                        cancelled
                        cancelled_reason
                    }
                }
            }
            orderStatuses(size: $size, page: $page) {
                id
                name
                code
                index
            }
            orderLogs(size: $size, page: $page, filter: $filter) {
                id
                file_id
                description
                hidden
            }
        }
    `,
    EMPLOYEE_QUOTATIONS_AND_ORDER: gql`
        query getEmployee($id: ID!) {
            employee(id: $id) {
                employee_quotations {
                    id
                    hidden
                    quotation_number
                    quotation_date
                    lease_amount
                    lease_amount_vat
                    salary_swap
                    insurance_amount
                    maintenance_amount
                    assistance_amount
                    warranty_grant
                    total_vat_included
                    total_vat_excluded
                    residual_value
                    dealer_fk
                    earliest_delivery_date
                    bikeType {
                        name
                    }
                    bikeBrand {
                        name
                    }
                    dealer {
                        id
                        name
                    }
                    quotation_fields {
                        value
                        field {
                            name
                            field_type {
                                code
                                name
                            }
                        }
                    }
                    order {
                        id
                        orderStatus {
                            id
                            name
                            code
                            index
                        }
                        order_status_fk
                        dealStatus {
                            id
                            name
                        }
                        orderDeal {
                            id
                            custom_fields {
                                value
                                definition {
                                    id
                                    label
                                }
                            }
                        }
                        bike {
                            id
                            model
                            bike_brand_fk
                            bikeBrand {
                                id
                                name
                            }
                            bike_type_fk
                            bikeType {
                                id
                                name
                            }
                        }
                    }
                }
            }
        }
    `,
    EMPLOYEE_QUOTATIONS_AND_ORDER_WITHOUT_DEAL: gql`
    query getEmployee($id: ID!) {
        employee(id: $id) {
            employee_quotations {
                id
                hidden
                quotation_number
                quotation_date
                lease_amount
                lease_amount_vat
                salary_swap
                insurance_amount
                maintenance_amount
                assistance_amount
                warranty_grant
                total_vat_included
                total_vat_excluded
                residual_value
                dealer_fk
                earliest_delivery_date
                bikeType {
                    name
                }
                bikeBrand {
                    name
                }
                dealer {
                    id
                    name
                }
                quotation_fields {
                    value
                    field {
                        name
                        field_type {
                            code
                            name
                        }
                    }
                }
                order {
                    id
                    orderStatus {
                        id
                        name
                        code
                        index
                    }
                    order_status_fk
                    dealStatus {
                        id
                        name
                    }
                    bike {
                        id
                        model
                        bike_brand_fk
                        bikeBrand {
                            id
                            name
                        }
                        bike_type_fk
                        bikeType {
                            id
                            name
                        }
                    }
                }
            }
        }
    }
`,
    EMPLOYEE_TITLES: gql`
        query getEmployeeTitles {
            employeeTitles {
                id
                name
                code
            }
        }
    `,
    LANGUAGES: gql`
        query getLanguages {
            languages {
                Id
                name
                code
            }
        }
    `,
    FIELDS: gql`
        query getFields($filter: FieldFilter!) {
            fields(size: 100, filter: $filter) {
                id
                name
                description
                index
                field_type {
                    id
                    name
                    code
                    dataType {
                        id
                        name
                        code
                    }
                }
                field_values {
                    id
                    value
                }
            }
        }
    `,
    SERVICE_PASS_DETAIL: gql`
        query getEmployee($id: ID!) {
            employee(id: $id) {
                employee_quotations {
                    id
                    quotation_fields {
                        id
                        value
                        field {
                            field_type {
                                code
                            }
                        }
                    }
                    order {
                        orderStatus {
                            code
                            index
                        }
                        orderDeal {
                            custom_fields {
                                value
                                definition {
                                    label
                                }
                            }
                        }
                        bike {
                            service_passes {
                                id
                                barcode
                                status
                                cardInformation {
                                    Balance
                                    BalanceFactor
                                    Currency
                                    IssueTime
                                    Status
                                }
                                cardActivationDetails {
                                    Type
                                    Value
                                }
                                cardTransactions {
                                    Retailer {
                                        Name
                                    }
                                    TransactionHeader {
                                        TransactionTime
                                    }
                                    TransactionId
                                    Type
                                    Value
                                }
                            }
                        }
                    }
                }
            }
        }
    `,
    END_OF_LEASE_CHOICES: gql`
        query getEndOfLeaseChoices {
            endOfLeaseChoices {
                id
                name
                code
            }
        }
    `,
    EMPLOYEE_END_OF_LEASE: gql`
        query getEndOfLeaseForEmployee($id: ID!) {
            employee(id: $id) {
                id
                employee_quotations {
                    id
                    order {
                        id
                        endOfLease {
                            id
                            order_fk
                            end_of_lease_choice_fk
                            completed
                        }
                    }
                }
            }
        }
    `,
    EMPLOYEE_COMPANY_FK: gql`
        query getEmployee($id: ID!) {
            employee(id: $id) {
                id
                company_fk
            }
        }
    `,
    EMPLOYEE_ACCESS_CODE: gql`
        query getEmployee($id: ID!) {
            employee(id: $id) {
                id
                employee_access_code
            }
        }
    `,
    EMPLOYEE_COMPANY_FIELDS: gql`
        query getEmployee($id: ID!) {
            employee(id: $id) {
                id
                employeeStatute {
                    id
                    name
                }
                company {
                    id
                    parentCompany {
                        id
                        name
                        calculation_sheet
                        folder_id
                    }
                    calculation_sheet
                    folder_id
                    name
                    companyFields {
                        id
                        name
                        description
                        index
                        field_type {
                            id
                            name
                            code
                            is_multivalue
                            dataType {
                                id
                                name
                                code
                            }
                        }
                        field_values {
                            id
                            value
                        }
                    }
                }
            }
        }
    `,
    ORDER_LOGS_BY_ORDER: gql`
        query getOrderLogs($page: Int, $size: Int, $filter: OrderLogFilter) {
            orderLogs(size: $size, page: $page, filter: $filter) {
                id
                file_id
                description
                hidden
                orderLogType {
                    id
                    name
                    code
                }
            }
        }
    `,
    ORDERS_BY_EMPLOYEE: gql`
        query getOrdersByEmployeeId($id: ID!) {
            employee(id: $id) {
                id
                company {
                    name
                }
                employee_quotations {
                    id
                    order {
                        id
                        date
                        deal_status_fk
                        deal_number
                        deal_id
                        orderStatus {
                            id
                            name
                        }
                        bike {
                            id
                            bikeBrand {
                                id
                                name
                            }
                            license_number
                            frame_number
                            sticker_number
                            service_passes {
                                id
                                barcode
                                cardInformation {
                                    Balance
                                    BalanceFactor
                                    Currency
                                    IssueTime
                                    Status
                                }
                                cardActivationDetails {
                                    Type
                                    Value
                                }
                            }
                        }
                    }
                }
            }
        }
    `,
    TL_DEAL: gql`
        query getTLDeal($id: ID!) {
            deal(id: $id) {
                id
                title
                reference
                status
                custom_fields {
                    value
                    definition {
                        id
                        label
                    }
                }
                department {
                    id
                    name
                }
                current_phase {
                    id
                    name
                }
                quotations {
                    id
                    grouped_lines {
                        line_items {
                            product {
                                id
                                type
                            }
                            quantity
                            description
                            extended_description
                            unit_price {
                                amount
                            }
                        }
                    }
                }
            }
        }
    `,
    CITIES: gql`
        query getCities($page: Int, $size: Int, $term: String) {
            cities(size: $size, page: $page, term: $term) {
                id
                lat
                long
                zip_code
                city_name_nl
                city_name_fr
                city_name_de
            }
        }
    `,
}
