import { Document, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer'
import { useTranslation } from 'react-i18next'

import { Item, SimulatorValues } from '../../../../types'
import { stringDefaulter } from '../../../../utils/utils'

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: '#E4E4E4'
    },
    section: {
        margin: 1,
        padding: 1,
        flexGrow: 1
    },
    mainSection: {
        padding: 1,
        flexGrow: 1,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center'
    },
    mainTitle: {
        fontSize: 20,
        margin: 2,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center'
    },
    text: {
        fontSize: 10,
        margin: 2,
        marginLeft: 10
    },
    shortText: {
        fontSize: 12,
        margin: 2
    },
    leaseAmount: {
        margin: 5,
        color: 'red',
        fontWeight: 'ultrabold',
        fontSize: '14px'
    },
    rowText: {
        fontSize: 10,
        margin: 5
    },
    rowWithBorder: {
        flexDirection: 'row',
        border: '1px solid black',
        borderRadius: 10,
        margin: '2 25',
        display: 'flex',
        justifyContent: 'space-between'
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between'
    },
    contentView: {
        border: '1px solid black',
        borderRadius: 25,
        margin: 10,
        marginRight: 25,
        marginLeft: 25
    },
    headerView: {
        marginTop: 10,
        marginRight: 25,
        marginLeft: 25
    },
    headerImg: {
        width: '50%',
        display: 'flex',
        margin: '0 auto'
    },
    imgView: {
        border: '1px solid rgb(0,0,0,0.1) ',
        borderRadius: 10
    }
})

export const PDFDocument = (props: {
    simulatorValues: SimulatorValues
    calcSheetData: (number | Item | undefined)[]
    calcSheetDataLease: (number | Item | undefined)[]
}) => {
    const { t } = useTranslation()

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.headerView}>
                    <View style={styles.imgView}>
                        <Image style={styles.headerImg} src="/img/Logo-B2BIKE.png" />
                    </View>
                    <View style={styles.mainTitle}>
                        <Text>{t('simulation.pdf.prefixMainTitle')}</Text>
                    </View>
                    <View style={styles.shortText}>
                        <Text>{t('simulation.pdf.shortExplanation')}</Text>
                    </View>
                </View>
                <View style={styles.contentView}>
                    <View style={styles.mainTitle}>
                        <Text>{t('simulation.pdf.title')}</Text>
                    </View>
                    <View style={styles.mainSection}>
                        <View style={styles.row}>
                            <View style={styles.section}>
                                <Text style={styles.text}>
                                    {t('simulation.pdf.brand') +
                                        ': ' +
                                        stringDefaulter(props.simulatorValues.bikeBrand?.label)}
                                </Text>
                            </View>
                        </View>
                        <View style={styles.row}>
                            <View style={styles.section}>
                                <Text style={styles.text}>
                                    {t('simulation.pdf.type') +
                                        ': ' +
                                        stringDefaulter(props.simulatorValues.bikeType?.label)}
                                </Text>
                            </View>
                        </View>
                        <View style={styles.row}>
                            <View style={styles.section}>
                                <Text style={styles.text}>
                                    {t('simulation.pdf.budget') +
                                        ': ' +
                                        stringDefaulter(
                                            props.simulatorValues.bikeBudget as number,
                                            'currencyFormat'
                                        )}
                                </Text>
                            </View>
                        </View>
                        <View style={styles.row}>
                            <View style={styles.section}>
                                <Text style={styles.text}>
                                    {t('simulation.pdf.accBudget') +
                                        ': ' +
                                        stringDefaulter(
                                            props.simulatorValues.accessoriesBudget as number,
                                            'currencyFormat'
                                        )}
                                </Text>
                            </View>
                        </View>
                        <View style={styles.row}>
                            <View style={styles.section}>
                                {props.simulatorValues.defaultOptions.map((option, i: number) => (
                                    <Text key={i} style={styles.text}>
                                        {option.name} : {stringDefaulter(option.value?.toString())}
                                    </Text>
                                ))}
                            </View>
                        </View>
                    </View>
                </View>

                <View style={styles.mainTitle}>
                    <Text>{t('simulation.pdf.prices')}</Text>
                </View>
                {props.calcSheetData.map((item, i) => (
                    <View
                        key={i}
                        style={{
                            ...styles.rowWithBorder,
                            backgroundColor: i % 2 === 0 ? 'rgba(0, 0, 0, 0.1)' : undefined
                        }}
                    >
                        <Text style={styles.rowText}>{(item as Item)?.label}</Text>
                        <Text style={styles.rowText}>{(item as Item)?.value}</Text>
                    </View>
                ))}
                {props.calcSheetDataLease.map((item, i) => (
                    <View
                        key={i}
                        style={{
                            ...styles.rowWithBorder,
                            backgroundColor: i % 2 === 0 ? 'rgba(0, 0, 0, 0.1)' : undefined,
                            marginTop: i === 0 ? '15px' : undefined
                        }}
                    >
                        <Text style={styles.leaseAmount}>{(item as Item)?.label}</Text>
                        <Text style={styles.leaseAmount}>{(item as Item)?.value}</Text>
                    </View>
                ))}
            </Page>
        </Document>
    )
}
