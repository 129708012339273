import { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import {
    ApolloClient,
    InMemoryCache,
    ApolloProvider,
    NormalizedCacheObject,
    HttpLink,
    ApolloLink,
    concat
} from '@apollo/client'
import {
    // browserSessionPersistence,
    browserLocalPersistence,
    getAuth,
    setPersistence,
    User
} from 'firebase/auth'
import { getDatabase, ref, child, get } from 'firebase/database'
import { useTranslation } from 'react-i18next'

import './App.css'
import { Home } from '../pages/home/Home'
import firebaseApp from '../../firebase'
import { myProfileState, userObjectState, userProfileState, alertObjState } from '../../store/store'
import { ConfirmationRegister } from '../pages/register/ConfirmationRegister'
import Containers from '../containers'
import { Login } from '../pages/login/Login'
import { Register } from '../pages/register/Register'
import { PasswordReset } from '../pages/password/PasswordReset'
import API from '../../API'
import { headers } from '../../utils/utils'
import { Files } from '../pages/files/Files'
import { Calculations } from '../pages/calculations/Calculations'
import { Dealer } from '../pages/dealer'
import { MyCalculation } from '../pages/calculations/MyCalculation'
import { BlogPosts } from '../pages/blogPosts/BlogPosts'
import { MUIComponents } from '../muiComponents'
import { MyProfile } from '../pages/myProfile/MyProfile'

function App() {
    const { i18n, t } = useTranslation();

    const [userProfile, setUserProfile] = useRecoilState(userProfileState)
    const [fbUserObject, setFbUserObject] = useRecoilState(userObjectState)
    const [, setAlertObject] = useRecoilState(alertObjState)
    const [idToken, setIdToken] = useState<any>(null)
    const [fbUser, setFbUser] = useState<User | null>(null)
    const [apolloClient, setApolloClient] = useState<
        ApolloClient<NormalizedCacheObject> | undefined
    >()
    const tokenRefreshTime = 1000 * 60 * 30 // half an hour
    const [openProfile, setOpenProfile] = useRecoilState(myProfileState)

    const httpLink = new HttpLink({
        uri: API.utils.gateway
    })

    const authMiddleware = new ApolloLink((operation, forward) => {
        // add the authorization to the headers
        operation.setContext({
            headers: {
                authorization: localStorage.getItem('token')
                    ? `Bearer ${localStorage.getItem('token')}`
                    : undefined,
                ...headers
            }
        })

        return forward(operation)
    })

    useEffect(() => {
        if (fbUser) {
            setInterval(async () => {
                const token = await fbUser.getIdToken(true)
                setIdToken(token)
            }, tokenRefreshTime) // 60000
        }
    }, [fbUser])

    useEffect(() => {
        const client = new ApolloClient({
            link: concat(authMiddleware, httpLink),
            cache: new InMemoryCache({
                addTypename: false
            })
        })
        setApolloClient(client)
    }, [])

    useEffect(() => {
        if (idToken) {
            localStorage.setItem('token', idToken)
        }
    }, [idToken])

    useEffect(() => {
        const init = async () => {
            // Handle Auth
            const auth = getAuth(firebaseApp)
            await setPersistence(auth, browserLocalPersistence)

            auth.onAuthStateChanged(async (user) => {
                if (user) {
                    if (!fbUser) {
                        setFbUser(user)
                        const token = await user.getIdToken(true)
                        setIdToken(token)
                        setFbUserObject({
                            uid: user.uid,
                            displayName: user.displayName
                        })
                    }
                } else {
                    setFbUserObject({})
                }
            })
        }

        init()
    }, [])

    // Listen to changes in the fbUserObject.
    // If it has a uid, we try to fetch the user's profile.
    // Otherwise we set it as an empty object.
    // If he has a profile we set it in the global state
    useEffect(() => {
        if (fbUserObject?.uid) {
            const dbRef = ref(getDatabase(firebaseApp))
            get(child(dbRef, `profiles/employee-portal/${fbUserObject.uid}`))
                .then((snapshot) => {
                    if (snapshot.exists()) {
                        setUserProfile(snapshot.val())

                        //Lang handler
                        i18n.changeLanguage(snapshot?.val()?.language?.toLowerCase())
                    } else {
                        console.log('No user profile for uid')
                        setAlertObject({
                            message: t('app.fbNoProfileAlert'),
                            severity: 'error'
                        })
                    }
                })
                .catch((err) => {
                    console.error(err)
                    setUserProfile({})
                    setAlertObject({
                        message: t('app.fbUserAlert'), 
                        severity: 'error'
                    })
                })
        } else {
            setUserProfile({})
        }
    }, [fbUserObject])

    return (
        <>
            {apolloClient && (
                <ApolloProvider client={apolloClient as ApolloClient<any>}>
                    <BrowserRouter>
                        <>
                            <Containers.HeaderMenu
                                userData={{
                                    uid: fbUserObject?.uid as string,
                                    employee_id: userProfile?.employee_id as string
                                }}
                            />
                            <Routes>
                                <Route
                                    path="/"
                                    element={
                                        <Containers.PrivateRoute>
                                            <Home />
                                        </Containers.PrivateRoute>
                                    }
                                />
                                <Route
                                    path="/home"
                                    element={
                                        <Containers.PrivateRoute>
                                            <Home />
                                        </Containers.PrivateRoute>
                                    }
                                />
                                <Route
                                    path="/dossiers"
                                    element={
                                        <Containers.PrivateRoute>
                                            <Files />
                                        </Containers.PrivateRoute>
                                    }
                                />
                                <Route
                                    path="/calculations"
                                    element={
                                        <Containers.PrivateRoute>
                                            <Calculations />
                                        </Containers.PrivateRoute>
                                    }
                                />
                                <Route
                                    path="/blogpost/:blogId"
                                    element={
                                        <Containers.PrivateRoute>
                                            <BlogPosts />
                                        </Containers.PrivateRoute>
                                    }
                                />
                                <Route
                                    path="/dealer"
                                    element={
                                        <Containers.PrivateRoute>
                                            <Dealer />
                                        </Containers.PrivateRoute>
                                    }
                                />
                                <Route
                                    path="/simulator"
                                    element={
                                        <Containers.PrivateRoute>
                                            <MyCalculation />
                                        </Containers.PrivateRoute>
                                    }
                                />
                                <Route path="/login" element={<Login />} />
                                <Route path="/register" element={<Register />} />
                                <Route
                                    path="/confirmationRegister"
                                    element={<ConfirmationRegister />}
                                />
                                <Route path="/passwordReset" element={<PasswordReset />} />
                                <Route
                                    path="*"
                                    element={
                                        <Containers.PrivateRoute>
                                            <Home />
                                        </Containers.PrivateRoute>
                                    }
                                />
                            </Routes>

                            <MUIComponents.Dialog
                                onClose={() => {
                                    setOpenProfile(false)
                                }}
                                open={openProfile}
                                contentComponent={
                                    <MyProfile
                                        onClose={() => {
                                            setOpenProfile(false)
                                        }}
                                    />
                                }
                                fullWidth
                                maxWidth="md"
                            />
                        </>
                    </BrowserRouter>
                </ApolloProvider>
            )}
        </>
    )
}

export default App
