import MuiCheckbox, { CheckboxProps as MuiCheckboxProps } from '@mui/material/Checkbox'
import styled from 'styled-components'
import FormControlLabel from '@mui/material/FormControlLabel'

import { genericMaxInputWidth, noop } from '../../utils/utils'
import { Typography } from './Typography'

interface CheckboxProps extends MuiCheckboxProps {
    // you can declare custom props in here
    colorToOverwrite?: string
}

const StyledCheckbox = styled(MuiCheckbox)<CheckboxProps>`
    color: ${({ colorToOverwrite, color, ...props }) =>
        color ?? colorToOverwrite ?? props.theme.palette.primary.main} !important;
`

export const Checkbox = ({
    // assign default values here
    checked = false,
    onChange = noop,
    disabled = false,
    disableRipple = false,
    required = false,
    size = 'small',
    colorToOverwrite = undefined,
    ...props
}: CheckboxProps) => (
    <StyledCheckbox
        disableRipple={disableRipple}
        disabled={disabled}
        checked={checked}
        onChange={onChange}
        required={required}
        size={size}
        colorToOverwrite={colorToOverwrite}
        {...props}
        sx={{ ...props.sx, maxWidth: genericMaxInputWidth }}
    />
)

export const CheckboxWithLabel = ({
    // assign default values here
    label = '',
    checked = false,
    onChange = noop,
    disabled = false,
    disableRipple = false,
    required = false,
    size = 'small',
    colorToOverwrite = undefined,
    ...props
}: CheckboxProps & { label: string }) => (
    <FormControlLabel
        control={
            <StyledCheckbox
                disableRipple={disableRipple}
                disabled={disabled}
                checked={checked}
                onChange={onChange}
                required={required}
                size={size}
                colorToOverwrite={colorToOverwrite}
                {...props}
                sx={{ ...props.sx, maxWidth: genericMaxInputWidth }}
            />
        }
        label={
            <Typography
                sx={{
                    overflow: 'hidden',
                    display: '-webkit-box',
                    '-webkit-line-clamp': '2',
                    '-webkit-box-orient': 'vertical',
                    color: 'rgba(0, 0, 0, 0.87)',
                    fontSize: 14,
                    ...props.sx
                }}
            >
                {label}
            </Typography>
        }
    />
)

// Example of this component with props:
{
    /* <MUIComponents.Checkbox
                                        indeterminate={
                                            selected.length > 0 &&
                                            selected.length < props.rows.length
                                        }
                                        checked={
                                            props.rows.length > 0 &&
                                            selected.length === props.rows.length
                                        }
                                        onChange={handleSelectAll}
                                        inputProps={{
                                            'aria-label': 'select all desserts'
                                        }}
                                        colorToOverwrite="white"
                                    /> */
}

{
    /* <MUIComponents.CheckboxWithLabel
                                        indeterminate={
                                            selected.length > 0 &&
                                            selected.length < props.rows.length
                                        }
                                        checked={
                                            props.rows.length > 0 &&
                                            selected.length === props.rows.length
                                        }
                                        onChange={handleSelectAll}
                                        inputProps={{
                                            'aria-label': 'select all desserts'
                                        }}
                                        colorToOverwrite="white"
                                        label="Test"
                                    /> */
}
