import { createTheme } from '@mui/material/styles'

export interface CustomTheme {
    bg: {
        main: string
        light: string
    }
    text: {
        main: string
        light: string
    }
}

const theme = createTheme({
    palette: {
        primary: {
            main: '#95c11f',
            contrastText: '#fff'
        },
        secondary: {
            main: '#2e3c09',
            contrastText: '#fff'
        },
        warning: {
            main: '#ffa800',
            contrastText: '#fff'
        },
        error: {
            main: '#ff502e',
            contrastText: '#fff'
        },
        divider: '#575756',
        success: {
            main: '#95c11f',
            contrastText: '#fff'
        },
        info: {
            main: '#95c11f',
            contrastText: '#fff'
        }
    },
    bg: {
        main: '#fff',
        light: '#F4F5F7'
    },
    text: {
        main: '#172B4D',
        light: '#262930'
    }
})

export default theme
