import styled, { css, CSSProperties } from 'styled-components'

import { Device } from '../../types'

const ItemNavigation = styled.p<{
    padding?: boolean
    noMargin?: boolean
    justifyContent?: CSSProperties['justifyContent']
    sx?: CSSProperties
}>`
    text-decoration: underline solid 1px rgb(128, 128, 128);
    text-underline-offset: 5px;
    color: rgb(128, 128, 128);
    cursor: pointer;
    padding: ${(props) => (props.padding ? '0 20px' : undefined)};
    display: flex;
    justify-content: ${(props) => props.justifyContent};
    width: fit-content;

    ${(props) =>
        props.noMargin &&
        css`
            margin: 0;
            margin-left: auto;
        `}

    ${(props) =>
        props.sx &&
        css`
            ${{ ...props.sx }}
        `}
`

const Image = styled.img`
    background-size: cover;
    background-position: center;
    height: 100%;
    width: 100%;
`

const Wrapper = styled.div`
    margin: 0 auto;
`

const SelectsWrapper = styled.div<{ isSmallerThanMob?: Device }>`
    display: flex;
    flex-direction: row;
    padding: 10px;
    justify-content: space-around;

    ${(props) =>
        props.isSmallerThanMob &&
        css`
            flex-direction: column;
        `}
`

const TruncatedText = css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

const BreakupText = css`
    word-wrap: break-word;
    word-break: break-word;
    hyphens: auto;
`

const truncatedText: CSSProperties = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
}

const breakupText: CSSProperties = {
    wordWrap: 'break-word',
    wordBreak: 'break-word',
    hyphens: 'auto'
}

const ButtonText = styled.p`
    margin: unset;

    ${BreakupText}
`

export default {
    ItemNavigation,
    Image,
    Wrapper,
    SelectsWrapper,
    ButtonText,
    TruncatedText,
    BreakupText,
    helper: { truncatedText, breakupText }
}
