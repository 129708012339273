import {
    ApolloCache,
    DefaultContext,
    FetchResult,
    LazyQueryExecFunction,
    MutationFunctionOptions
} from '@apollo/client'
import React from 'react'
import { SetterOrUpdater } from 'recoil'

import { AlertObjectProps, CustomProp, TranslatorFunction } from '../../../types'
import {
    Company,
    Employee,
    EmployeeTitle,
    Language,
    Maybe,
    MutationUpdateEmployeeArgs,
    QueryEmployeeArgs,
    QueryEmployeeTitlesArgs,
    QueryLanguagesArgs
} from '../../../types/GQLTypes'

export const submitEmployee = async ({
    updateEmployee,
    employeeData,
    setOpenNotification,
    t
}: HelperProps) => {
    try {
        const employeeUpdateObject = { ...employeeData }
        employeeUpdateObject.date_of_birth = employeeUpdateObject?.date_of_birth
            ? new Date(Number(employeeUpdateObject.date_of_birth)).toISOString()
            : null
        employeeUpdateObject.drivers_license_date = employeeUpdateObject?.drivers_license_date
            ? new Date(Number(employeeUpdateObject.drivers_license_date)).toISOString()
            : null

        // ! These fields we do not want to update, these are disabled too in the UI
        // ! -> therefore we are going to delete then
        // ! the update will only take values that are filled in for the employee -> deleting these -> is no problemo!
        delete employeeUpdateObject.company_fk
        delete employeeUpdateObject.email

        // ! When the employee_title is not filled in, it's value is null
        // ! We are going to delete this field when this is happening ->
        // ! otherwise the GRAPHQL API gives an error around the employee_title_fk (because it can't find it)
        // ! Added an extra check for when the value is just an empty string
        if (
            employeeUpdateObject.employee_title_fk === null ||
            employeeUpdateObject.employee_title_fk?.length === 0
        ) {
            delete employeeUpdateObject.employee_title_fk
        }

        await updateEmployee?.({
            variables: {
                employeeToUpdate: employeeUpdateObject
            }
        })
        return 'ok'
    } catch (err) {
        setOpenNotification?.({
            message: t('myProfile.errors.saveEmployee'),
            severity: 'error'
        })
        return 'fail'
    }
}

export const handleCollectEmployee = async ({
    employeeId,
    collectEmployee,
    setOriginalEmployeeData,
    setEmployeeData,
    setCompanyData,
    setDisabled,
    setOpenNotification,
    t
}: HelperProps) => {
    try {
        const employeeData = await collectEmployee?.({
            variables: {
                id: employeeId ?? ''
            }
        })

        //Collect & remove company from the employee's object (mutation purposes)
        const company = { ...employeeData?.data?.employee?.company }
        if (employeeData?.data?.employee && (!company || !company.name)) {
            setDisabled?.(true)
            setOpenNotification?.({
                message: t('myProfile.errors.getCompany'),
                severity: 'error'
            })
            return
        }
        setCompanyData?.({ ...company })

        const newEmployeeData = { ...employeeData?.data?.employee }
        delete newEmployeeData.company
        setOriginalEmployeeData?.(newEmployeeData)
        setEmployeeData?.(newEmployeeData)

        return { ...employeeData?.data?.employee, company: { ...company } }
    } catch (err) {
        console.log('Error', err)
        setDisabled?.(true)
        setOpenNotification?.({
            message: t('myProfile.errors.getEmployee'),
            severity: 'error'
        })
        return
    }
}

export const handleCollectEmployeeTitles = async ({
    collectEmployeeTitles,
    setEmployeeTitles,
    setDisabled,
    setOpenNotification,
    t
}: HelperProps) => {
    try {
        const employeeTitlesData = await collectEmployeeTitles?.()
        setEmployeeTitles?.(employeeTitlesData?.data?.employeeTitles ?? [])
    } catch (err) {
        setDisabled?.(true)
        setOpenNotification?.({
            message: t('myProfile.errors.getEmployeeTitles'),
            severity: 'error'
        })
    }
}

export const handleCustomPropsJsonStorage = (
    employeesCustomJson: string,
    propertyName: string,
    value: string
) => {
    const obj = JSON.parse(employeesCustomJson)
    obj[propertyName] = value

    return JSON.stringify(obj)
}

export const getCustomPropValue = (employeesCustomJson: string, propertyName: string) => {
    const obj = JSON.parse(employeesCustomJson)

    return obj[propertyName] ?? ''
}

export const pushRequiredCustomProps = ({
    companyData,
    requiredFields,
    setRequiredFields,
    setRequiredCustomPropFields
}: HelperProps) => {
    const requiredProps: CustomProp[] = companyData?.custom_employee_properties
        ? JSON.parse(companyData?.custom_employee_properties)
        : []
    const companyRequiredFields: string[] = companyData?.employee_required_fields
        ? JSON.parse(companyData?.employee_required_fields)
        : []

    //Setting all the required fields
    const currentRequiredFields = [...(requiredFields ?? [])]
    currentRequiredFields.push(...companyRequiredFields)
    setRequiredFields?.([...Array.from(new Set(currentRequiredFields))])

    //Setting the required custom property fields
    const requiredPropsStringArr: Array<string> = requiredProps.map((customProp) => {
        if (customProp?.required && customProp?.name && customProp?.name !== '') {
            return customProp?.name
        }
        return ''
    })
    setRequiredCustomPropFields?.([...Array.from(new Set(requiredPropsStringArr))])
}

export const validateRequiredFields = ({
    employeeData,
    requiredFields,
    requiredCustomPropFields,
    setOpenNotification,
    t
}: HelperProps) => {
    //Main props
    for (const field of requiredFields ?? []) {
        if (
            !(field in (employeeData ?? {})) ||
            !employeeData?.[field as keyof Employee] ||
            employeeData?.[field as keyof Employee] === ''
        ) {
            setOpenNotification?.({
                message: `${t('myProfile.errors.validateRequiredFields')} ${
                    employeeFieldMapping(t)[field]
                }.`,
                severity: 'warning'
            })
            return false
        }
    }

    //Custom props
    const customPropValues = employeeData?.custom_properties
        ? JSON.parse(employeeData?.custom_properties)
        : {}

    for (const field of requiredCustomPropFields ?? []) {
        if (
            !(field in customPropValues) ||
            !customPropValues[field] ||
            customPropValues[field] === ''
        ) {
            setOpenNotification?.({
                message: `${t('myProfile.errors.validateCustomRequieredFields')} ${
                    employeeFieldMapping(t)[field]
                }.`,
                severity: 'warning'
            })
            return false
        }
    }

    return true
}

interface HelperProps {
    updateEmployee?: (
        options?:
            | MutationFunctionOptions<
                  {
                      id: Employee['id']
                  },
                  MutationUpdateEmployeeArgs,
                  DefaultContext,
                  ApolloCache<unknown>
              >
            | undefined
    ) => Promise<
        FetchResult<
            { id: Maybe<string> | undefined },
            Record<string, unknown>,
            Record<string, unknown>
        >
    >
    employeeData?: Employee
    employeeId?: Employee['id']
    collectEmployee?: LazyQueryExecFunction<
        {
            employee: Employee
        },
        QueryEmployeeArgs
    >
    setOriginalEmployeeData?: React.Dispatch<React.SetStateAction<Employee>>
    setEmployeeData?: React.Dispatch<React.SetStateAction<Employee>>
    setCompanyData?: React.Dispatch<React.SetStateAction<Company>>
    collectEmployeeTitles?: LazyQueryExecFunction<
        {
            employeeTitles: EmployeeTitle[]
        },
        QueryEmployeeTitlesArgs
    >
    setEmployeeTitles?: React.Dispatch<React.SetStateAction<EmployeeTitle[]>>
    collectLanguages?: LazyQueryExecFunction<
        {
            languages: Language[]
        },
        QueryLanguagesArgs
    >
    setLanguages?: React.Dispatch<React.SetStateAction<Language[]>>
    setDisabled?: React.Dispatch<React.SetStateAction<boolean>>
    companyData?: Company
    requiredFields?: Array<string>
    setRequiredFields?: React.Dispatch<React.SetStateAction<Array<string>>>
    setRequiredCustomPropFields?: React.Dispatch<React.SetStateAction<Array<string>>>
    requiredCustomPropFields?: Array<string>
    setOpenNotification?: SetterOrUpdater<AlertObjectProps>
    t: TranslatorFunction
}

const employeeFieldMapping: (t: TranslatorFunction) => { [key: string]: string } = (t) => ({
    address: t('myProfile.addressInput'),
    city: t('myProfile.cityInput'),
    date_of_birth: t('myProfile.birthdayInput'),
    drivers_license_date: t('myProfile.licenseDate'),
    employeeStatute: t('myProfile.statuteInput'),
    employeeTitle: t('myProfile.designation'),
    email: t('myProfile.mailInput'),
    employee_number: t('myProfile.employeeNrInput'),
    first_name: t('myProfile.FirstNameInput'),
    house_number: t('myProfile.houseNrInput'),
    language: t('myProfile.languageInput'),
    language_fk: t('myProfile.languageInput'),
    company_fk: t('myProfile.companyInput'),
    company: t('myProfile.companyInput'),
    employee_statute_fk: t('myProfile.statuteInput'),
    employee_title_fk: t('myProfile.designation'),
    last_name: t('myProfile.lastNameInput'),
    name: t('myProfile.nameInput'),
    national_register: t('myProfile.nationalIdentificationInput'),
    street: t('myProfile.streetInput'),
    tel: t('myProfile.TelephoneInput')
})
