import { useLazyQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import API from '../../../API'
import { MUIComponents } from '../../muiComponents'
import * as GQLTypes from '../../../types/GQLTypes'
import { calculateDateByDuration } from '../../../utils/utils'
import { tabProps } from '../../../utils/utilsWithJSX'
import { alertObjState, userProfileState } from '../../../store/store'
import { IService, Template, TemplateProps } from './Template'
import theme from '../../../theme/theme'
import mappingServices from './mappingServices.json'
import constants from '../../../constants'
import StyledComponents from '../../StyledComponents'
import { DeviceParameters } from '../../../types'

const iconStyling = { color: theme.palette.primary.main, marginBottom: '10px' }

const serviceTabs: {
    id: string
    description: TemplateProps['activeTemplate']
    hidden: boolean
    icon: JSX.Element
}[] = [
    {
        id: 'service_1',
        description: 'Onderhoud',
        hidden: false,
        icon: <MUIComponents.Icons.HandymanIcon sx={iconStyling} />
    },
    {
        id: 'service_2',
        description: 'Verzekering',
        hidden: false,
        icon: <MUIComponents.Icons.HealthAndSafetyIcon sx={iconStyling} />
    },
    {
        id: 'service_3',
        description: 'Pechhulp',
        hidden: false,
        icon: <MUIComponents.Icons.CarCrashIcon sx={iconStyling} />
    },
    {
        id: 'service_4',
        description: 'Garantie E-bike',
        hidden: false,
        icon: <MUIComponents.Icons.WorkspacePremiumIcon sx={iconStyling} />
    },
    {
        id: 'service_5',
        description: 'BA-verzekering',
        hidden: false,
        icon: <MUIComponents.Icons.HealthAndSafetyIcon sx={iconStyling} />
    }
]

export const MyServices = ({
    isMob = false,
    isSmallerThanMob = false,
    isTablet = false,
    ...props
}: DeviceParameters) => {
    const { t } = useTranslation()

    // global state
    const [userProfile] = useRecoilState(userProfileState)
    const [, setAlertObject] = useRecoilState(alertObjState)

    const [loading, setLoading] = useState(true)
    const [activeTemplate, setActiveTemplate] = useState<TemplateProps['activeTemplate']>()
    const [openDialog, setOpenDialog] = useState(false)
    const [TLDeal, setTLDeal] = useState<GQLTypes.Deal>()
    const [TLQuote, setTLQuote] = useState<GQLTypes.Quote>()
    const [quotation, setQuotation] = useState<GQLTypes.Quotation>()
    const [employeeCompany, setEmployeeCompany] = useState<GQLTypes.Employee['company']>()
    const [services, setServices] = useState<
        GQLTypes.Maybe<GQLTypes.Maybe<GQLTypes.LineItem>[]> | undefined
    >([])

    const [collectOrders, { error: ordersError }] = useLazyQuery<
        { employee: GQLTypes.Employee },
        GQLTypes.QueryEmployeeArgs
    >(API.Queries.ORDERS_BY_EMPLOYEE)

    const [collectTlDeal, { error: tlDealError }] = useLazyQuery<
        { deal: GQLTypes.Deal },
        GQLTypes.QueryDealArgs
    >(API.Queries.TL_DEAL)

    useEffect(() => {
        const fetchData = async () => {
            const resultOrders = await collectOrders({
                variables: {
                    id: userProfile?.employee_id as string
                }
            })

            if (resultOrders.data?.employee === null) {
                setLoading(false)
            }

            const quotation = resultOrders.data?.employee?.employee_quotations
                ?.filter((quote) => quote?.order?.deal_status_fk === '6') // 6 -> Gefactureerd
                ?.reduce(
                    (a, b) =>
                        new Date(a?.order?.date ?? '') > new Date(b?.order?.date ?? '') ? a : b,
                    {}
                )

            if (quotation) {
                setQuotation(quotation)
            }

            if (resultOrders.data?.employee) {
                setEmployeeCompany(resultOrders.data?.employee?.company)
            }

            const resultTLDeal = await collectTlDeal({
                variables: {
                    id: quotation?.order?.deal_id ?? '/'
                }
            })

            if (resultTLDeal?.data?.deal) {
                setTLDeal(resultTLDeal.data.deal)

                if (resultTLDeal?.data?.deal?.quotations?.[0]) {
                    setTLQuote(resultTLDeal.data.deal.quotations[0])
                    setServices(
                        resultTLDeal.data.deal.quotations[0]?.grouped_lines?.[0]?.line_items
                    )
                    setLoading(false)
                } else {
                    setLoading(false)
                }
            } else {
                setLoading(false)
            }

            if (ordersError) {
                setAlertObject({
                    message: t('myServices.ordersErr'),
                    severity: 'error'
                })
                console.log(ordersError)
                setLoading(false)
            }

            if (tlDealError) {
                setAlertObject({
                    message: t('myServices.dealsErr'),
                    severity: 'error'
                })
                console.log(tlDealError)
                setLoading(false)
            }
        }

        if (userProfile?.employee_id) {
            fetchData()
        }
    }, [userProfile?.employee_id])

    const serviceIds = (services ?? [])?.map((service) => service?.product?.id)

    const mappingServicesT = mappingServices as unknown as {
        [key: string]: { [key: string]: IService }
    }

    const mappingServicesKeys = Object.keys(mappingServices)

    const allIds = mappingServicesKeys.map((key) => {
        const idsByService = Object.keys(mappingServicesT[key])

        return idsByService
    })

    const finalServices = serviceIds
        ?.map((id) => {
            for (let i = 0; i < 5; i++) {
                if (
                    id &&
                    allIds[i].includes(id) &&
                    mappingServicesT[mappingServicesKeys[i]][id]?.button?.visible
                ) {
                    return mappingServicesKeys[i]
                }
            }
            return
        })
        .filter((service) => service)

    const myServices = (serviceIds ?? [])
        ?.map((id) => {
            for (let i = 0; i < 5; i++) {
                const typeOfService = finalServices?.[i]
                if (id && allIds[i].includes(id) && finalServices && typeOfService !== undefined) {
                    return mappingServicesT[typeOfService][id]
                }
            }
            return
        })
        .filter((service) => service) as IService[]

    const allTabs = serviceTabs
        .map((tab) => {
            if (finalServices?.includes(tab?.description)) {
                return {
                    label: t(`myServices.${tab?.description}`),
                    id: tab?.id,
                    ariaControls: `Panel-${tab?.id}`,
                    wrapped: true,
                    disabled: false,
                    icon: tab?.icon,
                    fileId: undefined
                }
            }
            return {}
        })
        .filter((tab) => Object.keys(tab).length !== 0)

    const startDate = TLDeal?.custom_fields?.find(
        (field) => field?.definition?.label === constants.fields.startdate
    )?.value

    const durationContract = TLDeal?.custom_fields?.find(
        (field) => field?.definition?.label === constants.fields.durationTime
    )?.value

    const endDate = calculateDateByDuration({
        startDate,
        duration: durationContract
    })

    const isNotBigScreen = isMob || isTablet || isSmallerThanMob

    return (
        <>
            {!isNotBigScreen ? (
                <MUIComponents.Tabs
                    value={false}
                    variant="fullWidth"
                    scrollButtons={false}
                    sx={{ borderRadius: '10px', height: '100%' }}
                    noActivation={true}
                    border
                    parentBoxStyling={{ height: '100%' }}
                    parentTabsBoxStyling={{ height: '100%' }}
                    allowScrollButtonsMobile
                    onClick={(_e, _fileId, label) => {
                        if (label === t('myServices.emptyServices')) {
                            return
                        }

                        if (label) {
                            setActiveTemplate(label as TemplateProps['activeTemplate'])
                        }
                        setOpenDialog(true)
                    }}
                    colorToOverwrite="white"
                    allTabs={
                        loading
                            ? [...new Array(5)].map((_x, i) =>
                                  tabProps(
                                      {
                                          label: (
                                              <MUIComponents.Skeleton
                                                  width="100%"
                                                  height={30}
                                                  variant="rectangular"
                                              />
                                          ),
                                          icon: (
                                              <MUIComponents.Skeleton
                                                  width="45px"
                                                  height="45px"
                                                  variant="circular"
                                                  sx={{ margin: 'auto' }}
                                              />
                                          ),
                                          id: i.toString()
                                      },
                                      i
                                  )
                              )
                            : allTabs?.length === 0
                            ? [
                                  {
                                      label: t('myServices.emptyServices'),
                                      id: 'empty_services',
                                      wrapped: true,
                                      disabled: true,
                                      icon: undefined,
                                      fileId: undefined
                                  }
                              ]
                            : allTabs
                    }
                    component="IconWithLabel"
                />
            ) : (
                <MUIComponents.Styling.Box
                    sx={{
                        display: 'grid',
                        gridAutoFlow: 'row',
                        gridTemplateColumns: 'repeat(2, 1fr)',
                        gridTemplateRows: `repeat(${Math.round(allTabs.length / 2) ?? 4}, 1fr)`,
                        width: '100%',
                        height: '100%',
                        padding: isMob || isSmallerThanMob ? undefined : '0 25px',
                        ...((isMob || isSmallerThanMob) && {
                            border: '0.1px solid rgb(0, 0, 0, 0.25)',
                            borderRadius: '10px'
                        }),
                        ...(allTabs.length <= 2 && {
                            display: 'flex',
                            flexDirection: 'row'
                        })
                    }}
                >
                    {(loading
                        ? allTabs.map((_x, i) =>
                              tabProps(
                                  {
                                      label: (
                                          <MUIComponents.Skeleton
                                              width="100%"
                                              height={30}
                                              variant="rectangular"
                                          />
                                      ),
                                      icon: (
                                          <MUIComponents.Skeleton
                                              width="45px"
                                              height="45px"
                                              variant="circular"
                                              sx={{ margin: 'auto' }}
                                          />
                                      ),
                                      id: i.toString()
                                  },
                                  i
                              )
                          )
                        : allTabs?.length === 0
                        ? [
                              {
                                  label: t('myServices.emptyServices'),
                                  id: 'empty_services',
                                  wrapped: true,
                                  disabled: true,
                                  icon: undefined,
                                  fileId: undefined
                              }
                          ]
                        : allTabs
                    )?.map((tab, i) => {
                        const isEven = i % 2 === 0

                        const getGridRow = (i: number) => {
                            if (i < 2) {
                                return '1/2'
                            } else if (i < 4) {
                                return '2/3'
                            } else if (i < 6) {
                                return '3/4'
                            } else if (i < 8) {
                                return '4/5'
                            } else {
                                return '5/6'
                            }
                        }

                        return (
                            <Wrapper
                                key={i}
                                {...(tab?.label !== t('myServices.emptyServices') && {
                                    onClick: () => {
                                        if (tab?.label) {
                                            setActiveTemplate(
                                                tab?.label as TemplateProps['activeTemplate']
                                            )
                                        }
                                        setOpenDialog(true)
                                    }
                                })}
                                {...(allTabs.length <= 4 && {
                                    style: { width: '100%' }
                                })}
                            >
                                <MUIComponents.Styling.Box
                                    sx={{
                                        gridColumn: isEven ? '1' : '2',
                                        gridRow: getGridRow(i),
                                        minWidth: 0,
                                        textAlign: 'center',
                                        cursor: 'pointer',
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        ...((isMob || isSmallerThanMob) && {
                                            borderLeft: isEven
                                                ? undefined
                                                : '0.1px solid rgb(0, 0, 0, 0.25)',
                                            borderRight: !isEven
                                                ? undefined
                                                : '0.1px solid rgb(0, 0, 0, 0.25)',
                                            borderBottom: '0.1px solid rgb(0, 0, 0, 0.25)',
                                            padding: '10px',
                                            borderWidth: 'thin'
                                        }),
                                        ...(allTabs.length <= 2 && {
                                            borderLeft: 'unset',
                                            borderRight:
                                                (allTabs.length === 0 ? 4 : allTabs.length) ===
                                                i + 1
                                                    ? 'unset'
                                                    : '0.1px solid rgb(0, 0, 0, 0.25)',
                                            borderBottom: 'unset',
                                            borderWidth: 'thin'
                                        })
                                    }}
                                >
                                    {tab.icon}
                                    <MUIComponents.Typography
                                        sx={{
                                            ...StyledComponents.helper.truncatedText,
                                            width: '100%'
                                        }}
                                        variant="body1"
                                    >
                                        {tab.label}
                                    </MUIComponents.Typography>
                                </MUIComponents.Styling.Box>
                            </Wrapper>
                        )
                    })}
                </MUIComponents.Styling.Box>
            )}

            <MUIComponents.Dialog
                open={openDialog && (activeTemplate?.length ?? 0) > 0}
                onClose={() => {
                    setOpenDialog(false)
                }}
                cancelClick={() => {
                    setOpenDialog(false)
                }}
                cancelButtonText={t('general.buttons.close')}
                contentComponent={
                    (activeTemplate?.length ?? 0) > 0 ? (
                        <Template
                            bikeData={quotation?.order?.bike}
                            services={myServices}
                            activeTemplate={activeTemplate!}
                            orderData={quotation?.order}
                            companyData={employeeCompany}
                            startDate={startDate ?? '/'}
                            endDate={endDate ?? '/'}
                        />
                    ) : (
                        <></>
                    )
                }
                fullScreen
            />
        </>
    )
}

const Wrapper = styled.div`
    ${StyledComponents.TruncatedText}
    display: flex;
    color: rgba(0, 0, 0, 0.38);
    pointer-events: none;
    cursor: default;
`
