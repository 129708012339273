import styled from 'styled-components'

import { Blogpost } from '../../../../types'

const ContentParagaraph = styled.p`
    font-weight: bold;
    margin-bottom: 0px;
`

const ContentUnderline = styled.p`
    text-decoration: underline;
`

const bannerImages = [
    '/blogImg/bannerBlog1.jpeg',
    '/blogImg/bannerBlog2.jpg',
    '/blogImg/bannerBlog3.jpeg',
    '/blogImg/bannerBlog4.jpg',
    '/blogImg/bannerBlog5.jpeg'
]

// TODO: Change language blogposts

export const postsNL: Blogpost[] = [
    {
        key: '1',
        bannerImg: bannerImages[0],
        title: '6 tips voor fietsonderhoud',
        subtitle: '05/06/2023',
        paragraphs: [
            {
                title: <></>,
                content: (
                    <p>
                        Om de gebruiksduur van je fiets optimaal te benutten is het noodzakelijk om
                        hem goed te onderhouden. Echte onderhoudswerken kun je laten doen bij de
                        fietsenwinkel, maar een simpel nazicht kun je ook eenvoudig zelf doen. Een
                        belangrijk punt om rekening mee te houden: wacht niet met onderhoud tot het
                        nodig is. Zorg er gewoon voor dat je verschillende onderdelen van je fiets
                        regelmatig nakijkt. Met deze tips kun je jouw fiets in goede conditie
                        houden!
                    </p>
                )
            },
            {
                title: <ContentParagaraph>1. Maak je fiets regelmatig schoon</ContentParagaraph>,
                content: (
                    <p>
                        Om de gebruiksduur van je fiets optimaal te benutten is het noodzakelijk om
                        hem goed te onderhouden. Echte onderhoudswerken kun je laten doen bij de
                        fietsenwinkel, maar een simpel nazicht kun je ook eenvoudig zelf doen. Een
                        belangrijk punt om rekening mee te houden: wacht niet met onderhoud tot het
                        nodig is. Zorg er gewoon voor dat je verschillende onderdelen van je fiets
                        regelmatig nakijkt. Met deze tips kun je jouw fiets in goede conditie
                        houden!
                    </p>
                )
            },
            {
                title: <ContentParagaraph>2. Regelmatig ontvetten en smeren</ContentParagaraph>,
                content: (
                    <div>
                        <p>
                            Je ketting en andere onderdelen voor de aandrijving moeten af en toe
                            ontvet en opnieuw gesmeerd worden. Eerst ontvetten en schoonmaken, en
                            als het goed schoon en droog is kun je opnieuw gaan smeren. Om je
                            ketting te smeren kun je het beste een speciale fietsolie gebruiken die
                            vuilwerend en waterafstotend is, en geen gewone olie omdat deze vuil
                            juist extra aantrekt. Hoe vaak je de onderdelen van je fiets moet
                            smeren, hangt af van hoe vaak en waar je gaat rijden.
                        </p>
                        <p>
                            De ketting, derailleur en remmen dienen regelmatig gesmeerd te worden.
                            Wat je niet smeert: de cassette. Het wordt zelf afgeraden deze te
                            smeren, een vette cassette trekt extra vuil aan. Belangrijk is ook dat
                            je niet te veel vet gebruikt. Een aantal druppels olie/vet is al genoeg
                            om de ketting te smeren.
                        </p>
                    </div>
                )
            },
            {
                title: (
                    <ContentParagaraph>
                        3. Controleer de banden en wielen van je fiets
                    </ContentParagaraph>
                ),
                content: (
                    <div>
                        <p>
                            Bij een uitgebreide onderhoudsbeurt van je fiets kan je ook aandacht
                            besteden aan je banden en wielen. Eerst kan je controleren of de wielen
                            nog recht zijn door ze te laten draaien en kijken of er geen slag in het
                            wiel zit. Als er een slag in zit dan zie je het wiel zwabberen.
                        </p>
                        <p>
                            Ook de banden verdienen het om regelmatig eens nagekeken te worden. Ga
                            daarom na of er geen steentjes of kleine sneetjes te vinden zijn in je
                            banden. Controleer daarnaast ook eens het profiel en kijk of deze niet
                            versleten zijn. Het profiel van de fietsband wordt in de loop van de
                            tijd steeds gladder. Hoe gladder de banden, hoe minder grip je hebt op
                            de weg. Wanneer je de de anti-leklaag tevoorschijn ziet komen, dan is je
                            band zeker aan vervanging toe. Dit zie je vaak aan de veranderende kleur
                            van de laag.
                        </p>
                        <p>
                            Ten slotte check je ook beter regelmatig de bandenspanning. Een te lage
                            bandenspanning zorgt er namelijk voor dat je banden sneller gaan slijten
                            en het risico op lekke banden vermindert drastisch. Bovendien is het ook
                            veel prettiger rijden op banden met de juiste spanning. De juiste
                            spanning hangt af van het soort band waar je op rijdt. Wanneer je niet
                            zeker bent over de bandenspanning kan je die altijd terugvinden op de
                            zijkant van de band.
                        </p>
                    </div>
                )
            },
            {
                title: <ContentParagaraph>4. Controleer je remmen</ContentParagaraph>,
                content: (
                    <p>
                        Natuurlijk is het belangrijk om ervoor te zorgen dat je fiets goed en soepel
                        blijft rijden, maar wat zeker net zo belangrijk is, is dat je fiets goed
                        blijft remmen. Goede remblokjes zijn essentieel wanneer je gaat fietsen.
                        Controleer daarom geregeld of de voorrem en achterrem goed werken. Merk je
                        dat één van de remmen minder soepel werkt of moet je de handel ver inknijpen
                        voordat hij werkt? Laat je remmen dan best nakijken door een fietsenmaker.{' '}
                    </p>
                )
            },
            {
                title: <ContentParagaraph>5. Je batterij langer laten meegaan</ContentParagaraph>,
                content: (
                    <div key={1}>
                        <p>
                            Elektrische fietsen beschikken over een batterij waar wat extra aandacht
                            aan moet geschonken worden. Enkele aandachtspunten:
                        </p>
                        <ul>
                            <li>
                                Extreme koude is nooit goed voor accu’s. Zorg er dus voor dat je de
                                accu’s niet in een koude omgeving laat staan of liggen.
                            </li>
                            <li>
                                Haal de accu’s van de fiets en leg ze bij voorkeur ergens binnen
                                waar ze op kamertemperatuur bewaard kunnen worden.
                            </li>
                            <li>
                                Zet je fiets nooit weg met een lege accu. Laad een lege accu zo snel
                                mogelijk weer op. Iedere accu loopt namelijk na verloop van tijd
                                leeg, ook wanneer je de fiets niet gebruikt. Een accu die leeg is en
                                niet wordt opgeladen kan door zelfontlading een diepteontlading
                                krijgen en defect raken.
                            </li>
                            <li>
                                Stal je je fiets de hele dag buiten? Op het werk of bij het station?
                                Neem dan de afneembare accu(‘s) mee en bewaar deze droog en koel.
                            </li>
                        </ul>
                        <p>
                            Gemiddeld houdt een fietsaccu het tussen de vijf en acht jaar vol, maar
                            de levensduur hangt natuurlijk voor een groot deel samen met hoe
                            intensief je hem gebruikt en hoe vaak je hem oplaadt.
                        </p>
                    </div>
                )
            },
            {
                title: (
                    <ContentParagaraph>
                        6. Je fiets af en toe laten nakijken door de fietsenwinkel
                    </ContentParagaraph>
                ),
                content: (
                    <p>
                        Ondanks dat je veel onderhoud al zelf thuis kunt doen, is het toch slim om
                        je fiets af en toe grondig na te laten kijken. Een professional kan dingen
                        ontdekken die je zelf over het hoofd hebt gezien of dingen vervangen die je
                        zelf niet kunt vervangen. Door bovenstaande tips en deze tip te volgen, kun
                        je de gebruiksduur van je fiets en fietsonderdelen optimaal benutten.{' '}
                    </p>
                )
            }
        ],
        listItems: [
            {
                title: 'Fietsen in alle weersomstandigheden',
                subTitle: '05/10/2022',
                src: bannerImages[1],
                endpoint: '/blogpost/2'
            },
            {
                title: 'Waarom naar het werk fietsen goed voor je is',
                subTitle: '05/10/2022',
                src: bannerImages[2],
                endpoint: '/blogpost/3'
            },
            {
                title: 'Hoe de juiste fiets kiezen',
                subTitle: '05/10/2022',
                src: bannerImages[3],
                endpoint: '/blogpost/4'
            }
        ]
    },
    {
        key: '2',
        bannerImg: bannerImages[1],
        title: 'Fietsen in alle weersomstandigheden',
        subtitle: '05/06/2023',
        paragraphs: [
            {
                title: <></>,
                content: (
                    <p>
                        Fietsen is geweldig. Je laat files links liggen, maakt korte metten met
                        opkomende stress en je werkt bijna ongemerkt aan je conditie én een beter
                        milieu. En die regenbuien? Daar fiets je fluitend doorheen. Je geheim?
                        Slimme fietskleding en accessoires.
                    </p>
                )
            },
            {
                title: <ContentParagaraph>1. Gebruik goede regenwerende kleding</ContentParagaraph>,
                content: (
                    <p>
                        Wil je consequent op de fiets gaan, dan kom je niet onder een goed regenpak
                        of goede regenjas uit. Kies een regenpak dat nauw aansluit, zodat je
                        comfortabel kunt blijven fietsen. Ook is het fijn als je het regenpak tot
                        klein formaat kan opvouwen, en het in een tasje standaard in je fietstas
                        laat zitten. Je kunt voor een regenpak kiezen of gaan voor een
                        allround-fietsbroek in combinatie met een sportieve waterafstotende fietsjas
                        met capuchon. Het is aan jou om te bepalen wat jij het prettigst vindt.
                    </p>
                )
            },
            {
                title: (
                    <ContentParagaraph>
                        2. Kleed je voldoende warm als je gaat fietsen in de winter
                    </ContentParagaraph>
                ),
                content: (
                    <p>
                        Warme kledij is een must en daarbij geldt één regel: laagjes, laagjes en nog
                        eens laagjes. Ga voor kledij die ademt. Zo zul je minder zweten en fiets je
                        warm de winter door.
                    </p>
                )
            },
            {
                title: (
                    <ContentParagaraph>
                        3. Bescherm je handen en voeten tegen de kou
                    </ContentParagaraph>
                ),
                content: (
                    <p>
                        Niets zo demotiverend als verkleumde voeten en handen. Met waterdichte
                        overschoenen en handschoenen kom je al een heel eind. Dikke kousen zijn dan
                        weer een afrader. Hierdoor knellen je schoenen meer en vermindert je
                        bloedsomloop, wat leidt tot koude voeten. Kies liever een dun, maar water-
                        en winddicht paar kousen.
                    </p>
                )
            },
            {
                title: (
                    <ContentParagaraph>
                        4. Maak je fiets winterproof voor een veilige rit
                    </ContentParagaraph>
                ),
                content: (
                    <p>
                        Investeer in goed materiaal waar je ook in nat weer op kunt vertrouwen,
                        zoals winterbanden en nieuwe remblokken. In de nattere maanden vergroot
                        namelijk je remafstand door het vaak gladde wegdek. Een spatbord is trouwens
                        geen overbodige luxe. Zo bescherm je jezelf én je fietsonderdelen tegen
                        water en vuil.
                    </p>
                )
            },
            {
                title: <ContentParagaraph>5. Zorg dat je gezien wordt</ContentParagaraph>,
                content: (
                    <p>
                        Je ziet er misschien liever niet uit als een ‘fietsende kerstboom’, maar het
                        is uiterst belangrijk dat je opvalt in het donker. Ook overdag kun je het
                        best lichtgevende accessoires voorzien. Controleer voor je vertrekt of je
                        fietslichten nog wel werken. Een fluohesje, reflecterend harnas of
                        spaakreflectoren verhogen je zichtbaarheid.
                    </p>
                )
            }
        ],
        listItems: [
            {
                title: 'Waarom naar het werk fietsen goed voor je is',
                subTitle: '05/10/2022',
                src: bannerImages[2],
                endpoint: '/blogpost/3'
            },
            {
                title: 'Hoe de juiste fiets kiezen',
                subTitle: '05/10/2022',
                src: bannerImages[3],
                endpoint: '/blogpost/4'
            },
            {
                title: 'De 7 tips om je fiets diefstal-proof te maken',
                subTitle: '05/10/2022',
                src: bannerImages[4],
                endpoint: '/blogpost/5'
            }
        ]
    },
    {
        key: '3',
        bannerImg: bannerImages[2],
        title: 'Waarom naar het werk fietsen goed voor je is',
        subtitle: '05/06/2023',
        paragraphs: [
            {
                title: (
                    <ContentParagaraph>
                        1. Fietsen is goedkoper dan met de auto naar het werk gaan
                    </ContentParagaraph>
                ),
                content: (
                    <p>
                        De steeds stijgende brandstofprijzen, onderhoud van je wagen, verzekering,
                        taksen,… het hoeft niet gezegd dat zich met de auto verplaatsen veel duurder
                        is dan een verplaatsing met de fiets. Daarnaast geven veel bedrijven een
                        fietsvergoeding aan werknemers die naar het werk fietsen. Win-win!
                    </p>
                )
            },
            {
                title: <ContentParagaraph>2. Gratis work-out</ContentParagaraph>,
                content: (
                    <div>
                        <p>
                            Met uitzondering van de aankoop van een fiets en een occasionele
                            herstelling kost fietsen je amper iets. Je moet je heus niet blauw
                            betalen aan een fitnessabonnement om dagelijks een beetje te sporten.
                            Iedere dag met de fiets naar het werk is een activiteit die niet alleen
                            goed is voor je gezondheid, maar je bovendien ook meer energie geeft.
                        </p>
                        <p>
                            Dat fietsen goed is voor je gezondheid werd ook geconcludeerd uit een
                            studie van het Nederlandse onderzoeksinstituut TNO. Des te vaker mensen
                            fietsen en des te groter de afstand die zij afleggen, des te minder zij
                            zich bovendien ziek melden, zo blijkt.
                        </p>
                    </div>
                )
            },
            {
                title: <ContentParagaraph>3. Bye bye, file!</ContentParagaraph>,
                content: (
                    <p>
                        Zeg vaarwel tegen die frustraties die spontaan opwellen wanneer je weeral in
                        een file je tijd staat te verspillen. Vooral kleine afstanden kunnen soms
                        zelfs sneller met de fiets worden afgelegd dan met de auto, gewoonweg omdat
                        het verkeer op drukke momenten helemaal vast komt te staan.
                    </p>
                )
            },
            {
                title: <ContentParagaraph>4. Zeg vaarwel tegen parkeerproblemen</ContentParagaraph>,
                content: (
                    <p>
                        Sommige bedrijven beschikken niet over een gigantische (gratis) autoparking,
                        waardoor het wel eens vloeken kan zijn om een parkeerplaats te vinden
                        dichtbij het werk. Een fiets kan je daarentegen veel makkelijker kwijt. Een
                        goede investering in dat geval is uiteraard een degelijk fietsslo
                    </p>
                )
            },
            {
                title: <ContentParagaraph>5. Van fietsen word je gelukkiger</ContentParagaraph>,
                content: (
                    <p>
                        Mensen die fietsen naar het werk zijn gelukkiger, zo blijkt uit onderzoek.
                        Met name de fietstocht naar het werk is het perfecte moment om te
                        ontspannen. Het geeft je tijd om je mentaal voor te bereiden op alles wat op
                        kantoor wacht. Andersom is het na een lange werkdag de perfecte manier om je
                        hoofd even leeg te maken. Fietsen heeft namelijk aantoonbare fysiologische
                        effecten op stress en depressie. Daarnaast heb je als fietser meer grip op
                        je aankomsttijd, doordat je files vermijdt en kun je meer genieten van je
                        omgeving.
                    </p>
                )
            },
            {
                title: <ContentParagaraph>6. Minder CO2-uitstoot</ContentParagaraph>,
                content: (
                    <p>
                        Zonder CO2-uitstoot heen-en-weer naar je bestemming: het kan! Op lopen na,
                        is er geen manier van reizen zo milieuvriendelijk als de gewone fiets. De
                        gemiddelde CO2-uitstoot van één kilometer rijden met een normale auto is
                        0,22 kg. Met een elektrische fiets die met grijze stroom wordt opgeladen is
                        dat 0,007 kg, ofwel 97 procent lager. En op de reguliere fiets? Daarmee
                        beperk je jouw uitstoot tot nul! Door vaker de fiets te pakken naar het werk
                        kun je dus heel wat CO2 besparen en jouw impact op het klimaat verkleinen.
                    </p>
                )
            }
        ],
        listItems: [
            {
                title: 'Hoe de juiste fiets kiezen',
                subTitle: '05/10/2022',
                src: bannerImages[3],
                endpoint: '/blogpost/4'
            },
            {
                title: 'De 7 tips om je fiets diefstal-proof te maken',
                subTitle: '05/10/2022',
                src: bannerImages[4],
                endpoint: '/blogpost/5'
            },
            {
                title: '6 tips voor fietsonderhoud',
                subTitle: '05/10/2022',
                src: bannerImages[0],
                endpoint: '/blogpost/1'
            }
        ]
    },
    {
        key: '4',
        bannerImg: bannerImages[3],
        title: 'Hoe de juiste fiets kiezen',
        subtitle: '05/10/2022',
        paragraphs: [
            {
                title: <ContentParagaraph>1. Soort fiets</ContentParagaraph>,
                content: (
                    <div>
                        <p>
                            Hoe wil je zitten? Met een hoog stuur zit je rechtop en kun je goed om
                            je heen kijken. Het is een fijne houding voor samen fietsen. Maar als je
                            een langere afstand fietst is een sportieve houding comfortabeler.
                            Bovendien krijg je minder snel zadelpijn. Op een racefiets waarop het
                            stuur lager staat heb je de minste luchtweerstand en het minste gewicht
                            op het zadel, maar die houding is wel zwaarder voor het bovenlichaam.
                            Hoeveel kilometer wil je afleggen? Volstaat een goede stadsfiets of heb
                            je toch eerder een tourfiets nodig? Staat je fiets veel buiten op ongure
                            plekken? Dan zijn kwetsbare versnellingen met loshangende kabels niet zo
                            handig. Moet je er boodschappen op kunnen vervoeren? Dan is een
                            bagagedrager wel zo handig.
                        </p>
                        <ContentUnderline>A Robuuste eenvoudige stadsfiets</ContentUnderline>
                        <ul>
                            <li>Terugtraprem</li>
                            <li>Rechtop zit</li>
                            <li>Geen versnellingen</li>
                            <li>Gewicht niet zo belangrijk. Robuustheid telt zwaarder</li>
                            <li>Onderhoudsarm</li>
                        </ul>
                        <ContentUnderline>
                            B Luxe stadsfiets waarmee je grotere afstanden kan afleggen
                        </ContentUnderline>
                        <ul>
                            <li>V-brakes of hydraulische schrijfremmen</li>
                            <li>Rechtop zit</li>
                            <li>3 of 8 versnellingen</li>
                            <li>Vering</li>
                            <li>Naafdynamo met goede kwaliteit koplamp</li>
                            <li>
                                Moet vanwege kwetsbare onderdelen als versnellingen ‘s nachts binnen
                                kunnen staan
                            </li>
                        </ul>
                        <ContentUnderline>C Toerfiets voor lange afstanden</ContentUnderline>
                        <ul>
                            <li>Sportieve zit</li>
                            <li>Derailleur</li>
                            <li>27 versnellingen</li>
                            <li>Hydraulische velgremmen</li>
                            <li>Moet binnen kunnen staan</li>
                        </ul>

                        <ContentUnderline>C Toerfiets voor lange afstanden</ContentUnderline>
                        <p>
                            Of is het nog niet zo duidelijk wat je wilt? De meeste robuuste
                            stadsfietsen hebben een rechtop zit. Maar er zijn er ook met sportieve
                            zit. En als je op comfort gesteld bent, kies je voor een stadsfiets met
                            dikke ballonbanden. Sommige fietsmerken bieden de mogelijkheid om jouw
                            favoriete model helemaal naar wens uit te rusten.
                        </p>
                    </div>
                )
            },
            {
                title: <ContentParagaraph>2. Framemaat</ContentParagaraph>,
                content: (
                    <>
                        Een passende framemaat voorkomt zadelpijn, knieklachten, rugpijn of
                        schouderpijn. Een vuistregel voor het vaststellen van de framemaat is: 2/3
                        van de binnenbeenlengte, gemeten vanaf de lies tot aan de onderkant van de
                        voet. Over het algemeen hebben vrouwen hebben iets langere benen dan mannen.
                        Uitgaan van iemands lengte leidt dus makkelijk tot een verkeerde framemaat.
                        Het is maar een indicatie. Erg lange en erg kleine mensen kunnen het beste
                        een fiets op maat laten maken.
                    </>
                )
            },
            {
                title: <ContentParagaraph>3. Versnellingen of niet</ContentParagaraph>,
                content: (
                    <p>
                        Kies als je versnellingen wilt voor 3 of 8 versnellingen op je stadsfiets.
                        Op vakantiefietsen zit vaak een naafversnelling. Tamelijk ideaal. Want met
                        de naaf heb je altijd genoeg versnellingen, de naaf is nagenoeg
                        onderhoudsvrij, duurzaam en het rendement lijkt nauwelijks slechter dan van
                        derailleurversnellingen. Het enige nadeel is eigenlijk de prijs. Op
                        mountainbikes en racefietsen zit doorgaans een derailleurversnelling: lekker
                        licht en hoog rendement, maar wel kwetsbaar.
                    </p>
                )
            },
            {
                title: <ContentParagaraph>4. Remmen</ContentParagaraph>,
                content: (
                    <div>
                        <p>
                            De beste remmen zijn over het algemeen hydraulische schrijfremmen.
                            Vroeger zaten ze alleen op mountainbikes, maar tegenwoordig zitten ze
                            ook op elektrische fietsen en luxe toerfietsen. Ze werken prima onder
                            alle omstandigheden en de velgen slijten niet. Het grote nadeel is dat
                            het systeem kwetsbaar is. Ze lopen snel aan. De terugtraprem is robuust,
                            maar het duurt lang voordat je stilstaat in vergelijking met andere
                            remmen. Niet zo handig als je een berg afrijdt of met hoge snelheid door
                            de stad rijdt.
                        </p>
                        <p>
                            Op luxere stadsfietsen en recreatieve fietsen zitten vaak rollerbrake
                            remmen. Ze hebben weinig onderhoud nodig en slijten minder dan
                            velgremmen. Nadeel is dat de remkracht minder goed te doseren is. Als je
                            het achterwiel los wilt halen, moet je een kabeltje losmaken. Velgremmen
                            zijn prima als het droog weer is, maar minder goed als het regent. Een
                            ander nadeel is dat de velgen ervan slijten. Voordeel is dat het
                            achterwiel makkelijk te verwijderen is.
                        </p>
                        <p>
                            V-brakes zitten op goedkopere vakantiefietsen, sportieve hybrides en
                            elektrische fietsen. Ze zijn eenvoudig, goedkoop en goed. Een nadeel is
                            dat het plaatsen van nieuwe remblokjes een secuur werkje is. Maar dat
                            kun je natuurlijk ook door de fietsenmaker laten doen. Magura-velgremmen
                            zijn verbeterde velgremmen. Ze zijn hydraulisch, dus zonder kabelremmen.
                            Het voordeel daarvan is dat ze niet kunnen vastvriezen. Het nadeel is
                            dat een hydraulisch systeem wat kwetsbaarder is bij transport en in de
                            stalling. Magura-velgremmen zijn bijna onderhoudsvrij. Ze zijn wel wat
                            duurder dan V-brakes.
                        </p>
                    </div>
                )
            },
            {
                title: <ContentParagaraph>5. Breedte van de velg</ContentParagaraph>,
                content: (
                    <p>
                        De klassieke stadsfietsband (30 a 45 mm) is een redelijk compromis tussen
                        snelheid, comfort en gewicht, maar heeft als nadeel dat je er bijvoorbeeld
                        niet onverhard mee kan fietsen. Je kunt ook kiezen voor een fiets met brede
                        ballonbanden (meer dan 45 mm). Zulke banden rijden comfortabeler, je valt
                        minder snel als je een onverwachte hobbel tegenkomt en ze fietsen ook nog
                        prima als ze minder hard zijn opgepompt. Je kunt wel bredere banden zetten
                        op smalle velgen, maar als je ballonbanden wilt, kun je beter bij aankoop al
                        voor een wiel met brede velgen kiezen.
                    </p>
                )
            },
            {
                title: <ContentParagaraph>6. Welk merk?</ContentParagaraph>,
                content: (
                    <p>
                        Ook goede merken maken ook wel eens slechte fietsen, dus een garantie geven
                        bij een merk is onmogelijk. Rondkijken op internet of raad vragen aan je
                        fietsenwinkel kan wat dat betreft veel informatie opleveren.{' '}
                    </p>
                )
            },
            {
                title: (
                    <ContentParagaraph>
                        7. Hoe belangrijk is het gewicht van een fiets?
                    </ContentParagaraph>
                ),
                content: (
                    <div>
                        <p>
                            Voor het fietsen zelf is het gewicht van een fiets doorgaans niet zo
                            belangrijk. Het is wel van belang als je de fiets op een fietsdrager
                            moet tillen of een helling op moet duwen. Over het algemeen geldt dat
                            besparen op gewicht het makkelijkst is als je onderdelen weglaat.
                        </p>
                        <p>
                            De meeste frames zijn gemaakt van staal of aluminium. De beide
                            materialen hebben voor- en nadelen. Zo is een stalen frame duurzaam en
                            solide en kan het tegen een stootje. Het is echter een stuk zwaarder dan
                            een aluminium frame. Ook roest aluminium minder snel dan staal bij
                            eventuele lakbeschadiging van het frame. Racefietsen zijn meestal
                            gemaakt van aluminium of carbon frames. Carbon is erg licht en zeer
                            sterk.
                        </p>
                    </div>
                )
            }
        ],
        listItems: [
            {
                title: 'De 7 tips om je fiets diefstal-proof te maken',
                subTitle: '05/10/2022',
                src: bannerImages[4],
                endpoint: '/blogpost/5'
            },
            {
                title: '6 tips voor fietsonderhoud',
                subTitle: '05/10/2022',
                src: bannerImages[0],
                endpoint: '/blogpost/1'
            },
            {
                title: 'Fietsen in alle weersomstandigheden',
                subTitle: '05/10/2022',
                src: bannerImages[1],
                endpoint: '/blogpost/2'
            }
        ]
    },
    {
        key: '5',
        bannerImg: bannerImages[4],
        title: 'De 7 tips om je fiets diefstal-proof te maken',
        subtitle: '05/10/2022',
        paragraphs: [
            {
                title: <ContentParagaraph>Tip 1: Zet je fiets goed op slot</ContentParagaraph>,
                content: (
                    <p>
                        Nogal voor de hand liggend, zou je denken. Maar toch is er een verschil
                        tussen je fiets op slot zetten, en je fiets goed op slot zetten. Veel
                        fietsen zijn uitgerust met een standaard ringslot. Prima voor snelle
                        beveiliging, maar over het algemeen draait een fietsendief hier zijn of haar
                        hand niet voor om. Zorg er daarom voor dat je altijd minstens twee soorten
                        sloten hebt op één fiets. Fietsendieven specialiseren zich vaak in één type
                        slot, dus met twee sloten is jouw fiets ineens een stuk minder
                        aantrekkelijk. Onder het goed op slot zetten verstaan we ook: koop
                        gecertificeerde sloten. En daar volgt meteen tip nummer 2.
                    </p>
                )
            },
            {
                title: <ContentParagaraph>Tip 2: Koop een gecertificeerd slot</ContentParagaraph>,
                content: (
                    <p>
                        Het ART Keurmerk is het leidinggevende certificaat voor sloten. Sloten
                        krijgen van ART een beoordeling van 1 tot en met 5 sterren. Hoe meer
                        sterren, hoe hoger de preventieve waarde tegen diefstal. Minimum een
                        ART2-slot is steeds verplicht in elke leasing.
                    </p>
                )
            },
            {
                title: <ContentParagaraph>Tip 3: Maak je fiets vast</ContentParagaraph>,
                content: (
                    <p>
                        Wist je dat het verzekeringsgewijs verplicht is je fiets aan een vast punt
                        vast te maken? Door je fiets aan een vast punt, zoals een paal, een
                        fietsenrek of bijvoorbeeld een brug, vast te maken, kan een fietsendief deze
                        niet gemakkelijk meenemen. Een slot dat geschikt is om de fiets te
                        verankeren aan een vast punt dient daarom steeds opgenomen te worden in elke
                        leasing. Trouwens, onze afdeling Fietsparkeren.net kan jou of je werkgever
                        verder helpen met oplossingen voor het veilig en netjes parkeren van je
                        fiets.
                    </p>
                )
            },
            {
                title: <ContentParagaraph>Tip 4: Zet je fiets slim vast</ContentParagaraph>,
                content: (
                    <p>
                        Een vervolgtip op nummer 3: wees slim bij het op slot zetten. Je fiets enkel
                        aan het voorwiel ergens aan vast maken is bijvoorbeeld geen goed idee. Het
                        is voor een dief geen probleem om het voorwiel achter te laten en de rest
                        van je fiets mee te nemen. Maak je fiets ook niet vast aan het midden van je
                        frame. Een dief kan de fiets dan draaien om extra kracht te zetten en zo het
                        slot open te breken. Dit geldt vooral bij het gebruik van een U-slot.
                    </p>
                )
            },
            {
                title: <ContentParagaraph>Tip 5: Maak gebruik van je ventiel</ContentParagaraph>,
                content: (
                    <p>
                        Dat klinkt een beetje vreemd, maar het is een tip van een oud-fietsendief
                        die erg effectief blijkt te zijn. Als je je fiets op slot zet met een
                        ringslot (dus je standaard slot), zorg er dan voor dat je ventiel in de
                        buurt zit van het slot. Een fietsendief probeert je slot door te knippen, en
                        natuurlijk de rest van de fiets intact te houden. Als jij je ventiel dicht
                        bij het slot hebt zitten, knippen ze vaak ook door het ventiel heen waardoor
                        de band leegloopt. De dief heeft dan niets meer aan jouw fiets. Zien dieven
                        dit, dan beginnen ze er vaak niet eens aan.
                    </p>
                )
            },
            {
                title: <ContentParagaraph>Tip 6: Maak je fiets hoog vast</ContentParagaraph>,
                content: (
                    <p>
                        Hiermee bedoelen we je fiets vastmaken met een ketting-, vouw-, of
                        beugelslot. Als je deze aan een paal vastmaakt en vervolgens op de grond
                        laat liggen, kan een dief de fiets gemakkelijker stelen. Ze gebruiken dan
                        namelijk de grond om extra druk op hun materiaal te zetten. Dat geeft ze
                        extra kracht, en dus een gemakkelijkere vangst. Als je je fiets hoger
                        vastmaakt (aan het frame bijvoorbeeld), dan geef je ze die kans niet. Bij
                        kou helpt dit je ook nog eens: een hangend slot bevriest minder snel dan een
                        slot dat op de grond ligt.
                    </p>
                )
            },
            {
                title: (
                    <ContentParagaraph>Tip 7: Zorg dat je fiets herkenbaar is</ContentParagaraph>
                ),
                content: (
                    <p>
                        ‘Maar ik herken mijn eigen fiets toch wel?’ Dat geloven we zeker, maar niet
                        iedereen herkent jouw fiets. Als je jouw gegevens laat graveren op een
                        herkenbare plek, bijvoorbeeld bovenop het frame, dan zien anderen ook direct
                        om wiens fiets het gaat. Een dief pakt deze fietsen liever niet, want er is
                        een grote kans dat ze dan gepakt worden (aangezien de fiets aantoonbaar niet
                        van hem of haar is). Maar dit is ook ideaal voor wanneer je fiets tóch
                        gestolen wordt en je hem graag terug wilt hebben. Aan de hand van je
                        gegevens op de fiets, kunnen anderen dan contact met je opnemen.
                    </p>
                )
            }
        ],
        listItems: [
            {
                title: '6 tips voor fietsonderhoud',
                subTitle: '05/10/2022',
                src: bannerImages[0],
                endpoint: '/blogpost/1'
            },
            {
                title: 'Fietsen in alle weersomstandigheden',
                subTitle: '05/10/2022',
                src: bannerImages[1],
                endpoint: '/blogpost/2'
            },
            {
                title: 'Waarom naar het werk fietsen goed voor je is',
                subTitle: '05/10/2022',
                src: bannerImages[2],
                endpoint: '/blogpost/3'
            }
        ]
    }
]

export const postsFR: Blogpost[] = [
    {
        key: '1',
        bannerImg: bannerImages[0],
        title: '6 tips voor fietsonderhoud',
        subtitle: '05/06/2023',
        paragraphs: [
            {
                title: <></>,
                content: (
                    <p>
                        Om de gebruiksduur van je fiets optimaal te benutten is het noodzakelijk om
                        hem goed te onderhouden. Echte onderhoudswerken kun je laten doen bij de
                        fietsenwinkel, maar een simpel nazicht kun je ook eenvoudig zelf doen. Een
                        belangrijk punt om rekening mee te houden: wacht niet met onderhoud tot het
                        nodig is. Zorg er gewoon voor dat je verschillende onderdelen van je fiets
                        regelmatig nakijkt. Met deze tips kun je jouw fiets in goede conditie
                        houden!
                    </p>
                )
            },
            {
                title: <ContentParagaraph>1. Maak je fiets regelmatig schoon</ContentParagaraph>,
                content: (
                    <p>
                        Om de gebruiksduur van je fiets optimaal te benutten is het noodzakelijk om
                        hem goed te onderhouden. Echte onderhoudswerken kun je laten doen bij de
                        fietsenwinkel, maar een simpel nazicht kun je ook eenvoudig zelf doen. Een
                        belangrijk punt om rekening mee te houden: wacht niet met onderhoud tot het
                        nodig is. Zorg er gewoon voor dat je verschillende onderdelen van je fiets
                        regelmatig nakijkt. Met deze tips kun je jouw fiets in goede conditie
                        houden!
                    </p>
                )
            },
            {
                title: <ContentParagaraph>2. Regelmatig ontvetten en smeren</ContentParagaraph>,
                content: (
                    <div>
                        <p>
                            Je ketting en andere onderdelen voor de aandrijving moeten af en toe
                            ontvet en opnieuw gesmeerd worden. Eerst ontvetten en schoonmaken, en
                            als het goed schoon en droog is kun je opnieuw gaan smeren. Om je
                            ketting te smeren kun je het beste een speciale fietsolie gebruiken die
                            vuilwerend en waterafstotend is, en geen gewone olie omdat deze vuil
                            juist extra aantrekt. Hoe vaak je de onderdelen van je fiets moet
                            smeren, hangt af van hoe vaak en waar je gaat rijden.
                        </p>
                        <p>
                            De ketting, derailleur en remmen dienen regelmatig gesmeerd te worden.
                            Wat je niet smeert: de cassette. Het wordt zelf afgeraden deze te
                            smeren, een vette cassette trekt extra vuil aan. Belangrijk is ook dat
                            je niet te veel vet gebruikt. Een aantal druppels olie/vet is al genoeg
                            om de ketting te smeren.
                        </p>
                    </div>
                )
            },
            {
                title: (
                    <ContentParagaraph>
                        3. Controleer de banden en wielen van je fiets
                    </ContentParagaraph>
                ),
                content: (
                    <div>
                        <p>
                            Bij een uitgebreide onderhoudsbeurt van je fiets kan je ook aandacht
                            besteden aan je banden en wielen. Eerst kan je controleren of de wielen
                            nog recht zijn door ze te laten draaien en kijken of er geen slag in het
                            wiel zit. Als er een slag in zit dan zie je het wiel zwabberen.
                        </p>
                        <p>
                            Ook de banden verdienen het om regelmatig eens nagekeken te worden. Ga
                            daarom na of er geen steentjes of kleine sneetjes te vinden zijn in je
                            banden. Controleer daarnaast ook eens het profiel en kijk of deze niet
                            versleten zijn. Het profiel van de fietsband wordt in de loop van de
                            tijd steeds gladder. Hoe gladder de banden, hoe minder grip je hebt op
                            de weg. Wanneer je de de anti-leklaag tevoorschijn ziet komen, dan is je
                            band zeker aan vervanging toe. Dit zie je vaak aan de veranderende kleur
                            van de laag.
                        </p>
                        <p>
                            Ten slotte check je ook beter regelmatig de bandenspanning. Een te lage
                            bandenspanning zorgt er namelijk voor dat je banden sneller gaan slijten
                            en het risico op lekke banden vermindert drastisch. Bovendien is het ook
                            veel prettiger rijden op banden met de juiste spanning. De juiste
                            spanning hangt af van het soort band waar je op rijdt. Wanneer je niet
                            zeker bent over de bandenspanning kan je die altijd terugvinden op de
                            zijkant van de band.
                        </p>
                    </div>
                )
            },
            {
                title: <ContentParagaraph>4. Controleer je remmen</ContentParagaraph>,
                content: (
                    <p>
                        Natuurlijk is het belangrijk om ervoor te zorgen dat je fiets goed en soepel
                        blijft rijden, maar wat zeker net zo belangrijk is, is dat je fiets goed
                        blijft remmen. Goede remblokjes zijn essentieel wanneer je gaat fietsen.
                        Controleer daarom geregeld of de voorrem en achterrem goed werken. Merk je
                        dat één van de remmen minder soepel werkt of moet je de handel ver inknijpen
                        voordat hij werkt? Laat je remmen dan best nakijken door een fietsenmaker.{' '}
                    </p>
                )
            },
            {
                title: <ContentParagaraph>5. Je batterij langer laten meegaan</ContentParagaraph>,
                content: (
                    <div key={1}>
                        <p>
                            Elektrische fietsen beschikken over een batterij waar wat extra aandacht
                            aan moet geschonken worden. Enkele aandachtspunten:
                        </p>
                        <ul>
                            <li>
                                Extreme koude is nooit goed voor accu’s. Zorg er dus voor dat je de
                                accu’s niet in een koude omgeving laat staan of liggen.
                            </li>
                            <li>
                                Haal de accu’s van de fiets en leg ze bij voorkeur ergens binnen
                                waar ze op kamertemperatuur bewaard kunnen worden.
                            </li>
                            <li>
                                Zet je fiets nooit weg met een lege accu. Laad een lege accu zo snel
                                mogelijk weer op. Iedere accu loopt namelijk na verloop van tijd
                                leeg, ook wanneer je de fiets niet gebruikt. Een accu die leeg is en
                                niet wordt opgeladen kan door zelfontlading een diepteontlading
                                krijgen en defect raken.
                            </li>
                            <li>
                                Stal je je fiets de hele dag buiten? Op het werk of bij het station?
                                Neem dan de afneembare accu(‘s) mee en bewaar deze droog en koel.
                            </li>
                        </ul>
                        <p>
                            Gemiddeld houdt een fietsaccu het tussen de vijf en acht jaar vol, maar
                            de levensduur hangt natuurlijk voor een groot deel samen met hoe
                            intensief je hem gebruikt en hoe vaak je hem oplaadt.
                        </p>
                    </div>
                )
            },
            {
                title: (
                    <ContentParagaraph>
                        6. Je fiets af en toe laten nakijken door de fietsenwinkel
                    </ContentParagaraph>
                ),
                content: (
                    <p>
                        Ondanks dat je veel onderhoud al zelf thuis kunt doen, is het toch slim om
                        je fiets af en toe grondig na te laten kijken. Een professional kan dingen
                        ontdekken die je zelf over het hoofd hebt gezien of dingen vervangen die je
                        zelf niet kunt vervangen. Door bovenstaande tips en deze tip te volgen, kun
                        je de gebruiksduur van je fiets en fietsonderdelen optimaal benutten.{' '}
                    </p>
                )
            }
        ],
        listItems: [
            {
                title: 'Fietsen in alle weersomstandigheden',
                subTitle: '05/10/2022',
                src: bannerImages[1],
                endpoint: '/blogpost/2'
            },
            {
                title: 'Waarom naar het werk fietsen goed voor je is',
                subTitle: '05/10/2022',
                src: bannerImages[2],
                endpoint: '/blogpost/3'
            },
            {
                title: 'Hoe de juiste fiets kiezen',
                subTitle: '05/10/2022',
                src: bannerImages[3],
                endpoint: '/blogpost/4'
            }
        ]
    },
    {
        key: '2',
        bannerImg: bannerImages[1],
        title: 'Fietsen in alle weersomstandigheden',
        subtitle: '05/06/2023',
        paragraphs: [
            {
                title: <></>,
                content: (
                    <p>
                        Fietsen is geweldig. Je laat files links liggen, maakt korte metten met
                        opkomende stress en je werkt bijna ongemerkt aan je conditie én een beter
                        milieu. En die regenbuien? Daar fiets je fluitend doorheen. Je geheim?
                        Slimme fietskleding en accessoires.
                    </p>
                )
            },
            {
                title: <ContentParagaraph>1. Gebruik goede regenwerende kleding</ContentParagaraph>,
                content: (
                    <p>
                        Wil je consequent op de fiets gaan, dan kom je niet onder een goed regenpak
                        of goede regenjas uit. Kies een regenpak dat nauw aansluit, zodat je
                        comfortabel kunt blijven fietsen. Ook is het fijn als je het regenpak tot
                        klein formaat kan opvouwen, en het in een tasje standaard in je fietstas
                        laat zitten. Je kunt voor een regenpak kiezen of gaan voor een
                        allround-fietsbroek in combinatie met een sportieve waterafstotende fietsjas
                        met capuchon. Het is aan jou om te bepalen wat jij het prettigst vindt.
                    </p>
                )
            },
            {
                title: (
                    <ContentParagaraph>
                        2. Kleed je voldoende warm als je gaat fietsen in de winter
                    </ContentParagaraph>
                ),
                content: (
                    <p>
                        Warme kledij is een must en daarbij geldt één regel: laagjes, laagjes en nog
                        eens laagjes. Ga voor kledij die ademt. Zo zul je minder zweten en fiets je
                        warm de winter door.
                    </p>
                )
            },
            {
                title: (
                    <ContentParagaraph>
                        3. Bescherm je handen en voeten tegen de kou
                    </ContentParagaraph>
                ),
                content: (
                    <p>
                        Niets zo demotiverend als verkleumde voeten en handen. Met waterdichte
                        overschoenen en handschoenen kom je al een heel eind. Dikke kousen zijn dan
                        weer een afrader. Hierdoor knellen je schoenen meer en vermindert je
                        bloedsomloop, wat leidt tot koude voeten. Kies liever een dun, maar water-
                        en winddicht paar kousen.
                    </p>
                )
            },
            {
                title: (
                    <ContentParagaraph>
                        4. Maak je fiets winterproof voor een veilige rit
                    </ContentParagaraph>
                ),
                content: (
                    <p>
                        Investeer in goed materiaal waar je ook in nat weer op kunt vertrouwen,
                        zoals winterbanden en nieuwe remblokken. In de nattere maanden vergroot
                        namelijk je remafstand door het vaak gladde wegdek. Een spatbord is trouwens
                        geen overbodige luxe. Zo bescherm je jezelf én je fietsonderdelen tegen
                        water en vuil.
                    </p>
                )
            },
            {
                title: <ContentParagaraph>5. Zorg dat je gezien wordt</ContentParagaraph>,
                content: (
                    <p>
                        Je ziet er misschien liever niet uit als een ‘fietsende kerstboom’, maar het
                        is uiterst belangrijk dat je opvalt in het donker. Ook overdag kun je het
                        best lichtgevende accessoires voorzien. Controleer voor je vertrekt of je
                        fietslichten nog wel werken. Een fluohesje, reflecterend harnas of
                        spaakreflectoren verhogen je zichtbaarheid.
                    </p>
                )
            }
        ],
        listItems: [
            {
                title: 'Waarom naar het werk fietsen goed voor je is',
                subTitle: '05/10/2022',
                src: bannerImages[2],
                endpoint: '/blogpost/3'
            },
            {
                title: 'Hoe de juiste fiets kiezen',
                subTitle: '05/10/2022',
                src: bannerImages[3],
                endpoint: '/blogpost/4'
            },
            {
                title: 'De 7 tips om je fiets diefstal-proof te maken',
                subTitle: '05/10/2022',
                src: bannerImages[4],
                endpoint: '/blogpost/5'
            }
        ]
    },
    {
        key: '3',
        bannerImg: bannerImages[2],
        title: 'Waarom naar het werk fietsen goed voor je is',
        subtitle: '05/06/2023',
        paragraphs: [
            {
                title: (
                    <ContentParagaraph>
                        1. Fietsen is goedkoper dan met de auto naar het werk gaan
                    </ContentParagaraph>
                ),
                content: (
                    <p>
                        De steeds stijgende brandstofprijzen, onderhoud van je wagen, verzekering,
                        taksen,… het hoeft niet gezegd dat zich met de auto verplaatsen veel duurder
                        is dan een verplaatsing met de fiets. Daarnaast geven veel bedrijven een
                        fietsvergoeding aan werknemers die naar het werk fietsen. Win-win!
                    </p>
                )
            },
            {
                title: <ContentParagaraph>2. Gratis work-out</ContentParagaraph>,
                content: (
                    <div>
                        <p>
                            Met uitzondering van de aankoop van een fiets en een occasionele
                            herstelling kost fietsen je amper iets. Je moet je heus niet blauw
                            betalen aan een fitnessabonnement om dagelijks een beetje te sporten.
                            Iedere dag met de fiets naar het werk is een activiteit die niet alleen
                            goed is voor je gezondheid, maar je bovendien ook meer energie geeft.
                        </p>
                        <p>
                            Dat fietsen goed is voor je gezondheid werd ook geconcludeerd uit een
                            studie van het Nederlandse onderzoeksinstituut TNO. Des te vaker mensen
                            fietsen en des te groter de afstand die zij afleggen, des te minder zij
                            zich bovendien ziek melden, zo blijkt.
                        </p>
                    </div>
                )
            },
            {
                title: <ContentParagaraph>3. Bye bye, file!</ContentParagaraph>,
                content: (
                    <p>
                        Zeg vaarwel tegen die frustraties die spontaan opwellen wanneer je weeral in
                        een file je tijd staat te verspillen. Vooral kleine afstanden kunnen soms
                        zelfs sneller met de fiets worden afgelegd dan met de auto, gewoonweg omdat
                        het verkeer op drukke momenten helemaal vast komt te staan.
                    </p>
                )
            },
            {
                title: <ContentParagaraph>4. Zeg vaarwel tegen parkeerproblemen</ContentParagaraph>,
                content: (
                    <p>
                        Sommige bedrijven beschikken niet over een gigantische (gratis) autoparking,
                        waardoor het wel eens vloeken kan zijn om een parkeerplaats te vinden
                        dichtbij het werk. Een fiets kan je daarentegen veel makkelijker kwijt. Een
                        goede investering in dat geval is uiteraard een degelijk fietsslo
                    </p>
                )
            },
            {
                title: <ContentParagaraph>5. Van fietsen word je gelukkiger</ContentParagaraph>,
                content: (
                    <p>
                        Mensen die fietsen naar het werk zijn gelukkiger, zo blijkt uit onderzoek.
                        Met name de fietstocht naar het werk is het perfecte moment om te
                        ontspannen. Het geeft je tijd om je mentaal voor te bereiden op alles wat op
                        kantoor wacht. Andersom is het na een lange werkdag de perfecte manier om je
                        hoofd even leeg te maken. Fietsen heeft namelijk aantoonbare fysiologische
                        effecten op stress en depressie. Daarnaast heb je als fietser meer grip op
                        je aankomsttijd, doordat je files vermijdt en kun je meer genieten van je
                        omgeving.
                    </p>
                )
            },
            {
                title: <ContentParagaraph>6. Minder CO2-uitstoot</ContentParagaraph>,
                content: (
                    <p>
                        Zonder CO2-uitstoot heen-en-weer naar je bestemming: het kan! Op lopen na,
                        is er geen manier van reizen zo milieuvriendelijk als de gewone fiets. De
                        gemiddelde CO2-uitstoot van één kilometer rijden met een normale auto is
                        0,22 kg. Met een elektrische fiets die met grijze stroom wordt opgeladen is
                        dat 0,007 kg, ofwel 97 procent lager. En op de reguliere fiets? Daarmee
                        beperk je jouw uitstoot tot nul! Door vaker de fiets te pakken naar het werk
                        kun je dus heel wat CO2 besparen en jouw impact op het klimaat verkleinen.
                    </p>
                )
            }
        ],
        listItems: [
            {
                title: 'Hoe de juiste fiets kiezen',
                subTitle: '05/10/2022',
                src: bannerImages[3],
                endpoint: '/blogpost/4'
            },
            {
                title: 'De 7 tips om je fiets diefstal-proof te maken',
                subTitle: '05/10/2022',
                src: bannerImages[4],
                endpoint: '/blogpost/5'
            },
            {
                title: '6 tips voor fietsonderhoud',
                subTitle: '05/10/2022',
                src: bannerImages[0],
                endpoint: '/blogpost/1'
            }
        ]
    },
    {
        key: '4',
        bannerImg: bannerImages[3],
        title: 'Hoe de juiste fiets kiezen',
        subtitle: '05/10/2022',
        paragraphs: [
            {
                title: <ContentParagaraph>1. Soort fiets</ContentParagaraph>,
                content: (
                    <div>
                        <p>
                            Hoe wil je zitten? Met een hoog stuur zit je rechtop en kun je goed om
                            je heen kijken. Het is een fijne houding voor samen fietsen. Maar als je
                            een langere afstand fietst is een sportieve houding comfortabeler.
                            Bovendien krijg je minder snel zadelpijn. Op een racefiets waarop het
                            stuur lager staat heb je de minste luchtweerstand en het minste gewicht
                            op het zadel, maar die houding is wel zwaarder voor het bovenlichaam.
                            Hoeveel kilometer wil je afleggen? Volstaat een goede stadsfiets of heb
                            je toch eerder een tourfiets nodig? Staat je fiets veel buiten op ongure
                            plekken? Dan zijn kwetsbare versnellingen met loshangende kabels niet zo
                            handig. Moet je er boodschappen op kunnen vervoeren? Dan is een
                            bagagedrager wel zo handig.
                        </p>
                        <ContentUnderline>A Robuuste eenvoudige stadsfiets</ContentUnderline>
                        <ul>
                            <li>Terugtraprem</li>
                            <li>Rechtop zit</li>
                            <li>Geen versnellingen</li>
                            <li>Gewicht niet zo belangrijk. Robuustheid telt zwaarder</li>
                            <li>Onderhoudsarm</li>
                        </ul>
                        <ContentUnderline>
                            B Luxe stadsfiets waarmee je grotere afstanden kan afleggen
                        </ContentUnderline>
                        <ul>
                            <li>V-brakes of hydraulische schrijfremmen</li>
                            <li>Rechtop zit</li>
                            <li>3 of 8 versnellingen</li>
                            <li>Vering</li>
                            <li>Naafdynamo met goede kwaliteit koplamp</li>
                            <li>
                                Moet vanwege kwetsbare onderdelen als versnellingen ‘s nachts binnen
                                kunnen staan
                            </li>
                        </ul>
                        <ContentUnderline>C Toerfiets voor lange afstanden</ContentUnderline>
                        <ul>
                            <li>Sportieve zit</li>
                            <li>Derailleur</li>
                            <li>27 versnellingen</li>
                            <li>Hydraulische velgremmen</li>
                            <li>Moet binnen kunnen staan</li>
                        </ul>

                        <ContentUnderline>C Toerfiets voor lange afstanden</ContentUnderline>
                        <p>
                            Of is het nog niet zo duidelijk wat je wilt? De meeste robuuste
                            stadsfietsen hebben een rechtop zit. Maar er zijn er ook met sportieve
                            zit. En als je op comfort gesteld bent, kies je voor een stadsfiets met
                            dikke ballonbanden. Sommige fietsmerken bieden de mogelijkheid om jouw
                            favoriete model helemaal naar wens uit te rusten.
                        </p>
                    </div>
                )
            },
            {
                title: <ContentParagaraph>2. Framemaat</ContentParagaraph>,
                content: (
                    <>
                        Een passende framemaat voorkomt zadelpijn, knieklachten, rugpijn of
                        schouderpijn. Een vuistregel voor het vaststellen van de framemaat is: 2/3
                        van de binnenbeenlengte, gemeten vanaf de lies tot aan de onderkant van de
                        voet. Over het algemeen hebben vrouwen hebben iets langere benen dan mannen.
                        Uitgaan van iemands lengte leidt dus makkelijk tot een verkeerde framemaat.
                        Het is maar een indicatie. Erg lange en erg kleine mensen kunnen het beste
                        een fiets op maat laten maken.
                    </>
                )
            },
            {
                title: <ContentParagaraph>3. Versnellingen of niet</ContentParagaraph>,
                content: (
                    <p>
                        Kies als je versnellingen wilt voor 3 of 8 versnellingen op je stadsfiets.
                        Op vakantiefietsen zit vaak een naafversnelling. Tamelijk ideaal. Want met
                        de naaf heb je altijd genoeg versnellingen, de naaf is nagenoeg
                        onderhoudsvrij, duurzaam en het rendement lijkt nauwelijks slechter dan van
                        derailleurversnellingen. Het enige nadeel is eigenlijk de prijs. Op
                        mountainbikes en racefietsen zit doorgaans een derailleurversnelling: lekker
                        licht en hoog rendement, maar wel kwetsbaar.
                    </p>
                )
            },
            {
                title: <ContentParagaraph>4. Remmen</ContentParagaraph>,
                content: (
                    <div>
                        <p>
                            De beste remmen zijn over het algemeen hydraulische schrijfremmen.
                            Vroeger zaten ze alleen op mountainbikes, maar tegenwoordig zitten ze
                            ook op elektrische fietsen en luxe toerfietsen. Ze werken prima onder
                            alle omstandigheden en de velgen slijten niet. Het grote nadeel is dat
                            het systeem kwetsbaar is. Ze lopen snel aan. De terugtraprem is robuust,
                            maar het duurt lang voordat je stilstaat in vergelijking met andere
                            remmen. Niet zo handig als je een berg afrijdt of met hoge snelheid door
                            de stad rijdt.
                        </p>
                        <p>
                            Op luxere stadsfietsen en recreatieve fietsen zitten vaak rollerbrake
                            remmen. Ze hebben weinig onderhoud nodig en slijten minder dan
                            velgremmen. Nadeel is dat de remkracht minder goed te doseren is. Als je
                            het achterwiel los wilt halen, moet je een kabeltje losmaken. Velgremmen
                            zijn prima als het droog weer is, maar minder goed als het regent. Een
                            ander nadeel is dat de velgen ervan slijten. Voordeel is dat het
                            achterwiel makkelijk te verwijderen is.
                        </p>
                        <p>
                            V-brakes zitten op goedkopere vakantiefietsen, sportieve hybrides en
                            elektrische fietsen. Ze zijn eenvoudig, goedkoop en goed. Een nadeel is
                            dat het plaatsen van nieuwe remblokjes een secuur werkje is. Maar dat
                            kun je natuurlijk ook door de fietsenmaker laten doen. Magura-velgremmen
                            zijn verbeterde velgremmen. Ze zijn hydraulisch, dus zonder kabelremmen.
                            Het voordeel daarvan is dat ze niet kunnen vastvriezen. Het nadeel is
                            dat een hydraulisch systeem wat kwetsbaarder is bij transport en in de
                            stalling. Magura-velgremmen zijn bijna onderhoudsvrij. Ze zijn wel wat
                            duurder dan V-brakes.
                        </p>
                    </div>
                )
            },
            {
                title: <ContentParagaraph>5. Breedte van de velg</ContentParagaraph>,
                content: (
                    <p>
                        De klassieke stadsfietsband (30 a 45 mm) is een redelijk compromis tussen
                        snelheid, comfort en gewicht, maar heeft als nadeel dat je er bijvoorbeeld
                        niet onverhard mee kan fietsen. Je kunt ook kiezen voor een fiets met brede
                        ballonbanden (meer dan 45 mm). Zulke banden rijden comfortabeler, je valt
                        minder snel als je een onverwachte hobbel tegenkomt en ze fietsen ook nog
                        prima als ze minder hard zijn opgepompt. Je kunt wel bredere banden zetten
                        op smalle velgen, maar als je ballonbanden wilt, kun je beter bij aankoop al
                        voor een wiel met brede velgen kiezen.
                    </p>
                )
            },
            {
                title: <ContentParagaraph>6. Welk merk?</ContentParagaraph>,
                content: (
                    <p>
                        Ook goede merken maken ook wel eens slechte fietsen, dus een garantie geven
                        bij een merk is onmogelijk. Rondkijken op internet of raad vragen aan je
                        fietsenwinkel kan wat dat betreft veel informatie opleveren.{' '}
                    </p>
                )
            },
            {
                title: (
                    <ContentParagaraph>
                        7. Hoe belangrijk is het gewicht van een fiets?
                    </ContentParagaraph>
                ),
                content: (
                    <div>
                        <p>
                            Voor het fietsen zelf is het gewicht van een fiets doorgaans niet zo
                            belangrijk. Het is wel van belang als je de fiets op een fietsdrager
                            moet tillen of een helling op moet duwen. Over het algemeen geldt dat
                            besparen op gewicht het makkelijkst is als je onderdelen weglaat.
                        </p>
                        <p>
                            De meeste frames zijn gemaakt van staal of aluminium. De beide
                            materialen hebben voor- en nadelen. Zo is een stalen frame duurzaam en
                            solide en kan het tegen een stootje. Het is echter een stuk zwaarder dan
                            een aluminium frame. Ook roest aluminium minder snel dan staal bij
                            eventuele lakbeschadiging van het frame. Racefietsen zijn meestal
                            gemaakt van aluminium of carbon frames. Carbon is erg licht en zeer
                            sterk.
                        </p>
                    </div>
                )
            }
        ],
        listItems: [
            {
                title: 'De 7 tips om je fiets diefstal-proof te maken',
                subTitle: '05/10/2022',
                src: bannerImages[4],
                endpoint: '/blogpost/5'
            },
            {
                title: '6 tips voor fietsonderhoud',
                subTitle: '05/10/2022',
                src: bannerImages[0],
                endpoint: '/blogpost/1'
            },
            {
                title: 'Fietsen in alle weersomstandigheden',
                subTitle: '05/10/2022',
                src: bannerImages[1],
                endpoint: '/blogpost/2'
            }
        ]
    },
    {
        key: '5',
        bannerImg: bannerImages[4],
        title: 'De 7 tips om je fiets diefstal-proof te maken',
        subtitle: '05/10/2022',
        paragraphs: [
            {
                title: <ContentParagaraph>Tip 1: Zet je fiets goed op slot</ContentParagaraph>,
                content: (
                    <p>
                        Nogal voor de hand liggend, zou je denken. Maar toch is er een verschil
                        tussen je fiets op slot zetten, en je fiets goed op slot zetten. Veel
                        fietsen zijn uitgerust met een standaard ringslot. Prima voor snelle
                        beveiliging, maar over het algemeen draait een fietsendief hier zijn of haar
                        hand niet voor om. Zorg er daarom voor dat je altijd minstens twee soorten
                        sloten hebt op één fiets. Fietsendieven specialiseren zich vaak in één type
                        slot, dus met twee sloten is jouw fiets ineens een stuk minder
                        aantrekkelijk. Onder het goed op slot zetten verstaan we ook: koop
                        gecertificeerde sloten. En daar volgt meteen tip nummer 2.
                    </p>
                )
            },
            {
                title: <ContentParagaraph>Tip 2: Koop een gecertificeerd slot</ContentParagaraph>,
                content: (
                    <p>
                        Het ART Keurmerk is het leidinggevende certificaat voor sloten. Sloten
                        krijgen van ART een beoordeling van 1 tot en met 5 sterren. Hoe meer
                        sterren, hoe hoger de preventieve waarde tegen diefstal. Minimum een
                        ART2-slot is steeds verplicht in elke leasing.
                    </p>
                )
            },
            {
                title: <ContentParagaraph>Tip 3: Maak je fiets vast</ContentParagaraph>,
                content: (
                    <p>
                        Wist je dat het verzekeringsgewijs verplicht is je fiets aan een vast punt
                        vast te maken? Door je fiets aan een vast punt, zoals een paal, een
                        fietsenrek of bijvoorbeeld een brug, vast te maken, kan een fietsendief deze
                        niet gemakkelijk meenemen. Een slot dat geschikt is om de fiets te
                        verankeren aan een vast punt dient daarom steeds opgenomen te worden in elke
                        leasing. Trouwens, onze afdeling Fietsparkeren.net kan jou of je werkgever
                        verder helpen met oplossingen voor het veilig en netjes parkeren van je
                        fiets.
                    </p>
                )
            },
            {
                title: <ContentParagaraph>Tip 4: Zet je fiets slim vast</ContentParagaraph>,
                content: (
                    <p>
                        Een vervolgtip op nummer 3: wees slim bij het op slot zetten. Je fiets enkel
                        aan het voorwiel ergens aan vast maken is bijvoorbeeld geen goed idee. Het
                        is voor een dief geen probleem om het voorwiel achter te laten en de rest
                        van je fiets mee te nemen. Maak je fiets ook niet vast aan het midden van je
                        frame. Een dief kan de fiets dan draaien om extra kracht te zetten en zo het
                        slot open te breken. Dit geldt vooral bij het gebruik van een U-slot.
                    </p>
                )
            },
            {
                title: <ContentParagaraph>Tip 5: Maak gebruik van je ventiel</ContentParagaraph>,
                content: (
                    <p>
                        Dat klinkt een beetje vreemd, maar het is een tip van een oud-fietsendief
                        die erg effectief blijkt te zijn. Als je je fiets op slot zet met een
                        ringslot (dus je standaard slot), zorg er dan voor dat je ventiel in de
                        buurt zit van het slot. Een fietsendief probeert je slot door te knippen, en
                        natuurlijk de rest van de fiets intact te houden. Als jij je ventiel dicht
                        bij het slot hebt zitten, knippen ze vaak ook door het ventiel heen waardoor
                        de band leegloopt. De dief heeft dan niets meer aan jouw fiets. Zien dieven
                        dit, dan beginnen ze er vaak niet eens aan.
                    </p>
                )
            },
            {
                title: <ContentParagaraph>Tip 6: Maak je fiets hoog vast</ContentParagaraph>,
                content: (
                    <p>
                        Hiermee bedoelen we je fiets vastmaken met een ketting-, vouw-, of
                        beugelslot. Als je deze aan een paal vastmaakt en vervolgens op de grond
                        laat liggen, kan een dief de fiets gemakkelijker stelen. Ze gebruiken dan
                        namelijk de grond om extra druk op hun materiaal te zetten. Dat geeft ze
                        extra kracht, en dus een gemakkelijkere vangst. Als je je fiets hoger
                        vastmaakt (aan het frame bijvoorbeeld), dan geef je ze die kans niet. Bij
                        kou helpt dit je ook nog eens: een hangend slot bevriest minder snel dan een
                        slot dat op de grond ligt.
                    </p>
                )
            },
            {
                title: (
                    <ContentParagaraph>Tip 7: Zorg dat je fiets herkenbaar is</ContentParagaraph>
                ),
                content: (
                    <p>
                        ‘Maar ik herken mijn eigen fiets toch wel?’ Dat geloven we zeker, maar niet
                        iedereen herkent jouw fiets. Als je jouw gegevens laat graveren op een
                        herkenbare plek, bijvoorbeeld bovenop het frame, dan zien anderen ook direct
                        om wiens fiets het gaat. Een dief pakt deze fietsen liever niet, want er is
                        een grote kans dat ze dan gepakt worden (aangezien de fiets aantoonbaar niet
                        van hem of haar is). Maar dit is ook ideaal voor wanneer je fiets tóch
                        gestolen wordt en je hem graag terug wilt hebben. Aan de hand van je
                        gegevens op de fiets, kunnen anderen dan contact met je opnemen.
                    </p>
                )
            }
        ],
        listItems: [
            {
                title: '6 tips voor fietsonderhoud',
                subTitle: '05/10/2022',
                src: bannerImages[0],
                endpoint: '/blogpost/1'
            },
            {
                title: 'Fietsen in alle weersomstandigheden',
                subTitle: '05/10/2022',
                src: bannerImages[1],
                endpoint: '/blogpost/2'
            },
            {
                title: 'Waarom naar het werk fietsen goed voor je is',
                subTitle: '05/10/2022',
                src: bannerImages[2],
                endpoint: '/blogpost/3'
            }
        ]
    }
]

export const postsEN: Blogpost[] = [
    {
        key: '1',
        bannerImg: bannerImages[0],
        title: '6 tips voor fietsonderhoud',
        subtitle: '05/06/2023',
        paragraphs: [
            {
                title: <></>,
                content: (
                    <p>
                        Om de gebruiksduur van je fiets optimaal te benutten is het noodzakelijk om
                        hem goed te onderhouden. Echte onderhoudswerken kun je laten doen bij de
                        fietsenwinkel, maar een simpel nazicht kun je ook eenvoudig zelf doen. Een
                        belangrijk punt om rekening mee te houden: wacht niet met onderhoud tot het
                        nodig is. Zorg er gewoon voor dat je verschillende onderdelen van je fiets
                        regelmatig nakijkt. Met deze tips kun je jouw fiets in goede conditie
                        houden!
                    </p>
                )
            },
            {
                title: <ContentParagaraph>1. Maak je fiets regelmatig schoon</ContentParagaraph>,
                content: (
                    <p>
                        Om de gebruiksduur van je fiets optimaal te benutten is het noodzakelijk om
                        hem goed te onderhouden. Echte onderhoudswerken kun je laten doen bij de
                        fietsenwinkel, maar een simpel nazicht kun je ook eenvoudig zelf doen. Een
                        belangrijk punt om rekening mee te houden: wacht niet met onderhoud tot het
                        nodig is. Zorg er gewoon voor dat je verschillende onderdelen van je fiets
                        regelmatig nakijkt. Met deze tips kun je jouw fiets in goede conditie
                        houden!
                    </p>
                )
            },
            {
                title: <ContentParagaraph>2. Regelmatig ontvetten en smeren</ContentParagaraph>,
                content: (
                    <div>
                        <p>
                            Je ketting en andere onderdelen voor de aandrijving moeten af en toe
                            ontvet en opnieuw gesmeerd worden. Eerst ontvetten en schoonmaken, en
                            als het goed schoon en droog is kun je opnieuw gaan smeren. Om je
                            ketting te smeren kun je het beste een speciale fietsolie gebruiken die
                            vuilwerend en waterafstotend is, en geen gewone olie omdat deze vuil
                            juist extra aantrekt. Hoe vaak je de onderdelen van je fiets moet
                            smeren, hangt af van hoe vaak en waar je gaat rijden.
                        </p>
                        <p>
                            De ketting, derailleur en remmen dienen regelmatig gesmeerd te worden.
                            Wat je niet smeert: de cassette. Het wordt zelf afgeraden deze te
                            smeren, een vette cassette trekt extra vuil aan. Belangrijk is ook dat
                            je niet te veel vet gebruikt. Een aantal druppels olie/vet is al genoeg
                            om de ketting te smeren.
                        </p>
                    </div>
                )
            },
            {
                title: (
                    <ContentParagaraph>
                        3. Controleer de banden en wielen van je fiets
                    </ContentParagaraph>
                ),
                content: (
                    <div>
                        <p>
                            Bij een uitgebreide onderhoudsbeurt van je fiets kan je ook aandacht
                            besteden aan je banden en wielen. Eerst kan je controleren of de wielen
                            nog recht zijn door ze te laten draaien en kijken of er geen slag in het
                            wiel zit. Als er een slag in zit dan zie je het wiel zwabberen.
                        </p>
                        <p>
                            Ook de banden verdienen het om regelmatig eens nagekeken te worden. Ga
                            daarom na of er geen steentjes of kleine sneetjes te vinden zijn in je
                            banden. Controleer daarnaast ook eens het profiel en kijk of deze niet
                            versleten zijn. Het profiel van de fietsband wordt in de loop van de
                            tijd steeds gladder. Hoe gladder de banden, hoe minder grip je hebt op
                            de weg. Wanneer je de de anti-leklaag tevoorschijn ziet komen, dan is je
                            band zeker aan vervanging toe. Dit zie je vaak aan de veranderende kleur
                            van de laag.
                        </p>
                        <p>
                            Ten slotte check je ook beter regelmatig de bandenspanning. Een te lage
                            bandenspanning zorgt er namelijk voor dat je banden sneller gaan slijten
                            en het risico op lekke banden vermindert drastisch. Bovendien is het ook
                            veel prettiger rijden op banden met de juiste spanning. De juiste
                            spanning hangt af van het soort band waar je op rijdt. Wanneer je niet
                            zeker bent over de bandenspanning kan je die altijd terugvinden op de
                            zijkant van de band.
                        </p>
                    </div>
                )
            },
            {
                title: <ContentParagaraph>4. Controleer je remmen</ContentParagaraph>,
                content: (
                    <p>
                        Natuurlijk is het belangrijk om ervoor te zorgen dat je fiets goed en soepel
                        blijft rijden, maar wat zeker net zo belangrijk is, is dat je fiets goed
                        blijft remmen. Goede remblokjes zijn essentieel wanneer je gaat fietsen.
                        Controleer daarom geregeld of de voorrem en achterrem goed werken. Merk je
                        dat één van de remmen minder soepel werkt of moet je de handel ver inknijpen
                        voordat hij werkt? Laat je remmen dan best nakijken door een fietsenmaker.{' '}
                    </p>
                )
            },
            {
                title: <ContentParagaraph>5. Je batterij langer laten meegaan</ContentParagaraph>,
                content: (
                    <div key={1}>
                        <p>
                            Elektrische fietsen beschikken over een batterij waar wat extra aandacht
                            aan moet geschonken worden. Enkele aandachtspunten:
                        </p>
                        <ul>
                            <li>
                                Extreme koude is nooit goed voor accu’s. Zorg er dus voor dat je de
                                accu’s niet in een koude omgeving laat staan of liggen.
                            </li>
                            <li>
                                Haal de accu’s van de fiets en leg ze bij voorkeur ergens binnen
                                waar ze op kamertemperatuur bewaard kunnen worden.
                            </li>
                            <li>
                                Zet je fiets nooit weg met een lege accu. Laad een lege accu zo snel
                                mogelijk weer op. Iedere accu loopt namelijk na verloop van tijd
                                leeg, ook wanneer je de fiets niet gebruikt. Een accu die leeg is en
                                niet wordt opgeladen kan door zelfontlading een diepteontlading
                                krijgen en defect raken.
                            </li>
                            <li>
                                Stal je je fiets de hele dag buiten? Op het werk of bij het station?
                                Neem dan de afneembare accu(‘s) mee en bewaar deze droog en koel.
                            </li>
                        </ul>
                        <p>
                            Gemiddeld houdt een fietsaccu het tussen de vijf en acht jaar vol, maar
                            de levensduur hangt natuurlijk voor een groot deel samen met hoe
                            intensief je hem gebruikt en hoe vaak je hem oplaadt.
                        </p>
                    </div>
                )
            },
            {
                title: (
                    <ContentParagaraph>
                        6. Je fiets af en toe laten nakijken door de fietsenwinkel
                    </ContentParagaraph>
                ),
                content: (
                    <p>
                        Ondanks dat je veel onderhoud al zelf thuis kunt doen, is het toch slim om
                        je fiets af en toe grondig na te laten kijken. Een professional kan dingen
                        ontdekken die je zelf over het hoofd hebt gezien of dingen vervangen die je
                        zelf niet kunt vervangen. Door bovenstaande tips en deze tip te volgen, kun
                        je de gebruiksduur van je fiets en fietsonderdelen optimaal benutten.{' '}
                    </p>
                )
            }
        ],
        listItems: [
            {
                title: 'Fietsen in alle weersomstandigheden',
                subTitle: '05/10/2022',
                src: bannerImages[1],
                endpoint: '/blogpost/2'
            },
            {
                title: 'Waarom naar het werk fietsen goed voor je is',
                subTitle: '05/10/2022',
                src: bannerImages[2],
                endpoint: '/blogpost/3'
            },
            {
                title: 'Hoe de juiste fiets kiezen',
                subTitle: '05/10/2022',
                src: bannerImages[3],
                endpoint: '/blogpost/4'
            }
        ]
    },
    {
        key: '2',
        bannerImg: bannerImages[1],
        title: 'Fietsen in alle weersomstandigheden',
        subtitle: '05/06/2023',
        paragraphs: [
            {
                title: <></>,
                content: (
                    <p>
                        Fietsen is geweldig. Je laat files links liggen, maakt korte metten met
                        opkomende stress en je werkt bijna ongemerkt aan je conditie én een beter
                        milieu. En die regenbuien? Daar fiets je fluitend doorheen. Je geheim?
                        Slimme fietskleding en accessoires.
                    </p>
                )
            },
            {
                title: <ContentParagaraph>1. Gebruik goede regenwerende kleding</ContentParagaraph>,
                content: (
                    <p>
                        Wil je consequent op de fiets gaan, dan kom je niet onder een goed regenpak
                        of goede regenjas uit. Kies een regenpak dat nauw aansluit, zodat je
                        comfortabel kunt blijven fietsen. Ook is het fijn als je het regenpak tot
                        klein formaat kan opvouwen, en het in een tasje standaard in je fietstas
                        laat zitten. Je kunt voor een regenpak kiezen of gaan voor een
                        allround-fietsbroek in combinatie met een sportieve waterafstotende fietsjas
                        met capuchon. Het is aan jou om te bepalen wat jij het prettigst vindt.
                    </p>
                )
            },
            {
                title: (
                    <ContentParagaraph>
                        2. Kleed je voldoende warm als je gaat fietsen in de winter
                    </ContentParagaraph>
                ),
                content: (
                    <p>
                        Warme kledij is een must en daarbij geldt één regel: laagjes, laagjes en nog
                        eens laagjes. Ga voor kledij die ademt. Zo zul je minder zweten en fiets je
                        warm de winter door.
                    </p>
                )
            },
            {
                title: (
                    <ContentParagaraph>
                        3. Bescherm je handen en voeten tegen de kou
                    </ContentParagaraph>
                ),
                content: (
                    <p>
                        Niets zo demotiverend als verkleumde voeten en handen. Met waterdichte
                        overschoenen en handschoenen kom je al een heel eind. Dikke kousen zijn dan
                        weer een afrader. Hierdoor knellen je schoenen meer en vermindert je
                        bloedsomloop, wat leidt tot koude voeten. Kies liever een dun, maar water-
                        en winddicht paar kousen.
                    </p>
                )
            },
            {
                title: (
                    <ContentParagaraph>
                        4. Maak je fiets winterproof voor een veilige rit
                    </ContentParagaraph>
                ),
                content: (
                    <p>
                        Investeer in goed materiaal waar je ook in nat weer op kunt vertrouwen,
                        zoals winterbanden en nieuwe remblokken. In de nattere maanden vergroot
                        namelijk je remafstand door het vaak gladde wegdek. Een spatbord is trouwens
                        geen overbodige luxe. Zo bescherm je jezelf én je fietsonderdelen tegen
                        water en vuil.
                    </p>
                )
            },
            {
                title: <ContentParagaraph>5. Zorg dat je gezien wordt</ContentParagaraph>,
                content: (
                    <p>
                        Je ziet er misschien liever niet uit als een ‘fietsende kerstboom’, maar het
                        is uiterst belangrijk dat je opvalt in het donker. Ook overdag kun je het
                        best lichtgevende accessoires voorzien. Controleer voor je vertrekt of je
                        fietslichten nog wel werken. Een fluohesje, reflecterend harnas of
                        spaakreflectoren verhogen je zichtbaarheid.
                    </p>
                )
            }
        ],
        listItems: [
            {
                title: 'Waarom naar het werk fietsen goed voor je is',
                subTitle: '05/10/2022',
                src: bannerImages[2],
                endpoint: '/blogpost/3'
            },
            {
                title: 'Hoe de juiste fiets kiezen',
                subTitle: '05/10/2022',
                src: bannerImages[3],
                endpoint: '/blogpost/4'
            },
            {
                title: 'De 7 tips om je fiets diefstal-proof te maken',
                subTitle: '05/10/2022',
                src: bannerImages[4],
                endpoint: '/blogpost/5'
            }
        ]
    },
    {
        key: '3',
        bannerImg: bannerImages[2],
        title: 'Waarom naar het werk fietsen goed voor je is',
        subtitle: '05/06/2023',
        paragraphs: [
            {
                title: (
                    <ContentParagaraph>
                        1. Fietsen is goedkoper dan met de auto naar het werk gaan
                    </ContentParagaraph>
                ),
                content: (
                    <p>
                        De steeds stijgende brandstofprijzen, onderhoud van je wagen, verzekering,
                        taksen,… het hoeft niet gezegd dat zich met de auto verplaatsen veel duurder
                        is dan een verplaatsing met de fiets. Daarnaast geven veel bedrijven een
                        fietsvergoeding aan werknemers die naar het werk fietsen. Win-win!
                    </p>
                )
            },
            {
                title: <ContentParagaraph>2. Gratis work-out</ContentParagaraph>,
                content: (
                    <div>
                        <p>
                            Met uitzondering van de aankoop van een fiets en een occasionele
                            herstelling kost fietsen je amper iets. Je moet je heus niet blauw
                            betalen aan een fitnessabonnement om dagelijks een beetje te sporten.
                            Iedere dag met de fiets naar het werk is een activiteit die niet alleen
                            goed is voor je gezondheid, maar je bovendien ook meer energie geeft.
                        </p>
                        <p>
                            Dat fietsen goed is voor je gezondheid werd ook geconcludeerd uit een
                            studie van het Nederlandse onderzoeksinstituut TNO. Des te vaker mensen
                            fietsen en des te groter de afstand die zij afleggen, des te minder zij
                            zich bovendien ziek melden, zo blijkt.
                        </p>
                    </div>
                )
            },
            {
                title: <ContentParagaraph>3. Bye bye, file!</ContentParagaraph>,
                content: (
                    <p>
                        Zeg vaarwel tegen die frustraties die spontaan opwellen wanneer je weeral in
                        een file je tijd staat te verspillen. Vooral kleine afstanden kunnen soms
                        zelfs sneller met de fiets worden afgelegd dan met de auto, gewoonweg omdat
                        het verkeer op drukke momenten helemaal vast komt te staan.
                    </p>
                )
            },
            {
                title: <ContentParagaraph>4. Zeg vaarwel tegen parkeerproblemen</ContentParagaraph>,
                content: (
                    <p>
                        Sommige bedrijven beschikken niet over een gigantische (gratis) autoparking,
                        waardoor het wel eens vloeken kan zijn om een parkeerplaats te vinden
                        dichtbij het werk. Een fiets kan je daarentegen veel makkelijker kwijt. Een
                        goede investering in dat geval is uiteraard een degelijk fietsslo
                    </p>
                )
            },
            {
                title: <ContentParagaraph>5. Van fietsen word je gelukkiger</ContentParagaraph>,
                content: (
                    <p>
                        Mensen die fietsen naar het werk zijn gelukkiger, zo blijkt uit onderzoek.
                        Met name de fietstocht naar het werk is het perfecte moment om te
                        ontspannen. Het geeft je tijd om je mentaal voor te bereiden op alles wat op
                        kantoor wacht. Andersom is het na een lange werkdag de perfecte manier om je
                        hoofd even leeg te maken. Fietsen heeft namelijk aantoonbare fysiologische
                        effecten op stress en depressie. Daarnaast heb je als fietser meer grip op
                        je aankomsttijd, doordat je files vermijdt en kun je meer genieten van je
                        omgeving.
                    </p>
                )
            },
            {
                title: <ContentParagaraph>6. Minder CO2-uitstoot</ContentParagaraph>,
                content: (
                    <p>
                        Zonder CO2-uitstoot heen-en-weer naar je bestemming: het kan! Op lopen na,
                        is er geen manier van reizen zo milieuvriendelijk als de gewone fiets. De
                        gemiddelde CO2-uitstoot van één kilometer rijden met een normale auto is
                        0,22 kg. Met een elektrische fiets die met grijze stroom wordt opgeladen is
                        dat 0,007 kg, ofwel 97 procent lager. En op de reguliere fiets? Daarmee
                        beperk je jouw uitstoot tot nul! Door vaker de fiets te pakken naar het werk
                        kun je dus heel wat CO2 besparen en jouw impact op het klimaat verkleinen.
                    </p>
                )
            }
        ],
        listItems: [
            {
                title: 'Hoe de juiste fiets kiezen',
                subTitle: '05/10/2022',
                src: bannerImages[3],
                endpoint: '/blogpost/4'
            },
            {
                title: 'De 7 tips om je fiets diefstal-proof te maken',
                subTitle: '05/10/2022',
                src: bannerImages[4],
                endpoint: '/blogpost/5'
            },
            {
                title: '6 tips voor fietsonderhoud',
                subTitle: '05/10/2022',
                src: bannerImages[0],
                endpoint: '/blogpost/1'
            }
        ]
    },
    {
        key: '4',
        bannerImg: bannerImages[3],
        title: 'Hoe de juiste fiets kiezen',
        subtitle: '05/10/2022',
        paragraphs: [
            {
                title: <ContentParagaraph>1. Soort fiets</ContentParagaraph>,
                content: (
                    <div>
                        <p>
                            Hoe wil je zitten? Met een hoog stuur zit je rechtop en kun je goed om
                            je heen kijken. Het is een fijne houding voor samen fietsen. Maar als je
                            een langere afstand fietst is een sportieve houding comfortabeler.
                            Bovendien krijg je minder snel zadelpijn. Op een racefiets waarop het
                            stuur lager staat heb je de minste luchtweerstand en het minste gewicht
                            op het zadel, maar die houding is wel zwaarder voor het bovenlichaam.
                            Hoeveel kilometer wil je afleggen? Volstaat een goede stadsfiets of heb
                            je toch eerder een tourfiets nodig? Staat je fiets veel buiten op ongure
                            plekken? Dan zijn kwetsbare versnellingen met loshangende kabels niet zo
                            handig. Moet je er boodschappen op kunnen vervoeren? Dan is een
                            bagagedrager wel zo handig.
                        </p>
                        <ContentUnderline>A Robuuste eenvoudige stadsfiets</ContentUnderline>
                        <ul>
                            <li>Terugtraprem</li>
                            <li>Rechtop zit</li>
                            <li>Geen versnellingen</li>
                            <li>Gewicht niet zo belangrijk. Robuustheid telt zwaarder</li>
                            <li>Onderhoudsarm</li>
                        </ul>
                        <ContentUnderline>
                            B Luxe stadsfiets waarmee je grotere afstanden kan afleggen
                        </ContentUnderline>
                        <ul>
                            <li>V-brakes of hydraulische schrijfremmen</li>
                            <li>Rechtop zit</li>
                            <li>3 of 8 versnellingen</li>
                            <li>Vering</li>
                            <li>Naafdynamo met goede kwaliteit koplamp</li>
                            <li>
                                Moet vanwege kwetsbare onderdelen als versnellingen ‘s nachts binnen
                                kunnen staan
                            </li>
                        </ul>
                        <ContentUnderline>C Toerfiets voor lange afstanden</ContentUnderline>
                        <ul>
                            <li>Sportieve zit</li>
                            <li>Derailleur</li>
                            <li>27 versnellingen</li>
                            <li>Hydraulische velgremmen</li>
                            <li>Moet binnen kunnen staan</li>
                        </ul>

                        <ContentUnderline>C Toerfiets voor lange afstanden</ContentUnderline>
                        <p>
                            Of is het nog niet zo duidelijk wat je wilt? De meeste robuuste
                            stadsfietsen hebben een rechtop zit. Maar er zijn er ook met sportieve
                            zit. En als je op comfort gesteld bent, kies je voor een stadsfiets met
                            dikke ballonbanden. Sommige fietsmerken bieden de mogelijkheid om jouw
                            favoriete model helemaal naar wens uit te rusten.
                        </p>
                    </div>
                )
            },
            {
                title: <ContentParagaraph>2. Framemaat</ContentParagaraph>,
                content: (
                    <>
                        Een passende framemaat voorkomt zadelpijn, knieklachten, rugpijn of
                        schouderpijn. Een vuistregel voor het vaststellen van de framemaat is: 2/3
                        van de binnenbeenlengte, gemeten vanaf de lies tot aan de onderkant van de
                        voet. Over het algemeen hebben vrouwen hebben iets langere benen dan mannen.
                        Uitgaan van iemands lengte leidt dus makkelijk tot een verkeerde framemaat.
                        Het is maar een indicatie. Erg lange en erg kleine mensen kunnen het beste
                        een fiets op maat laten maken.
                    </>
                )
            },
            {
                title: <ContentParagaraph>3. Versnellingen of niet</ContentParagaraph>,
                content: (
                    <p>
                        Kies als je versnellingen wilt voor 3 of 8 versnellingen op je stadsfiets.
                        Op vakantiefietsen zit vaak een naafversnelling. Tamelijk ideaal. Want met
                        de naaf heb je altijd genoeg versnellingen, de naaf is nagenoeg
                        onderhoudsvrij, duurzaam en het rendement lijkt nauwelijks slechter dan van
                        derailleurversnellingen. Het enige nadeel is eigenlijk de prijs. Op
                        mountainbikes en racefietsen zit doorgaans een derailleurversnelling: lekker
                        licht en hoog rendement, maar wel kwetsbaar.
                    </p>
                )
            },
            {
                title: <ContentParagaraph>4. Remmen</ContentParagaraph>,
                content: (
                    <div>
                        <p>
                            De beste remmen zijn over het algemeen hydraulische schrijfremmen.
                            Vroeger zaten ze alleen op mountainbikes, maar tegenwoordig zitten ze
                            ook op elektrische fietsen en luxe toerfietsen. Ze werken prima onder
                            alle omstandigheden en de velgen slijten niet. Het grote nadeel is dat
                            het systeem kwetsbaar is. Ze lopen snel aan. De terugtraprem is robuust,
                            maar het duurt lang voordat je stilstaat in vergelijking met andere
                            remmen. Niet zo handig als je een berg afrijdt of met hoge snelheid door
                            de stad rijdt.
                        </p>
                        <p>
                            Op luxere stadsfietsen en recreatieve fietsen zitten vaak rollerbrake
                            remmen. Ze hebben weinig onderhoud nodig en slijten minder dan
                            velgremmen. Nadeel is dat de remkracht minder goed te doseren is. Als je
                            het achterwiel los wilt halen, moet je een kabeltje losmaken. Velgremmen
                            zijn prima als het droog weer is, maar minder goed als het regent. Een
                            ander nadeel is dat de velgen ervan slijten. Voordeel is dat het
                            achterwiel makkelijk te verwijderen is.
                        </p>
                        <p>
                            V-brakes zitten op goedkopere vakantiefietsen, sportieve hybrides en
                            elektrische fietsen. Ze zijn eenvoudig, goedkoop en goed. Een nadeel is
                            dat het plaatsen van nieuwe remblokjes een secuur werkje is. Maar dat
                            kun je natuurlijk ook door de fietsenmaker laten doen. Magura-velgremmen
                            zijn verbeterde velgremmen. Ze zijn hydraulisch, dus zonder kabelremmen.
                            Het voordeel daarvan is dat ze niet kunnen vastvriezen. Het nadeel is
                            dat een hydraulisch systeem wat kwetsbaarder is bij transport en in de
                            stalling. Magura-velgremmen zijn bijna onderhoudsvrij. Ze zijn wel wat
                            duurder dan V-brakes.
                        </p>
                    </div>
                )
            },
            {
                title: <ContentParagaraph>5. Breedte van de velg</ContentParagaraph>,
                content: (
                    <p>
                        De klassieke stadsfietsband (30 a 45 mm) is een redelijk compromis tussen
                        snelheid, comfort en gewicht, maar heeft als nadeel dat je er bijvoorbeeld
                        niet onverhard mee kan fietsen. Je kunt ook kiezen voor een fiets met brede
                        ballonbanden (meer dan 45 mm). Zulke banden rijden comfortabeler, je valt
                        minder snel als je een onverwachte hobbel tegenkomt en ze fietsen ook nog
                        prima als ze minder hard zijn opgepompt. Je kunt wel bredere banden zetten
                        op smalle velgen, maar als je ballonbanden wilt, kun je beter bij aankoop al
                        voor een wiel met brede velgen kiezen.
                    </p>
                )
            },
            {
                title: <ContentParagaraph>6. Welk merk?</ContentParagaraph>,
                content: (
                    <p>
                        Ook goede merken maken ook wel eens slechte fietsen, dus een garantie geven
                        bij een merk is onmogelijk. Rondkijken op internet of raad vragen aan je
                        fietsenwinkel kan wat dat betreft veel informatie opleveren.{' '}
                    </p>
                )
            },
            {
                title: (
                    <ContentParagaraph>
                        7. Hoe belangrijk is het gewicht van een fiets?
                    </ContentParagaraph>
                ),
                content: (
                    <div>
                        <p>
                            Voor het fietsen zelf is het gewicht van een fiets doorgaans niet zo
                            belangrijk. Het is wel van belang als je de fiets op een fietsdrager
                            moet tillen of een helling op moet duwen. Over het algemeen geldt dat
                            besparen op gewicht het makkelijkst is als je onderdelen weglaat.
                        </p>
                        <p>
                            De meeste frames zijn gemaakt van staal of aluminium. De beide
                            materialen hebben voor- en nadelen. Zo is een stalen frame duurzaam en
                            solide en kan het tegen een stootje. Het is echter een stuk zwaarder dan
                            een aluminium frame. Ook roest aluminium minder snel dan staal bij
                            eventuele lakbeschadiging van het frame. Racefietsen zijn meestal
                            gemaakt van aluminium of carbon frames. Carbon is erg licht en zeer
                            sterk.
                        </p>
                    </div>
                )
            }
        ],
        listItems: [
            {
                title: 'De 7 tips om je fiets diefstal-proof te maken',
                subTitle: '05/10/2022',
                src: bannerImages[4],
                endpoint: '/blogpost/5'
            },
            {
                title: '6 tips voor fietsonderhoud',
                subTitle: '05/10/2022',
                src: bannerImages[0],
                endpoint: '/blogpost/1'
            },
            {
                title: 'Fietsen in alle weersomstandigheden',
                subTitle: '05/10/2022',
                src: bannerImages[1],
                endpoint: '/blogpost/2'
            }
        ]
    },
    {
        key: '5',
        bannerImg: bannerImages[4],
        title: 'De 7 tips om je fiets diefstal-proof te maken',
        subtitle: '05/10/2022',
        paragraphs: [
            {
                title: <ContentParagaraph>Tip 1: Zet je fiets goed op slot</ContentParagaraph>,
                content: (
                    <p>
                        Nogal voor de hand liggend, zou je denken. Maar toch is er een verschil
                        tussen je fiets op slot zetten, en je fiets goed op slot zetten. Veel
                        fietsen zijn uitgerust met een standaard ringslot. Prima voor snelle
                        beveiliging, maar over het algemeen draait een fietsendief hier zijn of haar
                        hand niet voor om. Zorg er daarom voor dat je altijd minstens twee soorten
                        sloten hebt op één fiets. Fietsendieven specialiseren zich vaak in één type
                        slot, dus met twee sloten is jouw fiets ineens een stuk minder
                        aantrekkelijk. Onder het goed op slot zetten verstaan we ook: koop
                        gecertificeerde sloten. En daar volgt meteen tip nummer 2.
                    </p>
                )
            },
            {
                title: <ContentParagaraph>Tip 2: Koop een gecertificeerd slot</ContentParagaraph>,
                content: (
                    <p>
                        Het ART Keurmerk is het leidinggevende certificaat voor sloten. Sloten
                        krijgen van ART een beoordeling van 1 tot en met 5 sterren. Hoe meer
                        sterren, hoe hoger de preventieve waarde tegen diefstal. Minimum een
                        ART2-slot is steeds verplicht in elke leasing.
                    </p>
                )
            },
            {
                title: <ContentParagaraph>Tip 3: Maak je fiets vast</ContentParagaraph>,
                content: (
                    <p>
                        Wist je dat het verzekeringsgewijs verplicht is je fiets aan een vast punt
                        vast te maken? Door je fiets aan een vast punt, zoals een paal, een
                        fietsenrek of bijvoorbeeld een brug, vast te maken, kan een fietsendief deze
                        niet gemakkelijk meenemen. Een slot dat geschikt is om de fiets te
                        verankeren aan een vast punt dient daarom steeds opgenomen te worden in elke
                        leasing. Trouwens, onze afdeling Fietsparkeren.net kan jou of je werkgever
                        verder helpen met oplossingen voor het veilig en netjes parkeren van je
                        fiets.
                    </p>
                )
            },
            {
                title: <ContentParagaraph>Tip 4: Zet je fiets slim vast</ContentParagaraph>,
                content: (
                    <p>
                        Een vervolgtip op nummer 3: wees slim bij het op slot zetten. Je fiets enkel
                        aan het voorwiel ergens aan vast maken is bijvoorbeeld geen goed idee. Het
                        is voor een dief geen probleem om het voorwiel achter te laten en de rest
                        van je fiets mee te nemen. Maak je fiets ook niet vast aan het midden van je
                        frame. Een dief kan de fiets dan draaien om extra kracht te zetten en zo het
                        slot open te breken. Dit geldt vooral bij het gebruik van een U-slot.
                    </p>
                )
            },
            {
                title: <ContentParagaraph>Tip 5: Maak gebruik van je ventiel</ContentParagaraph>,
                content: (
                    <p>
                        Dat klinkt een beetje vreemd, maar het is een tip van een oud-fietsendief
                        die erg effectief blijkt te zijn. Als je je fiets op slot zet met een
                        ringslot (dus je standaard slot), zorg er dan voor dat je ventiel in de
                        buurt zit van het slot. Een fietsendief probeert je slot door te knippen, en
                        natuurlijk de rest van de fiets intact te houden. Als jij je ventiel dicht
                        bij het slot hebt zitten, knippen ze vaak ook door het ventiel heen waardoor
                        de band leegloopt. De dief heeft dan niets meer aan jouw fiets. Zien dieven
                        dit, dan beginnen ze er vaak niet eens aan.
                    </p>
                )
            },
            {
                title: <ContentParagaraph>Tip 6: Maak je fiets hoog vast</ContentParagaraph>,
                content: (
                    <p>
                        Hiermee bedoelen we je fiets vastmaken met een ketting-, vouw-, of
                        beugelslot. Als je deze aan een paal vastmaakt en vervolgens op de grond
                        laat liggen, kan een dief de fiets gemakkelijker stelen. Ze gebruiken dan
                        namelijk de grond om extra druk op hun materiaal te zetten. Dat geeft ze
                        extra kracht, en dus een gemakkelijkere vangst. Als je je fiets hoger
                        vastmaakt (aan het frame bijvoorbeeld), dan geef je ze die kans niet. Bij
                        kou helpt dit je ook nog eens: een hangend slot bevriest minder snel dan een
                        slot dat op de grond ligt.
                    </p>
                )
            },
            {
                title: (
                    <ContentParagaraph>Tip 7: Zorg dat je fiets herkenbaar is</ContentParagaraph>
                ),
                content: (
                    <p>
                        ‘Maar ik herken mijn eigen fiets toch wel?’ Dat geloven we zeker, maar niet
                        iedereen herkent jouw fiets. Als je jouw gegevens laat graveren op een
                        herkenbare plek, bijvoorbeeld bovenop het frame, dan zien anderen ook direct
                        om wiens fiets het gaat. Een dief pakt deze fietsen liever niet, want er is
                        een grote kans dat ze dan gepakt worden (aangezien de fiets aantoonbaar niet
                        van hem of haar is). Maar dit is ook ideaal voor wanneer je fiets tóch
                        gestolen wordt en je hem graag terug wilt hebben. Aan de hand van je
                        gegevens op de fiets, kunnen anderen dan contact met je opnemen.
                    </p>
                )
            }
        ],
        listItems: [
            {
                title: '6 tips voor fietsonderhoud',
                subTitle: '05/10/2022',
                src: bannerImages[0],
                endpoint: '/blogpost/1'
            },
            {
                title: 'Fietsen in alle weersomstandigheden',
                subTitle: '05/10/2022',
                src: bannerImages[1],
                endpoint: '/blogpost/2'
            },
            {
                title: 'Waarom naar het werk fietsen goed voor je is',
                subTitle: '05/10/2022',
                src: bannerImages[2],
                endpoint: '/blogpost/3'
            }
        ]
    }
]
