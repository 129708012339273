import { RoadsideAssistance } from './assistanceMessages/RoadsideAssistanceMessage'
import { ContractMessage } from './assistanceMessages/ContractMessage'
import { ProfileMessage } from './assistanceMessages/ProfileMessage'

import { BackButton } from './backButton/BackButton'

import { Carousel } from './carousel/Carousel'
import { SmallScreenCarousel } from './carousel/SmallScreenCarousel'

import { Copyright } from './copyRight/CopyRight'

import { MyDocuments } from './documents/MyDocuments'
import { TabsDocuments } from './documents/TabsDocuments'

import Info from './info'

import { LayoutImage } from './layoutImage/LayoutImage'

import { HeaderMenu } from './menu/HeaderMenu'

import { PrivateRoute } from './privateRoute/PrivateRoute'

import { LeasingSimulation } from './simulaties/LeasingSimulation'

import { DealerLocator } from './locators/DealerLocator'
import { Dossiers } from './dossiers/Dossiers'

import { ImagesList } from './images/ImagesList'
import { BackgroundImage } from './images/BackgroundImage'

export default {
    Messages: {
        RoadsideAssistance,
        ContractMessage,
        ProfileMessage
    },
    BackButton,
    Carousel,
    SmallScreenCarousel,
    Copyright,
    Documents: { MyDocuments, TabsDocuments },
    Info,
    LayoutImage,
    HeaderMenu,
    PrivateRoute,
    LeasingSimulation,
    Locators: { DealerLocator },
    Dossiers: { Dossiers },
    ImagesList,
    BackgroundImage
}
