import styled, { CSSProperties } from 'styled-components'
import { useRecoilState } from 'recoil'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'

import Containers from '../../../containers'
import { MUIComponents } from '../../../muiComponents'
import { alertObjState, simulatorObjectState, userProfileState } from '../../../../store/store'
import { DeviceParameters, StepProps } from '../../../../types'
import { formatter, getHelpTextForOption } from '../../../../utils/utils'
import { Warning } from './Warning'
import useDeviceDetection from '../../../../hooks/useDeviceDetection'
import StyledComponents from '../../../StyledComponents'
import { Summary } from './Summary'
import { Explanation } from './Explanation'

export const StepThree = (props: StepProps) => {
    const { t } = useTranslation()

    //global states
    const [UserProfile] = useRecoilState(userProfileState)
    const [, setAlertObject] = useRecoilState(alertObjState)
    const [simulatorValues, setSimulatorValues] = useRecoilState(simulatorObjectState)

    // hooks
    const [devices] = useDeviceDetection()

    const boxWidth = devices.isMob || devices.isSmallerThanMob ? 'calc(100% - 20px)' : undefined

    return (
        <MUIComponents.Styling.Box
            sx={{
                display: 'grid',
                gridAutoFlow: devices.isMob || devices.isSmallerThanMob ? 'column' : 'row',
                gridTemplateColumns: 'repeat(10, 1fr)',
                gridTemplateRows: 'repeat(10, 1fr)',
                gap: 1,
                width: '100%',
                height: 'calc(100% - 150px)',
                marginTop: '20px',
                minHeight: '200px',
                overflow: devices.isWeb && devices.heightIsSmallerThan850 ? 'auto' : undefined
            }}
        >
            <Explanation />

            <MUIComponents.Styling.Box
                sx={{
                    gridColumn: '1/11',
                    gridRow: '2/4',
                    minWidth: 0,
                    width: boxWidth,
                    height: 'min-content'
                }}
            >
                <MUIComponents.Typography
                    sx={{ textAlign: 'left', ...StyledComponents.helper.truncatedText }}
                    variant="body1"
                >
                    {t('simulation.step3Container.summary')}
                </MUIComponents.Typography>
                <Row isMob={devices.isMob} isSmallerThanMob={devices.isSmallerThanMob}>
                    <LabelValue>
                        <MUIComponents.Typography
                            sx={{
                                fontSize: 'small',
                                color: 'rgba(0,0,0,0.6)',
                                textAlign: 'left',
                                ...StyledComponents.helper.truncatedText
                            }}
                            variant="body2"
                        >
                            {t('simulation.step3Container.brand')}
                        </MUIComponents.Typography>
                        <MUIComponents.Typography
                            sx={{
                                paddingBottom: '20px',
                                textAlign: 'left',
                                ...StyledComponents.helper.truncatedText
                            }}
                            variant="body2"
                        >
                            {
                                (
                                    simulatorValues.bikeBrand as {
                                        value: string
                                        label: string
                                    }
                                )?.label as string
                            }
                        </MUIComponents.Typography>
                    </LabelValue>

                    <LabelValue>
                        <MUIComponents.Typography
                            sx={{
                                fontSize: 'small',
                                color: 'rgba(0,0,0,0.6)',
                                textAlign: 'left',
                                ...StyledComponents.helper.truncatedText
                            }}
                            variant="body2"
                        >
                            {t('simulation.step3Container.type')}
                        </MUIComponents.Typography>
                        <MUIComponents.Typography
                            sx={{
                                paddingBottom: '20px',
                                textAlign: 'left',
                                ...StyledComponents.helper.truncatedText
                            }}
                            variant="body2"
                        >
                            {
                                (
                                    simulatorValues.bikeType as {
                                        value: string
                                        label: string
                                    }
                                )?.label as string
                            }
                        </MUIComponents.Typography>
                    </LabelValue>
                </Row>
            </MUIComponents.Styling.Box>
            <MUIComponents.Styling.Box
                sx={{
                    gridColumn: '1/11',
                    gridRow: '4/6',
                    minWidth: 0,
                    marginBottom: '10px',
                    display: 'flex',
                    flexDirection: 'column',
                    width: boxWidth,
                    height: 'min-content'
                }}
            >
                <MUIComponents.Typography
                    sx={{
                        textAlign: 'left',
                        ...StyledComponents.helper.truncatedText
                    }}
                    variant="body1"
                >
                    {t('simulation.step3Container.budget')}
                </MUIComponents.Typography>

                <Row isMob={devices.isMob} isSmallerThanMob={devices.isSmallerThanMob}>
                    <LabelValue>
                        <MUIComponents.Typography
                            sx={{
                                fontSize: 'small',
                                color: 'rgba(0,0,0,0.6)',
                                textAlign: 'left',
                                ...StyledComponents.helper.truncatedText
                            }}
                            variant="body2"
                        >
                            {t('simulation.step3Container.bike')}
                        </MUIComponents.Typography>
                        <MUIComponents.Typography
                            sx={{
                                paddingBottom: '20px',
                                textAlign: 'left',
                                ...StyledComponents.helper.truncatedText
                            }}
                            variant="body2"
                        >
                            {formatter(simulatorValues.bikeBudget as number)}
                        </MUIComponents.Typography>
                    </LabelValue>

                    <LabelValue>
                        <MUIComponents.Typography
                            sx={{
                                fontSize: 'small',
                                color: 'rgba(0,0,0,0.6)',
                                textAlign: 'left',
                                ...StyledComponents.helper.truncatedText
                            }}
                            variant="body2"
                        >
                            {t('simulation.step3Container.accesoirees')}
                        </MUIComponents.Typography>
                        <MUIComponents.Typography
                            sx={{
                                paddingBottom: '20px',
                                textAlign: 'left',
                                ...StyledComponents.helper.truncatedText
                            }}
                            variant="body2"
                        >
                            {formatter(simulatorValues.accessoriesBudget as number)}
                        </MUIComponents.Typography>
                    </LabelValue>
                </Row>
            </MUIComponents.Styling.Box>
            <MUIComponents.Styling.Box
                sx={{
                    gridColumn: '1/11',
                    gridRow: '6/9',
                    minWidth: 0,
                    overflow: 'auto',
                    width: boxWidth,
                    height: 'min-content'
                }}
            >
                <MUIComponents.Typography
                    sx={{
                        textAlign: 'left',
                        ...StyledComponents.helper.truncatedText
                    }}
                    variant="body1"
                >
                    {t('simulation.step3Container.options')}
                </MUIComponents.Typography>

                <LabelValue>
                    <MUIComponents.Typography
                        sx={{
                            fontSize: 'small',
                            color: 'rgba(0,0,0,0.6)',
                            textAlign: 'left',
                            ...StyledComponents.helper.truncatedText
                        }}
                        variant="body2"
                    >
                        {t('simulation.step3Container.chosenOptions')}
                    </MUIComponents.Typography>
                    {simulatorValues.defaultOptions.map((defaultOption, i) =>
                        getOptionDisplay({
                            item: defaultOption,
                            index: i,
                            type: 'checkbox',
                            translateFunction: t,
                            devices
                        })
                    )}
                </LabelValue>

                {/* <LabelValue>
                    <MUIComponents.Typography
                        sx={{
                            fontSize: 'small',
                            color: 'rgba(0,0,0,0.6)',
                            textAlign: 'left',
                            ...StyledComponents.helper.truncatedText
                        }}
                        variant="body2"
                    >
                        {t('simulation.step3Container.additionalOptions')}
                    </MUIComponents.Typography>
                    {simulatorValues.extraOptions.map((extraOption, i) =>
                        getOptionDisplay({
                            item: extraOption,
                            index: i,
                            type: 'radio',
                            translateFunction: t,
                            devices
                        })
                    )}
                </LabelValue> */}
            </MUIComponents.Styling.Box>

            <MUIComponents.Styling.Box
                sx={{
                    gridColumn: '1/11',
                    gridRow: '9/10',
                    minWidth: 0,
                    display: 'flex',
                    flexDirection: 'row',
                    backgroundColor: '#FAFAFA',
                    padding: '20px',
                    color: 'rgba(0,0,0,0.6)',
                    width:
                        devices.isMob || devices.isSmallerThanMob
                            ? 'calc(100% - 60px)'
                            : 'calc(100% - 40px)',
                    height: 'min-content'
                }}
            >
                <Warning content={t('simulation.companyDependentWarning')} />
            </MUIComponents.Styling.Box>

            <MUIComponents.Styling.Box
                sx={{
                    gridColumn: '1/11',
                    gridRow: '11',
                    minWidth: 0,
                    display: 'flex',
                    flexDirection: devices.isMob || devices.isSmallerThanMob ? 'column' : 'row',
                    width: boxWidth,
                    height: 'min-content',
                    marginBottom: !(devices.isMob || devices.isSmallerThanMob) ? '-5px' : undefined
                    // flexDirection: 'row'
                }}
            >
                <MUIComponents.Buttons.Button
                    variant="outlined"
                    fullWidth
                    onClick={() => props.setActiveStep(undefined, 1)}
                    sx={{
                        marginBottom: '10px',
                        marginTop: '10px',
                        width: devices.isMob || devices.isSmallerThanMob ? '100%' : '200px',
                        display: 'block'
                    }}
                >
                    {t('general.buttons.previousStep')}
                </MUIComponents.Buttons.Button>

                <MUIComponents.Buttons.Button
                    variant="contained"
                    fullWidth
                    onClick={() => {
                        props.setActiveStep(undefined, 0)
                        setSimulatorValues((old) => ({
                            ...old,
                            accessoriesBudget: 0,
                            bikeBrand: undefined,
                            bikeType: undefined,
                            bikeBudget: 0,
                            // extraOptions: [],
                            defaultOptions: []
                        }))
                    }}
                    sx={{
                        marginBottom: '10px',
                        marginTop: '10px',
                        width: devices.isMob || devices.isSmallerThanMob ? '100%' : '200px',
                        display: 'block',
                        marginLeft: 'auto'
                    }}
                >
                    {t('simulation.step3Container.newSimulation')}
                </MUIComponents.Buttons.Button>

                {(devices.isMob || devices.isSmallerThanMob) && (
                    <Summary fieldsData={props.fieldsData} />
                )}

                {(devices.isMob || devices.isSmallerThanMob) && (
                    <MUIComponents.Styling.Paper
                        style={{
                            width: '100%',
                            margin: '10px auto auto',
                            boxShadow: 'unset'
                        }}
                    >
                        <Containers.Copyright
                            sx={{
                                color: 'rgb(128,128,128)',
                                margin: 'auto 5px',
                                fontSize: 'small'
                            }}
                            paperStyling={{ margin: '10px 0px', width: 'unset' }}
                            isMob={devices.isMob || devices.isSmallerThanMob}
                            isTablet={devices.isTablet}
                        />
                    </MUIComponents.Styling.Paper>
                )}
            </MUIComponents.Styling.Box>
        </MUIComponents.Styling.Box>
    )
}

const LabelValue = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 50px;
`

const Row = styled.div<DeviceParameters>`
    display: flex;
    flex-direction: ${(props) => (props.isMob || props.isSmallerThanMob ? 'column' : 'row')};
    width: calc(100% - 15px);
`

const typoGraphyStyling: CSSProperties = {
    textAlign: 'left',
    color: 'rgba(0, 0, 0, 0.6)'
}

const Label = styled.p`
    margin: 10px 10px;
    text-align: left;
    color: rgba(0, 0, 0, 0.6);
`

const getOptionDisplay = ({
    item,
    index,
    type,
    devices,
    translateFunction
}: {
    item: { value?: boolean | string; name: string | boolean; code: string | undefined }
    index: number
    type: 'checkbox' | 'radio'
    devices: DeviceParameters
    translateFunction: TFunction
}) => (
    <Row key={index} isMob={devices.isMob} isSmallerThanMob={devices.isSmallerThanMob}>
        {(devices.isMob || devices.isSmallerThanMob) && (
            <Row>
                <MUIComponents.Typography variant="subtitle1" style={typoGraphyStyling}>
                    {item.name}
                </MUIComponents.Typography>
                <MUIComponents.Buttons.IconButton
                    aria-label="optional value 1"
                    onClick={(e) => {
                        return
                    }}
                    onMouseDown={(e) => {
                        e.preventDefault()
                    }}
                    edge="end"
                    tooltip={{
                        placement: 'right',
                        content: getHelpTextForOption(item.code ?? undefined, translateFunction)
                    }}
                >
                    <MUIComponents.Icons.HelpIcon />
                </MUIComponents.Buttons.IconButton>
            </Row>
        )}

        {typeof item.value === 'string' ? (
            <Label>{item.value?.length > 0 ? item.value : '/'}</Label>
        ) : type === 'radio' ? (
            <MUIComponents.Radio
                checked={item.value}
                value={item.value}
                name="radio-buttons"
                inputProps={{ 'aria-label': 'B' }}
                colorToOverwrite="rgba(0, 0, 0, 0.6)"
                sx={{
                    paddingLeft: 'unset',
                    width: devices.isMob || devices.isSmallerThanMob ? '50px' : undefined
                }}
                disabled
            />
        ) : (
            <MUIComponents.Checkbox
                sx={{
                    paddingLeft: 'unset',
                    width: devices.isMob || devices.isSmallerThanMob ? '50px' : undefined
                }}
                colorToOverwrite="rgba(0, 0, 0, 0.6)"
                disabled
                checked={item.value}
            />
        )}

        {!(devices.isMob || devices.isSmallerThanMob) && (
            <Row>
                <MUIComponents.Typography variant="subtitle1" style={typoGraphyStyling}>
                    {item.name}
                </MUIComponents.Typography>
                <MUIComponents.Buttons.IconButton
                    aria-label="optional value 2"
                    onClick={(e) => {
                        return
                    }}
                    onMouseDown={(e) => {
                        e.preventDefault()
                    }}
                    edge="end"
                    tooltip={{
                        placement: 'right',
                        content: getHelpTextForOption(item.code ?? undefined, translateFunction)
                    }}
                >
                    <MUIComponents.Icons.HelpIcon />
                </MUIComponents.Buttons.IconButton>
            </Row>
        )}
    </Row>
)
