import MUICarousel from 'react-material-ui-carousel'
import { useRecoilState } from 'recoil'

import theme from '../../../theme/theme'
import { MUIComponents } from '../../muiComponents'
import { Blogpost } from '../../../types'
import { userProfileState } from '../../../store/store'
import { Item } from './Item'

// TODO: Make this more generic -> Implement when we are going to use the carousel on places that are not blogposts
export const Carousel = (props: { items: Blogpost[] }) => {
    const [userProfile] = useRecoilState(userProfileState)

    return (
        <MUIComponents.Styling.Paper style={{ height: '100%' }} elevation={5}>
            <MUICarousel
                navButtonsProps={{
                    style: {
                        color: 'white',
                        backgroundColor: theme.palette.primary.main
                    }
                }}
                navButtonsAlwaysVisible
                indicators={false}
                autoPlay={true}
                interval={10000}
                index={0}
                sx={{
                    height: '100%',
                    /* Left button */
                    '& .css-hn784z button': {
                        top: '125px !important'
                    },
                    /* Right button */
                    '& .css-1abc02a button': {
                        top: '125px !important'
                    }
                }}
                navButtonsWrapperProps={{
                    style: {
                        bottom: '0',
                        top: 'unset !important'
                    }
                }}
            >
                {props.items.length > 0 &&
                    props.items.map((item, i) => <Item key={i} item={item} />)}
            </MUICarousel>
        </MUIComponents.Styling.Paper>
    )
}
