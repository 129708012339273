// Following the official docs for once :)
// https://reactrouter.com/docs/en/v6/examples/auth
import { Navigate, useLocation } from 'react-router-dom'
import { useRecoilValue } from 'recoil'

import firebaseApp from '../../../firebase'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { MUIComponents } from '../../muiComponents'
import { useEffect, useState } from 'react'
import { userObjectState } from '../../../store/store'

export const PrivateRoute = ({ children }: { children: JSX.Element }) => {
    const fbUserObject = useRecoilValue(userObjectState)

    const location = useLocation()
    const [pendingAuth, setPendingAuth] = useState<boolean>(true)
    // const [isLoggedOut, setIsLoggedOut] = useState<boolean>(false)

    const loader = (
        <MUIComponents.Styling.Box style={{ width: '100%', textAlign: 'center', paddingTop: 50 }}>
            <MUIComponents.Loader />
            <MUIComponents.Typography itemType="p">Authorizeren...</MUIComponents.Typography>
        </MUIComponents.Styling.Box>
    )

    // Check if user is logged in on firebase Auth level
    // userObject set is an App(.tsx) based async logic so won't always be available on refresh yet
    useEffect(() => {
        const auth = getAuth(firebaseApp)

        onAuthStateChanged(auth, async (user) => {
            if (!user) {
                // setIsLoggedOut(true)
                setPendingAuth(false)
                return
            }

            setPendingAuth(false)
        })
    }, [])

    if (pendingAuth) return loader

    // userObject needs a uid, without these you cannot be considered logged in.
    if (fbUserObject?.uid) {
        return children
    }

    return <Navigate state={{ from: location }} to="/login" replace={true} />
}
