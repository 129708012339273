import MUITooltip, { TooltipProps } from '@mui/material/Tooltip'

// ! For letting the tooltip to work on mobile -> use the following prop: enterTouchDelay={0}
// TODO: added 2 more default props: enterTouchDelay & leaveDelay
export const Tooltip = ({
    arrow = true,
    // ! auto default of 1 sec for hiding the tooltip itself
    leaveDelay = 1000,
    // ! This is default added for showing the tooltip directly when you are on a mobile or a tablet version
    enterTouchDelay = 0,
    ...props
}: TooltipProps) => (
    <MUITooltip leaveDelay={leaveDelay} enterTouchDelay={enterTouchDelay} {...props} arrow={arrow}>
        {props.children}
    </MUITooltip>
)

// Example of this component with props:
{
    /* <MUIComponents.Tooltip title={tooltip}>
                <Text error={props.error}>{props.content}</Text>
            </MUIComponents.Tooltip> */
}
