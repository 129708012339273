import MuiSwitch, { SwitchProps as MuiSwitchProps } from '@mui/material/Switch'
import styled from 'styled-components'
import FormControlLabel from '@mui/material/FormControlLabel'
import MuiFormLabel from '@mui/material/FormLabel'
import FormControl from '@mui/material/FormControl'
import FormGroup from '@mui/material/FormGroup'
import FormHelperText from '@mui/material/FormHelperText'

import { noop } from '../../utils/utils'

interface SwitchProps extends MuiSwitchProps {
    // you can declare custom props in here
    colorToOverwrite?: string
}

const StyledSwitch = styled(MuiSwitch)<SwitchProps>`
    &.MuiSwitch-switchBase,
    .Mui-checked {
        color: ${({ color, colorToOverwrite, ...props }) =>
            color ?? colorToOverwrite ?? props.theme.palette.primary.main} !important;
    }

    &.Mui-checked,
    .MuiSwitch-track {
        background-color: ${({ color, colorToOverwrite, ...props }) =>
            color ?? colorToOverwrite ?? props.theme.palette.primary.main} !important;
    }
`

export const Switch = ({
    // assign default values here
    checked = false,
    onChange = noop,
    disabled = false,
    disableRipple = false,
    required = false,
    size = 'small',
    ...props
}: SwitchProps) => (
    <StyledSwitch
        disableRipple={disableRipple}
        disabled={disabled}
        checked={checked}
        onChange={onChange}
        required={required}
        size={size}
        {...props}
    />
)

export const SwitchWithLabel = ({
    label,
    checked = false,
    defaultChecked = false,
    onChange = noop,
    disabled = false,
    disableRipple = false,
    required = false,
    size = 'small',
    ...props
}: SwitchProps & { label: string }) => (
    <FormControlLabel
        control={
            <StyledSwitch
                disableRipple={disableRipple}
                disabled={disabled}
                checked={checked}
                defaultChecked={defaultChecked}
                onChange={onChange}
                required={required}
                size={size}
                {...props}
            />
        }
        label={label}
    />
)

const StyledFormLabel = styled(MuiFormLabel)`
    &.Mui-focused {
        color: ${(props) => props.theme.palette.primary.main} !important;
    }
`

export const SwitchGroup = ({
    title,
    helperText = 'Be careful',
    switches
}: {
    title: string
    helperText?: string
    switches: {
        name: string
        checked: boolean
        onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
        label: string
    }[]
}) => (
    <FormControl component="fieldset" variant="standard">
        <StyledFormLabel>{title}</StyledFormLabel>
        <FormGroup>
            {switches.map((item) => (
                <FormControlLabel
                    key={item.label}
                    control={
                        <Switch checked={item.checked} onChange={item.onChange} name={item.name} />
                    }
                    label={item.label}
                />
            ))}
        </FormGroup>
        <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
)

// Example of the Switch component with props:
{
    /* <MUIComponents.Switch checked={foo} onChange={() => setFoo(!foo)} /> */
}

// Example of the SwitchWithLabel component with props:
{
    /* <MUIComponents.SwitchWithLabel
                label="Test it out"
                checked={foo}
                onChange={() => setFoo(!foo)}
            /> */
}

// Example of the SwitchGroup component with props:
// STATE
// const [foo, setFoo] = useState<{ [key: string]: boolean }>({})
{
    /* <MUIComponents.SwitchGroup
                title="Welcome"
                helperText="This is a test"
                switches={[
                    {
                        name: 'Mike',
                        label: 'Mike Meyers',
                        checked: foo['Mike'],
                        onChange: (event, checked) => {
                            setFoo({
                                ...foo,
                                [event.target.name]: checked
                            })
                        }
                    },
                    {
                        name: 'Tom',
                        label: 'Tom Nahooy',
                        checked: foo['Tom'],
                        onChange: (event, checked) => {
                            setFoo({
                                ...foo,
                                [event.target.name]: checked
                            })
                        }
                    },
                    {
                        name: 'BrentVT',
                        label: 'Brent van Tilborg',
                        checked: foo['BrentVT'],
                        onChange: (event, checked) => {
                            setFoo({
                                ...foo,
                                [event.target.name]: checked
                            })
                        }
                    },
                    {
                        name: 'BrentB',
                        label: 'Brent Bastianen',
                        checked: foo['BrentB'],
                        onChange: (event, checked) => {
                            setFoo({
                                ...foo,
                                [event.target.name]: checked
                            })
                        }
                    }
                ]}
            /> */
}
