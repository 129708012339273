import { useState, useEffect } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
    getAuth,
    getMultiFactorResolver,
    MultiFactorResolver,
    PhoneAuthProvider,
    PhoneMultiFactorGenerator,
    RecaptchaVerifier,
    signInWithEmailAndPassword,
    setPersistence,
    ApplicationVerifier,
    MultiFactorError, // type
    browserLocalPersistence
    // browserSessionPersistence
} from 'firebase/auth'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { useTranslation } from 'react-i18next'

import { alertObjState, userObjectState, userProfileState } from '../../../store/store'
import firebaseApp from '../../../firebase'
import { MUIComponents } from '../../muiComponents'
import Containers from '../../containers'
import { FormValue } from '../../../types'
import { validateEmail, getErrorMessage, headers } from '../../../utils/utils'
import API from '../../../API'
import theme from '../../../theme/theme'
import StyledComponents from '../../StyledComponents'
import { getComponentsOrdering } from '../../../utils/pageComponentsOrdering'
import useDeviceDetection from '../../../hooks/useDeviceDetection'

// TODO: fetch the languages when the first page loads and put it in global state so we can use it everywhere, register, portal zelf, ...
export const Login = () => {
    const { t } = useTranslation()

    const [isAuthenticating, setIsAuthenticating] = useState(false)
    const [passwordForgotDialogVisible, setPasswordForgotDialogVisible] = useState(false)
    const [mfaDialogVisible, setMfaDialogVisible] = useState(false)
    const [mfaResolver, setMfaResolver] = useState({})
    const [mfaSmsCode, setMfaSmsCode] = useState('')
    const [mfaVerificationID, setMfaVerificationID] = useState('')
    const [mfaLoading, setMfaLoading] = useState(false)
    const [emailError, setEmailError] = useState<{ valid: boolean; error: boolean }>({
        error: false,
        valid: false
    })
    const [password, setPassword] = useState<FormValue>({ error: false, value: '' })
    const [email, setEmail] = useState<string>('')
    const [passwordForgotLoading, setPasswordForgotLoading] = useState(false)
    const [passwordEmail, setPasswordEmail] = useState<string>('')
    const [passwordEmailError, setPasswordEmailError] = useState<{
        valid: boolean
        error: boolean
    }>({
        error: false,
        valid: false
    })

    const userObject = useRecoilValue(userObjectState)
    const userProfile = useRecoilValue(userProfileState)
    const [_, setAlertObject] = useRecoilState(alertObjState)

    const [fbUserObject, setFbUserObject] = useRecoilState(userObjectState)

    // hooks
    const navigate = useNavigate()
    const [devices] = useDeviceDetection()

    const auth = getAuth(firebaseApp)
    const [captchaVerifier, setCaptchaVerifier] = useState<ApplicationVerifier | undefined>(
        undefined
    )

    useEffect(() => {
        if (fbUserObject?.uid) {
            navigate('/', { replace: true })
        }
    }, [fbUserObject])

    // Check if user is logged in on firebase Auth level
    // userObject set is an App(.tsx) based async logic so won't always be available on refresh yet
    // useEffect(() => {
    //     auth.onAuthStateChanged((user) => {
    //         if (user) {
    //             navigate(originalLocation, { replace: true })
    //         }
    //     })
    // }, [])

    // There was an issue with the captchaVerifier that said that the captcha already has been rendered
    // The solution for this is this useEffect
    // In here we are going to create a new captchaVerifier and there is a check provided to verify if there has been created 1 already
    useEffect(() => {
        if (!captchaVerifier) {
            const verifier = new RecaptchaVerifier(
                'login',
                {
                    size: 'invisible'
                },
                auth
            )

            setCaptchaVerifier(verifier)
        }
    }, [])

    const handleSubmit = async (event?: React.MouseEvent<HTMLButtonElement>) => {
        event?.preventDefault()

        if (email && (password.value as string).length > 0) {
            try {
                setIsAuthenticating(true)

                await setPersistence(auth, browserLocalPersistence)
                await signInWithEmailAndPassword(auth, email, password.value as string).then(
                    async (res) => {
                        setFbUserObject({
                            uid: res.user.uid,
                            displayName: res.user.displayName,
                            accessToken: await res.user.getIdToken(true)
                        })
                    }
                )

                return
            } catch (err) {
                //MFA handling
                if ((err as MultiFactorError)?.code == 'auth/multi-factor-auth-required') {
                    setMfaLoading(false)
                    const mfaResolver = getMultiFactorResolver(auth, err as MultiFactorError)
                    setMfaResolver(mfaResolver)
                    const phoneInfoOptions = {
                        multiFactorHint: mfaResolver.hints[0],
                        session: mfaResolver.session
                    }

                    const phoneAuthProvider = new PhoneAuthProvider(auth)
                    //Send the sms code
                    phoneAuthProvider
                        .verifyPhoneNumber(phoneInfoOptions, captchaVerifier as ApplicationVerifier)
                        .then((verificationID) => {
                            captchaVerifier
                                ?.verify()
                                .then((result) => {
                                    console.log('result verifier is: ', result)
                                    setMfaLoading(false)
                                    setMfaDialogVisible(true)
                                })
                                .catch((err) => {
                                    console.log(err)
                                    setMfaLoading(false)
                                    setAlertObject({
                                        message: t('login.smsAlert'),
                                        severity: 'error'
                                    })
                                })

                            setMfaVerificationID(verificationID)
                        })
                        .catch((err) => {
                            console.log(err)
                            setAlertObject({
                                message: t('login.smsAlert'),
                                severity: 'error'
                            })
                            setMfaDialogVisible(false)
                        })
                } else {
                    const error = getErrorMessage(err)
                    console.error(error)
                    setAlertObject({
                        message: t('login.mailAlert'),
                        severity: 'error'
                    })

                    return
                }
            } finally {
                setIsAuthenticating(false)
            }
        }

        // show errors if there are any
        if (!email) {
            setEmailError({ ...emailError, error: true })
        }

        if ((password.value as string).length === 0) {
            setPassword({ ...password, error: true })
        }

        return
    }

    const handleMfaDialogSubmit = () => {
        setMfaLoading(true)
        const credential = PhoneAuthProvider.credential(mfaVerificationID, mfaSmsCode)
        const assertion = PhoneMultiFactorGenerator.assertion(credential)
        ;(mfaResolver as MultiFactorResolver)
            .resolveSignIn(assertion)
            .then(() => setMfaLoading(false))
            .catch(() => {
                setAlertObject({
                    message: t('login.mfaCodeAlert'),
                    severity: 'error'
                })
                setMfaLoading(false)
                setMfaDialogVisible(false)
            })
    }

    // Dialog content and actions (start)
    const mfaDialogContent = (
        <MUIComponents.Styling.Container style={{ width: 350, height: 100 }}>
            <MUIComponents.TextFields.TextFieldWithLabel
                label={t('login.smsCode')}
                onChange={(e) => setMfaSmsCode(e.target.value)}
                autoFocus
                size="small"
            />
        </MUIComponents.Styling.Container>
    )

    const mfaActionsContent = (
        <>
            <MUIComponents.Buttons.Button variant="text" onClick={() => setMfaDialogVisible(false)}>
                Annuleren
            </MUIComponents.Buttons.Button>

            <MUIComponents.Buttons.LoadingButton
                loading={mfaLoading}
                loadingPosition="start"
                content={t('general.buttons.confirm')}
                onClick={() => handleMfaDialogSubmit()}
            />
        </>
    )

    const forgotPasswordActionsContent = (
        <>
            <MUIComponents.Buttons.Button
                variant="text"
                onClick={() => {
                    setPasswordForgotDialogVisible(false)
                }}
            >
                {t('general.buttons.cancel')}
            </MUIComponents.Buttons.Button>

            <MUIComponents.Buttons.LoadingButton
                loading={passwordForgotLoading}
                loadingPosition="center"
                content={t('general.buttons.confirm')}
                onClick={async () => {
                    try {
                        setPasswordForgotLoading(true)
                        const result = await axios({
                            method: 'post',
                            url: API.utils.apiUrlsEnv.handlePasswordReset,
                            data: {
                                email: passwordEmail,
                                type: 'reset',
                                originUrl: window.location.origin
                            },
                            headers
                        })

                        if (result.status === 200) {
                            setAlertObject({
                                message: t('login.resetAlertSucces'),
                                severity: 'success'
                            })
                            setPasswordForgotLoading(false)
                        } else {
                            setAlertObject({
                                message: t('login.resetAlertError'),
                                severity: 'error'
                            })
                            setPasswordForgotLoading(false)
                        }
                    } catch (err) {
                        console.error(err)
                        setAlertObject({
                            message: t('login.resetAlertError'),
                            severity: 'error'
                        })
                        setPasswordForgotLoading(false)
                    }

                    setPasswordForgotLoading(false)
                    setPasswordForgotDialogVisible(false)
                }}
            />
        </>
    )
    // Dialog content and actions (end)

    return (
        <StyledComponents.Wrapper>
            <MUIComponents.Styling.Grid
                container
                sx={{
                    height: devices.isWeb ? 'calc(100vh - 20vh)' : 'calc(100vh - 10vh)',
                    marginTop: devices.isWeb ? '19vh' : '9vh'
                }}
                columns={15}
                spacing={1}
            >
                <MUIComponents.Styling.Grid item xs={devices.isWeb ? 4.5 : 1.5} />

                {devices.isWeb && <Containers.BackgroundImage />}

                <MUIComponents.Styling.Grid
                    item
                    xs={devices.isWeb ? 4 : 12}
                    component={MUIComponents.Styling.Paper}
                    elevation={6}
                    sx={{
                        maxHeight: '75%',
                        position: 'relative',
                        height: '100%',
                        boxShadow: devices.isMob || devices.isSmallerThanMob ? 'unset' : undefined,
                        minHeight: '550px'
                    }}
                >
                    <MUIComponents.Styling.Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            height:
                                devices.isMob || devices.isSmallerThanMob
                                    ? '100%'
                                    : 'calc(100% - 100px)',
                            ...((devices.isTablet || devices.isWeb) && { my: 6, mx: 6 })
                        }}
                    >
                        {getComponentsOrdering(
                            [
                                <MUIComponents.Typography
                                    sx={{ mt: 3 }}
                                    id="login-helper-text"
                                    variant="body1"
                                    key="1"
                                >
                                    {t('login.description')}
                                </MUIComponents.Typography>,
                                <MUIComponents.Typography
                                    sx={{ color: theme.palette.primary.main }}
                                    variant="h5"
                                    id="login-header"
                                    key="0"
                                >
                                    {t('login.title')}
                                </MUIComponents.Typography>
                            ],
                            'login'
                        )}

                        <MUIComponents.Styling.Box
                            component="div"
                            sx={{
                                mt: 1,
                                ...(devices.isWeb && { height: '100%', position: 'relative' })
                            }}
                        >
                            {getComponentsOrdering(
                                [
                                    <MUIComponents.TextFields.HiddenTextField
                                        key="0"
                                        id="password"
                                        label={t('login.passInput')}
                                        name="password"
                                        type="password"
                                        required
                                        autoFocus={false}
                                        autoComplete="current-password"
                                        value={password.value}
                                        error={password.error}
                                        onKeyDown={(e) => {
                                            if (
                                                ['Enter', 'NumpadEnter'].includes(e.code || e.key)
                                            ) {
                                                handleSubmit()
                                            }
                                        }}
                                        onChange={(e) => {
                                            if (e.target.value) {
                                                setPassword({ value: e.target.value, error: false })
                                            }
                                        }}
                                        {...(password.error && {
                                            errorText: t('login.passwordRequiredError')
                                        })}
                                    />,
                                    <MUIComponents.TextFields.TextField
                                        key="1"
                                        id="email"
                                        label={t('login.mailInput')}
                                        name="email"
                                        type="email"
                                        size="small"
                                        autoFocus={false}
                                        autoComplete="email"
                                        error={emailError.error}
                                        onChange={(e) => {
                                            const isEmailValid = validateEmail(e.target.value)

                                            if (!isEmailValid) {
                                                setEmailError({ error: true, valid: isEmailValid })
                                            } else {
                                                setEmailError({
                                                    error: false,
                                                    valid: isEmailValid
                                                })
                                                setEmail(e.target.value)
                                            }
                                        }}
                                        {...(emailError.error && {
                                            helperText: !emailError.valid
                                                ? t('login.mailCheckError')
                                                : t('login.mailRequiredError')
                                        })}
                                    />,
                                    <StyledComponents.ItemNavigation
                                        key="2"
                                        onClick={(e) => {
                                            e.preventDefault()
                                            setPasswordForgotDialogVisible(true)
                                        }}
                                        justifyContent="flex-end"
                                        noMargin
                                        id="password-forgot-link"
                                    >
                                        {t('login.fergotten')}
                                    </StyledComponents.ItemNavigation>,
                                    <MUIComponents.Buttons.LoadingButton
                                        key="3"
                                        id="login"
                                        type="submit"
                                        sx={{
                                            mt: 3,
                                            display: 'flex',
                                            marginLeft: 'auto',
                                            ...((devices.isMob || devices.isSmallerThanMob) && {
                                                width: '100%'
                                            })
                                        }}
                                        content={t('login.button')}
                                        loading={isAuthenticating}
                                        onClick={handleSubmit}
                                    />,
                                    <StyledComponents.ItemNavigation
                                        key="4"
                                        onClick={() => {
                                            navigate('/register')
                                        }}
                                        sx={{
                                            justifyContent: 'center',
                                            ...(devices.heightIsSmallerThan850 && {
                                                paddingBottom: 10
                                            }),
                                            ...(devices.isWeb &&
                                                !devices.heightIsSmallerThan850 && {
                                                    bottom: '-55px',
                                                    position: 'absolute',
                                                    left: '10%'
                                                })
                                        }}
                                        id="register-link"
                                    >
                                        {t('login.register')}
                                    </StyledComponents.ItemNavigation>
                                ],
                                'login'
                            )}
                        </MUIComponents.Styling.Box>
                    </MUIComponents.Styling.Box>

                    {(devices.isMob || devices.isSmallerThanMob) && (
                        <Containers.Copyright
                            isMob={devices.isMob || devices.isSmallerThanMob}
                            isSmallerThanMob={devices.isSmallerThanMob}
                            sx={{
                                color: 'rgb(128,128,128)',
                                margin: 'auto 5px',
                                fontSize: 'small'
                            }}
                        />
                    )}
                </MUIComponents.Styling.Grid>

                {(devices.isTablet || devices.isWeb) && (
                    <MUIComponents.Styling.Grid
                        item
                        xs={20}
                        elevation={6}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                            marginTop: 'auto'
                        }}
                        id="copyright"
                    >
                        <Containers.Copyright
                            isMob={devices.isMob}
                            isTablet={devices.isTablet}
                            sx={{
                                color: 'rgb(128,128,128)',
                                margin: devices.isTablet ? 'auto 5px' : 'auto 25px'
                            }}
                        />
                    </MUIComponents.Styling.Grid>
                )}

                <MUIComponents.Dialog
                    title={t('login.multiFactor')}
                    open={mfaDialogVisible}
                    contentComponent={mfaDialogContent}
                    actionsContent={mfaActionsContent}
                />
                <MUIComponents.Dialog
                    open={passwordForgotDialogVisible}
                    actionsContent={forgotPasswordActionsContent}
                    onClose={() => {
                        setPasswordForgotDialogVisible(false)
                    }}
                    title={t('login.resetModalTitle')}
                    contentComponent={
                        <MUIComponents.TextFields.TextField
                            id="email"
                            label={t('login.mailInput')}
                            name="email"
                            size="small"
                            autoFocus={false}
                            onBlur={(e) => {
                                const isEmailValid = validateEmail(e.target.value)

                                if (!isEmailValid) {
                                    setPasswordEmailError({ error: true, valid: isEmailValid })
                                } else {
                                    setPasswordEmailError({
                                        error: false,
                                        valid: isEmailValid
                                    })
                                    setPasswordEmail(e.target.value)
                                }
                            }}
                            error={passwordEmailError.error}
                            {...(passwordEmailError.error && {
                                helperText: !passwordEmailError.valid
                                    ? t('login.mailCheckError')
                                    : t('login.mailRequiredError')
                            })}
                            colorToOverwrite="rgba(0, 0, 0, 0.9)"
                        />
                    }
                    fullScreen
                />
            </MUIComponents.Styling.Grid>
        </StyledComponents.Wrapper>
    )
}
