import MuiImageList, { ImageListProps as MuiImageListProps } from '@mui/material/ImageList'
import MuiImageListItem from '@mui/material/ImageListItem'
import MuiImageListItemBar from '@mui/material/ImageListItemBar'
import styled from 'styled-components'

import { MUIComponents } from '.'

const StyledImageList = styled(MuiImageList)`
    background-color: ${(props) => props.theme.palette.primary.main} !important;
    padding: 15px !important;
`

const StyledImageListItemBar = styled(MuiImageListItemBar)`
    background-color: ${(props) => props.theme.palette.primary.light} !important;
    padding: 10px;
`

interface ImageListProps extends Omit<MuiImageListProps, 'children'> {
    // you can declare custom props in here
    items?: { img: string; title: string; subTitle?: string }[]
    titleBar?: { show: boolean; underImage: boolean }
}

export const ImageList = ({
    cols = 3,
    gap = 15, // Important: cols on 2 will deactivate the gap
    variant = 'standard',
    rowHeight = 'auto',
    items = [],
    titleBar = { show: false, underImage: false },
    sx = { width: 500, height: 500 },
    ...props
}: ImageListProps) => (
    <StyledImageList
        sx={sx}
        cols={cols}
        gap={gap}
        variant={variant}
        rowHeight={rowHeight}
        {...props}
    >
        {items.map((item) => (
            <MuiImageListItem key={item.img}>
                <img
                    src={`${item.img}?w=${rowHeight ?? 150}&h=${
                        rowHeight ?? 150
                    }&fit=crop&auto=format`}
                    srcSet={`${item.img}?w=${rowHeight ?? 150}&h=${
                        rowHeight ?? 150
                    }&fit=crop&auto=format&dpr=2 2x`}
                    alt={item.title}
                />
                {titleBar.show &&
                    (titleBar.underImage ? (
                        <StyledImageListItemBar
                            title={item.title}
                            subtitle={<span>{item.subTitle}</span>}
                            position="below"
                        />
                    ) : (
                        <MuiImageListItemBar
                            title={item.title}
                            subtitle={item.subTitle}
                            actionIcon={
                                <MUIComponents.Buttons.IconButton
                                    sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                    aria-label={`info about ${item.title}`}
                                >
                                    <MUIComponents.Icons.InfoIcon />
                                </MUIComponents.Buttons.IconButton>
                            }
                        />
                    ))}
            </MuiImageListItem>
        ))}
    </StyledImageList>
)

// Example of this component with props:
{
    /* <MUIComponents.ImageList
                titleBar={{ show: true, underImage: false }}
                items={[
                    {
                        img: 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
                        title: 'Breakfast',
                        subTitle: 'Breakfast'
                    },
                    {
                        img: 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d',
                        title: 'Burger',
                        subTitle: 'Breakfast'
                    },
                    {
                        img: 'https://images.unsplash.com/photo-1522770179533-24471fcdba45',
                        title: 'Camera',
                        subTitle: 'Breakfast'
                    },
                    {
                        img: 'https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c',
                        title: 'Coffee'
                    },
                    {
                        img: 'https://images.unsplash.com/photo-1533827432537-70133748f5c8',
                        title: 'Hats'
                    },
                    {
                        img: 'https://images.unsplash.com/photo-1558642452-9d2a7deb7f62',
                        title: 'Honey'
                    },
                    {
                        img: 'https://images.unsplash.com/photo-1516802273409-68526ee1bdd6',
                        title: 'Basketball'
                    },
                    {
                        img: 'https://images.unsplash.com/photo-1518756131217-31eb79b20e8f',
                        title: 'Fern'
                    },
                    {
                        img: 'https://images.unsplash.com/photo-1597645587822-e99fa5d45d25',
                        title: 'Mushrooms'
                    },
                    {
                        img: 'https://images.unsplash.com/photo-1567306301408-9b74779a11af',
                        title: 'Tomato basil'
                    },
                    {
                        img: 'https://images.unsplash.com/photo-1471357674240-e1a485acb3e1',
                        title: 'Sea star'
                    },
                    {
                        img: 'https://images.unsplash.com/photo-1589118949245-7d38baf380d6',
                        title: 'Bike'
                    }
                ]}
            /> */
}
