import { CSSProperties } from 'styled-components'
import { useTranslation } from 'react-i18next'

import { MUIComponents } from '../../muiComponents'
import Containers from '../../containers'
import * as GQLTypes from '../../../types/GQLTypes'

const border = '1px solid #D8D8D8'

const commonStyling: CSSProperties = {
    gridRow: '1/7',
    minWidth: 0,
    borderRight: border,
    overflow: 'auto'
}

export const Content = ({
    quotation,
    orderId
}: {
    quotation: GQLTypes.Maybe<GQLTypes.Quotation>
    orderId: GQLTypes.Order['id']
}) => {
    const { t } = useTranslation()

    return (
        <MUIComponents.Styling.Box
            sx={{
                display: 'grid',
                gridAutoFlow: 'row',
                gridTemplateColumns: 'repeat(14, 1fr)',
                gridTemplateRows: 'repeat(6, 1fr)',
                width: '100%',
                height: '100%'
            }}
        >
            <MUIComponents.Styling.Box
                sx={{
                    gridColumn: '1/3',
                    gridRow: '1/7',
                    minWidth: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                    overflow: 'auto'
                }}
            >
                <MUIComponents.Stepper
                    loading={!quotation}
                    alternativeLabel
                    activeStep={
                        quotation?.order?.orderStatus?.index
                            ? Number(quotation?.order?.orderStatus?.index - 1)
                            : -1
                    }
                    orientation="vertical"
                    steps={[
                        t('filesAndCalc.files.step1'),
                        t('filesAndCalc.files.step2'),
                        t('filesAndCalc.files.step3'),
                        t('filesAndCalc.files.step4'),
                        t('filesAndCalc.files.step5'),
                        t('filesAndCalc.files.step6')
                    ]}
                    icons={{
                        1: <>1</>,
                        2: <>2</>,
                        3: <>3</>,
                        4: <>4</>,
                        5: <>5</>,
                        6: <MUIComponents.Icons.StarIcon />
                    }}
                />
            </MUIComponents.Styling.Box>
            <MUIComponents.Styling.Box
                sx={{
                    gridColumn: '3/6',
                    ...commonStyling
                }}
            >
                <Containers.Info.BasisInfo
                    quotation={quotation}
                    button={{ visible: false }}
                    sourceComponent="Files"
                />
            </MUIComponents.Styling.Box>
            <MUIComponents.Styling.Box
                sx={{
                    gridColumn: '6/9',
                    borderLeft: border,
                    ...commonStyling
                }}
            >
                <Containers.Info.Calculation quotation={quotation} />
            </MUIComponents.Styling.Box>
            <MUIComponents.Styling.Box
                sx={{
                    gridColumn: '9/12',
                    borderLeft: border,
                    ...commonStyling
                }}
            >
                <Containers.Info.Details quotation={quotation} />
            </MUIComponents.Styling.Box>
            <MUIComponents.Styling.Box sx={{ gridColumn: '12/15', gridRow: '1/7', minWidth: 0 }}>
                <Containers.Info.Documents
                    orderId={orderId}
                    quotationId={quotation?.id}
                    bikeType={quotation?.bikeType?.name ?? '/'}
                />
            </MUIComponents.Styling.Box>
        </MUIComponents.Styling.Box>
    )
}
