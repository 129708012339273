import Collapse from '@mui/material/Collapse'
import MuiList, { ListProps as MuiListProps } from '@mui/material/List'
import MuiListItem from '@mui/material/ListItem'
import MuiListItemAvatar, {
    ListItemAvatarProps as MuiListItemAvatarProps
} from '@mui/material/ListItemAvatar'
import MuiListSubheader from '@mui/material/ListSubheader'
import styled, { css } from 'styled-components'
import MuiListItemText from '@mui/material/ListItemText'
import { useEffect, useState } from 'react'
import { SxProps } from '@mui/material'

import { MUIComponents } from '.'
import { ItemProps, Theme } from '../../types'
import { noop } from '../../utils/utils'
import theme from '../../theme/theme'
import { ListItemsProps, SubListItemsProps } from './types'
import StyledComponents from '../StyledComponents'

interface ListProps extends MuiListProps {
    // you can declare custom props in here
    content?: string
    icons?: { [key: number]: JSX.Element }
    listItems: ItemProps[]
    setListItem?: (listItem: ItemProps) => void
    itemContent?: (
        title: string,
        subTitle: string,
        index: number,
        item: ItemProps['item']
    ) => JSX.Element
    listItemAvatarStyle?: MuiListItemAvatarProps['sx']
    listHeader?: string
    add?: boolean
    addFunction?: () => void
    deleteFunction?: (index: number) => void
    loading?: boolean
    goToItem?: (item: { subItem: SubListItemsProps; mainItem: string }) => void
    collapseStyling?: SxProps<Theme> | undefined
}

// TODO: made icons prop optional
// TODO: updated listItemAvatarStyle
// TODO: added wrapper around the children of MuiListItem
export const List = ({
    add = true,
    addFunction = noop,
    deleteFunction = noop,
    listItems = [],
    listItemAvatarStyle = { mt: 0 },
    itemContent = (title, _subTitle, _index) => (
        <MUIComponents.Typography
            sx={{
                alignSelf: 'center',
                margin: 'auto',
                textOverflow: 'ellipsis',
                overflow: 'hidden'
            }}
            variant="body2"
            color="text.primary"
        >
            {title}
        </MUIComponents.Typography>
    ),
    loading = false,
    goToItem = noop,
    collapseStyling = {},
    ...props
}: ListProps) => (
    <MuiList
        sx={{ width: '100%', ...props.sx }}
        {...(props.listHeader && {
            subheader: (
                <Wrapper>
                    <MuiListSubheader
                        sx={{ lineHeight: '40px' }}
                        component="div"
                        id="nested-list-subheader"
                    >
                        {props.listHeader}
                    </MuiListSubheader>
                    {add && (
                        <MUIComponents.Buttons.IconButton
                            onClick={addFunction}
                            edge="end"
                            aria-label="add"
                        >
                            <MUIComponents.Icons.AddCircleIcon />
                        </MUIComponents.Buttons.IconButton>
                    )}
                </Wrapper>
            )
        })}
        {...props}
    >
        {loading ? (
            <MUIComponents.Skeleton
                sx={{ margin: 'auto', marginBottom: '10px' }}
                width="100%"
                height={400}
                variant="rectangular"
            />
        ) : (
            listItems.map((child, index) => (
                <MuiListItem
                    key={index}
                    {...(child.delete && {
                        secondaryAction: (
                            <MUIComponents.Buttons.IconButton
                                onClick={() => deleteFunction(index)}
                                edge="end"
                                aria-label="delete"
                            >
                                <MUIComponents.Icons.DeleteIcon />
                            </MUIComponents.Buttons.IconButton>
                        )
                    })}
                    alignItems="flex-start"
                    sx={{ justifyContent: 'center', padding: 'unset' }}
                >
                    <>
                        {props.icons && (
                            <MuiListItemAvatar sx={listItemAvatarStyle}>
                                <MUIComponents.Avatar
                                    icon={props.icons?.[child.avatar]}
                                    alt="avatar"
                                />
                            </MuiListItemAvatar>
                        )}

                        {itemContent(child.title, child.subTitle, index, child.item)}
                    </>
                </MuiListItem>
            ))
        )}
    </MuiList>
)

// TODO: added new NestedList
// TODO: Updated ListItemProps (added new interface)
// TODO: Added defaultOpen on item level
// TODO: added new prop goToItem and renamed x
// TODO: added new prop collapseStyling
// TODO: added new styled component NestedWrapper
// TODO: added an option to give subElement instead of subItems
// TODO: updated sx of the MuiListItemText and Typography
// TODO: updated styling of the NestedListWrapper
// TODO: removed onClick for sub items (when subElement is there)
// TODO: fixed bug with only opening 1 at a time -> now you can open them all at the same time
// TODO: updated NestedWrapper props and styling 
export const NestedList = ({
    listItems = [],
    listItemAvatarStyle = { mt: 0 },
    itemContent = (title, _subTitle, _index) => (
        <MUIComponents.Typography
            sx={{
                alignSelf: 'center',
                margin: 'auto',
                textOverflow: 'ellipsis',
                overflow: 'hidden'
            }}
            variant="body2"
            color="text.primary"
        >
            {title}
        </MUIComponents.Typography>
    ),
    loading = false,
    goToItem = noop,
    collapseStyling = {},
    ...props
}: Omit<ListProps, 'listItems'> & {
    listItems?: ListItemsProps[]
}) => {
    const [open, setOpen] = useState<{ [key: string]: boolean }>({})

    useEffect(() => {
        listItems?.map((item) => {
            if (item?.defaultOpen) setOpen((old) => ({ ...old, [item?.name]: item?.defaultOpen }))
        })
    }, [listItems])

    const rowFontSize = '12px'

    return (
        <MuiList
            sx={{ width: '100%', bgcolor: 'background.paper' }}
            component="nav"
            aria-labelledby="nested-list-subheader"
            {...props}
        >
            {listItems.map((item) => (
                <>
                    {item.subItems || item.subElement ? (
                        <NestedWrapper
                            isOpen={open[item.name]}
                            areAllItemsOpen={Object.values(open)?.every((itemIsOpen) => itemIsOpen)}
                            key={item.id}
                        >
                            <MuiListItem
                                button
                                key={item.id}
                                onClick={() => {
                                    setOpen((old) => ({ ...old, [item?.name]: !old[item.name] }))
                                }}
                            >
                                {open[item.name] ? (
                                    <MUIComponents.Icons.ArrowDropUpIcon
                                        sx={{ color: theme.palette.primary.main }}
                                    />
                                ) : (
                                    <MUIComponents.Icons.ArrowDropDownIcon />
                                )}
                                <MuiListItemText
                                    sx={{
                                        ...StyledComponents.helper.truncatedText,
                                        '.MuiListItemText-primary': {
                                            display: 'unset'
                                        }
                                    }}
                                    primary={item?.name ?? '/'}
                                />
                                {item.subElement === undefined && (
                                    <MUIComponents.Typography
                                        sx={{ margin: 'unset' }}
                                        variant="body1"
                                    >
                                        {item?.subItems?.length ?? 0}
                                    </MUIComponents.Typography>
                                )}
                            </MuiListItem>
                            <Collapse
                                key={item.id}
                                component="li"
                                in={open[item.name]}
                                timeout="auto"
                                unmountOnExit
                                sx={{ ...collapseStyling }}
                            >
                                {open[item.name] &&
                                    (item.subElement !== undefined ? (
                                        <MuiListItem button key={item.id}>
                                            {item.subElement}
                                        </MuiListItem>
                                    ) : (
                                        <MuiList disablePadding>
                                            {item.subItems?.map((subItem) => (
                                                <MuiListItem
                                                    button
                                                    key={subItem.id}
                                                    onClick={() => {
                                                        goToItem?.({
                                                            subItem,
                                                            mainItem: item.name
                                                        })
                                                    }}
                                                >
                                                    <MuiListItemText
                                                        primaryTypographyProps={{
                                                            fontSize: rowFontSize
                                                        }}
                                                        key={subItem.id}
                                                        primary={subItem.main}
                                                        sx={{
                                                            ...StyledComponents.helper
                                                                .truncatedText,
                                                            '.MuiListItemText-primary': {
                                                                display: 'unset'
                                                            }
                                                        }}
                                                    />
                                                    <MuiListItemText
                                                        primaryTypographyProps={{
                                                            fontSize: rowFontSize
                                                        }}
                                                        key={subItem.id}
                                                        primary={subItem.sub1}
                                                        sx={{
                                                            ...StyledComponents.helper
                                                                .truncatedText,
                                                            '.MuiListItemText-primary': {
                                                                display: 'unset'
                                                            }
                                                        }}
                                                    />
                                                    <MUIComponents.Typography
                                                        sx={{
                                                            margin: 'unset',
                                                            fontSize: rowFontSize,
                                                            ...StyledComponents.helper
                                                                .truncatedText,
                                                            '.MuiListItemText-primary': {
                                                                display: 'unset'
                                                            }
                                                        }}
                                                        variant="body1"
                                                    >
                                                        {subItem.sub2}
                                                    </MUIComponents.Typography>
                                                </MuiListItem>
                                            ))}
                                        </MuiList>
                                    ))}
                            </Collapse>
                        </NestedWrapper>
                    ) : (
                        <MuiListItem
                            button
                            onClick={() => {
                                setOpen((old) => ({ ...old, [item?.name]: !old[item.name] }))
                            }}
                            key={item.id}
                        >
                            <MuiListItemText primary={item.name} />
                        </MuiListItem>
                    )}
                </>
            ))}
        </MuiList>
    )
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`

const NestedWrapper = styled.div<{ isOpen: boolean; areAllItemsOpen: boolean }>`
    height: 50%;

    ${(props) =>
        !props.isOpen &&
        !props.areAllItemsOpen &&
        css`
            height: unset;
        `}

    ${(props) =>
        !props.areAllItemsOpen &&
        props.isOpen &&
        css`
            height: calc(100% - 48px);
        `}
`

// Example of the List component with props:
{
    /* <MUIComponents.List
                icons={{
                    1: <MUIComponents.Icons.SportsMotorsportsIcon />
                }}
                listItems={props.data}
                itemContent={(title, subTitle, index) =>
                    ItemContent({
                        description: { error: false, value: title },
                        price: { error: false, value: subTitle },
                        row: index,
                        setData
                    })
                }
                listItemAvatarStyle={{ mt: 1 }}
                listheader="Voeg nieuwe kledij toe"
                addFunction={() => {return}}
                deleteFunction={() => {return}}
            /> */
}

// Example of the NestedList component with props:
{
    /* <MUIComponents.NestedList
                listItems={[
                    {
                        id: 1,
                        name: 'Contracten',
                        subItems: [
                            {
                                id: 101,
                                main: 'Q12345',
                                sub1: 'Damesfiets voor Leila',
                                sub2: 'Geleverd'
                            },
                            {
                                id: 102,
                                main: 'Q12346',
                                sub1: 'Speedelec werk',
                                sub2: 'Getekend'
                            }
                        ]
                    },
                    {
                        id: 2,
                        name: 'Berekeningen',
                        subItems: [
                            {
                                id: 201,
                                main: 'Q12347',
                                sub1: '12/03/2023',
                                sub2: '€ 3.279,34'
                            },
                            {
                                id: 202,
                                main: 'Q12348',
                                sub1: '12/03/2023',
                                sub2: '€ 1.525,00'
                            }
                        ]
                    }
                ]}
                addFunction={() => {
                    return
                }}
                deleteFunction={() => {
                    return
                }}
            /> */
}
