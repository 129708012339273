import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'

import { MUIComponents } from '../../muiComponents'
import { MuiTabsProps } from '../../muiComponents/types'

interface NoContentProps {
    orientation?: MuiTabsProps['orientation']
    homePage?: boolean
}

export const NoContent = (props: NoContentProps) => {
    const { t } = useTranslation()

    return (
        <Wrapper orientation={props.orientation}>
            <MUIComponents.Typography
                variant="subtitle1"
                style={{
                    textAlign: 'center',
                    fontSize: '12px',
                    margin: 'auto'
                }}
            >
                {props.homePage
                    ? t('filesAndCalc.noContent.noActiveContractsErr')
                    : t('filesAndCalc.noContent.noDocsErr')}
            </MUIComponents.Typography>
        </Wrapper>
    )
}

const Wrapper = styled.div<{ orientation: MuiTabsProps['orientation'] }>`
    width: calc(100% - 40px);
    height: calc(100% - 80px);
    background-color: rgb(255, 255, 255);
    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: rgb(0 0 0 / 20%) 0px 3px 5px -1px, rgb(0 0 0 / 14%) 0px 5px 8px 0px,
        rgb(0 0 0 / 12%) 0px 1px 14px;
    padding: 20px;
    margin: auto;
    display: flex;
    justify-content: center;
    min-height: 100px;

    ${(props) =>
        props.orientation === 'vertical' &&
        css`
            background-color: unset;
            transition: unset;
            border-radius: unset;
            box-shadow: unset;
        `}

    ${(props) =>
        props.orientation === 'horizontal' &&
        css`
            height: calc(100% - 40px);
        `}
`
