import { useState } from 'react'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectProps as MuiSelectProps } from '@mui/material/Select'
import FormHelperText from '@mui/material/FormHelperText'
import styled, { CSSProperties } from 'styled-components'
import OutlinedInput from '@mui/material/OutlinedInput'

import { noop, genericFontSize, genericMaxInputWidth } from '../../utils/utils'
import { MUIComponents } from '.'

interface CustomProps {
    colorToOverwrite?: SelectProps['colorToOverwrite']
    customSelect?: SelectProps['customSelect']
}

const StyledInputLabel = styled(InputLabel)`
    font-size: ${genericFontSize} !important;
    top: 0px !important;
`

const StyledFormControl = styled(FormControl)<CustomProps>`
    margin-top: 16px !important;
    margin-bottom: 8px !important;

    & label {
        font-size: ${genericFontSize};
        left: unset;
    }

    & label.Mui-focused {
        font-size: ${genericFontSize};
        color: ${(props) => props.colorToOverwrite ?? props.theme.palette.primary.main};
    }

    & .MuiOutlinedInput-root {
        & fieldset {
            font-size: ${genericFontSize};
        }

        &.Mui-focused fieldset {
            font-size: ${genericFontSize};
            border-color: ${(props) => props.colorToOverwrite ?? props.theme.palette.primary.main};
        }
    }

    & .MuiOutlinedInput-notchedOutline {
        border-color: ${(props) =>
            props.customSelect ? props.theme.palette.primary.main : undefined};
    }

    & label,
    .MuiSelect-icon {
        color: ${(props) => (props.customSelect ? props.theme.palette.primary.main : undefined)};
    }
`

// TODO: updated styling
// TODO: made menuItems optional
// TODO: added size prop to the formControl (Single and Multi) 
interface SelectProps {
    fullWidth?: boolean
    labelContent?: string
    menuItems?: { value: string; label: string; icon?: JSX.Element }[]
    errorText?: string
    colorToOverwrite?: string
    customSelect?: boolean
    formStyling?: CSSProperties
}

// TODO: updated single select
// TODO: added new prop colorToOverwrite
// TODO: added new prop customSelect
// TODO: added new prop formStyling
// TODO: updated styling
// TODO: and updated styling
// TODO: updated StyledInputLabel styling
// TODO: updated checkbox with the wrapper checkbox
export const SingleSelect = ({
    fullWidth = true,
    labelId = 'single-select-label',
    id = 'single-select',
    onChange = noop,
    value = '',
    required = false,
    menuItems = [],
    labelContent = '',
    formStyling,
    ...props
}: SelectProps & MuiSelectProps) => (
    <StyledFormControl
        required={required}
        fullWidth={fullWidth}
        colorToOverwrite={props.colorToOverwrite}
        error={props.error}
        customSelect={props.customSelect}
        sx={{maxWidth: genericMaxInputWidth, ...formStyling}}
        size={props.size}
    >
        <StyledInputLabel id={labelId}>{labelContent}</StyledInputLabel>
        <Select
            label={labelContent}
            labelId={labelId}
            id={id}
            value={value}
            onChange={onChange}
            inputProps={{ style: { fontSize: genericFontSize } }}
            style={{ width: '100%', fontSize: genericFontSize }}
            MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
            renderValue={(value) => (
                <span>{menuItems.find((x) => x.value === value)?.label as React.ReactNode}</span>
            )}
            input={<OutlinedInput label={labelContent} />}
            {...props}
        >
            {menuItems.map((menuItem) => (
                <MenuItem
                    key={menuItem.label}
                    value={menuItem.value}
                    {...(menuItem.icon && {
                        sx: { display: 'flex', justifyContent: 'space-between' }
                    })}
                >
                    {menuItem.label}
                    {menuItem.icon}
                </MenuItem>
            ))}
        </Select>
        {props.error && props.errorText && <FormHelperText>{props.errorText}</FormHelperText>}
    </StyledFormControl>
)

// TODO: updated styling
export const MultiSelect = ({
    fullWidth = true,
    labelId = 'multi-select-label',
    id = 'multi-select',
    onChange = noop,
    value = [],
    required = false,
    menuItems = [],
    labelContent = '...',
    ...props
}: SelectProps & MuiSelectProps) => (
    <StyledFormControl size={props.size} required={required} fullWidth={fullWidth} sx={{maxWidth: genericMaxInputWidth}} error={props.error}>
        <StyledInputLabel id={labelId}>{labelContent}</StyledInputLabel>
        <Select
            multiple
            labelId={labelId}
            label={labelContent}
            id={id}
            value={value}
            onChange={onChange}
            inputProps={{ style: { fontSize: genericFontSize } }}
            style={{ width: '100%', fontSize: genericFontSize }}
            renderValue={(value) => (
                <span>
                    {
                        menuItems
                            .filter((x) => (value as string[]).includes(x.value))
                            .map((x) => x.label)
                            .join(', ') as React.ReactNode
                    }
                </span>
            )}
            {...props}
        >
            {menuItems.map((menuItem) => (
                <MenuItem
                    key={menuItem.label}
                    value={menuItem.value}
                    {...(menuItem.icon && {
                        sx: { display: 'flex', justifyContent: 'space-between' }
                    })}
                >
                    <MUIComponents.Checkbox
                        checked={(value as string).indexOf(menuItem.value) > -1}
                    />
                    {menuItem.label}
                    {menuItem.icon}
                </MenuItem>
            ))}
        </Select>
        {props.error && props.errorText && <FormHelperText>{props.errorText}</FormHelperText>}
    </StyledFormControl>
)

// Example of the SingleSelect component with props:
{
    /* <MUIComponents.Select.SingleSelect
                labelId="bikeType"
                id="select-bike-type"
                name="bikeType"
                required
                labelContent="Fiets model"
                error={bikeTypeError}
                value={bikeType?.value}
                menuItems={bikeTypes.map((type) => ({
                    value: type.id ?? '',
                    label: type.name ?? '',
                    icon: <MUIComponents.Icons.AccountBoxOutlinedIcon />
                }))}
                onChange={(e) => {
                    const newBikeType = {
                        value: e.target.value as string,
                        label: bikeTypes.find((type) => type.id === e.target.value)?.name ?? ''
                    }

                    setBikeType(newBikeType)
                    setBikeTypeError(false)
                    setData({
                        ...props.data,
                        bike_type: newBikeType
                    })

                    return
                }}
                {...(bikeTypeError && {
                    errorText: 'Het selecteren van een fiets model is verplicht.'
                })}
            /> */
}

// Example of the MultiSelect component with props:
{
    /* <MUIComponents.Select.MultiSelect
                labelId="type"
                id="select-type"
                name="type"
                required
                labelContent="Type"
                error={statute.error}
                value={statute.value}
                menuItems={statutes}
                onChange={(e) => {
                    if (e.target.value) {
                        setStatute({
                            error: false,
                            value: e.target.value
                        })
                    }

                    return
                }}
                {...(statute.error && {
                    errorText: 'Het invullen van uw werknemers statuut is verplicht.'
                })}
            /> */
}
