import { BasisInfo } from './BasisInfo'
import { Calculation } from './Calculation'
import { Details } from './Details'
import { Documents } from './Documents'
import { InfoFields } from './InfoFields'
import { Label } from './Label'
import { Value } from './Value'
import { NoContent } from './NoContent'

export default {
    BasisInfo,
    Calculation,
    Details,
    Documents,
    InfoFields,
    Label,
    Value,
    NoContent
}
