import { useEffect, useState } from 'react'
import styled, { CSSProperties } from 'styled-components'
import { useRecoilState } from 'recoil'
import { useLazyQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import Containers from '../../../containers'
import { MUIComponents } from '../../../muiComponents'
import { alertObjState, simulatorObjectState, userProfileState } from '../../../../store/store'
import { DeviceParameters, SimulatorValues, StepProps } from '../../../../types'
import { DataType, Employee, Field, Maybe, QueryEmployeeArgs } from '../../../../types/GQLTypes'
import API from '../../../../API'
import { Warning } from './Warning'
import useDeviceDetection from '../../../../hooks/useDeviceDetection'
import StyledComponents from '../../../StyledComponents'
import { Explanation } from './Explanation'
import { getBooleanValue, getHelpTextForOption } from '../../../../utils/utils'

const getComponent = (
    componentType: DataType['code'],
    type: 'default' | 'extra',
    props: { [key: string]: unknown; width: string }
) => {
    const defaultProps = {
        size: 'small' as const,
        customSelect: false,
        fullWidth: false,
        formStyling: { width: props.width }
    }

    switch (componentType) {
        case 'LIST':
            return MUIComponents.Select.SingleSelect({ ...defaultProps, ...props })
        case 'TEXT':
        case 'NUMBER':
        default:
            return type === 'default'
                ? MUIComponents.Checkbox({
                      ...defaultProps,
                      ...props,
                      required: true,
                      autoFocus: false,
                      style: { marginLeft: '-10px' },
                      color: props.error ? 'error' : undefined
                  })
                : MUIComponents.Radio({
                      ...defaultProps,
                      ...props,
                      required: true,
                      autoFocus: false,
                      style: { marginLeft: '-10px' }
                  })
    }
}

const hardcodedOptions = {
    default: [
        'ASSISTANCE',
        'INSURANCE',
        'MAINTENANCE',
        'DURATIONMONTHS',
        'USERDECLARATION',
        'HELMET',
        'SOLWERTLEASE',
        'LEASINGCOMPANY',
        'INSURANCE2',
        'TAKEOVER',
        'FRAMEMATERIAL',
        'WORKDAYNR',
        'SHOPQUOTATIONR',
        'WORKINGHOURS',
        'LIABILITYINSURANCE',
        'REPLACEMENT_BIKE'
    ],
    extra: [],
    skip: [
        'BIKE_DESCRIPTION',
        'BIKE_VALUE',
        'PARTS_DESCRIPTION',
        'LOOSE_PARTS_VALUE',
        'BIKEBRAND',
        'CLOTHING',
        'VALUEBIKE_NOCLOTHING',
        'CLOTHING_DESCRIPTION',
        'BIKETYPE'
    ]
}

export const StepTwo = (props: StepProps) => {
    const { t } = useTranslation()

    //global states
    const [userProfile] = useRecoilState(userProfileState)
    const [, setAlertObject] = useRecoilState(alertObjState)
    const [simulatorValues, setSimulatorValues] = useRecoilState(simulatorObjectState)

    // states
    const [loading, setLoading] = useState(false)
    const [employeeInfo, setEmployeeInfo] = useState<Employee>({})
    const [fields, setFields] = useState<Maybe<Field>[]>([])
    const [errors, setErrors] = useState<{ [key: string]: boolean }>({})

    // graphql queries
    const [getEmployeeInfo] = useLazyQuery<{ employee: Employee }, QueryEmployeeArgs>(
        API.Queries.EMPLOYEE_COMPANY_FK
    )

    // hooks
    const [devices] = useDeviceDetection()

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)

            try {
                const employeeData = await getEmployeeInfo({
                    variables: {
                        id: userProfile.employee_id as string
                    }
                })

                if (employeeData?.data?.employee) {
                    const defaultValue = (option: Maybe<Field>) =>
                        option?.field_type?.dataType?.code &&
                        ['TEXT', 'NUMBER'].includes(option.field_type.dataType.code)
                            ? false
                            : option?.field_type?.code
                            ? getBooleanValue(
                                  localStorage.getItem(`${option?.field_type?.code}`)
                              ) ?? undefined
                            : undefined

                    setEmployeeInfo(employeeData?.data?.employee)

                    setFields(props.fieldsData?.company?.companyFields ?? [])
                    setSimulatorValues((old) => ({
                        ...old,
                        company: props.fieldsData?.company,
                        employeeStatute: props.fieldsData?.employeeStatute,
                        defaultOptions: (props.fieldsData?.company?.companyFields ?? [])
                            .filter((option) =>
                                hardcodedOptions.default.includes(
                                    option?.field_type?.code as string
                                )
                            )
                            .map((option) => ({
                                value: defaultValue(option),
                                name: option?.name as string,
                                code: option?.field_type?.code ?? undefined,
                                index: option?.index
                            }))
                        // extraOptions: (fieldsData?.data?.employee?.company?.companyFields ?? [])
                        //     .filter((option) =>
                        //         hardcodedOptions.extra.includes(
                        //             option?.field_type?.code as string
                        //         )
                        //     )
                        //     .map((option) => ({
                        //         value: defaultValue(option),
                        //         name: option?.name as string
                        //     }))
                    }))
                }
            } catch (err) {
                console.log(err)
                setFields([])
                setEmployeeInfo({})
                setAlertObject({
                    message: t('simulation.step2Container.alertEmployeeInfo'),
                    severity: 'error'
                })
            }

            setLoading(false)
        }

        fetchData()
    }, [])

    const boxWidth = devices.isMob || devices.isSmallerThanMob ? 'calc(100% - 20px)' : undefined

    return (
        <MUIComponents.Styling.Box
            sx={{
                display: 'grid',
                gridAutoFlow: 'row',
                gridTemplateColumns: 'repeat(10, 1fr)',
                gridTemplateRows: 'repeat(10, 1fr)',
                gap: 1,
                width: '100%',
                height: devices.isSmallerThanMob ? 'calc(100% - 370px)' : 'calc(100% - 150px)',
                marginTop: '20px',
                overflow: devices.isWeb ? 'auto' : undefined
            }}
        >
            <Explanation />

            <MUIComponents.Styling.Box
                sx={{
                    gridColumn: devices.isSmallerThanMob || devices.isMob ? '1/11' : '1/9',
                    gridRow: devices.isSmallerThanMob || devices.isMob ? '2/5' : '2/8',
                    minWidth: 0,
                    ...((devices.isSmallerThanMob || devices.isMob) && {
                        borderBottom: '1px solid #D8D8D8'
                    }),
                    width: boxWidth,
                    height: 'min-content'
                }}
            >
                <MUIComponents.Typography
                    variant="subtitle1"
                    style={{
                        ...typoGraphyStyling,
                        ...StyledComponents.helper.breakupText,
                        fontWeight: 'bold',
                        marginLeft: '0'
                    }}
                >
                    {t('simulation.step2Container.optionsSubtitle')}
                </MUIComponents.Typography>

                <OptionsColumn>
                    {fields
                        .filter((option) =>
                            hardcodedOptions.default.includes(option?.field_type?.code as string)
                        )
                        .map((option: Maybe<Field>, i) => (
                            <Row
                                isMob={devices.isMob}
                                isSmallerThanMob={devices.isSmallerThanMob}
                                key={i}
                            >
                                {getComponent(option?.field_type?.dataType?.code, 'default', {
                                    onChange: (e: Event) => {
                                        const target = e.target as HTMLInputElement

                                        setSimulatorValues((old) =>
                                            getSheetValues({
                                                oldData: old,
                                                option,
                                                value: target.checked ?? target.value,
                                                type: 'default'
                                            })
                                        )

                                        if (option?.field_type?.code) {
                                            localStorage.setItem(
                                                option?.field_type?.code,
                                                target.checked?.toString() ?? target.value
                                            )

                                            setErrors((old) => ({
                                                ...old,
                                                [option?.field_type?.code as string]: false
                                            }))

                                            if (props.setErrors) {
                                                props.setErrors()
                                            }
                                        }
                                    },
                                    labelContent: option?.name,
                                    value: simulatorValues.defaultOptions.find(
                                        (dOption) => dOption.name === option?.name
                                    )?.value,
                                    checked: simulatorValues.defaultOptions.find(
                                        (dOption) => dOption.name === option?.name
                                    )?.value,
                                    menuItems: option?.field_values?.map((val) => ({
                                        value: val?.value ?? '',
                                        label: val?.value ?? ''
                                    })),
                                    width: '100%',
                                    error:
                                        props.errors?.options === true
                                            ? props.errors?.options
                                            : option?.field_type?.code
                                            ? errors[option?.field_type?.code]
                                            : false
                                })}
                                {option?.field_type?.dataType?.code !== 'LIST' && (
                                    <MUIComponents.Typography
                                        variant="subtitle1"
                                        style={{
                                            ...typoGraphyStyling,
                                            ...(option?.field_type?.dataType?.code &&
                                                (errors[option?.field_type?.dataType?.code] ||
                                                    props.errors?.options) && {
                                                    color: errors[
                                                        option?.field_type?.dataType?.code
                                                    ]
                                                        ? 'red'
                                                        : undefined
                                                })
                                        }}
                                    >
                                        {option?.name}
                                    </MUIComponents.Typography>
                                )}
                                {getHelpTextForOption(option?.field_type?.code ?? undefined, t) && (
                                    <MUIComponents.Buttons.IconButton
                                        aria-label="toggle-description"
                                        onClick={() => {
                                            return
                                        }}
                                        onMouseDown={(e) => {
                                            e.preventDefault()
                                        }}
                                        edge="end"
                                        style={{ height: '50%', margin: 'auto' }}
                                        tooltip={{
                                            placement: 'right',
                                            content: `${getHelpTextForOption(
                                                option?.field_type?.code ?? undefined,
                                                t
                                            )}`
                                        }}
                                    >
                                        <MUIComponents.Icons.HelpIcon />
                                    </MUIComponents.Buttons.IconButton>
                                )}
                            </Row>
                        ))}
                </OptionsColumn>
            </MUIComponents.Styling.Box>
            {/* <MUIComponents.Styling.Box
                sx={{
                    gridColumn: devices.isSmallerThanMob || devices.isMob ? '1/11' : '6/11',
                    gridRow: devices.isSmallerThanMob || devices.isMob ? '5/8' : '2/8',
                    minWidth: 0,
                    marginBottom: '10px',
                    width: boxWidth,
                    height: 'min-content'
                }}
            > */}
            {/* <MUIComponents.Typography
                    variant="subtitle1"
                    style={{
                        ...typoGraphyStyling,
                        ...StyledComponents.helper.breakupText,
                        fontWeight: 'bold',
                        marginLeft: '0'
                    }}
                >
                    {t('simulation.step2Container.additionalOptionsInput')}
                </MUIComponents.Typography> */}

            {/* <OptionsColumn>
                    {fields
                        .filter((option) =>
                            hardcodedOptions.extra.includes(option?.field_type?.code as string)
                        )
                        .map((option, i) => (
                            <Row
                                isMob={devices.isMob}
                                isSmallerThanMob={devices.isSmallerThanMob}
                                key={i}
                            >
                                {getComponent(option?.field_type?.dataType?.code, 'extra', {
                                    onChange: (e: Event) => {
                                        setSimulatorValues((old) =>
                                            getSheetValues({
                                                oldData: old,
                                                option,
                                                value: (e.target as HTMLInputElement).value,
                                                type: 'extra'
                                            })
                                        )
                                    },
                                    labelContent: option?.name,
                                    value: simulatorValues.extraOptions.find(
                                        (value) => value.name === option?.name
                                    )?.value,
                                    checked: simulatorValues.extraOptions.find(
                                        (dOption) => dOption.name === option?.name
                                    )?.value,
                                    menuItems: option?.field_values?.map((val) => ({
                                        value: val?.value ?? '',
                                        label: val?.value ?? ''
                                    })),
                                    width: '100%'
                                })}
                                {option?.field_type?.dataType?.code !== 'LIST' && (
                                    <MUIComponents.Typography
                                        variant="subtitle1"
                                        style={typoGraphyStyling}
                                    >
                                        {option?.name}
                                    </MUIComponents.Typography>
                                )}
                                {getHelpTextForOption(option?.field_type?.code ?? undefined, t) && (
                                    <MUIComponents.Buttons.IconButton
                                        aria-label="toggle-description"
                                        onClick={(e) => {
                                            return
                                        }}
                                        onMouseDown={(e) => {
                                            e.preventDefault()
                                        }}
                                        edge="end"
                                        style={{ height: '50%', margin: 'auto' }}
                                        tooltip={{
                                            placement: 'right',
                                            content: getHelpTextForOption(option?.field_type?.code ?? undefined, t)
                                        }}
                                    >
                                        <MUIComponents.Icons.HelpIcon />
                                    </MUIComponents.Buttons.IconButton>
                                )}
                            </Row>
                        ))}
                </OptionsColumn> */}
            {/* </MUIComponents.Styling.Box> */}

            <MUIComponents.Styling.Box
                sx={{
                    gridColumn: '1/11',
                    gridRow: '8/11',
                    minWidth: 0,
                    display: 'flex',
                    flexDirection: 'row',
                    backgroundColor: '#FAFAFA',
                    padding: '20px',
                    color: 'rgba(0,0,0,0.6)',
                    width:
                        devices.isMob || devices.isSmallerThanMob
                            ? 'calc(100% - 60px)'
                            : 'calc(100% - 40px)',
                    height: 'min-content'
                }}
            >
                <Warning content={t('simulation.companyDependentWarning')} />
            </MUIComponents.Styling.Box>

            <MUIComponents.Styling.Box
                sx={{
                    gridColumn: '1/11',
                    gridRow: '11/12',
                    minWidth: 0,
                    display: 'flex',
                    flexDirection: devices.isMob || devices.isSmallerThanMob ? 'column' : 'row',
                    width: boxWidth,
                    height: 'min-content'
                }}
            >
                <MUIComponents.Buttons.Button
                    variant="outlined"
                    fullWidth
                    onClick={() => props.setActiveStep(undefined, 0)}
                    sx={{
                        marginTop: '20px',
                        width: devices.isMob || devices.isSmallerThanMob ? '100%' : '200px',
                        display: 'block'
                    }}
                >
                    {t('general.buttons.previousStep')}
                </MUIComponents.Buttons.Button>

                <MUIComponents.Buttons.Button
                    variant="contained"
                    fullWidth
                    onClick={() => {
                        const errorsByOptionCode = simulatorValues.defaultOptions.reduce(
                            (acc, option) => {
                                if (option?.code) {
                                    return {
                                        ...acc,
                                        [option?.code]: option?.value !== undefined ? false : true
                                    }
                                }
                                return {}
                            },
                            {}
                        )

                        setErrors(errorsByOptionCode)

                        const doesOptionsContainErrors = Object.values(errorsByOptionCode)
                        if (doesOptionsContainErrors.some((error) => error)) {
                            setAlertObject({
                                message: t('simulation.step2Container.requiredFieldsCheckErr'),
                                severity: 'error'
                            })
                            setSimulatorValues((old) => ({
                                ...old,
                                errorOptions: errorsByOptionCode
                            }))

                            return
                        }

                        setSimulatorValues((old) => ({ ...old, errorOptions: errorsByOptionCode }))

                        props.setActiveStep(undefined, 2)
                    }}
                    sx={{
                        marginTop: '20px',
                        width: devices.isMob || devices.isSmallerThanMob ? '100%' : '200px',
                        display: 'block',
                        marginLeft: 'auto'
                    }}
                >
                    {t('general.buttons.nextStep')}
                </MUIComponents.Buttons.Button>

                {(devices.isMob || devices.isSmallerThanMob) && (
                    <MUIComponents.Styling.Paper
                        style={{
                            width: '100%',
                            margin: '10px auto auto',
                            boxShadow: 'unset'
                        }}
                    >
                        <Containers.Copyright
                            sx={{
                                color: 'rgb(128,128,128)',
                                margin: 'auto 5px',
                                fontSize: 'small'
                            }}
                            paperStyling={{ margin: '10px 0px', width: 'unset' }}
                            isMob={devices.isMob || devices.isSmallerThanMob}
                            isTablet={devices.isTablet}
                        />
                    </MUIComponents.Styling.Paper>
                )}
            </MUIComponents.Styling.Box>
        </MUIComponents.Styling.Box>
    )
}

const Row = styled.div<DeviceParameters>`
    display: flex;
    flex-direction: row;
    width: calc(100% - 15px);
`

const OptionsColumn = styled.div`
    height: calc(100% - 40px);
    overflow: auto;
`

const typoGraphyStyling: CSSProperties = {
    textAlign: 'left',
    color: 'rgba(0, 0, 0, 0.6)',
    margin: 'auto 10px'
}

const getSheetValues = ({
    oldData,
    option,
    value,
    type
}: {
    oldData: SimulatorValues
    option: Maybe<Field>
    value: boolean | string
    type: 'extra' | 'default'
}) => {
    // let optionsToUpdate = type === 'extra' ? oldData.extraOptions : oldData.defaultOptions
    let optionsToUpdate = oldData.defaultOptions

    const map = Object.fromEntries(optionsToUpdate.map((optionU) => [optionU.name, optionU]))

    map[option?.name as string] = {
        value,
        code: option?.field_type?.code ?? undefined,
        name: option?.name as string,
        index: option?.index
    }

    optionsToUpdate = Object.values(map)

    return {
        ...oldData,
        [type === 'extra' ? 'extraOptions' : 'defaultOptions']: optionsToUpdate
    }
}
