import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useRecoilState } from 'recoil'
import { useTranslation } from 'react-i18next'

import { MUIComponents } from '../../muiComponents'
import { alertObjState, simulatorObjectState, userProfileState } from '../../../store/store'
import theme from '../../../theme/theme'
import Containers from '../../containers'
import SubComponents from './subComponents'
import useDeviceDetection from '../../../hooks/useDeviceDetection'
import StyledComponents from '../../StyledComponents'
import { DeviceParameters } from '../../../types'
import { ConditionalWrapper } from '../../../utils/utils'
import { useLazyQuery } from '@apollo/client'
import { Employee, QueryEmployeeArgs } from '../../../types/GQLTypes'
import API from '../../../API'

export const MyCalculation = () => {
    const { t } = useTranslation()

    //global states
    const [simulatorValues] = useRecoilState(simulatorObjectState)
    const [_, setAlertObject] = useRecoilState(alertObjState)
    const [userProfile] = useRecoilState(userProfileState)

    // states
    const [loadingCreateCalculation, setLoadingCreateCalculation] = useState(false)
    const [activeStep, setActiveStep] = useState<number>(0)
    const [fieldsData, setFieldsData] = useState<Employee>()
    const [errors, setErrors] = useState<Record<string, boolean>>({
        bikeBrand: false,
        bikeType: false,
        options: false
    })

    // hooks
    const [devices] = useDeviceDetection()

    // Queries
    const [getEmployeeCompanyFields] = useLazyQuery<{ employee: Employee }, QueryEmployeeArgs>(
        API.Queries.EMPLOYEE_COMPANY_FIELDS
    )

    useEffect(() => {
        const fetchData = async () => {
            const fieldsData = await getEmployeeCompanyFields({
                variables: { id: userProfile.employee_id as string }
            })
            setFieldsData(fieldsData.data?.employee ?? undefined)
        }
        fetchData()
    }, [])

    return (
        <Main isMob={devices.isMob} isSmallerThanMob={devices.isSmallerThanMob}>
            <Wrapper>
                <Row
                    isMob={devices.isMob}
                    isSmallerThanMob={devices.isSmallerThanMob}
                    style={{
                        height:
                            (devices.isMob || devices.isSmallerThanMob) && activeStep === 2
                                ? 'calc(50% - 15px)'
                                : '100%',
                        width: '100%',
                        marginBottom: '10px'
                    }}
                >
                    {ConditionalWrapper({
                        condition: !(devices.isMob || devices.isSmallerThanMob),
                        wrapper: (wrappedChildren) => (
                            <MUIComponents.Styling.Paper
                                elevation={5}
                                style={{
                                    padding: 25,
                                    height: 'calc(100% - 50px)',
                                    width: '100%',
                                    marginRight:
                                        !(devices.isMob || devices.isSmallerThanMob) &&
                                        activeStep === 2
                                            ? '20px'
                                            : undefined,
                                    overflow:
                                        devices.isSmallerThanMob || devices.isMob
                                            ? 'auto'
                                            : undefined
                                }}
                            >
                                {wrappedChildren}
                            </MUIComponents.Styling.Paper>
                        ),
                        children: (
                            <ChildrenWrapper
                                {...((devices.isWeb || devices.isTablet) && {
                                    style: { height: '100%' }
                                })}
                            >
                                <MUIComponents.Typography
                                    sx={{
                                        textAlign: 'left',
                                        mb: 3,
                                        minHeight: '35px',
                                        color: theme.palette.primary.main,
                                        ...(devices.isSmallerThanMob && {
                                            ...StyledComponents.helper.truncatedText
                                        })
                                    }}
                                    variant="h5"
                                >
                                    {t('simulation.title')}
                                </MUIComponents.Typography>
                                <MUIComponents.Stepper
                                    alternativeLabel
                                    activeStep={activeStep}
                                    steps={[
                                        t('simulation.step1'),
                                        t('simulation.step2'),
                                        t('simulation.step3')
                                    ]}
                                    icons={{
                                        1: <>1</>,
                                        2: <>2</>,
                                        3: <>3</>
                                    }}
                                    stepClick={(icon) => {
                                        if (activeStep === 0 && icon === 2) {
                                            return
                                        }

                                        if (
                                            (!simulatorValues.bikeBrand ||
                                                !simulatorValues.bikeType) &&
                                            activeStep === 0
                                        ) {
                                            setErrors({
                                                bikeBrand: !simulatorValues.bikeBrand?.value,
                                                bikeType: !simulatorValues.bikeType?.value
                                            })

                                            setAlertObject({
                                                message: t(
                                                    'simulation.step1Container.requiredFieldsCheckErr'
                                                ),
                                                severity: 'error'
                                            })

                                            return
                                        }

                                        const optionValues = simulatorValues.defaultOptions.map(
                                            (option) => option.value
                                        )

                                        if (
                                            activeStep === 1 &&
                                            !optionValues.every((value) => value)
                                        ) {
                                            setErrors((old) => ({
                                                ...old,
                                                options: true
                                            }))

                                            setAlertObject({
                                                message: t(
                                                    'simulation.step2Container.requiredFieldsCheckErr'
                                                ),
                                                severity: 'error'
                                            })

                                            return
                                        }

                                        setActiveStep(icon)
                                    }}
                                    loading={loadingCreateCalculation}
                                    orientation={
                                        devices.isSmallerThanMob ? 'vertical' : 'horizontal'
                                    }
                                />

                                {activeStep === 0 && (
                                    <SubComponents.StepOne
                                        setActiveStep={(_, step) => {
                                            if (typeof step === 'number') {
                                                setActiveStep(step)
                                            }
                                        }}
                                        errors={errors}
                                    />
                                )}

                                {activeStep === 1 && (
                                    <SubComponents.StepTwo
                                        fieldsData={fieldsData}
                                        setActiveStep={(_, step) => {
                                            if (typeof step === 'number') {
                                                setActiveStep(step)
                                            }
                                        }}
                                        errors={errors}
                                        setErrors={() => {
                                            setErrors((old) => ({
                                                ...old,
                                                options: false
                                            }))
                                        }}
                                    />
                                )}

                                {activeStep === 2 && (
                                    <SubComponents.StepThree
                                        fieldsData={fieldsData}
                                        setActiveStep={(_, step) => {
                                            if (typeof step === 'number') {
                                                setActiveStep(step)
                                            }
                                        }}
                                    />
                                )}
                            </ChildrenWrapper>
                        )
                    })}

                    {!(devices.isMob || devices.isSmallerThanMob) && activeStep === 2 && (
                        <SubComponents.Summary fieldsData={fieldsData} />
                    )}
                </Row>

                {(devices.isTablet || devices.isWeb) && (
                    <MUIComponents.Styling.Paper
                        style={{
                            margin: 'auto',
                            marginTop: '10px'
                        }}
                    >
                        <Containers.Copyright
                            sx={{
                                color: 'rgb(128,128,128)',
                                margin: devices.isTablet ? 'auto 5px' : 'auto 25px'
                            }}
                            paperStyling={{ margin: '10px 0px', width: 'unset' }}
                            isMob={devices.isMob || devices.isSmallerThanMob}
                            isTablet={devices.isTablet}
                        />
                    </MUIComponents.Styling.Paper>
                )}
            </Wrapper>
        </Main>
    )
}

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    z-index: 1;
`

const Main = styled.div<DeviceParameters>`
    margin: ${(props) => (props.isMob || props.isSmallerThanMob ? '10px' : '20px')};
    margin-top: 60px;
    height: ${(props) =>
        props.isMob || props.isSmallerThanMob ? 'calc(100vh - 200px)' : 'calc(100vh - 150px)'};
`

const Row = styled.div<DeviceParameters>`
    display: flex;
    flex-direction: row;
`

const ChildrenWrapper = styled.div`
    display: flex;
    flex-direction: column;
`
