import { useState } from 'react'

import { SortDirection } from '../types'

export const useSorting = (data: { [key: string]: string }[]) => {
    const [dataToSort, setDataToSort] = useState(data)

    const handleSorting = (sortField: string, sortOrder: SortDirection) => {
        if (sortField) {
            const sorted = [...dataToSort].sort((a, b) => {
                if (a[sortField] === null || a[sortField] === undefined) return 1

                if (b[sortField] === null || b[sortField] === undefined) return -1

                if (
                    (a[sortField] === null && b[sortField] === null) ||
                    (a[sortField] === undefined && b[sortField] === undefined)
                )
                    return 0

                return (
                    a[sortField].toString().localeCompare(b[sortField].toString(), 'en', {
                        numeric: true
                    }) * (sortOrder === 'asc' ? 1 : -1)
                )
            })

            setDataToSort(sorted)
        }
    }

    return [dataToSort, setDataToSort, handleSorting] as const
}
