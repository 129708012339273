import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import nl from './jsonTranslations/nl.json'
import en from './jsonTranslations/en.json'
import fr from './jsonTranslations/fr.json'
import de from './jsonTranslations/de.json'

// ! When testing out translations on places where there is no account information foreseen (like registration page for example),
// ! you need to add the following option at the init options -> lng: 'nl',

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: true,
        fallbackLng: 'nl',
        interpolation: {
            escapeValue: false
        },
        resources: {
            nl,
            en,
            fr,
            de
        }
    })

export default i18n
