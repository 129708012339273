import { useRef } from 'react'
import styled, { CSSProperties } from 'styled-components'
import { useTranslation } from 'react-i18next'

import { useIsOverflow } from '../../../../hooks/useIsOverflow'
import { RefType } from '../../../../types'
import { noop } from '../../../../utils/utils'
import { MUIComponents } from '../../../muiComponents'
import StyledComponents from '../../../StyledComponents'

export const Warning = (props: { content: string }) => {
    const { t } = useTranslation()

    const ref = useRef<RefType>(null)
    const isOverflow = useIsOverflow(ref, noop)

    return (
        <Column>
            <Row style={{ alignItems: 'center' }}>
                <MUIComponents.Icons.WarningIcon />
                <MUIComponents.Typography
                    variant="subtitle1"
                    style={{
                        ...typoGraphyStyling,
                        fontSize: '20px',
                        marginTop: 'unset',
                        marginLeft: '10px',
                        ...StyledComponents.helper.truncatedText
                    }}
                >
                    {t('simulation.warningTitle')}
                </MUIComponents.Typography>
            </Row>

            <MUIComponents.Tooltip
                enterTouchDelay={0}
                placement={'bottom'}
                title={isOverflow ? props.content : ''}
            >
                <Content ref={ref as RefType}>
                    {props.content ?? t('simulation.warningNoContent')}
                </Content>
            </MUIComponents.Tooltip>
        </Column>
    )
}

const Row = styled.div`
    display: flex;
    flex-direction: row;
`

const Column = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

const Content = styled.p`
    margin-bottom: unset;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;

    ${StyledComponents.BreakupText}
`

const typoGraphyStyling: CSSProperties = {
    textAlign: 'left',
    color: 'rgba(0, 0, 0, 0.6)'
}
