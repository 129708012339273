import { useLazyQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import API from '../../../API'
import theme from '../../../theme/theme'
import { MUIComponents } from '../../muiComponents'
import * as GQLTypes from '../../../types/GQLTypes'
import { userProfileState } from '../../../store/store'
import { SubListItemsProps } from '../../muiComponents/types'
import { statusesThatAreNotAContract, stringDefaulter } from '../../../utils/utils'
import useDeviceDetection from '../../../hooks/useDeviceDetection'
import StyledComponents from '../../StyledComponents'

export const Dossiers = () => {
    const { t } = useTranslation()

    // states
    const [allEmployeeQuotations, setAllEmployeeQuotations] = useState<
        GQLTypes.Maybe<GQLTypes.Quotation>[] | undefined
    >([])
    const [files, setFiles] = useState<GQLTypes.Maybe<GQLTypes.Quotation>[] | undefined>([])
    const [calculations, setCalculations] = useState<
        GQLTypes.Maybe<GQLTypes.Quotation>[] | undefined
    >([])
    const [isLoading, setIsLoading] = useState(true)

    // hook
    const navigate = useNavigate()
    const [devices] = useDeviceDetection()

    //global states
    const [userProfile] = useRecoilState(userProfileState)

    // query
    const [collectEmployeeQuotations, { loading }] = useLazyQuery<
        { employee: GQLTypes.Employee },
        GQLTypes.QueryQuotationsArgs & GQLTypes.QueryEmployeeArgs
    >(API.Queries.EMPLOYEE_QUOTATIONS_AND_ORDER_WITHOUT_DEAL)

    useEffect(() => {
        const initFetch = async () => {
            const allEmployeeQuotations = await collectEmployeeQuotations({
                variables: {
                    id: userProfile.employee_id as string
                }
            })

            setAllEmployeeQuotations(
                allEmployeeQuotations?.data?.employee?.employee_quotations ?? []
            )

            if (allEmployeeQuotations?.data?.employee === null) {
                setIsLoading(false)
            }

            if (
                !loading &&
                allEmployeeQuotations?.data?.employee?.employee_quotations?.length === 0
            ) {
                setIsLoading(false)
            }
        }

        if (userProfile.employee_id) initFetch()
    }, [userProfile.employee_id])

    useEffect(() => {
        if (allEmployeeQuotations && allEmployeeQuotations?.length > 0) {
            setFiles(
                allEmployeeQuotations?.filter(
                    (quotation) =>
                        quotation?.order !== null &&
                        quotation?.order?.order_status_fk &&
                        !statusesThatAreNotAContract.includes(quotation?.order?.order_status_fk)
                )
            )
            // ! hidden prop on quotation is to indicate that the quotation (= berekening) is deleted
            setCalculations(
                allEmployeeQuotations?.filter(
                    (quotation) =>
                        (quotation?.order === null || quotation?.order?.order_status_fk === '1') &&
                        quotation?.hidden === false
                )
            )

            setIsLoading(false)
        }
    }, [allEmployeeQuotations])

    const getSubItems = (
        quotations: GQLTypes.Maybe<GQLTypes.Quotation>[] | undefined,
        type: 'Contracten' | 'Berekeningen'
    ): SubListItemsProps[] => {
        const items: SubListItemsProps[] = []

        const checkType = type === 'Contracten'

        quotations?.map((quotation, i) => {
            const sub1 = checkType
                ? quotation?.order?.bike?.model
                : (stringDefaulter(quotation?.quotation_date, 'dateFormat') as string)
            const sub2 = checkType
                ? quotation?.order?.orderStatus?.name
                : (stringDefaulter(quotation?.total_vat_included, 'currencyFormat') as string)

            const obj: SubListItemsProps = {
                id: i,
                main: quotation?.quotation_number ?? '/',
                sub2: sub2 ?? '/'
            }
            if (!checkType) {
                obj.sub1 = sub1 ?? '/'
            }
            items.push(obj)
        })

        return items
    }

    return (
        <MUIComponents.Styling.Paper
            style={{ padding: '20px', height: 'calc(100% - 40px)' }}
            elevation={5}
        >
            <MUIComponents.Typography
                variant="h5"
                style={{
                    textAlign: 'left',
                    color: theme.palette.primary.main,
                    ...((devices.isMob || devices.isSmallerThanMob) && {
                        ...StyledComponents.helper.truncatedText
                    })
                }}
            >
                {t('home.files.title')}
            </MUIComponents.Typography>

            {!loading && !isLoading ? (
                <MUIComponents.NestedList
                    listItems={[
                        {
                            id: 1,
                            name: t('home.files.contracts'),
                            defaultOpen: true,
                            subItems: getSubItems(files, 'Contracten')
                        },
                        {
                            id: 2,
                            name: t('home.files.calculations'),
                            defaultOpen: true,
                            subItems: getSubItems(calculations, 'Berekeningen')
                        }
                    ]}
                    goToItem={(item) => {
                        const mainRoute =
                            item.mainItem === 'Contracten' ? 'dossiers' : 'calculations'
                        navigate(`/${mainRoute}?id=${item.subItem.main}`)
                    }}
                    sx={{ height: 'calc(100% - 50px)' }}
                    collapseStyling={{
                        height: 'calc(100% - 48px) !important',
                        overflowX: 'hidden'
                    }}
                />
            ) : (
                <MUIComponents.Styling.Container>
                    {[...Array(2)].map((_, i) => (
                        <MUIComponents.Styling.Container key={i}>
                            <MUIComponents.Skeleton
                                sx={{ margin: 'auto', marginTop: 2 }}
                                width={'100%'}
                                height={30}
                                variant="rectangular"
                            />
                            {i === 0 &&
                                [...Array(3)].map((_, l) => (
                                    <MUIComponents.Skeleton
                                        sx={{ margin: 'auto', marginTop: 1 }}
                                        width={'100%'}
                                        height={15}
                                        variant="rectangular"
                                        key={l}
                                    />
                                ))}
                        </MUIComponents.Styling.Container>
                    ))}
                </MUIComponents.Styling.Container>
            )}
        </MUIComponents.Styling.Paper>
    )
}
