import styled from 'styled-components'
import Stack from '@mui/material/Stack'
import MuiStepper, { StepperProps as MuiStepperProps } from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector'

import { Theme } from '../../types'
import { noop } from '../../utils/utils'
import { MUIComponents } from '.'

// TODO: added this StyledMuiStepper
const StyledMuiStepper = styled(MuiStepper)`
    & .MuiStep-root,
    .MuiStep-vertical,
    .MuiStep-horizontal,
    .MuiStep-alternativeLabel {
        align-items: center !important;
    }
`

const verticalLayout = 'vertical'

// TODO: styling for mobile
// TODO: updated styling
// TODO: added loading prop for extra styling
const ColorlibConnector = styled(StepConnector)<{
    loading: StepperProps['loading']
    orientation: MuiStepperProps['orientation']
}>(({ theme, orientation, loading }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 18,
        marginTop: '4px'
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: theme.palette.primary.main
        }
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: theme.palette.primary.main
        }
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: orientation === verticalLayout ? 1 : 3,
        border: 0,
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#BDBDBD',
        borderRadius: 1,
        margin: !loading && orientation === verticalLayout ? '0 0 0 15px' : 'auto',
        width: orientation === verticalLayout ? '2px' : undefined,
        minHeight: orientation === verticalLayout ? 15 : undefined
    },
    [`&.${stepConnectorClasses.root}`]: {
        position: orientation === verticalLayout ? 'unset' : undefined,
        marginLeft: orientation === verticalLayout ? 'unset' : undefined
    }
}))

const ColorlibStepIconRoot = styled('div')(
    ({
        theme,
        ownerState,
        orientation
    }: {
        theme: Theme
        ownerState: {
            active: Props['active']
            completed: Props['completed']
            stepClickIsEnabled?: boolean
            disableAllStepClick?: boolean
        }
        orientation: MuiStepperProps['orientation']
    }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
        zIndex: 1,
        color: '#fff',
        width: orientation === verticalLayout ? 25 : 40,
        height: orientation === verticalLayout ? 25 : 40,
        fontSize: 'small',
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        ...(ownerState.stepClickIsEnabled &&
            !ownerState.disableAllStepClick && { cursor: 'pointer' }),
        ...(ownerState.active && {
            boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
            backgroundColor: theme.palette.primary.main
        }),
        ...(ownerState.completed && {
            backgroundColor: theme.palette.primary.main
        })
    })
)

interface Props {
    stepClick?: (icon: number) => void
    active?: boolean
    className?: string
    completed?: boolean
    icon: number
    icons: { [key: number]: JSX.Element }
    disableStepClick?: number
    disableAllStepClick?: boolean
    orientation: MuiStepperProps['orientation']
}

const ColorlibStepIcon = ({
    active,
    completed,
    className,
    icon,
    icons,
    disableStepClick,
    disableAllStepClick,
    ...props
}: Props) => (
    <ColorlibStepIconRoot
        onClick={() => {
            if (icon === disableStepClick || disableAllStepClick) {
                return
            }
            props.stepClick?.(icon - 1)
        }}
        ownerState={{
            completed,
            active,
            stepClickIsEnabled: icon !== disableStepClick,
            disableAllStepClick
        }}
        className={className}
        orientation={props.orientation}
    >
        {icons[icon]}
    </ColorlibStepIconRoot>
)

interface StepperProps extends MuiStepperProps {
    // you can declare custom props in here
    steps: string[]
    icons: { [key: number]: JSX.Element }
    stepClick?: (icon: number) => void
    loading?: boolean
    disableStepClick?: number
    disableAllStepClick?: boolean
}

// icons prop in this format: {
//     1: <MUIComponents.Icons.AccountCircleIcon />,
//     2: <MUIComponents.Icons.ContactMailIcon />,
//     3: <MUIComponents.Icons.CheckCircleIcon />
// }

// TODO: mobile -> styling steps with accordion dan heb je ook niet zoveel white space
// TODO: updated the styling for orientation -> vertical (StyledMuiStepper, ColorlibConnector, ColorlibStepIconRoot & sx of Stack (below))
// TODO: updated styling for the label and on root niveau
// TODO: updated alternativeLabel prop
// TODO: updated loading styling prop of the skeletons
export const Stepper = ({
    alternativeLabel,
    orientation,
    activeStep,
    steps,
    icons,
    stepClick = noop,
    loading = false,
    disableStepClick,
    disableAllStepClick = false
}: StepperProps) => (
    <Stack
        sx={{
            width: '100%',
            '& .MuiStepper-root': {
                padding: orientation === verticalLayout ? '10px' : undefined,
                alignItems: loading && orientation === verticalLayout ? 'center' : undefined
            },
            '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
                marginTop: '8px'
            },
            '& .MuiStepLabel-root': {
                padding: '4px'
            }
        }}
        spacing={4}
    >
        <StyledMuiStepper
            alternativeLabel={orientation === verticalLayout ? undefined : alternativeLabel}
            activeStep={activeStep}
            orientation={orientation}
            connector={<ColorlibConnector loading={loading} orientation={orientation} />}
        >
            {steps.map((step, i) => (
                <Step key={i}>
                    {loading ? (
                        <MUIComponents.Skeleton
                            sx={{ margin: 'auto' }}
                            width={35}
                            height={35}
                            variant="circular"
                        />
                    ) : (
                        <StepLabel
                            StepIconComponent={(props) =>
                                ColorlibStepIcon({
                                    ...props,
                                    icons,
                                    stepClick,
                                    disableStepClick,
                                    disableAllStepClick,
                                    orientation
                                })
                            }
                        >
                            {step}
                        </StepLabel>
                    )}
                </Step>
            ))}
        </StyledMuiStepper>
    </Stack>
)

// Example of this component with props:
{
    /* <MUIComponents.Stepper
                    alternativeLabel
                    activeStep={activeStep}
                    steps={['Fietsgegevens', 'Accessoires', 'Kledij', 'Bevestiging berekening']}
                    icons={{
                        1: <MUIComponents.Icons.DirectionsBikeIcon />,
                        2: <MUIComponents.Icons.SportsMotorsportsIcon />,
                        3: <MUIComponents.Icons.CheckroomIcon />,
                        4: <MUIComponents.Icons.CheckCircleIcon />
                    }}
                /> */
}
