import capitalize from 'lodash.capitalize'
import isEqual from 'lodash.isequal'
import axios from 'axios'
import { TFunction } from 'i18next'

import API from '../API'
import { MUIComponents } from '../components/muiComponents'
import {
    Device,
    Documents,
    FieldTypes,
    PossibleLanguages,
    SortDirection,
    TranslatorFunction
} from '../types'
import { DataType, Field, Maybe } from '../types/GQLTypes'

export const noop = () => undefined

export const validateEmail = (email: string) => {
    const pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

    return pattern.test(email)
}

export const genericFontSize = '16px'
export const genericMaxInputWidth = '1000px'

export const convertCodeBasedMenuItems = (
    dataArr: {
        id?: Maybe<string>
        Id?: Maybe<string> // ! komt door die language met Id in de graphql ...
        code?: Maybe<string>
        name?: Maybe<string>
    }[]
) => dataArr.map((item) => ({ label: item.name ?? '...', value: item.id ?? item.Id ?? '...' }))

export const dataIsEquals = (
    obj1: { [key: string]: unknown },
    obj2: { [key: string]: unknown }
) => {
    return isEqual(obj1, obj2)
}

type ErrorWithMessage = {
    message: string
}

const isErrorWithMessage = (error: unknown): error is ErrorWithMessage =>
    typeof error === 'object' &&
    error !== null &&
    'message' in error &&
    typeof (error as Record<string, unknown>).message === 'string'

const toErrorWithMessage = (maybeError: unknown): ErrorWithMessage => {
    if (isErrorWithMessage(maybeError)) return maybeError

    try {
        return new Error(JSON.stringify(maybeError))
    } catch {
        // fallback in case there's an error stringifying the maybeError
        // like with circular references for example.
        return new Error(String(maybeError))
    }
}

export const getErrorMessage = (error: unknown) => toErrorWithMessage(error).message

export const generateBarcode = () => {
    // This is going to generate a random barcode that exists out of 19 characters and starts with 3120017 at the beginning
    const val1 = Math.floor(100000 + Math.random() * 900000)
    const val2 = Math.floor(100000 + Math.random() * 900000)

    return '3120017' + val1 + val2
}

export const removeEmptyValuesFromArray = (values: unknown[]) => values.filter((x) => x)

export const getValuesFromObjectByKey = (values: Record<string, unknown>, keyToFind: string) =>
    Object.entries(values).map(([key, value]) => (key.startsWith(keyToFind) ? value : undefined))

export const headers = {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
    userType: API.utils.userType
}

export const getComponent = (componentType: DataType['code'], props: Record<string, unknown>) => {
    switch (componentType) {
        case 'TEXT':
            return MUIComponents.TextFields.TextField({
                ...props,
                required: true,
                autoFocus: false
            })
        case 'NUMBER':
            return MUIComponents.TextFields.TextField({
                ...props,
                required: true,
                autoFocus: false
            })
        case 'LIST':
            return MUIComponents.Select.SingleSelect(props)
        default:
            return MUIComponents.TextFields.TextField({
                ...props,
                required: true,
                autoFocus: false
            })
    }
}

export const getInitials = (fullName: string) => {
    const allNames = fullName.trim().split(' ')

    const initials = allNames.reduce((acc, curr, index) => {
        if (index === 0 || index === allNames.length - 1) {
            acc = `${acc}${curr.charAt(0).toUpperCase()}`
        }
        return acc
    }, '')

    return initials
}

export const hardCodedFields = {
    bike: ['BIKE_DESCRIPTION', 'BIKE_VALUE', 'BIKETYPE', 'BIKEBRAND'],
    clothes: ['CLOTHING_DESCRIPTION', 'CLOTHING'],
    accessories: ['PARTS_DESCRIPTION', 'LOOSE_PARTS_VALUE']
}

export const getConditionBasedOnType = (type: FieldTypes, field?: Field | null) => {
    switch (type) {
        case 'bike': {
            return hardCodedFields.bike.includes(field?.field_type?.code ?? '')
        }
        case 'clothes': {
            return hardCodedFields.clothes.includes(field?.field_type?.code ?? '')
        }
        case 'accessories': {
            return hardCodedFields.accessories.includes(field?.field_type?.code ?? '')
        }
        case 'extra': {
            return !(
                hardCodedFields.accessories.includes(field?.field_type?.code ?? '') ||
                hardCodedFields.clothes.includes(field?.field_type?.code ?? '') ||
                hardCodedFields.bike.includes(field?.field_type?.code ?? '')
            )
        }
        default: {
            return false
        }
    }
}

export const ConditionalRender = ({
    condition,
    children
}: {
    condition: boolean
    children: JSX.Element
}) => (condition ? children : null)

export const calculateDateByDuration = (props: {
    startDate?: Maybe<string> | undefined
    duration?: Maybe<string> | undefined
}) => {
    if (!props?.startDate) {
        return
    }

    const date = new Date(props?.startDate)

    date.setMonth(date.getMonth() + Number(props?.duration?.split(' ')[0] ?? 0))
    date.setDate(date.getDate() - 1)

    return date
}

export const turnDateInReadableFormat = (date: Date) =>
    `${date.getDate()} ${capitalize(
        date.toLocaleString('nl-BE', {
            month: 'long'
        })
    )} ${date.getFullYear()}`

export const isInThePast = (date: Date) => {
    const today = new Date()
    today.setHours(0, 0, 0, 0)
    return date < today
}

export const checkIfValueIncludesSearchValue = (value: string | undefined, searchValue: string) =>
    value?.toString().toLowerCase().includes(searchValue)

export const getAmountByTwoDecimals = (
    value: Maybe<string> | Maybe<number> | undefined
): number | string => {
    if (!value || value === 'NaN') return '/'

    return Number(value)?.toFixed(2)
}

export const iconStyling = (color = 'white') => ({
    width: 30,
    height: 30,
    backgroundColor: color,
    color: 'white',
    borderRadius: 50,
    padding: '7px'
})

export const formatter = (value: number) =>
    new Intl.NumberFormat(undefined, {
        style: 'currency',
        currency: 'EUR'
    }).format(value)

export const sortArrayByName = ({
    arrayToSort,
    direction,
    key = 'name'
}: {
    arrayToSort: { [key: string]: unknown; name?: Maybe<string> | undefined }[]
    direction: SortDirection
    key?: string
}) => {
    const directionIsAscending = direction === 'asc'

    return arrayToSort.sort((a, b) =>
        ((a?.[key] ?? '') as string)?.toLowerCase() > ((b?.[key] ?? '') as string)?.toLowerCase()
            ? directionIsAscending
                ? 1
                : -1
            : directionIsAscending
            ? -1
            : 1
    )
}

export const mappingCalculationFields: { [key: string]: string } = {
    assistance_amount: 'Pechhulp full period',
    insurance_amount: 'Verzekering full period',
    lease_amount: 'Leasebedrag excl. BTW',
    lease_amount_vat: 'Leasebedrag incl. BTW',
    maintenance_amount: 'Onderhoud full period',
    residual_value: 'Restwaarde incl BTW',
    salary_swap: 'Bruto loonruil',
    total_vat_excluded: 'Totaal fiets & toebehoren excl, BTW',
    total_vat_included: 'Totaal fiets & toebehoren incl, BTW',
    warning_message: 'Warning',
    warranty_grant: 'Garantieverlenging'
}

export const stringDefaulter = (
    input: Maybe<string> | Maybe<number> | undefined,
    type?: 'dateFormat' | 'currencyFormat' | undefined
) => {
    if (!input) return '/'

    if (typeof input === 'string' && type === undefined) return input

    if (typeof input === 'string' && type !== 'dateFormat' && input.length > 0) return input

    switch (type) {
        case 'dateFormat':
            return turnDateInReadableFormat(new Date(Number(input)))
        case 'currencyFormat':
            return formatter(Number(input))

        default:
            return input
    }
}

export const collectSkeletonTabs = (label: JSX.Element, chrildren: JSX.Element) => {
    const tabsArr = []

    for (let i = 0; i < 3; i++) {
        tabsArr.push({
            label: label,
            children: chrildren,
            id: `${i}`,
            ariaControls: `Panel-${i}`,
            wrapped: false,
            disabled: false
        })
    }

    return tabsArr
}

export const ConditionalWrapper = ({
    condition,
    wrapper,
    children
}: {
    condition: Device | boolean
    wrapper: (children: JSX.Element) => JSX.Element
    children: JSX.Element
}) => (condition ? wrapper(children) : children)

export const resolveLanguage = (browserLang: string | null): string => {
    if (browserLang?.toLowerCase().includes('nl')) return 'NL'
    if (browserLang?.toLowerCase().includes('en')) return 'EN'
    if (browserLang?.toLowerCase().includes('fr')) return 'NL'

    return 'NL'
}

export const downloadFileAsPdf = async ({
    item,
    token
}: {
    item:
        | {
              [key: string]: unknown
          }
        | undefined
    token: string
}) => {
    const fileToDownload = await axios({
        method: 'post',
        url: API.utils.apiUrlsEnv.fetchFromDrive,
        responseType: 'blob',
        data: {
            fileId: item?.fileId,
            orderLogId: item?.id,
            userType: API.utils.userType
        },
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            ['x-forwarded-authorization']: `Bearer ${localStorage.getItem('token')}`,
            ...headers
        }
    })
    return fileToDownload
}

export const getRandomColor = () => {
    const letters = '0123456789ABCDEF'.split('')
    let color = '#'
    for (let i = 0; i < 6; i++) {
        color += letters[Math.round(Math.random() * 15)]
    }
    return color
}

export const getHelpTextForOption = (
    optionCode: string | undefined,
    translateFunction: TFunction
) => {
    if (!optionCode) return null
    const translationKey = `simulation.step2Container.optionDescriptions.${optionCode}`
    const helpText = translateFunction(translationKey)
    if (helpText === translationKey) return null
    return helpText
}

export const defaultVisibleFileTypes = [
    'Je groene kaart',
    'E-bike certificaat',
    'Je Servicepas',
    'Aansluiting pechhulp',
    'Ontvangstbewijs',
    'Getekend ontvangstbewijs',
    'Offerte van dealer',
    'Factuur van dealer',
    'Overige',
    'COC document',
    'Document 705',
    'Innamedocument',
    'Einde lease factuur'
]

// 1 -> Nieuw
// 9 -> Testorder
export const statusesThatAreNotAContract = ['1', '9']

export const getBooleanValue = (value: string | null) => {
    switch (value) {
        case 'false':
            return false
        case 'true':
            return true
        default:
            return value
    }
}

// ! This function is specific for getting documents out of the cloud storage (these are set to public access)
// ! You can upload the documents in here for the specific languages and then you can use the public url in here for showing it to the users
// TODO: TODO comments mogen nog weggehaald worden na de updates van de documenten
export const getDocuments = (t: TranslatorFunction, key: string, language: PossibleLanguages) => {
    const documents: Documents = {
        // TODO: Duitstalige versie nog voorzien
        [t('copyRight.privacy')]: {
            en: 'https://storage.googleapis.com/b2bike-documents/Privacy%20Statement%20B2Bike%20EN.pdf',
            nl: 'https://storage.googleapis.com/b2bike-documents/Privacy%20statement%20B2bike%20NL.pdf',
            fr: 'https://storage.googleapis.com/b2bike-documents/Privacy%20Statement%20B2bike%20FR.pdf',
            de: 'https://storage.googleapis.com/b2bike-documents/Privacy%20Statement%20B2Bike%20EN.pdf'
        },
        // TODO: de terms of use files nog toevoegen aan de cloud storage en ook publiekelijk toegang geven zoals bij de Privacy statement!
        // TODO: deze links nog updaten met de PDF's of Word documents van de Terms of use (moeten nog bezorgd worden!)
        [t('copyRight.terms')]: {
            en: 'https://storage.googleapis.com/b2bike-documents/Privacy%20Statement%20B2Bike%20EN.pdf',
            nl: 'https://storage.googleapis.com/b2bike-documents/Privacy%20statement%20B2bike%20NL.pdf',
            fr: 'https://storage.googleapis.com/b2bike-documents/Privacy%20Statement%20B2bike%20FR.pdf',
            de: 'https://storage.googleapis.com/b2bike-documents/Privacy%20Statement%20B2Bike%20EN.pdf'
        }
    }

    return documents[key][language]
}
