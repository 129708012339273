import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import axios from 'axios'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useLazyQuery } from '@apollo/client'

import { MUIComponents } from '../../muiComponents'
import Containers from '../../containers'
import { Company, DeviceParameters, FormValue, Value } from '../../../types'
import { alertObjState, languagesState } from '../../../store/store'
import theme from '../../../theme/theme'
import StyledComponents from '../../StyledComponents'
import API from '../../../API'
import { headers, validateEmail } from '../../../utils/utils'
import useDeviceDetection from '../../../hooks/useDeviceDetection'
import { Language, QueryLanguagesArgs } from '../../../types/GQLTypes'

// ! This helper function is for setting the language to the appropriate value
const checkLanguage = (props: { switch: boolean; value?: Value }) => {
    const valueNL = { code: 'NL', id: '1' }
    const valueFR = { code: 'FR', id: '2' }
    const valueEN = { code: 'EN', id: '3' }
    const valueDE = { code: 'DE', id: '4' }

    if (props.switch && props.value) {
        switch (props.value) {
            case valueNL.id:
                return valueNL.code
            case valueFR.id:
                return valueFR.code
            case valueEN.id:
                return valueEN.code
            case valueDE.id:
                return valueDE.code
            default:
                return valueNL.code
        }
    } else {
        switch (navigator?.languages?.[1]?.toUpperCase() ?? 'NL') {
            case valueNL.code:
                return valueNL.id
            case valueFR.code:
                return valueFR.id
            case valueEN.code:
                return valueEN.id
            case valueDE.code:
                return valueDE.id
            default:
                return valueNL.id
        }
    }
}

export const Register = () => {
    const { t } = useTranslation()

    const [_, setAlertObject] = useRecoilState(alertObjState)
    const [languages, setLanguages] = useRecoilState(languagesState)

    const [firstName, setFirstName] = useState<FormValue>({ error: false, value: '' })
    const [lastName, setLastName] = useState<FormValue>({ error: false, value: '' })
    const [companyCode, setCompanyCode] = useState<FormValue>({ error: false, value: '' })
    const [company, setCompany] = useState<Company>({})
    const [emailError, setEmailError] = useState<{ valid: boolean; error: boolean }>({
        error: false,
        valid: false
    })
    const [email, setEmail] = useState<string | null>(null)
    const [language, setLanguage] = useState<FormValue>({
        error: false,
        value: checkLanguage({ switch: false })
    })
    const [statute, setStatute] = useState<FormValue>({
        error: false,
        value: ''
    })
    const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [openTooltipInfo, setOpenTooltipInfo] = useState(false)
    const [openTooltipPasswordReset, setOpenTooltipPasswordReset] = useState(false)
    const [readPrivacyStatementAndTermsOfUse, setReadPrivacyStatementAndTermsOfUse] =
        useState(false)

    // hooks
    const navigate = useNavigate()
    const [devices] = useDeviceDetection()

    const [getLanguages, { error }] = useLazyQuery<{ languages: Language[] }, QueryLanguagesArgs>(
        API.Queries.LANGUAGES
    )

    const getCompanyName = async () => {
        try {
            const result = await axios({
                method: 'post',
                url: API.utils.apiUrlsEnv.registerCompanyCode,
                data: {
                    companyByEmployeeAccessCodeParameters: {
                        employee_access_code: companyCode?.value
                    },
                    email: process.env.REACT_APP_REGISTER_SERVICE_ACCOUNT_MAIL ?? '/'
                },
                headers: {
                    ...headers,
                    workflow: API.utils.workflowEnv('registerCompanyCode')
                }
            })

            setCompany(result?.data?.data?.companyByEmployeeAccessCode ?? { employeeStatutes: [] })
            setLoading(false)
        } catch (err) {
            setCompany({})
            setAlertObject({
                message: t('register.companyNameCodeAlert'),
                severity: 'error'
            })
            setLoading(false)
        }
    }

    /*useEffect(() => {
        const fetchLanguages = async () => {
            const result = await getLanguages()

            if (error) {
                setLanguages([])
                setAlertObject({
                    message: t('register.getLanguagesError'),
                    severity: 'error'
                })
            }

            if (result?.data?.languages) {
                setLanguages(
                    (result?.data?.languages ?? []).map((item) => ({ ...item, id: item.Id }))
                )
            } else {
                setLanguages([])
                setAlertObject({
                    message: t('register.getLanguagesError'),
                    severity: 'error'
                })
            }
        }

        fetchLanguages()
    }, [])*/

    useEffect(() => {
        if (companyCode.value !== '') {
            setLoading(true)
            getCompanyName()
        }
    }, [companyCode.value])

    const handleSubmit = async (event: React.FormEvent<HTMLButtonElement>, error?: boolean) => {
        event.preventDefault()

        if (!firstName.value) {
            setFirstName({ ...firstName, error: true })
        }

        if (!lastName.value) {
            setLastName({ ...lastName, error: true })
        }

        if (!companyCode.value) {
            setCompanyCode({ ...companyCode, error: true })
        }

        if (!language.value) {
            setLanguage({ ...language, error: true })
        }

        if (!statute.value) {
            setStatute({ ...statute, error: true })
        }

        if (!email) {
            setEmailError({ ...emailError, error: true })
        }

        if (email && error === false) {
            const trimmedEmail = email?.trim()

            try {
                setLoading(true)
                const result = await axios({
                    method: 'post',
                    url: API.utils.apiUrlsEnv.register,
                    data: {
                        createEmployeesParameters: {
                            employeeToCreate: {
                                company_fk: (company as Company)?.id ?? '8',
                                email: trimmedEmail,
                                employee_statute_fk:
                                    (statute?.value as string)?.length > 0 ? statute?.value : '2', // 2 -> bediende as default
                                first_name: firstName?.value,
                                last_name: lastName?.value,
                                name: `${firstName?.value} ${lastName?.value}`,
                                employee_access_code: companyCode?.value,
                                language_fk: language?.value
                            }
                        },
                        email: trimmedEmail,
                        employeesParameters: {
                            filter: {
                                email: trimmedEmail
                            }
                        },
                        userInfo: {
                            email: trimmedEmail,
                            first_name: firstName?.value,
                            last_name: lastName?.value,
                            language: checkLanguage({ switch: true, value: language?.value }),
                            employee_id: null
                        }
                    },
                    headers: {
                        ...headers,
                        workflow: API.utils.workflowEnv('register')
                    }
                })

                if (result.data?.email) {
                    setAlertObject({
                        message: t('register.registerSuccessAlert'),
                        severity: 'success'
                    })

                    const mailResult = await axios({
                        method: 'post',
                        url: API.utils.apiUrlsEnv.handlePasswordReset,
                        data: {
                            email: trimmedEmail,
                            type: 'register',
                            originUrl: window.location.origin
                        },
                        headers
                    })

                    setLoading(false)

                    if (mailResult.data === 'Mail has been sent successfully') {
                        setAlertObject({
                            message: t('register.registerMailSucces'),
                            severity: 'success'
                        })

                        setLoading(false)
                        navigate('/confirmationRegister')
                    } else {
                        setAlertObject({
                            message: t('register.registerMailError'),
                            severity: 'warning'
                        })

                        setLoading(false)
                    }
                } else {
                    setLoading(false)
                }

                return
            } catch (err) {
                console.error(err)
                setAlertObject({
                    message: t('register.registerErrorAlert'),
                    severity: 'error'
                })
                setLoading(false)
                return
            }
        } else {
            setLoading(false)
            return
        }
    }

    const statutes = ((company as Company)?.employeeStatutes ?? []).map((statute) => ({
        value: statute.id,
        label: statute.name
    }))

    return (
        <StyledComponents.Wrapper>
            <MUIComponents.Styling.Grid
                container
                sx={{
                    height: devices.isWeb ? 'calc(100vh - 20vh)' : 'calc(100vh - 10vh)',
                    marginTop: devices.isWeb ? '19vh' : '9vh'
                }}
                columns={15}
                spacing={1}
            >
                <MUIComponents.Styling.Grid item xs={devices.isWeb ? 4.5 : 1.5} />

                {devices.isWeb && <Containers.BackgroundImage />}

                <MUIComponents.Styling.Grid
                    item
                    xs={devices.isWeb ? 4 : 12}
                    component={MUIComponents.Styling.Paper}
                    elevation={6}
                    sx={{
                        maxHeight: '75%',
                        position: 'relative',
                        height: '100%',
                        minHeight: '550px',
                        boxShadow: devices.isMob || devices.isSmallerThanMob ? 'unset' : undefined
                    }}
                >
                    <MUIComponents.Styling.Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            height:
                                devices.isMob || devices.isSmallerThanMob
                                    ? '100%'
                                    : 'calc(100% - 100px)',
                            ...((devices.isTablet || devices.isWeb) && {
                                my: 3,
                                mx: 3
                            })
                        }}
                        component="form"
                        noValidate
                        onSubmit={handleSubmit}
                    >
                        <MUIComponents.Tooltip
                            open={openTooltipInfo}
                            onClose={() => {
                                setOpenTooltipInfo(false)
                            }}
                            onOpen={() => {
                                setOpenTooltipInfo(true)
                            }}
                            enterTouchDelay={500}
                            title={t('register.titleHelperMessage')}
                        >
                            <MUIComponents.Typography
                                sx={{ color: theme.palette.primary.main, cursor: 'pointer' }}
                                variant="h5"
                            >
                                {t('register.title')}
                            </MUIComponents.Typography>
                        </MUIComponents.Tooltip>
                        <MUIComponents.Typography variant="body1">
                            {t('register.description')}
                        </MUIComponents.Typography>
                        <MUIComponents.Styling.Box
                            component="div"
                            sx={{
                                mt: 1,
                                width: '100%',
                                ...((devices.isTablet || devices.isWeb) && {
                                    overflowX: 'unset',
                                    overflowY: 'auto'
                                })
                            }}
                        >
                            <Row isMob={devices.isMob} isSmallerThanMob={devices.isSmallerThanMob}>
                                <MUIComponents.TextFields.TextField
                                    id="firstName"
                                    label={t('register.firstNameInput')}
                                    name="firstName"
                                    type="text"
                                    size="small"
                                    autoFocus={false}
                                    error={firstName.error}
                                    onBlur={(e) => {
                                        setFirstName({ value: e?.target.value, error: false })

                                        return
                                    }}
                                    {...(firstName.error && {
                                        helperText: 'Het invullen van uw voornaam is verplicht.'
                                    })}
                                />
                                <MUIComponents.TextFields.TextField
                                    id="lastName"
                                    label={t('register.lastNameInput')}
                                    name="lastName"
                                    type="text"
                                    size="small"
                                    autoFocus={false}
                                    error={lastName.error}
                                    onBlur={(e) => {
                                        setLastName({ value: e?.target.value, error: false })

                                        return
                                    }}
                                    {...(lastName.error && {
                                        helperText: t('register.firstNameInputHelper')
                                    })}
                                    {...(!(devices.isMob || devices.isSmallerThanMob) && {
                                        sx: {
                                            marginLeft: '10px'
                                        }
                                    })}
                                    autoComplete="off"
                                    inputProps={{
                                        autocomplete: 'off',
                                        form: {
                                            autocomplete: 'off'
                                        }
                                    }}
                                />
                            </Row>
                            <Row isMob={devices.isMob} isSmallerThanMob={devices.isSmallerThanMob}>
                                <MUIComponents.TextFields.TextField
                                    id="companyCode"
                                    label={t('register.companyCodeInput')}
                                    name="companyCode"
                                    defaultValue={companyCode.value}
                                    type="text"
                                    size="small"
                                    required
                                    autoFocus={false}
                                    error={companyCode.error}
                                    onBlur={async (e) => {
                                        if (e.target.value) {
                                            setCompanyCode({ error: false, value: e.target.value })
                                        }

                                        return
                                    }}
                                    {...(companyCode.error && {
                                        helperText: t('register.companyCodeInputHelper')
                                    })}
                                />
                                <MUIComponents.TextFields.TextField
                                    id="email"
                                    label={t('register.mailInput')}
                                    name="email"
                                    type="email"
                                    size="small"
                                    autoFocus={false}
                                    error={emailError.error}
                                    onBlur={(e) => {
                                        const isEmailValid = validateEmail(e.target.value)

                                        if (!isEmailValid) {
                                            setEmailError({ error: true, valid: isEmailValid })
                                        } else {
                                            setEmailError({
                                                error: false,
                                                valid: isEmailValid
                                            })
                                            setEmail(e.target.value?.trim())
                                        }
                                    }}
                                    {...(emailError.error && {
                                        helperText: !emailError.valid
                                            ? t('register.mailCheckError')
                                            : t('register.mailRequiredError')
                                    })}
                                    {...(!(devices.isMob || devices.isSmallerThanMob) && {
                                        sx: {
                                            marginLeft: '10px'
                                        }
                                    })}
                                />
                            </Row>
                            <Row isMob={devices.isMob} isSmallerThanMob={devices.isSmallerThanMob}>
                                <MUIComponents.Select.SingleSelect
                                    labelId="language"
                                    id="select-language"
                                    name="language"
                                    required
                                    labelContent={t('register.languageInput')}
                                    size="small"
                                    error={language.error}
                                    value={language.value}
                                    menuItems={languages.map((language) => ({
                                        // TODO: die id met hoofdletter Id weghalen in de DB en dit terug aanpassen
                                        // ! is wel niet enkel voor de V3, maar ook voor de V2 dat die updates dan moet gebeuren
                                        value: language.Id as string,
                                        label: language.name as string
                                    }))}
                                    onChange={(e) => {
                                        if (e.target.value) {
                                            setLanguage({
                                                error: false,
                                                value: e.target.value as FormDataEntryValue
                                            })
                                        }

                                        return
                                    }}
                                    {...(language.error && {
                                        errorText: t('register.languageInputHelper')
                                    })}
                                    {...(!(devices.isMob || devices.isSmallerThanMob) && {
                                        formStyling: {
                                            paddingRight: '5px'
                                        }
                                    })}
                                />
                                <MUIComponents.Select.SingleSelect
                                    labelId="statute"
                                    id="select-statute"
                                    name="statute"
                                    required
                                    size="small"
                                    labelContent={t('register.statuteInput')}
                                    error={statute.error}
                                    value={statute.value}
                                    menuItems={statutes}
                                    onChange={(e) => {
                                        if (e.target.value) {
                                            setStatute({
                                                error: false,
                                                value: e.target.value as FormDataEntryValue
                                            })
                                        }

                                        return
                                    }}
                                    {...(statute.error && {
                                        errorText: t('register.statuteInputHelper')
                                    })}
                                    {...(!(devices.isMob || devices.isSmallerThanMob) && {
                                        formStyling: {
                                            paddingLeft: '5px'
                                        }
                                    })}
                                    disabled={!statutes || statutes?.length <= 0}
                                />
                            </Row>
                        </MUIComponents.Styling.Box>
                        <MUIComponents.Styling.Box
                            component="div"
                            sx={{
                                mt: 1
                            }}
                        >
                            <MUIComponents.Tooltip
                                open={openTooltipPasswordReset}
                                onClose={() => {
                                    setOpenTooltipPasswordReset(false)
                                }}
                                onOpen={() => {
                                    setOpenTooltipPasswordReset(true)
                                }}
                                enterTouchDelay={500}
                                title={t('register.passwordResetMessage')}
                            >
                                <Link
                                    style={{
                                        display: 'flex',
                                        margin: 'auto',
                                        maxWidth: 'fit-content'
                                    }}
                                    to={'/passwordReset'}
                                >
                                    {t('register.linkPasswordReset')}
                                </Link>
                            </MUIComponents.Tooltip>

                            <MUIComponents.CheckboxWithLabel
                                sx={{
                                    marginTop: '5px',
                                    marginBottom: '5px',
                                    marginLeft: '10px',
                                    paddingLeft: 'unset'
                                }}
                                colorToOverwrite="rgba(0, 0, 0, 0.6)"
                                checked={readPrivacyStatementAndTermsOfUse}
                                onChange={(e) => {
                                    setReadPrivacyStatementAndTermsOfUse(e.target.checked)
                                }}
                                label={t('register.checkboxUseOfTermsAndPrivacyStatement')}
                            />

                            <MUIComponents.Buttons.LoadingButton
                                id="register"
                                type="submit"
                                content={t('register.registerButton')}
                                loading={loading}
                                sx={{
                                    mt: devices.isMob ? 1.5 : undefined,
                                    display: 'block',
                                    marginLeft: 'auto',
                                    width: '100%'
                                }}
                                onClick={() => {
                                    if (
                                        !firstName.value ||
                                        !lastName.value ||
                                        !companyCode.value ||
                                        !language.value ||
                                        !statute.value ||
                                        !email
                                    ) {
                                        return
                                    }

                                    setOpenConfirmModal(true)
                                    setLoading(true)
                                }}
                                disabled={!readPrivacyStatementAndTermsOfUse}
                                {...(!readPrivacyStatementAndTermsOfUse && {
                                    tooltip: {
                                        content: t('register.checkboxUseOfTermsAndPrivacyStatement')
                                    }
                                })}
                            />
                        </MUIComponents.Styling.Box>

                        <StyledComponents.ItemNavigation
                            onClick={() => {
                                navigate('/login')
                            }}
                            sx={{
                                justifyContent: 'center',
                                ...(devices.heightIsSmallerThan850 && {
                                    paddingBottom: 10
                                }),
                                ...(devices.isWeb &&
                                    !devices.heightIsSmallerThan850 && {
                                        bottom: 0,
                                        position: 'absolute',
                                        left: '10%'
                                    })
                            }}
                        >
                            {t('register.alreadyRegistered')}
                        </StyledComponents.ItemNavigation>

                        {(devices.isMob || devices.isSmallerThanMob) && (
                            <Containers.Copyright
                                isMob={devices.isMob || devices.isSmallerThanMob}
                                isSmallerThanMob={devices.isSmallerThanMob}
                                sx={{
                                    color: 'rgb(128,128,128)',
                                    margin: 'auto 5px',
                                    fontSize: 'small'
                                }}
                            />
                        )}
                    </MUIComponents.Styling.Box>
                </MUIComponents.Styling.Grid>

                <MUIComponents.Styling.Grid item xs={3} elevation={6} />

                {(devices.isTablet || devices.isWeb) && (
                    <MUIComponents.Styling.Grid
                        item
                        xs={20}
                        elevation={6}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                            marginTop: 'auto'
                        }}
                        id="copyright"
                    >
                        <Containers.Copyright
                            isMob={devices.isMob}
                            isTablet={devices.isTablet}
                            sx={{
                                color: 'rgb(128,128,128)',
                                margin: devices.isTablet ? 'auto 5px' : 'auto 25px'
                            }}
                        />
                    </MUIComponents.Styling.Grid>
                )}

                <MUIComponents.Dialog
                    open={openConfirmModal}
                    onClose={() => {
                        setOpenConfirmModal(false)
                    }}
                    cancelClick={(e) => {
                        setOpenConfirmModal(false)
                        setAlertObject({
                            message: t('register.validateError'),
                            severity: 'error'
                        })
                        setStatute({
                            error: false,
                            value: ''
                        })
                        setCompanyCode({ ...companyCode, error: true })
                        handleSubmit(e, true)
                    }}
                    confirmClick={(e) => {
                        setLoading(false)
                        setOpenConfirmModal(false)
                        setCompanyCode({ ...companyCode, error: false })
                        handleSubmit(e, false)
                    }}
                    title={t('register.companyConfirmationTitle')}
                    {...(company?.name
                        ? {
                              cancelButtonText: 'Nee',
                              confirmButtonText: 'Ja',
                              content: t('register.companyCodeMatchErr', {
                                  companyName: company.name
                              })
                          }
                        : { confirmButtonText: 'Ok', content: t('register.companyCodeCheckErr') })}
                    fullScreen
                />
            </MUIComponents.Styling.Grid>
        </StyledComponents.Wrapper>
    )
}

const Row = styled.div<DeviceParameters>`
    display: flex;
    flex-direction: ${(props) => (props.isMob || props.isSmallerThanMob ? 'column' : 'row')};
`
