import MuiRadioGroup, { RadioGroupProps as MuiRadioGroupProps } from '@mui/material/RadioGroup'
import MuiRadio, { RadioProps as MuiRadioProps } from '@mui/material/Radio'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import MuiFormLabel from '@mui/material/FormLabel'
import styled from 'styled-components'
import FormHelperText, { FormHelperTextProps } from '@mui/material/FormHelperText'

import { genericMaxInputWidth, noop } from '../../utils/utils'

interface RadioProps extends MuiRadioProps {
    // you can declare custom props in here
    colorToOverwrite?: string
}

const StyledFormLabel = styled(MuiFormLabel)`
    &.Mui-focused {
        color: ${(props) => props.theme.palette.primary.main} !important;
    }
`

const StyledRadio = styled(MuiRadio)<RadioProps>`
    &.Mui-checked {
        color: ${({ colorToOverwrite, color, ...props }) =>
            color ?? colorToOverwrite ?? props.theme.palette.primary.main} !important;
    }
`

// you can declare custom props in here
export interface RadioGroupProps extends MuiRadioGroupProps {
    // You can customise the pagination items like this
    error?: FormHelperTextProps['error']
    errorMessage?: FormHelperTextProps['children'] | string
    group?: {
        disabled?: MuiRadioProps['disabled']
        value: MuiRadioProps['value']
        label: string
    }[]
    size?: MuiRadioProps['size']
    label?: string
}

export const RadioGroup = ({
    onChange = noop,
    error = false,
    errorMessage = undefined,
    size = 'small',
    group = [],
    label = '',
    style = {},
    ...props
}: RadioGroupProps) => (
    <FormControl
        sx={{maxWidth: genericMaxInputWidth}}
        style={style}>
            <StyledFormLabel error={error} id="demo-radio-buttons-group-label">
                {label}
            </StyledFormLabel>
            <MuiRadioGroup
                aria-labelledby="radio-buttons-group-label"
                name="radio-buttons-group"
                defaultValue={props.defaultValue}
                row={props.row}
                value={props.value}
                onChange={onChange}
            >
                {group.map((x, i) => (
                    <FormControlLabel
                        key={i}
                        value={x.value}
                        control={<StyledRadio disabled={x.disabled} size={size} />}
                        label={x.label}
                    />
                ))}
            </MuiRadioGroup>
            {error && errorMessage && (
                <FormHelperText error={error} color="red">
                    {errorMessage}
                </FormHelperText>
            )}
    </FormControl>
)

// TODO: added new prop colorToOverwrite
export const Radio = ({
    onChange = noop,
    checked = false,
    colorToOverwrite = undefined,
    ...props
}: RadioProps) => (
    <StyledRadio
        checked={checked}
        onChange={onChange}
        colorToOverwrite={colorToOverwrite}
        {...props}
        sx={{...props.sx,maxWidth: genericMaxInputWidth}}
    />
)

// Example of the Radio component with props:
{
    /* <MUIComponents.Radio
        checked={selectedValue === 'b'}
        onChange={handleChange}
        value="b"
        name="radio-buttons"
        inputProps={{ 'aria-label': 'B' }}
      /> */
}

// Example of the RadioGroup component with props:
{
    /* <MUIComponents.RadioGroup
                error
                errorMessage="This is a test error"
                onChange={(e) => {
                    console.log('cliked radio', e)
                }}
                group={[
                    { value: '10', label: 'Then' },
                    { value: '20', label: 'Twenty' },
                    { value: '30', label: 'Thirthy' }
                ]}
                value="10"
                name="radio-buttons"
            /> */
}
