import MuiAccordion, { AccordionProps as MuiAccordionProps } from '@mui/material/Accordion'
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'
import MuiAccordionDetails, { AccordionDetailsProps } from '@mui/material/AccordionDetails'
import styled, { CSSProperties } from 'styled-components'
import { alpha } from '@mui/material'

import { noop } from '../../utils/utils'
import { MUIComponents } from '.'
import theme from '../../theme/theme'

interface AccordionProps extends Omit<MuiAccordionProps, 'children'> {
    // you can declare custom props in here
    details?: string | JSX.Element
    title: string
    id: string
    ariaControls?: string
    summaryProps?: AccordionSummaryProps
    detailProps?: AccordionDetailsProps
    titleContent?: JSX.Element
    titleStyling?: CSSProperties
}

const StyledAccordion = styled(MuiAccordion)`
    border: ${(props) => `1px solid ${props.theme.palette.divider}`};

    &:not(:last-child) {
        border-bottom: 0;
    }

    &:before {
        display: none;
    }
`

const StyledAccordionSummary = styled(MuiAccordionSummary)`
    border: ${(props) => `1px solid ${props.theme.palette.divider}`};

    & .MuiAccordionSummary-content {
        margin-left: 8px;
    }
`

const StyledAccordionDetails = styled(MuiAccordionDetails)`
    padding: 8px;
    border-top: ${(props) => `1px solid ${alpha(props.theme.palette.divider, 0.25)}`};
`

export const Accordion = ({
    summaryProps = {},
    detailProps = {},
    onChange = noop,
    ariaControls = '',
    titleContent = undefined,
    titleStyling = {},
    ...props
}: AccordionProps) => (
    <StyledAccordion onChange={onChange} {...props}>
        <StyledAccordionSummary
            expandIcon={
                <MUIComponents.Icons.ExpandMoreIcon sx={{ color: theme.palette.primary.main }} />
            }
            aria-controls={ariaControls}
            id={props.id}
            {...summaryProps}
        >
            <MUIComponents.Typography sx={titleStyling}>{props.title}</MUIComponents.Typography>
            {titleContent}
        </StyledAccordionSummary>
        <StyledAccordionDetails {...detailProps}>
            {typeof props.details === 'string' ? (
                <MUIComponents.Typography>{props.details}</MUIComponents.Typography>
            ) : (
                props.details
            )}
        </StyledAccordionDetails>
    </StyledAccordion>
)

// Example of this component with props:
//  <MUIComponents.Accordion id="accordion-test" content="this is the content of this wonderfull accordion" title="test title" />
