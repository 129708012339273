import React from 'react'
import { createRoot } from 'react-dom/client'
import { ThemeProvider } from 'styled-components'
import { RecoilRoot } from 'recoil'

import './index.css'
import App from './components/app/App'
import reportWebVitals from './reportWebVitals'
import theme from './theme/theme'
import { MUIProviders, MUIFns } from './components/muiComponents'
import AlertProvider from './providers/AlertProvider'
import './translations/i18n';

const container = document.getElementById('root')
const root = container ? createRoot(container) : undefined
root?.render(
    <React.StrictMode>
        <RecoilRoot>
            <ThemeProvider theme={theme}>
                <MUIProviders.LocalizationProvider dateAdapter={MUIFns.AdapterDateFns}>
                    <AlertProvider>
                        <App />
                    </AlertProvider>
                </MUIProviders.LocalizationProvider>
            </ThemeProvider>
        </RecoilRoot>
    </React.StrictMode>
)
// TODO: Is even afchecken met de rest, want er is een extension voor recoil (voorzien in Chrome) voor gemakkelijk te kunnen debuggen.
// TODO: Deze vereist nu nog wel React v16, anderen hebben al gevraagd of deze ook compatibel gaat zijn met de v18

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
