import { useNavigate } from 'react-router-dom'
import { CSSProperties } from 'styled-components'

import { MUIComponents } from '../../muiComponents'

export const BackButton = ({
    style = {},
    tooltipContent = undefined
}: {
    style?: CSSProperties
    tooltipContent?: string
}) => {
    // hook
    const navigate = useNavigate()

    return (
        <MUIComponents.Buttons.IconButton
            style={style}
            color="error"
            aria-label="Go back"
            onClick={() => navigate(-1)}
            {...(tooltipContent && { tooltip: { placement: 'top', content: tooltipContent } })}
        >
            <MUIComponents.Icons.CancelIcon style={{ fontSize: '30px' }} />
        </MUIComponents.Buttons.IconButton>
    )
}
