import React, { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import { useLazyQuery, useMutation } from '@apollo/client'
import { CSSProperties } from 'styled-components'
import { useTranslation } from 'react-i18next'

import API from '../../../API'
import { MUIComponents } from '../../muiComponents'
import { alertObjState, languagesState, userProfileState } from '../../../store/store'
import { convertCodeBasedMenuItems, dataIsEquals } from '../../../utils/utils'
import * as Business from './business'
import { Variant } from '../../muiComponents/types'
import theme from '../../../theme/theme'
import {
    Company,
    Employee,
    EmployeeStatute,
    EmployeeTitle,
    Language,
    MutationUpdateEmployeeArgs,
    QueryEmployeeArgs,
    QueryEmployeeTitlesArgs
} from '../../../types/GQLTypes'
import { CustomProp } from '../../../types'
import useDeviceDetection from '../../../hooks/useDeviceDetection'
import StyledComponents from '../../StyledComponents'

interface MyProfileProps {
    onClose: () => void
}

export const MyProfile = (props: MyProfileProps) => {
    const { t } = useTranslation()

    //global states
    const [UserProfile] = useRecoilState(userProfileState)
    const [, setAlertObject] = useRecoilState(alertObjState)
    const [languages] = useRecoilState(languagesState)
    const [devices] = useDeviceDetection()

    //states
    const [originalEmployeeData, setOriginalEmployeeData] = useState<Employee>({})
    const [employeeTitles, setEmployeeTitles] = useState<EmployeeTitle[]>([])
    const [employeeStatutes, setEmployeeStatutes] = useState<EmployeeStatute[]>([])
    const [employeeData, setEmployeeData] = useState<Employee>({})
    const [companyData, setCompanyData] = useState<Company>({})
    const [loading, setLoading] = useState<boolean>(true)
    const [disabled, setDisabled] = useState<boolean>(false)
    const [requiredFields, setRequiredFields] = useState<Array<string>>([
        'first_name',
        'last_name',
        'employee_statute_fk',
        'date_of_birth',
        'language_fk',
        'street',
        'house_number',
        'zip_code',
        'city'
    ])
    const [requiredCustomPropFields, setRequiredCustomPropFields] = useState<Array<string>>([])
    const [hasFetched, setHasFetched] = useState<boolean>(false)

    //graphql
    const [collectEmployee] = useLazyQuery<{ employee: Employee }, QueryEmployeeArgs>(
        API.Queries.EMPLOYEE,
        { fetchPolicy: 'network-only' }
    )
    const collectEmployeeFn = () =>
        Business.handleCollectEmployee({
            employeeId: String(UserProfile?.employee_id),
            collectEmployee,
            setOriginalEmployeeData,
            setEmployeeData,
            setCompanyData,
            setDisabled,
            setOpenNotification: setAlertObject,
            t
        })
    const [collectEmployeeTitles] = useLazyQuery<
        { employeeTitles: EmployeeTitle[] },
        QueryEmployeeTitlesArgs
    >(API.Queries.EMPLOYEE_TITLES)
    const [updateEmployee] = useMutation<{ id: Employee['id'] }, MutationUpdateEmployeeArgs>(
        API.Mutations.EMPLOYEE
    )

    const isMob = devices.isMob || devices.isSmallerThanMob || devices.isSmallerThen170Width

    useEffect(() => {
        const initFetch = async () => {
            setLoading(true)
            setDisabled(false)
            if (UserProfile?.employee_id && UserProfile.employee_id !== '' && !hasFetched) {
                const employeeResult = await collectEmployeeFn()
                await Business.handleCollectEmployeeTitles({
                    collectEmployeeTitles,
                    setEmployeeTitles,
                    setDisabled,
                    setOpenNotification: setAlertObject,
                    t
                })
                setEmployeeStatutes(
                    (employeeResult?.company?.employeeStatutes ?? []) as EmployeeStatute[]
                )

                setHasFetched(true)
            } else {
                setAlertObject({
                    message: t('myProfile.noEmployeeErr'),
                    severity: 'error'
                })
            }
            setLoading(false)
        }
        initFetch()
    }, [UserProfile?.employee_id])

    useEffect(() => {
        Business.pushRequiredCustomProps({
            companyData,
            requiredFields,
            setRequiredFields,
            setRequiredCustomPropFields,
            t
        })
    }, [companyData])

    const handleSubmission = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()

        //Required fields check
        const requiredFieldsAreFilled = Business.validateRequiredFields({
            employeeData,
            requiredFields,
            requiredCustomPropFields,
            setOpenNotification: setAlertObject,
            t
        })

        if (requiredFieldsAreFilled) {
            setLoading(true)
            //Employee submission
            const submissionRes = await Business.submitEmployee({
                updateEmployee,
                employeeData,
                setOpenNotification: setAlertObject,
                t
            })

            //Refetching
            if (submissionRes === 'ok' && UserProfile?.employee_id) await collectEmployeeFn()

            setLoading(false)
        }
    }

    const SubTitle = (title: string, variant: Variant) => (
        <MUIComponents.Typography
            variant={variant}
            style={{
                color: title ? theme.palette.primary.main : undefined,
                fontWeight: 'bold',
                textAlign: 'left'
            }}
        >
            {title}
        </MUIComponents.Typography>
    )

    const Label = ({
        label,
        dataKey,
        required = true,
        disabled = false,
        xs = isMob ? 12 : 6,
        style,
        gridStyle
    }: {
        label: string
        dataKey: string
        required?: boolean
        disabled?: boolean
        xs?: number
        style?: CSSProperties
        gridStyle?: CSSProperties
    }) => (
        <MUIComponents.Styling.Grid item xs={xs} sx={{ ...gridStyle, minWidth: '100px' }}>
            <MUIComponents.TextFields.TextFieldWithLabel
                label={label}
                onChange={(e) =>
                    setEmployeeData({
                        ...employeeData,
                        [dataKey]: e.target.value
                    })
                }
                size="small"
                value={employeeData?.[dataKey as keyof Employee] ?? ''}
                disabled={disabled ?? (loading || disabled)}
                {...(required && {
                    required: requiredFields.includes(dataKey)
                })}
                style={style}
            />
        </MUIComponents.Styling.Grid>
    )

    const SingleSelect = (props: {
        label: string
        dataKey: string
        required?: boolean
        disabled?: boolean
        xs?: number
        gridStyle?: CSSProperties
        menuItems?: (Language | EmployeeStatute | EmployeeTitle)[]
    }) => (
        <MUIComponents.Styling.Grid
            item
            xs={props?.xs}
            sx={{ ...props?.gridStyle, minWidth: '100px' }}
        >
            <MUIComponents.Select.SingleSelect
                onChange={(e) =>
                    setEmployeeData({
                        ...employeeData,
                        [props?.dataKey]: e.target.value as string
                    })
                }
                value={employeeData?.[props?.dataKey as keyof Employee] ?? ''}
                size="small"
                labelContent={props?.label}
                menuItems={convertCodeBasedMenuItems(props?.menuItems ?? [])}
                disabled={loading || disabled || props?.disabled}
                required={requiredFields.includes(props?.dataKey)}
            />
        </MUIComponents.Styling.Grid>
    )

    const customPropertiesInputs = () => {
        if (
            !companyData ||
            !companyData.custom_employee_properties ||
            companyData.custom_employee_properties === ''
        )
            return null
        const customProps: CustomProp[] = JSON.parse(companyData.custom_employee_properties ?? '[]')
        if (!customProps || customProps.length <= 0) return null

        return (
            <>
                <MUIComponents.Styling.Divider light style={{ marginTop: 15, marginBottom: 15 }} />
                {SubTitle(t('myProfile.companyFieldsTitle'), 'subtitle1')}
                <MUIComponents.Styling.Grid container rowSpacing={rowSpacing} columnSpacing={2}>
                    {customProps.map((propItem) => {
                        //TODO: support more types
                        if (propItem.name && propItem.type && propItem?.type === 'text')
                            return (
                                <MUIComponents.Styling.Grid item xs={6} key={propItem.name}>
                                    <MUIComponents.TextFields.TextFieldWithLabel
                                        label={propItem.name}
                                        onChange={(e) =>
                                            setEmployeeData({
                                                ...employeeData,
                                                custom_properties:
                                                    Business.handleCustomPropsJsonStorage(
                                                        employeeData?.custom_properties ?? '{}',
                                                        propItem.name,
                                                        e.target.value
                                                    )
                                            })
                                        }
                                        size="small"
                                        value={Business.getCustomPropValue(
                                            employeeData?.custom_properties ?? '{}',
                                            propItem.name
                                        )}
                                        disabled={loading || disabled}
                                        required={propItem?.required}
                                    />
                                </MUIComponents.Styling.Grid>
                            )
                        return null
                    })}
                </MUIComponents.Styling.Grid>
            </>
        )
    }

    const rowSpacing = devices.isSmallerThanMob || devices.isMob ? 0 : 2

    // ! This contains all profile fields
    const ProfileFields = [
        SingleSelect({
            label: t('myProfile.designation'),
            dataKey: 'employee_title_fk',
            xs: 3,
            menuItems: employeeTitles.map((title) => ({ ...title, id: title.id ?? '' }))
        }),
        Label({
            label: t('myProfile.FirstNameInput'),
            dataKey: 'first_name',
            xs: 4.5
        }),
        Label({
            label: t('myProfile.lastNameInput'),
            dataKey: 'last_name',
            xs: 4.5
        }),
        Label({
            label: t('myProfile.mailInput'),
            dataKey: 'email',
            disabled: true,
            required: false,
            xs: 12
        }),
        Label({ label: t('myProfile.TelephoneInput'), dataKey: 'tel', required: true }),
        <MUIComponents.Styling.Grid
            key="profile-geboortedatum"
            item
            sx={{ minWidth: '100px', marginTop: '16px' }}
            xs={isMob ? 12 : 6}
        >
            <MUIComponents.DatePicker
                label={t('myProfile.birthdayInput')}
                onChange={(date) => {
                    if (date) {
                        setEmployeeData({
                            ...employeeData,
                            date_of_birth: Date.parse(date.toDateString()).toString()
                        })
                    }
                }}
                value={
                    employeeData?.date_of_birth !== null
                        ? new Date(Number(employeeData?.date_of_birth))
                        : null
                }
                disabled={loading || disabled}
                required={requiredFields.includes('date_of_birth')}
                colorToOverwrite="rgba(0, 0, 0, 0.87)"
            />
        </MUIComponents.Styling.Grid>,
        Label({ label: t('myProfile.addressInput'), dataKey: 'street', xs: 12 }),
        Label({ label: t('myProfile.houseNrInput'), dataKey: 'house_number' }),
        Label({ label: t('myProfile.zipInput'), dataKey: 'zip_code' }),
        Label({ label: t('myProfile.cityInput'), dataKey: 'city' }),
        SingleSelect({
            label: t('myProfile.languageInput'),
            dataKey: 'language_fk',
            xs: 4,
            menuItems: languages
        }),
        <MUIComponents.Styling.Grid
            key="profile-datum-rijbewijs"
            item
            sx={{ minWidth: '100px', marginTop: '16px' }}
            xs={isMob ? 12 : 6}
        >
            <MUIComponents.DatePicker
                label={t('myProfile.licenseDate')}
                onChange={(date) => {
                    if (date) {
                        setEmployeeData({
                            ...employeeData,
                            drivers_license_date: Date.parse(date.toDateString()).toString()
                        })
                    }
                }}
                value={
                    employeeData?.drivers_license_date !== null
                        ? new Date(Number(employeeData?.drivers_license_date))
                        : null
                }
                disabled={loading || disabled}
                required={requiredFields.includes('drivers_license_date')}
                colorToOverwrite="rgba(0, 0, 0, 0.87)"
            />
        </MUIComponents.Styling.Grid>,
        Label({
            label: t('myProfile.nationalIdentificationInput'),
            dataKey: 'national_register'
        }),
        <MUIComponents.Styling.Grid key="field-company" item xs={6}>
            <MUIComponents.TextFields.TextFieldWithLabel
                label={t('myProfile.companyInput')}
                onChange={(e) => {
                    return
                }}
                size="small"
                value={companyData?.name ?? ''}
                disabled
            />
        </MUIComponents.Styling.Grid>,
        <MUIComponents.Styling.Grid key="field-company-image" item xs={6}>
            {companyData?.logo_url && companyData?.logo_url !== '' && (
                <img
                    src={companyData.logo_url}
                    style={{ marginTop: 15, height: 50, width: 'auto', maxWidth: '100%' }}
                    alt="companyLogo"
                />
            )}
        </MUIComponents.Styling.Grid>,
        Label({
            label: t('myProfile.employeeNrInput'),
            dataKey: 'employee_number',
            xs: isMob ? 12 : 6,
            gridStyle: { paddingTop: 'unset !important' }
        }),
        SingleSelect({
            label: t('myProfile.statuteInput'),
            dataKey: 'employee_statute_fk',
            xs: isMob ? 12 : 6,
            disabled: employeeStatutes?.length === 0,
            menuItems: employeeStatutes,
            gridStyle: { paddingTop: 'unset !important' }
        })
    ]

    const defineDeviceView = (fieldsIndexes: number[], extraStyling?: boolean) => {
        if (isMob) {
            return ProfileFields.map(
                (field, i) =>
                    fieldsIndexes.includes(i) && (
                        <MUIComponents.Styling.Grid
                            {...(extraStyling && {
                                sx: { display: 'flex', margin: '0', paddingLeft: '16px' },
                                xs: 12
                            })}
                            key={i}
                        >
                            {field}
                        </MUIComponents.Styling.Grid>
                    )
            )
        } else {
            return (
                <MUIComponents.Styling.Grid
                    {...(extraStyling && {
                        sx: { display: 'flex', margin: '0' }
                    })}
                    container
                    rowSpacing={rowSpacing}
                    columnSpacing={2}
                >
                    {fieldsIndexes.map((index) => ProfileFields[index])}
                </MUIComponents.Styling.Grid>
            )
        }
    }

    return (
        <>
            <MUIComponents.Typography
                variant="h5"
                style={{
                    color: theme.palette.primary.main,
                    fontWeight: 'bold',
                    textAlign: 'left'
                }}
            >
                {t('myProfile.title')}
            </MUIComponents.Typography>

            {defineDeviceView([0, 1, 2])}

            <MUIComponents.Styling.Grid container rowSpacing={rowSpacing} columnSpacing={2}>
                {ProfileFields[3]}
            </MUIComponents.Styling.Grid>

            {defineDeviceView([4, 5])}

            <MUIComponents.Styling.Grid container rowSpacing={rowSpacing} columnSpacing={2}>
                {ProfileFields[6]}
            </MUIComponents.Styling.Grid>

            {defineDeviceView([7, 8])}

            {defineDeviceView([9, 10])}

            <MUIComponents.Styling.Divider sx={{ margin: '8px 0' }} />

            {/* Velden voor speed pedelecs */}
            {defineDeviceView([11, 12])}

            <MUIComponents.Styling.Divider sx={{ margin: '8px 0' }} />

            {/* Bedrijfsvelden */}
            <MUIComponents.Styling.Grid
                container
                {...(isMob && { xs: 15 })}
                rowSpacing={rowSpacing}
                columnSpacing={2}
            >
                {ProfileFields[13]}
                {ProfileFields[14]}

                <MUIComponents.Styling.Grid
                    sx={{ display: 'flex', margin: '0' }}
                    container
                    rowSpacing={rowSpacing}
                    columnSpacing={2}
                >
                    {defineDeviceView([15, 16], true)}
                </MUIComponents.Styling.Grid>
            </MUIComponents.Styling.Grid>

            {customPropertiesInputs()}

            {companyData?.info && <MUIComponents.Styling.Divider sx={{ margin: '8px 0' }} />}

            <MUIComponents.Styling.Grid container rowSpacing={rowSpacing} columnSpacing={2}>
                {companyData?.info && (
                    <MUIComponents.Styling.Grid item xs={12}>
                        <iframe srcDoc={companyData?.info ?? null} title="company info" />
                    </MUIComponents.Styling.Grid>
                )}

                <MUIComponents.Styling.Grid
                    item
                    sx={{ display: 'flex', justifyContent: 'space-between', margin: '8px 0' }}
                    xs={12}
                >
                    <MUIComponents.Buttons.Button onClick={props.onClose} variant="outlined">
                        <StyledComponents.ButtonText>
                            {t('general.buttons.close')}
                        </StyledComponents.ButtonText>
                    </MUIComponents.Buttons.Button>

                    <MUIComponents.Buttons.LoadingButton
                        details={
                            <StyledComponents.ButtonText>
                                {t('myProfile.saveBtn')}
                            </StyledComponents.ButtonText>
                        }
                        onClick={(e) => handleSubmission(e)}
                        loading={loading}
                        disabled={dataIsEquals(originalEmployeeData, employeeData) || disabled}
                        loadingPosition={'center'}
                        {...((devices.isSmallerThanMob || devices.isMob) && {
                            sx: { marginLeft: '10px' }
                        })}
                    />
                </MUIComponents.Styling.Grid>
            </MUIComponents.Styling.Grid>
        </>
    )
}
