import { useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { useTranslation } from 'react-i18next'

import theme from '../../../theme/theme'
import { MUIComponents } from '../../muiComponents'
import { dealerZipFromHomeState } from '../../../store/store'
import { DeviceParameters } from '../../../types'
import StyledComponents from '../../StyledComponents'
import { useState } from 'react'

export const DealerLocator = (props: DeviceParameters) => {
    const { t } = useTranslation()

    // global states
    const [dealerZipFromHome, setDealerZipFromHome] = useRecoilState(dealerZipFromHomeState)

    // states
    const [zipInput, setZipInput] = useState('')

    // hooks
    const navigate = useNavigate()

    return (
        <MUIComponents.Styling.Paper
            style={{
                padding: '20px',
                backgroundColor: '#244F26',
                color: 'white',
                height: 'calc(100% - 40px)',
                position: 'relative'
            }}
            elevation={5}
        >
            <MUIComponents.Typography
                variant="h5"
                style={{
                    textAlign: 'center',
                    color: theme.palette.primary.main,
                    ...((props.isMob || props.isSmallerThanMob) && {
                        ...StyledComponents.helper.truncatedText
                    })
                }}
            >
                {t('home.dealerLocator.title')}
            </MUIComponents.Typography>

            <MUIComponents.Styling.Divider />

            <MUIComponents.Typography
                variant="subtitle1"
                style={{
                    textAlign: 'left',
                    marginBottom: '10px',
                    fontSize: '12px',
                    ...((props.isMob || props.isSmallerThanMob) && {
                        ...StyledComponents.helper.truncatedText
                    })
                }}
                sx={{ display: 'block', whiteSpace: 'pre-line' }}
            >
                {t('home.dealerLocator.nearbyDealer')}
                <br /> {t('home.dealerLocator.useLocator')}
            </MUIComponents.Typography>

            <MUIComponents.TextFields.TextField
                id="Postcode"
                label={t('myProfile.zipInput')}
                name="Postcode"
                required
                size="small"
                value={dealerZipFromHome}
                defaultValue={dealerZipFromHome}
                autoFocus={false}
                onChange={(e) => {
                    //! Only allow numeric zip codes
                    const re = /^[0-9\b]+$/
                    if (e?.target?.value === '' || re.test(e?.target?.value ?? ''))
                        setDealerZipFromHome(e?.target?.value ?? '')
                }}
                customTextField
            />

            <MUIComponents.Buttons.Button
                variant="contained"
                fullWidth
                disabled={
                    !dealerZipFromHome || dealerZipFromHome === '' || dealerZipFromHome?.length < 4
                }
                onClick={() => {
                    navigate('/dealer')
                }}
                sx={{
                    position: 'absolute',
                    bottom: '20px',
                    width: '90%',
                    margin: 'auto',
                    right: 0,
                    left: 0
                }}
            >
                {t('home.dealerLocator.search')}
            </MUIComponents.Buttons.Button>
        </MUIComponents.Styling.Paper>
    )
}
