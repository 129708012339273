import { useEffect, useState } from 'react'
import styled, { css, CSSProperties } from 'styled-components'
import { useRecoilState } from 'recoil'
import { useNavigate } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import Containers from '../../../containers'
import { MUIComponents } from '../../../muiComponents'
import { alertObjState, simulatorObjectState, userProfileState } from '../../../../store/store'
import { BikeData, DeviceParameters, StepProps } from '../../../../types'
import {
    BikeBrand,
    BikeType,
    QueryBikeBrandsArgs,
    QueryBikeTypesArgs
} from '../../../../types/GQLTypes'
import API from '../../../../API'
import { Warning } from './Warning'
import useDeviceDetection from '../../../../hooks/useDeviceDetection'
import { Explanation } from './Explanation'
import { sortArrayByName } from '../../../../utils/utils'

export const StepOne = (props: StepProps) => {
    const { t } = useTranslation()

    //global states
    const [UserProfile] = useRecoilState(userProfileState)
    const [, setAlertObject] = useRecoilState(alertObjState)
    const [simulatorValues, setSimulatorValues] = useRecoilState(simulatorObjectState)

    // states
    const [loading, setLoading] = useState(false)

    const [bikeBudget, setBikeBudget] = useState<number | number[]>(
        (simulatorValues.bikeBudget as number) ?? 0
    )
    const [accessoriesBudget, setAccessoriesBudget] = useState<number | number[]>(
        (simulatorValues.accessoriesBudget as number) ?? 0
    )
    const [bikeTypes, setBikeTypes] = useState<BikeType[]>([])
    const [bikeType, setBikeType] = useState<BikeData['bike_type']>(
        simulatorValues.bikeType ?? undefined
    )
    const [bikeBrands, setBikeBrands] = useState<BikeBrand[]>([])
    const [bikeBrand, setBikeBrand] = useState<BikeData['bike_brand']>(
        simulatorValues.bikeBrand ?? undefined
    )

    const [getBikeTypes] = useLazyQuery<{ bikeTypes: BikeType[] }, QueryBikeTypesArgs>(
        API.Queries.BIKE_TYPES
    )
    const [getBikeBrands] = useLazyQuery<{ bikeBrands: BikeBrand[] }, QueryBikeBrandsArgs>(
        API.Queries.BIKE_BRANDS
    )
    const [errors, setErrors] = useState<Record<string, boolean>>({
        bikeBrand: props.errors?.bikeBrand ?? false,
        bikeType: props.errors?.bikeType ?? false
    })

    // hooks
    const navigate = useNavigate()
    const [devices] = useDeviceDetection()

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)

            try {
                const bikeTypesData = await getBikeTypes()
                const bikeBrandsData = await getBikeBrands()

                if (bikeTypesData?.data?.bikeTypes) {
                    setBikeTypes(bikeTypesData.data.bikeTypes)
                }
                if (bikeBrandsData?.data?.bikeBrands) {
                    setBikeBrands(
                        sortArrayByName({
                            arrayToSort: [...bikeBrandsData.data.bikeBrands],
                            direction: 'asc'
                        })
                    )
                }
            } catch (err) {
                console.log(err)
                setBikeTypes([])
                setBikeBrands([])
                setAlertObject({
                    message: t('simulation.step1Container.alertBikes'),
                    severity: 'error'
                })
            }

            setLoading(false)
        }

        fetchData()
    }, [])

    const boxWidth = devices.isMob || devices.isSmallerThanMob ? 'calc(100% - 20px)' : undefined

    return (
        <MUIComponents.Styling.Box
            sx={{
                display: 'grid',
                gridAutoFlow: 'row',
                gridTemplateColumns: 'repeat(10, 1fr)',
                gridTemplateRows: 'repeat(10, 1fr)',
                gap: 1,
                width: '100%',
                height: devices.isSmallerThanMob ? 'calc(100% - 350px)' : 'calc(100% - 150px)',
                marginTop: '20px',
                overflow: devices.isWeb ? 'auto' : undefined
            }}
        >
            <Explanation />

            <MUIComponents.Styling.Box
                sx={{
                    gridColumn: '1/11',
                    gridRow: '3/4',
                    minWidth: 0,
                    display: 'flex',
                    flexDirection: devices.isMob || devices.isSmallerThanMob ? 'column' : 'row',
                    width: boxWidth
                }}
            >
                {loading ? (
                    <MUIComponents.Skeleton
                        sx={{ marginTop: '16px' }}
                        width="100%"
                        height={45}
                        variant="rectangular"
                    />
                ) : (
                    <MUIComponents.AutoComplete
                        onChange={(_e, value) => {
                            const newValue = value as {
                                value: string
                                label: string
                            }

                            if (newValue?.label === 'Alles') {
                                setBikeBrand(newValue)
                                return
                            }

                            setBikeBrand(newValue)
                            setSimulatorValues((old) => ({ ...old, bikeBrand: newValue }))

                            if (value) {
                                setErrors((old) => ({
                                    ...old,
                                    bikeBrand: false
                                }))
                            }
                        }}
                        size="small"
                        id="select-bike-brand"
                        label={t('simulation.step1Container.brandInput')}
                        value={bikeBrand}
                        options={bikeBrands.map((brand) => ({
                            value: brand.id ?? '',
                            label: brand.name ?? ''
                        }))}
                        required
                        error={errors.bikeBrand}
                        sx={{ width: '100%' }}
                    />
                )}

                {loading ? (
                    <MUIComponents.Skeleton
                        sx={{ marginTop: '16px' }}
                        width="100%"
                        height={45}
                        variant="rectangular"
                    />
                ) : (
                    <MUIComponents.Select.SingleSelect
                        onChange={(e) => {
                            const newBikeType = {
                                value: e.target.value as string,
                                label:
                                    bikeTypes.find((type) => type.id === e.target.value)?.name ?? ''
                            }

                            setBikeType(newBikeType)
                            setSimulatorValues((old) => ({ ...old, bikeType: newBikeType }))

                            if (e.target.value) {
                                setErrors((old) => ({
                                    ...old,
                                    bikeType: false
                                }))
                            }
                        }}
                        size="small"
                        labelContent={t('simulation.step1Container.typeInput')}
                        value={bikeType?.value}
                        menuItems={bikeTypes.map((type) => ({
                            value: type.id ?? '',
                            label: type.name ?? ''
                        }))}
                        customSelect={false}
                        formStyling={{
                            paddingLeft: devices.isMob || devices.isSmallerThanMob ? 'unset' : '5px'
                        }}
                        required
                        error={errors.bikeType}
                    />
                )}
            </MUIComponents.Styling.Box>

            <MUIComponents.Styling.Box
                sx={{
                    gridColumn: '1/11',
                    gridRow: '4/5',
                    minWidth: 0,
                    display: 'flex',
                    flexDirection: devices.isMob || devices.isSmallerThanMob ? 'column' : 'row',
                    width: boxWidth
                }}
            >
                <Slider>
                    <MUIComponents.Typography variant="subtitle1" style={typoGraphyStyling}>
                        {t('simulation.step1Container.spendingSubtitle')}
                    </MUIComponents.Typography>

                    <Row isMob={devices.isMob} isSmallerThanMob={devices.isSmallerThanMob}>
                        <MUIComponents.Slider
                            onChange={(_e, newValue) => {
                                setBikeBudget(newValue)
                                setSimulatorValues((old) => ({ ...old, bikeBudget: newValue }))
                            }}
                            value={bikeBudget}
                            size="small"
                            step={100}
                            marks={(devices.isMob || devices.isSmallerThanMob
                                ? [0, 10000]
                                : [0, 5000, 10000]
                            ).map((x) => ({
                                label: `€${x}`,
                                value: x
                            }))}
                            min={0}
                            max={10000}
                        />

                        <MUIComponents.NumberField
                            autoFocus={false}
                            value={(bikeBudget as number[])[0] ?? bikeBudget}
                            onChange={(e) => {
                                setBikeBudget(Number(e.target.value))
                                setSimulatorValues((old) => ({
                                    ...old,
                                    bikeBudget: Number(e.target.value)
                                }))
                            }}
                            margin="none"
                            size="small"
                            InputProps={{
                                style: {
                                    marginLeft: '10px',
                                    ...((devices.isMob || devices.isSmallerThanMob) && {
                                        marginTop: '10px'
                                    })
                                }
                            }}
                            inputProps={{
                                step: 100,
                                min: 0,
                                max: 5000,
                                type: 'number',
                                'aria-labelledby': 'input-slider'
                            }}
                            {...(!(devices.isMob || devices.isSmallerThanMob) && {
                                sx: { marginLeft: '20px' }
                            })}
                        />
                    </Row>
                </Slider>
                <Slider left={!(devices.isMob || devices.isSmallerThanMob)}>
                    <MUIComponents.Typography variant="subtitle1" style={typoGraphyStyling}>
                        {t('simulation.step1Container.SpendingAccessoireesInput')}
                    </MUIComponents.Typography>

                    <Row isMob={devices.isMob} isSmallerThanMob={devices.isSmallerThanMob}>
                        <MUIComponents.Slider
                            onChange={(_e, newValue) => {
                                setAccessoriesBudget(newValue)
                                setSimulatorValues((old) => ({
                                    ...old,
                                    accessoriesBudget: newValue
                                }))
                            }}
                            value={accessoriesBudget}
                            size="small"
                            step={50}
                            marks={(devices.isMob || devices.isSmallerThanMob
                                ? [0, 2500]
                                : [0, 1250, 2500]
                            ).map((amount) => ({
                                label: `€${amount}`,
                                value: amount
                            }))}
                            min={0}
                            max={2500}
                        />

                        <MUIComponents.NumberField
                            autoFocus={false}
                            value={(accessoriesBudget as number[])[0] ?? accessoriesBudget}
                            onChange={(e) => {
                                setAccessoriesBudget(Number(e.target.value))
                                setSimulatorValues((old) => ({
                                    ...old,
                                    accessoriesBudget: Number(e.target.value)
                                }))
                            }}
                            margin="none"
                            size="small"
                            InputProps={{
                                style: {
                                    marginLeft: '10px',
                                    ...((devices.isMob || devices.isSmallerThanMob) && {
                                        marginTop: '10px'
                                    })
                                }
                            }}
                            inputProps={{
                                step: 50,
                                min: 0,
                                max: 2500,
                                type: 'number',
                                'aria-labelledby': 'input-slider'
                            }}
                            {...(!(devices.isMob || devices.isSmallerThanMob) && {
                                sx: { marginLeft: '20px' }
                            })}
                        />
                    </Row>
                </Slider>
            </MUIComponents.Styling.Box>

            <MUIComponents.Styling.Box
                sx={{
                    gridColumn: '1/11',
                    gridRow: '7/8',
                    minWidth: 0,
                    display: 'flex',
                    flexDirection: 'row',
                    backgroundColor: '#FAFAFA',
                    padding: '20px',
                    color: 'rgba(0,0,0,0.6)',
                    width:
                        devices.isMob || devices.isSmallerThanMob
                            ? 'calc(100% - 60px)'
                            : 'calc(100% - 40px)'
                }}
            >
                <Warning content={t('simulation.companyDependentWarning')} />
            </MUIComponents.Styling.Box>

            <MUIComponents.Styling.Box
                sx={{
                    gridColumn: '1/11',
                    gridRow: '11/12',
                    minWidth: 0,
                    display: 'flex',
                    flexDirection: devices.isMob || devices.isSmallerThanMob ? 'column' : 'row',
                    width: boxWidth,
                    height: 'min-content'
                }}
            >
                <MUIComponents.Buttons.Button
                    variant="outlined"
                    fullWidth
                    onClick={() => navigate(-1)}
                    sx={{
                        marginTop: '20px',
                        width: devices.isMob || devices.isSmallerThanMob ? '100%' : '200px',
                        display: 'block'
                    }}
                >
                    {t('general.buttons.cancel')}
                </MUIComponents.Buttons.Button>

                <MUIComponents.Buttons.Button
                    variant="contained"
                    fullWidth
                    onClick={() => {
                        if (!bikeBrand || !bikeType) {
                            setErrors({
                                bikeBrand: !bikeBrand?.value,
                                bikeType: !bikeType?.value
                            })

                            setAlertObject({
                                message: t('simulation.step1Container.requiredFieldsCheckErr'),
                                severity: 'error'
                            })

                            return
                        }

                        props.setActiveStep(undefined, 1)
                    }}
                    sx={{
                        marginTop: '20px',
                        width: devices.isMob || devices.isSmallerThanMob ? '100%' : '200px',
                        display: 'block',
                        marginLeft: 'auto',
                        marginBottom: devices.isSmallerThanMob ? '20px' : undefined
                    }}
                >
                    {t('general.buttons.nextStep')}
                </MUIComponents.Buttons.Button>

                {(devices.isMob || devices.isSmallerThanMob) && (
                    <MUIComponents.Styling.Paper
                        style={{
                            width: '100%',
                            margin: '10px auto auto',
                            boxShadow: 'unset'
                        }}
                    >
                        <Containers.Copyright
                            sx={{
                                color: 'rgb(128,128,128)',
                                margin: 'auto 5px',
                                fontSize: 'small'
                            }}
                            paperStyling={{ margin: '10px 0px', width: 'unset' }}
                            isMob={devices.isMob || devices.isSmallerThanMob}
                            isTablet={devices.isTablet}
                        />
                    </MUIComponents.Styling.Paper>
                )}
            </MUIComponents.Styling.Box>
        </MUIComponents.Styling.Box>
    )
}

const Slider = styled.div<{ left?: boolean; right?: boolean }>`
    width: 100%;

    ${(props) =>
        props.left &&
        css`
            padding-left: 10px;
        `}

    ${(props) =>
        props.right &&
        css`
            padding-right: 10px;
        `}
`

const Row = styled.div<DeviceParameters>`
    display: flex;
    flex-direction: ${(props) => (props.isMob || props.isSmallerThanMob ? 'column' : 'row')};
    width: calc(100% - 10px);
`

const typoGraphyStyling: CSSProperties = {
    textAlign: 'left',
    color: 'rgba(0, 0, 0, 0.6)'
}
