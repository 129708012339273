import styled from 'styled-components'
import { DeviceParameters } from '../../../../types'

import { MUIComponents } from '../../../muiComponents'
import StyledComponents from '../../../StyledComponents'

export const CheckLabel = ({
    tooltip = '',
    ...props
}: {
    content: string
    error: boolean
    tooltip?: string
} & DeviceParameters) => (
    <Item>
        <IconWrapper
            isSmallerThanMob={props.isSmallerThanMob}
            isSmallerThen170Width={props.isSmallerThen170Width}
            error={props.error}
        >
            {props.error ? (
                <MUIComponents.Icons.CancelIcon />
            ) : (
                <MUIComponents.Icons.CheckCircleIcon />
            )}
        </IconWrapper>
        <MUIComponents.Tooltip title={tooltip}>
            <Text error={props.error}>{props.content}</Text>
        </MUIComponents.Tooltip>
    </Item>
)

const Item = styled(MUIComponents.Styling.Paper)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    box-shadow: unset;
`

const Text = styled.p<{ error: boolean }>`
    width: 100%;
    margin: 10px;
    color: ${(props) => (props.error ? 'red' : 'green')};

    ${StyledComponents.BreakupText}
`

const IconWrapper = styled.div<{ error: boolean } & DeviceParameters>`
    padding-left: ${(props) =>
        props.isSmallerThanMob || props.isSmallerThen170Width ? '5px' : '10px'};
    padding-right: ${(props) =>
        props.isSmallerThanMob || props.isSmallerThen170Width ? undefined : '10px'};
    color: ${(props) => (props.error ? 'red' : 'green')};
    display: flex;
`
