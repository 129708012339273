import { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import styled from 'styled-components'

import { MUIComponents } from '../components/muiComponents'
import { alertObjState } from '../store/store'
import { genericFontSize } from '../utils/utils'

// ! alles van alerts is voorzien in een provider en word bijgehouden in global state (Brent VT zijn fantastische oplossing)
const AlertProvider = ({ children }: { children: JSX.Element }) => {
    const [isOpen, setIsOpen] = useState(false)
    const [alertObject, setAlertObject] = useRecoilState(alertObjState)

    const closeAlert = () => {
        setIsOpen(false)
        setAlertObject({})
    }

    const alertHasMessage = alertObject && alertObject.message && alertObject.message !== ''

    useEffect(() => {
        if (alertHasMessage) setIsOpen(true)
    }, [alertObject])

    return (
        <>
            {children}
            {alertHasMessage && (
                <MUIComponents.Alert
                    open={isOpen}
                    severity={alertObject.severity}
                    details={<MessageWrapper>{alertObject.message}</MessageWrapper>}
                    onClose={closeAlert}
                    style={{
                        textAlign: 'left',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        marginTop: 0
                    }}
                />
            )}
        </>
    )
}

export default AlertProvider

const MessageWrapper = styled.p`
    margin-top: 25px;
    margin: 0;
    padding: 0;
    font-size: ${genericFontSize};
    vertical-align: middle;
    line-height: normal;
    display: inline-block;
`
