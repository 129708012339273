import styled, { CSSProperties } from 'styled-components'
import { useTranslation } from 'react-i18next'

export const Explanation = (props: { style?: CSSProperties }) => {
    const { t } = useTranslation()

    return (
        <ExplanationWrapper style={props.style}>
            {t('simulation.intro')}
        </ExplanationWrapper>
    )
}

const ExplanationWrapper = styled.div<{ style?: CSSProperties }>`
    font-size: small;
    grid-column: 1/11;
    grid-row: 1;

    ${(props) => props.style && { ...props.style }}
`