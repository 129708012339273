import { useLazyQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import { CSSProperties } from 'styled-components'
import { useRecoilState } from 'recoil'
import fileDownload from 'js-file-download'
import { useTranslation } from 'react-i18next'

import API from '../../../API'
import { MUIComponents } from '../../muiComponents'
import { MuiTabsProps, TabProps } from '../../muiComponents/types'
import * as GQLTypes from '../../../types/GQLTypes'
import { defaultVisibleFileTypes, downloadFileAsPdf } from '../../../utils/utils'
import { loadingDocuments } from '../../../utils/utilsWithJSX'
import {
    alertObjState,
    loadingDownloadState,
    userObjectState,
    userProfileState
} from '../../../store/store'
import Info from '../info'

interface TabsDocumentsProps {
    orientation?: MuiTabsProps['orientation']
    tabWidth?: CSSProperties['width']
    border?: boolean
    parentTabsBoxStyling?: CSSProperties
    noActivation?: boolean
    tabs?: TabProps[]
    orderId?: GQLTypes.Order['id']
    quotationId?: GQLTypes.Quotation['id']
    bikeTypeName?: GQLTypes.BikeType["name"]
    fileTypes?: string[]
    homePage?: boolean
}

export const TabsDocuments = ({
    fileTypes = defaultVisibleFileTypes,
    homePage = false,
    ...props
}: TabsDocumentsProps) => {
    const { t } = useTranslation()

    // global state
    const [userObject] = useRecoilState(userObjectState)
    const [userProfile] = useRecoilState(userProfileState)
    const [, setAlertObject] = useRecoilState(alertObjState)
    const [, setLoadingDownload] = useRecoilState(loadingDownloadState)

    const [tabs, setTabs] = useState<GQLTypes.OrderLog[]>([])
    const [loading, setLoading] = useState(true)

    // TODO: move to utils if logic is needed elsewhere too
    // helper functions
    const filterOrderlogs = (orderlogs: GQLTypes.OrderLog[]) => {
        return orderlogs.filter(orderLog => (
            fileTypes.includes(orderLog.orderLogType?.code ?? '') &&
            // return only the signed version of a document if available
            !orderlogs.find(ol => ol.orderLogType?.code === `${orderLog.orderLogType?.code}_SIGNED`)
        ))
    }

    // queries
    const [collectOrderLogs, { error: orderLogsError }] = useLazyQuery<
        { orderLogs: GQLTypes.OrderLog[] },
        GQLTypes.QueryOrderLogsArgs
    >(API.Queries.ORDER_LOGS_BY_ORDER)

    const [collectOrders, { error: ordersError }] = useLazyQuery<
        { employee: GQLTypes.Employee },
        GQLTypes.QueryEmployeeArgs
    >(API.Queries.ORDERS_BY_EMPLOYEE)

    useEffect(() => {
        const fetchData = async () => {
            const resultOrders = await collectOrders({
                variables: {
                    id: userProfile?.employee_id as string
                }
            })
            if (!props.orderId) {
                setTabs([])
                setLoading(false)
                return
            }
            // const orderId = !props?.orderId
            //     ? resultOrders.data?.employee?.employee_quotations
            //           ?.filter((quote) => quote?.order?.orderStatus?.id === '5')
            //           ?.reduce(
            //               (a, b) =>
            //                   new Date(a?.order?.date ?? '') > new Date(b?.order?.date ?? '')
            //                       ? a
            //                       : b,
            //               {}
            //           )?.order?.id
            //     : props?.orderId

            const resultOrderLogs = props.orderId
                ? await collectOrderLogs({
                    variables: {
                        filter: {
                            order_fk: props.orderId
                        },
                        size: 100,
                        page: 0
                    }
                })
                : { data: { orderLogs: [] } }

            if (resultOrderLogs?.data?.orderLogs && resultOrderLogs?.data?.orderLogs?.length > 0) {
                setTabs(
                    filterOrderlogs(resultOrderLogs.data.orderLogs)
                )
                setLoading(false)
            } else {
                setLoading(false)
            }

            if (orderLogsError || ordersError) {
                setAlertObject({
                    message: t('tabsDocuments.fetchErr'),
                    severity: 'error'
                })
                console.log(orderLogsError)
                console.log(ordersError)
                setTabs([])
                setLoading(false)
            }
        }

        setTimeout(() => {
            if (
                userProfile?.employee_id &&
                (props.tabs === undefined || props.tabs?.length === 0)
            ) {
                fetchData()
            }
        }, 2000)
    }, [userProfile?.employee_id, props?.orderId])

    if (
        !loading &&
        !props.orderId &&
        tabs.length === 0 &&
        (props.tabs?.length === 0 || props.tabs === undefined)
    ) {
        return <Info.NoContent orientation={props.orientation} homePage={homePage} />
    }

    return (
        <MUIComponents.Tabs
            value={false}
            variant="fullWidth"
            scrollButtons={false}
            sx={{ borderRadius: '10px', height: '100%' }}
            noActivation={true}
            border={props.border}
            parentTabsBoxStyling={props.parentTabsBoxStyling}
            parentBoxStyling={{ height: 'calc(100% - 60px)' }}
            allowScrollButtonsMobile
            onClick={async (_e, fileId, label, id) => {
                setLoadingDownload(true)
                try {
                    const result = await downloadFileAsPdf({
                        item: { fileId, id },
                        token: userObject.accessToken as string
                    })
                    const fileName = label === "Offerte van dealer" ? `Offerte B2Bike - ${props?.bikeTypeName}.pdf`: `Q${props?.quotationId}-${label}.pdf`
                    fileDownload(result?.data, fileName)
                    setLoadingDownload(false)
                } catch (err) {
                    setAlertObject({
                        message: t('tabsDocuments.downloadErr'),
                        severity: 'error'
                    })
                    setLoadingDownload(false)
                }
            }}
            colorToOverwrite="white"
            allTabs={loadingDocuments({
                loading,
                length:
                    (props.tabs ?? tabs ?? []).length === 0 ? 4 : (props.tabs ?? tabs ?? []).length,
                tabs: (props.tabs ?? []).length > 0 ? props.tabs : tabs
            })}
            component="IconWithLabel"
            orientation={props.orientation}
            tabWidth={props.tabWidth}
        />
    )
}
