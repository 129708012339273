import { useLocation, useNavigate } from 'react-router-dom'
import { getAuth } from 'firebase/auth'
import { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { useRecoilState } from 'recoil'
import { useTranslation } from 'react-i18next'

import firebaseApp from '../../../firebase'
import { MUIComponents } from '../../muiComponents'
import theme from '../../../theme/theme'
import { loadingDownloadState, myProfileState, userProfileState } from '../../../store/store'
import { LocationState } from '../../../types'
import useDeviceDetection from '../../../hooks/useDeviceDetection'
import StyledComponents from '../../StyledComponents'

const menuItemStyling = { paddingLeft: '10px' }

const userMenu = [
    {
        title: 'home',
        path: '/home',
        icon: <MUIComponents.Icons.HomeIcon sx={menuItemStyling} />
    },
    {
        title: 'files',
        path: '/dossiers',
        icon: <MUIComponents.Icons.FilePresentIcon sx={menuItemStyling} />
    },
    {
        title: 'calculations',
        path: '/calculations',
        icon: <MUIComponents.Icons.CalculateIcon sx={menuItemStyling} />
    },
    {
        title: 'profile',
        path: '/my-profile',
        icon: <MUIComponents.Icons.AccountBoxIcon sx={menuItemStyling} />
    },
    {
        title: 'simulator',
        path: '/simulator',
        icon: <MUIComponents.Icons.SettingsApplicationsIcon sx={menuItemStyling} />
    },
    {
        title: 'searcher',
        path: '/dealer',
        icon: <MUIComponents.Icons.LocationSearchingIcon sx={menuItemStyling} />
    }
]

export const HeaderMenu = (props: { userData: { uid: string; employee_id: string } }) => {
    const { t } = useTranslation()

    const navigate = useNavigate()
    const location = useLocation()
    const [devices] = useDeviceDetection()

    const originalLocation = location as LocationState

    const [, setOpenProfile] = useRecoilState(myProfileState)
    const [userProfile] = useRecoilState(userProfileState)
    const [loadingDownload] = useRecoilState(loadingDownloadState)

    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
    const [activeMenuItem, setActiveMenuItem] = useState<string>(originalLocation?.pathname)

    const handleCloseUserMenu = (path?: string) => {
        if (path === '/my-profile') {
            setOpenProfile(true)
            return
        }

        if (path && path !== '') navigate(path)
    }

    useEffect(() => {
        const homeRoutes = ['', '/', '/home', '/dashboard']
        if (homeRoutes.includes(originalLocation?.pathname)) {
            setActiveMenuItem('/home')
        }
    }, [originalLocation?.pathname])

    const checkIfUserIsLoggedIn = props?.userData?.uid && props?.userData?.employee_id

    const signOut = async () => {
        setIsMenuOpen(false)
        const auth = getAuth(firebaseApp)
        await auth.signOut()
    }

    return (
        <Menu isMenuOpen={isMenuOpen}>
            {isMenuOpen && (
                <MUIComponents.Appbar
                    style={{
                        width:
                            devices.isMob || devices.isTablet || devices.isSmallerThanMob
                                ? '100%'
                                : '20%',
                        backgroundColor: 'rgb(255,255,255)',
                        color: 'rgb(0,0,0,0.5)',
                        height: '100%',
                        zIndex: 9999
                    }}
                >
                    <MUIComponents.Styling.Container
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            borderBottom: '1px solid rgb(0,0,0,0.1)'
                        }}
                        maxWidth={false}
                        disableGutters
                    >
                        <MUIComponents.Styling.Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                paddingLeft: '20px'
                            }}
                        >
                            <ImageWrapper
                                src="/img/Logo-B2BIKE.png"
                                alt="logo"
                                onClick={() => {
                                    navigate('/home')
                                    setIsMenuOpen(!isMenuOpen)
                                }}
                            />
                        </MUIComponents.Styling.Box>

                        <MUIComponents.Styling.Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                borderLeft: '1px solid rgb(0,0,0,0.1)',
                                height: '50px'
                            }}
                        >
                            <MUIComponents.Buttons.Button
                                onClick={() => {
                                    if (!checkIfUserIsLoggedIn) {
                                        return
                                    }
                                    setIsMenuOpen(!isMenuOpen)
                                }}
                                tooltip={{ content: 'Menu' }}
                                variant="text"
                            >
                                <MUIComponents.Icons.CloseIcon
                                    style={{ color: 'rgb(0,0,0,0.5)' }}
                                />
                            </MUIComponents.Buttons.Button>
                        </MUIComponents.Styling.Box>
                    </MUIComponents.Styling.Container>
                    <MenuContentWrapper>
                        {userMenu.map((userMenuItem, i) => (
                            <>
                                <MenuContent
                                    onClick={() => {
                                        if (!checkIfUserIsLoggedIn) {
                                            return
                                        }
                                        setActiveMenuItem(userMenuItem.path)
                                        handleCloseUserMenu(userMenuItem.path)
                                        setIsMenuOpen(!isMenuOpen)
                                    }}
                                    active={activeMenuItem === userMenuItem.path}
                                    key={i}
                                >
                                    {userMenuItem.icon}
                                    <MUIComponents.Typography
                                        style={{
                                            marginTop: 'unset',
                                            paddingLeft: '30px',
                                            ...StyledComponents.helper.truncatedText
                                        }}
                                    >
                                        {t(`headerMenu.${userMenuItem.title}`)}
                                    </MUIComponents.Typography>
                                </MenuContent>
                                <MUIComponents.Styling.Divider sx={{ opacity: 0.25 }} />
                            </>
                        ))}
                        <MUIComponents.Styling.Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'start',
                                borderBottom: '1px solid rgb(0,0,0,0.2)',
                                width: '100%',
                                cursor: 'pointer',
                                marginTop: 'auto',
                                position: 'absolute',
                                bottom: 25
                            }}
                            onClick={signOut}
                        >
                            <MUIComponents.Icons.LogoutIcon
                                sx={{ paddingTop: '10px', paddingLeft: '20px' }}
                            />
                            <MUIComponents.Typography
                                style={{
                                    marginTop: 'unset',
                                    padding: '10px',
                                    paddingLeft: '30px',
                                    ...StyledComponents.helper.truncatedText
                                }}
                            >
                                {t('headerMenu.logout')}
                            </MUIComponents.Typography>
                        </MUIComponents.Styling.Box>
                    </MenuContentWrapper>
                </MUIComponents.Appbar>
            )}

            <MUIComponents.Appbar
                position={isMenuOpen ? 'fixed' : undefined}
                {...(isMenuOpen && {
                    style: {
                        transition: 'opacity 200ms ease 0s',
                        visibility: 'visible',
                        opacity: 1,
                        zIndex: 2,
                        backgroundColor: 'rgb(128,128,128)'
                    }
                })}
            >
                <MUIComponents.Styling.Container
                    sx={{ display: 'flex', justifyContent: 'space-between' }}
                    maxWidth={false}
                    disableGutters
                >
                    <MUIComponents.Styling.Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            borderRight: '1px solid rgb(0,0,0,0.1)',
                            ...(devices.isSmallerThanMob && { width: '50%' })
                        }}
                    >
                        <ImageWrapper
                            src="/img/Logo-B2BIKE.png"
                            alt="logo"
                            {...(!isMenuOpen && {
                                onClick: () => navigate('/home')
                            })}
                            {...(isMenuOpen && {
                                style: {
                                    cursor: 'unset'
                                }
                            })}
                        />

                        <MUIComponents.Buttons.Button
                            {...(!isMenuOpen && {
                                onClick: () => navigate('/home')
                            })}
                            tooltip={{ content: 'Home' }}
                            variant="text"
                            sx={{
                                borderLeft: '1px solid rgb(0,0,0,0.1)',
                                height: '50px',
                                ...(isMenuOpen && {
                                    cursor: 'unset'
                                })
                            }}
                        >
                            <MUIComponents.Icons.HomeIcon />
                        </MUIComponents.Buttons.Button>
                    </MUIComponents.Styling.Box>

                    <MUIComponents.Styling.Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            height: '50px',
                            ...(devices.isSmallerThanMob && {
                                width: '50%'
                            })
                        }}
                    >
                        <MUIComponents.Typography
                            variant="h6"
                            sx={{
                                color: 'rgb(0,0,0,0.6)',
                                margin: 'auto 5px',
                                fontSize: 'small',
                                ...(!devices.isSmallerThanMob && {
                                    minWidth: '75px'
                                }),
                                ...(devices.isSmallerThanMob && {
                                    ...StyledComponents.helper.truncatedText
                                })
                            }}
                        >
                            {userProfile.first_name as string} {userProfile.last_name as string}
                        </MUIComponents.Typography>

                        <MUIComponents.Buttons.Button
                            onClick={() => {
                                if (!checkIfUserIsLoggedIn) {
                                    return
                                }
                                setIsMenuOpen(!isMenuOpen)
                            }}
                            tooltip={{ content: 'Menu' }}
                            variant="text"
                            sx={{
                                borderLeft: '1px solid rgb(0,0,0,0.1)',
                                height: '50px',
                                ...(devices.isMob && {
                                    width: '50%',
                                    maxWidth: '80px',
                                    minWidth: '40px',
                                    padding: 'unset'
                                })
                            }}
                        >
                            <MUIComponents.Icons.MenuIcon />
                        </MUIComponents.Buttons.Button>
                    </MUIComponents.Styling.Box>
                </MUIComponents.Styling.Container>
                {loadingDownload && <MUIComponents.LinearLoader />}
            </MUIComponents.Appbar>
        </Menu>
    )
}

const Menu = styled.div<{ isMenuOpen: boolean }>`
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: opacity 200ms;
    z-index: 2;

    ${(props) =>
        props.isMenuOpen &&
        css`
            position: fixed;
            visibility: visible;
            opacity: 1;
            background: rgba(0, 0, 0, 0.5);
        `}
`

const ImageWrapper = styled.img`
    min-width: 50px;
    max-width: 150px;
    cursor: pointer;
`

const MenuContentWrapper = styled.div`
    height: 100%;
`

const MenuContent = styled.div<{ active: boolean }>`
    display: flex;
    flex-direction: row;
    cursor: pointer;
    padding: 10px;
    color: ${(props) => (props.active ? theme.palette.primary.main : undefined)};
`
