import { Blogpost } from '../../../types'
import { postsEN, postsFR, postsNL } from './subComponents/blogpostContent'

export const getBlogPosts = (language: string) => {
    let posts: Blogpost[] = []
    switch (language?.toUpperCase()) {
        case 'NL':
            posts = postsNL
            break
        case 'FR':
            posts = postsFR
            break
        case 'EN':
            posts = postsEN
            break
        default:
            // Default return "NL"
            posts = postsNL
            break
    }
    return posts
}
