import styled from 'styled-components'
import { useState } from 'react'
import OutlinedInput, { OutlinedInputProps } from '@mui/material/OutlinedInput'
import InputLabel, { InputLabelProps } from '@mui/material/InputLabel'
import InputAdornment from '@mui/material/InputAdornment'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'

import { noop, genericFontSize, genericMaxInputWidth } from '../../utils/utils'
import { MUIComponents } from '.'

interface Props extends Omit<OutlinedInputProps, 'size'> {
    errorText?: string
    required?: boolean
    extraLabel?: string
    size?: InputLabelProps['size']
    colorToOverwrite?: string
}

const StyledInputLabel = styled(InputLabel)`
    font-size: ${genericFontSize} !important;
    top: 0px !important;
`

const StyledOutlinedInput = styled(OutlinedInput)`
    font-size: ${genericFontSize} !important;

    & .MuiOutlinedInput-input {
        text-overflow: ellipsis;
    }
`

// TODO: added new prop colorToOverwrite
const StyledFormControl = styled(FormControl)<Props>`
    margin-top: 16px !important;
    margin-bottom: 8px !important;

    & label.Mui-focused {
        color: ${(props) =>
            props.error
                ? props.theme.palette.error.main
                : props.color ?? props.colorToOverwrite ?? props.theme.palette.primary.main};
    }
    & .MuiOutlinedInput-root {
        &.Mui-focused fieldset {
            border-color: ${(props) =>
                props.error
                    ? props.theme.palette.error.main
                    : props.color ?? props.colorToOverwrite ?? props.theme.palette.primary.main};
        }
    }
`

// TODO: added componentSize and size prop
// TODO: updated styling
// TODO: added default value small to size prop
export const HiddenTextField = ({
    onBlur = noop,
    onClick = noop,
    onKeyDown = noop,
    onChange = noop,
    label = null,
    required = false,
    size = 'small',
    ...props
}: Props) => {
    const [showValue, setShowValue] = useState(false)
    const componentSize = size === 'small' ? 'small' : 'medium'

    return (
        <StyledFormControl
            sx={{ width: '100%', maxWidth: genericMaxInputWidth }}
            required={required}
            variant="outlined"
            error={props.error}
        >
            <StyledInputLabel size={size} htmlFor="outlined-adornment-password">
                {label}
            </StyledInputLabel>
            <StyledOutlinedInput
                id="outlined-adornment-password"
                type={showValue ? 'text' : 'password'}
                onBlur={onBlur}
                onChange={onChange}
                onClick={onClick}
                onKeyDown={onKeyDown}
                size={componentSize}
                endAdornment={
                    <InputAdornment position="end">
                        <MUIComponents.Buttons.IconButton
                            aria-label="toggle password visibility"
                            onClick={(e) => {
                                setShowValue(!showValue)
                            }}
                            onMouseDown={(e) => {
                                e.preventDefault()
                            }}
                            edge="end"
                        >
                            {showValue ? (
                                <MUIComponents.Icons.VisibilityOffIcon />
                            ) : (
                                <MUIComponents.Icons.VisibilityIcon />
                            )}
                        </MUIComponents.Buttons.IconButton>
                    </InputAdornment>
                }
                label={label}
            />
            {props.error && props.errorText && <FormHelperText>{props.errorText}</FormHelperText>}
        </StyledFormControl>
    )
}

export const TextFieldWithLabel = ({
    onBlur = noop,
    onChange = noop,
    onClick = noop,
    onKeyDown = noop,
    label = null,
    required = false,
    size = 'small',
    ...props
}: Props) => {
    const componentSize = size === 'small' ? 'small' : 'medium'

    return (
        <StyledFormControl
            sx={{ width: '100%', maxWidth: genericMaxInputWidth }}
            required={required}
            variant="outlined"
            error={props.error}
        >
            <StyledInputLabel size={size} htmlFor="outlined-adornment-password">
                {label}
            </StyledInputLabel>
            <StyledOutlinedInput
                id="outlined-adornment-password"
                type="text"
                onBlur={onBlur}
                onChange={onChange}
                onClick={onClick}
                onKeyDown={onKeyDown}
                size={componentSize}
                endAdornment={
                    <InputAdornment position="end">
                        <StyledInputLabel htmlFor="outlined-adornment-password">
                            {props.extraLabel}
                        </StyledInputLabel>
                    </InputAdornment>
                }
                label={label}
                {...props}
            />
            {props.error && props.errorText && <FormHelperText>{props.errorText}</FormHelperText>}
        </StyledFormControl>
    )
}

// Example of the TextFieldWithLabel component with props:
{
    /* <MUIComponents.TextFields.TextFieldWithLabel
                            label="Voornaam"
                            onChange={(e) => setEmployeeData({...employeeData, first_name: e.target.value})}
                            size="small"
                            value={employeeData?.first_name ?? ''}
                            disabled={false}
                            required={false}
                        /> */
}

// Example of the HiddenTextField component with props:
{
    /* <MUIComponents.TextFields.HiddenTextField
                            id="password"
                            label="Wachtwoord"
                            name="password"
                            type="password"
                            required
                            autoFocus={false}
                            autoComplete="current-password"
                            value={password.value}
                            error={password.error}
                            onKeyDown={(e) => {
                                if (['Enter', 'NumpadEnter'].includes(e.code || e.key)) {
                                    handleSubmit()
                                }
                            }}
                            onChange={(e) => {
                                if (e.target.value) {
                                    setPassword({ value: e.target.value, error: false })
                                }
                            }}
                            {...(password.error && {
                                errorText: 'Het invullen van uw wachtwoord is verplicht.'
                            })}
                        /> */
}
