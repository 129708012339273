import MuiPopover, { PopoverProps } from '@mui/material/Popover'

import { noop } from '../../utils/utils'

export const Popover = ({
    anchorEl,
    onClose = noop,
    open,
    id = 'simple-popover',
    anchorOrigin = { vertical: 'top', horizontal: 'left' },
    elevation = 8,
    transitionDuration = 'auto',
    ...props
}: PopoverProps) => (
    <MuiPopover
        id={id}
        open={Boolean(anchorEl) ?? open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={anchorOrigin}
        elevation={elevation}
        transitionDuration={transitionDuration}
        {...props}
    >
        {props.children}
    </MuiPopover>
)

// Example of this component with props and a button:
// STATE:
// const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
// const open = Boolean(anchorEl)
{
    /* <MUIComponents.Buttons.Button
                aria-describedby={open ? 'simple-popover' : undefined}
                variant="contained"
                onClick={(event) => {
                    setAnchorEl(event.currentTarget)
                }}
            >
                Open Popover
            </MUIComponents.Buttons.Button>
            <MUIComponents.Popover
                id={open ? 'simple-popover' : undefined}
                open={open}
                anchorEl={anchorEl}
                onClose={() => {
                    setAnchorEl(null)
                }}
            >
                <MUIComponents.Typography sx={{ p: 2 }}>
                    The content of the Popover.
                </MUIComponents.Typography>
            </MUIComponents.Popover> */
}
