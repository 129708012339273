import { atom } from 'recoil'

import { AlertObjectProps, SimulatorValues } from '../types'
import { Dealer, Language, City } from '../types/GQLTypes'

export const userProfileState = atom<Record<string, unknown>>({
    key: 'UserProfile',
    default: {}
})

export const userObjectState = atom<Record<string, unknown>>({
    key: 'UserObject',
    default: {}
})

export const languagesState = atom<Language[]>({
    key: 'languages',
    default: [
        { Id: '1', name: 'Nederlands', code: 'NL' },
        { Id: '2', name: 'Français', code: 'FR' },
        { Id: '3', name: 'Deutsch', code: 'DE' },
        { Id: '4', name: 'English', code: 'EN' }
    ]
})

export const dealersState = atom<Dealer[]>({
    key: 'dealers',
    default: []
})

export const alertObjState = atom<AlertObjectProps>({
    key: 'alertObj',
    default: {},
    dangerouslyAllowMutability: true
})

export const simulatorObjectState = atom<SimulatorValues>({
    key: 'simulatorObject',
    default: {
        accessoriesBudget: 0,
        bikeBrand: undefined,
        bikeType: undefined,
        bikeBudget: 0,
        // extraOptions: [],
        defaultOptions: [],
        company: {},
        employeeStatute: {},
        errorOptions: {}
    }
})

export const dealerZipFromHomeState = atom<string>({
    key: 'dealerZipFromHomeState',
    default: '',
    dangerouslyAllowMutability: true
})

export const defaultCity = {
    zip_code: '',
    city_name_nl: 'Selecteer een stad/gemeente',
    city_name_de: 'Sélectionnez une ville/village',
    city_name_fr: 'Wählen Sie eine Stadt/Gemeinde aus',
    lat: 50.8427501,
    long: 4.351549900000009
}

export const dealerSearcherState = atom<City>({
    key: 'dealerSearcherState',
    default: defaultCity,
    dangerouslyAllowMutability: true
})

export const citiesState = atom<City[]>({
    key: 'citiesState',
    default: [defaultCity]
})

export const myProfileState = atom<boolean>({
    key: 'myProfile',
    default: false,
    dangerouslyAllowMutability: true
})

export const loadingDownloadState = atom<boolean>({
    key: 'loadingDownload',
    default: false,
    dangerouslyAllowMutability: true
})
