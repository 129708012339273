import {
    DatePicker as MuiDatePicker,
    DatePickerProps as MuiDatePickerProps
} from '@mui/x-date-pickers/DatePicker'

import { noop } from '../../utils/utils'
import { BaseTextFieldProps } from './types'
import theme from '../../theme/theme'

interface DatepickerProps<T> extends Omit<MuiDatePickerProps<Date>, 'renderInput'> {
    // you can declare custom props in here
    required?: boolean
    color?: BaseTextFieldProps['color']
    colorToOverwrite?: string
}

// TODO: updated InputLabelProps of TextField
// TODO: updated renderInput
export const DatePicker = ({
    onChange = noop,
    value,
    autoFocus = false,
    format = 'dd/MM/yyyy',
    required = false,
    ...props
}: DatepickerProps<Date>) => (
    <MuiDatePicker
        {...props}
        onChange={onChange}
        value={value}
        sx={{
            height: '40px',
            width: '100%',
            color: theme.palette.primary.main + ' !important',

            '& .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused': {
                color: theme.palette.primary.main + ' !important'
            },

            '& .MuiFormLabel-root-MuiInputLabel-root.Mui-focused': {
                color: theme.palette.primary.main + ' !important'
            },

            '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.primary.main + ' !important'
            }
        }}
        format={format}
        autoFocus={autoFocus}
        label={`${props?.label ?? ''} ${required ? '*' : ''}`}
        slotProps={{
            textField: {
                size: 'small',
                error: false
            }
        }}
    />
)

// Example of this component with props:
{
    /* <MUIComponents.DatePicker
                            label="Geboortedatum"
                            onChange={(date) => {
                                if (date) {
                                    setEmployeeData({...employeeData, date_of_birth: Date.parse(date.toDateString()).toString()})
                                }
                            }}
                            value={new Date(Number(employeeData?.date_of_birth ?? new Date().getTime()))}
                            disabled={loading || disabled}
                            required={requiredFields.includes('date_of_birth')}
                        /> */
}
