import styled, { css, CSSProperties } from 'styled-components'
import { useTranslation } from 'react-i18next'

import theme from '../../../theme/theme'
import { MUIComponents } from '../../muiComponents'
import { Quotation, Maybe } from '../../../types/GQLTypes'
import { stringDefaulter } from '../../../utils/utils'
import { DeviceParameters, TranslatorFunction } from '../../../types'
import StyledComponents from '../../StyledComponents'

const loadingSkeletons = (dividerStyling: CSSProperties) => (
    <Wrapper>
        {[...Array(2)].map((_, i) => (
            <MUIComponents.Skeleton
                sx={{ margin: 0, marginBottom: 2 }}
                width={'100%'}
                height={20}
                key={i}
                variant="rectangular"
            />
        ))}
        <MUIComponents.Styling.Divider sx={{ ...dividerStyling, borderColor: '#BDBDBD' }} />
    </Wrapper>
)

export const Calculation = ({
    isMob = false,
    isSmallerThanMob = false,
    isTablet = false,
    ...props
}: DeviceParameters & {
    quotation?: Maybe<Quotation>
}) => {
    const { t } = useTranslation()

    const isNotBigScreen = isMob || isTablet || isSmallerThanMob

    return (
        <>
            {!isNotBigScreen && (
                <MUIComponents.Typography
                    sx={{
                        color: theme.palette.primary.main,
                        fontWeight: 'bold',
                        padding: '30px',
                        textAlign: 'left'
                    }}
                    variant="body2"
                >
                    {t('filesAndCalc.calculationContainer.title')}
                </MUIComponents.Typography>
            )}

            {!isNotBigScreen && (
                <InfoWrapper>
                    {props?.quotation
                        ? getTotalFields(
                              {
                                  quotation: props.quotation,
                                  isMob: isMob,
                                  isSmallerThanMob: isSmallerThanMob
                              },
                              t
                          )
                        : loadingSkeletons({
                              marginTop: '-5px'
                          })}
                </InfoWrapper>
            )}

            <InfoWrapper
                style={{ width: !isNotBigScreen ? undefined : '100%', minHeight: 'fit-content' }}
            >
                {isNotBigScreen &&
                    (props?.quotation
                        ? getTotalFields(
                              {
                                  quotation: props.quotation,
                                  isMob: isMob,
                                  isSmallerThanMob: isSmallerThanMob
                              },
                              t
                          )
                        : loadingSkeletons({
                              borderWidth: 1,
                              marginBottom: '5px'
                          }))}

                <MUIComponents.Styling.Divider
                    sx={{
                        borderColor: '#BDBDBD',
                        marginBottom: '5px',
                        marginTop: props?.quotation ? '-5px' : undefined,
                        width: '100%'
                    }}
                />
                {props?.quotation
                    ? [
                          {
                              label: t('filesAndCalc.calculationContainer.exchange'),
                              value: stringDefaulter(
                                  props?.quotation?.salary_swap,
                                  'currencyFormat'
                              )
                          },
                          {
                              label: t('filesAndCalc.calculationContainer.insurance'),
                              value: stringDefaulter(
                                  props?.quotation?.insurance_amount,
                                  'currencyFormat'
                              )
                          },
                          {
                              label: t('filesAndCalc.calculationContainer.maintenance'),
                              value: stringDefaulter(
                                  props?.quotation?.maintenance_amount,
                                  'currencyFormat'
                              )
                          },
                          {
                              label: t('filesAndCalc.calculationContainer.assistance'),
                              value: stringDefaulter(
                                  props?.quotation?.assistance_amount,
                                  'currencyFormat'
                              )
                          },
                          {
                              label: t('filesAndCalc.calculationContainer.warranty'),
                              value: stringDefaulter(
                                  props?.quotation?.warranty_grant,
                                  'currencyFormat'
                              )
                          },
                          {
                              label: t('filesAndCalc.calculationContainer.totalIncl'),
                              value: stringDefaulter(
                                  props.quotation?.total_vat_included,
                                  'currencyFormat'
                              )
                          },
                          {
                              label: t('filesAndCalc.calculationContainer.totalExcl'),
                              value: stringDefaulter(
                                  props.quotation?.total_vat_excluded,
                                  'currencyFormat'
                              )
                          },
                          {
                              label: t('filesAndCalc.calculationContainer.residual'),
                              value: stringDefaulter(
                                  props.quotation?.residual_value,
                                  'currencyFormat'
                              )
                          }
                      ].map((info, i) => (
                          <Wrapper key={i}>
                              <Row
                                  style={{ paddingTop: i === 0 ? '15px' : undefined }}
                                  isMob={isMob}
                                  isSmallerThanMob={isSmallerThanMob}
                              >
                                  <MUIComponents.Typography
                                      sx={{
                                          fontSize: 'small',
                                          color: 'rgba(0,0,0,0.6)',
                                          textAlign: 'left',
                                          width: '50%',
                                          ...StyledComponents.helper.truncatedText
                                      }}
                                      variant="body2"
                                  >
                                      {info.label}
                                  </MUIComponents.Typography>

                                  <MUIComponents.Typography
                                      sx={{
                                          paddingBottom: '5px',
                                          textAlign: 'right',
                                          width: '50%',
                                          ...StyledComponents.helper.truncatedText
                                      }}
                                      variant="body2"
                                  >
                                      {info.value}
                                  </MUIComponents.Typography>
                              </Row>

                              <MUIComponents.Styling.Divider
                                  sx={{ borderColor: '#BDBDBD', marginBottom: '5px' }}
                              />
                          </Wrapper>
                      ))
                    : [...Array(5)].map((_, i) => (
                          <MUIComponents.Skeleton
                              sx={{
                                  margin: 0,
                                  marginBottom: 2,
                                  marginTop: i === 0 ? '15px' : undefined
                              }}
                              width={'100%'}
                              height={20}
                              key={i}
                              variant="rectangular"
                          />
                      ))}
            </InfoWrapper>
        </>
    )
}

const getTotalFields = (
    props: DeviceParameters & {
        quotation?: Maybe<Quotation>
    },
    t: TranslatorFunction
) =>
    [
        {
            label: t('filesAndCalc.calculationContainer.leaseExcl'),
            value: stringDefaulter(props?.quotation?.lease_amount, 'currencyFormat')
        },
        {
            label: t('filesAndCalc.calculationContainer.leaseIncl'),
            value: stringDefaulter(props?.quotation?.lease_amount_vat, 'currencyFormat')
        }
    ].map((info, i) => (
        <Wrapper key={i}>
            <Row isMob={props.isMob} isSmallerThanMob={props.isSmallerThanMob}>
                <MUIComponents.Typography
                    sx={{
                        fontSize: 'small',
                        fontWeight: 'bold',
                        textAlign: 'left',
                        width: '50%',
                        ...StyledComponents.helper.truncatedText
                    }}
                    variant="body2"
                >
                    {info.label}
                </MUIComponents.Typography>

                <MUIComponents.Typography
                    sx={{
                        paddingBottom: '5px',
                        fontWeight: 'bold',
                        textAlign: 'right',
                        width: '50%',
                        ...StyledComponents.helper.truncatedText
                    }}
                    variant="body2"
                >
                    {info.value}
                </MUIComponents.Typography>
            </Row>

            <MUIComponents.Styling.Divider sx={{ borderColor: '#BDBDBD', marginBottom: '5px' }} />
        </Wrapper>
    ))

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
`

const Row = styled.div<DeviceParameters>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 2px 0;

    ${(props) =>
        (props.isMob || props.isSmallerThanMob) &&
        css`
            width: calc(100% - 15px);
        `}
`

const InfoWrapper = styled.div<{ padding?: CSSProperties['padding'] }>`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    padding: ${(props) => props.padding ?? '0 30px'};
`
