import SnazzyInfoWindow from 'snazzy-info-window'
import { MarkerClusterer } from '@googlemaps/markerclusterer'

import { City, Dealer } from '../../../types/GQLTypes'
import { stringDefaulter } from '../../../utils/utils'
import maps from './googleMapsStyles'

interface Position {
    lat: Dealer['latitude']
    lng: Dealer['longitude']
}

const goToPosition = (position: Position, map: google.maps.Map) => {
    const bounds = new window.google.maps.LatLngBounds()
    const userPos = new window.google.maps.LatLng(position.lat as number, position.lng as number)

    // add user location and thee nearest stores in bounds
    bounds.extend(userPos)

    map.setCenter(userPos)
    map.fitBounds(bounds)
    map.setZoom(15)

    console.log(position, map, bounds, userPos)
}

const initialize = (position: Position, dealers: Dealer[], cities: City[]) => {
    const mapCanvas = document.getElementById('google_maps') as HTMLElement
    const mapOptions: google.maps.MapOptions = {
        center: new google.maps.LatLng(position.lat ?? 0, position.lng ?? 0),
        zoom: 12,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        scrollwheel: true,
        mapTypeControl: true,
        streetViewControl: true,
        zoomControl: true,
        zoomControlOptions: {
            position: google.maps.ControlPosition.RIGHT_BOTTOM
        },
        styles: maps.mapsStyling
    }

    const map = new google.maps.Map(mapCanvas, mapOptions)
    const allInfoWindows: SnazzyInfoWindow[] = []

    /*cities.forEach((city, i) => {
        const pinCard = document.getElementById(`autocomplete-select-city-option-${i}`)

        if (pinCard) {
            pinCard.onclick = () => {
                goToPosition({ lat: city.lat, lng: city.long }, map)
            }
        }
    })*/

    const markers: google.maps.Marker[] = []

    dealers.forEach((dealer) => {
        let info: SnazzyInfoWindow | null = null
        let marker: google.maps.Marker | null = null

        marker = addMarker({ lat: dealer.latitude, lng: dealer.longitude }, map)
        info = addInfoMarker(marker, dealer)

        allInfoWindows.push(info)

        google.maps.event.addListener(marker, 'click', () => {
            allInfoWindows.forEach((infoWindow) => {
                infoWindow?.close()
            })

            info?.open()
        })

        const pinCard = document.getElementById(`Card_pin_${dealer?.id}`)

        if (pinCard) {
            pinCard.onclick = () => {
                goToPosition({ lat: dealer.latitude, lng: dealer.longitude }, map)

                allInfoWindows.forEach((infoWindow) => {
                    infoWindow?.close()
                })

                info?.open()
            }
        }

        markers.push(marker)
    })

    // Add a marker clusterer to manage the markers.
    new MarkerClusterer({ markers, map })
}

const addMarker = (location: Position, map: google.maps.Map) =>
    new google.maps.Marker({
        position: new google.maps.LatLng(location.lat ?? 0, location.lng ?? 0),
        map: map,
        icon: '/img/maps-marker-theme-green.png',
        optimized: true
    })

const addInfoMarker = (
    marker: google.maps.Marker,
    dealer: Dealer & { latitude?: Dealer['latitude']; longitude?: Dealer['longitude'] }
) =>
    new SnazzyInfoWindow({
        marker: marker,
        content: `<div><div style='border-bottom: 1px solid black;'><h5 style='margin: unset; font-size: small;'>${stringDefaulter(
            dealer?.name
        )}</h5><p style='font-size: small; margin: 5px;'>${stringDefaulter(
            dealer?.street
        )}</p><p style='font-size: small; margin: 5px;'>${
            (stringDefaulter(dealer?.zip_code) || stringDefaulter(dealer?.city)) === '/'
                ? '/'
                : stringDefaulter(dealer?.zip_code) + ' ' + stringDefaulter(dealer?.city)
        }</p><p style='font-size: small; margin: 5px;'>${stringDefaulter(
            dealer?.tel
        )}</p><p style='font-size: small; margin: 5px;'>${stringDefaulter(
            dealer?.email
        )}</p></div><div style='padding-top: 10px; display: flex; flex-direction: row; align-items: center; justify-content: space-between;'><div style='display: flex; flex-direction: row; align-items: center;'><i style='color: #95c11f' class='material-icons'>place</i><a style='font-size: small;'>12 km</a></div></div></div>`
    })

export default { initialize }
