import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

import Icons from './Icons'
import Styling from './Styling'
import { Collapse } from './Transitions'
import { IconButton, Button, LoadingButton } from './Button'
import { Alert } from './Alert'
import { Avatar } from './Avatar'
import { Badge } from './Badge'
import { Link } from './Link'
import { Stepper } from './Stepper'
import { Typography } from './Typography'
import { SingleSelect, MultiSelect } from './Select'
import { TextField } from './TextField'
import { HiddenTextField, TextFieldWithLabel } from './CustomTextFields'
import { Loader, LinearLoader } from './Loader'
import { Dialog } from './Dialog'
import { Tooltip } from './Tooltip'
import { List, NestedList } from './List'
import { AutoComplete } from './AutoComplete'
import { Chip } from './Chip'
import { DatePicker } from './DatePicker'
import { Appbar } from './Appbar'
import { Toolbar } from './Toolbar'
import { Menu } from './Menu'
import { MenuItem } from './MenuItem'
import { Input } from './Input'
import { Pagination } from './Pagination'
import { Table } from './Table'
import { Checkbox, CheckboxWithLabel } from './Checkbox'
import { Card } from './Card'
import { Skeleton } from './Skeleton'
import { Accordion } from './Accordion'
import { Breadcrumbs } from './Breadcrumbs'
import { ButtonGroup } from './ButtonGroup'
import { RadioGroup, Radio } from './RadioButton'
import { ImageList } from './ImageList'
import { BottomNavigation } from './BottomNavigation'
import { Slider } from './Slider'
import { Rating, CustomRating, HoverRating } from './Rating'
import { SpeedDial } from './SpeedDial'
import { Fab } from './FloatingActionButton'
import { Popover } from './Popover'
import { Popper } from './Popper'
import { Switch, SwitchWithLabel, SwitchGroup } from './Switch'
import { ToggleButton, ToggleButtonGroup } from './ToggleButton'
import { Tabs } from './Tabs'
import { NumberField } from './NumberField'

// TODO: fix de warnings
// TODO: forwardRef meegeven, zie voorbeeld bij Typography (implementeer het bij de component wanneer het nodig is)
// TODO: added nestedList
// TODO: added NumberField
export const MUIComponents = {
    AutoComplete,
    Buttons: { IconButton, Button, LoadingButton },
    Badge,
    Appbar,
    Alert,
    Avatar,
    Input,
    Chip,
    Link,
    List,
    Typography,
    Styling,
    Stepper,
    Select: { SingleSelect, MultiSelect },
    Icons,
    Tooltip,
    TextFields: {
        TextField,
        HiddenTextField,
        TextFieldWithLabel
    },
    Loader,
    LinearLoader,
    Dialog,
    DatePicker,
    Toolbar,
    Menu,
    MenuItem,
    Pagination,
    Table,
    Checkbox,
    CheckboxWithLabel,
    Card,
    Skeleton,
    Accordion,
    Breadcrumbs,
    ButtonGroup,
    Radio,
    RadioGroup,
    ImageList,
    BottomNavigation,
    Slider,
    Rating,
    CustomRating,
    HoverRating,
    SpeedDial,
    Fab,
    Popover,
    Popper,
    Switch,
    SwitchWithLabel,
    SwitchGroup,
    ToggleButton,
    ToggleButtonGroup,
    Tabs,
    Transitions: { Collapse },
    NestedList,
    NumberField
}

export const MUIProviders = {
    LocalizationProvider
}

export const MUIFns = {
    AdapterDateFns
}

// TODO: all components need some extra styling for mobile
