import MuiSnackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'
import MuiAlertTitle from '@mui/material/AlertTitle'
import styled from 'styled-components'

import { noop } from '../../utils/utils'
import { AlertProps } from './types'

const StyledAlert = styled(MuiAlert)<AlertProps>`
    & .MuiAlert-icon {
        ${(props) => ({ ...props.stylingElements?.icon })}
    }

    & .MuiAlert-message {
        ${(props) => ({ ...props.stylingElements?.message })}
    }
`

export const Alert = ({
    // assign default values here
    open,
    onClose = noop,
    severity = 'success',
    details,
    autoHideDuration = 5000,
    title,
    style = {},
    icon,
    stylingElements = {
        icon: { fontSize: '33px' },
        title: { fontSize: '25px' },
        message: { fontSize: '22px' }
    }
}: AlertProps & { details?: JSX.Element | string }) => (
    <MuiSnackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open}
        autoHideDuration={autoHideDuration}
        onClose={onClose}
        style={style}
    >
        <StyledAlert
            icon={icon}
            variant="filled"
            severity={severity}
            sx={{ width: '100%', ...style }}
            stylingElements={stylingElements}
        >
            <>
                {title && <MuiAlertTitle sx={stylingElements?.title}>{title}</MuiAlertTitle>}
                {details}
            </>
        </StyledAlert>
    </MuiSnackbar>
)

// Example of this component with props:
/* <MUIComponents.Alert
    open={true}
    onClose={() => {
        console.log('close')
    }}
    content={'test content'}
    severity="success"
    title="Title test"
/> */
