import MuiCollapse, { CollapseProps } from '@mui/material/Collapse'

export const Collapse = (props: CollapseProps) => <MuiCollapse {...props} />

// Example of the Collapse component with props:
{
    /* <MUIComponents.Transitions.Collapse in={checked}>
                {icon}
            </MUIComponents.Transitions.Collapse> */
}

{
    /* <MUIComponents.Transitions.Collapse in={checked} collapsedSize={40}>
    {icon}
</MUIComponents.Transitions.Collapse> */
}

// An example of the children component:
// const icon = (
//     <MUIComponents.Styling.Paper sx={{ m: 1 }} elevation={4}>
//         <MUIComponents.Styling.Box component="svg" sx={{ width: 100, height: 100 }}>
//             <MUIComponents.Styling.Box
//                 component="polygon"
//                 sx={{
//                     fill: (theme) => theme.palette.common.white,
//                     stroke: (theme) => theme.palette.divider,
//                     strokeWidth: 1
//                 }}
//                 points="0,100 50,00, 100,100"
//             />
//         </MUIComponents.Styling.Box>
//     </MUIComponents.Styling.Paper>
// )

// STATE: const [checked, setChecked] = useState(false)
// Met een switch bijvoorbeeld om de state te updaten
{
    /* <MUIComponents.Switch
                checked={checked}
                onChange={() => {
                    setChecked((prev) => !prev)
                }}
            /> */
}
