import React from 'react'
import MuiAutocomplete, {
    AutocompleteChangeDetails,
    AutocompleteChangeReason,
    AutocompleteProps as MuiAutocompleteProps
} from '@mui/material/Autocomplete'
import styled from 'styled-components'

import { genericFontSize, noop } from '../../utils/utils'
import { MUIComponents } from '.'

const StyledAutocomplete = styled(MuiAutocomplete)``

type GenericAutoComplete = MuiAutocompleteProps<
    Record<string, string>,
    boolean,
    boolean,
    boolean,
    React.ElementType
>

interface AutocompleteProps extends Omit<GenericAutoComplete, 'renderInput'> {
    // you can declare custom props in here
    id: string
    label: string
    error?: boolean
    errorText?: string
    required?: boolean
    onTextChange?: React.ChangeEventHandler<HTMLInputElement>
    textValue?: string
}

// TODO: added colorToOverwrite to the textfield in renderInput
// TODO: added new prop fullWidth
// TODO: added new prop size
// TODO: updated styling
// TODO: updated minWidth
// TODO: added new prop disableClearable
// TODO: added new prop filterOptions and onInputChange
// TODO: added loading & disabled
// TODO: updated styling and removed colorToOverwrite prop
// TODO: added || null by value for error fixing => uncontrolled to controlled
export const AutoComplete = ({
    onChange = noop,
    required = false,
    disableClearable = false,
    disabled = false,
    loading = false,
    ...props
}: AutocompleteProps) => (
    <StyledAutocomplete
        disabled={disabled}
        loading={loading}
        filterOptions={props.filterOptions as () => unknown[] as () => Record<string, string>[]}
        size={props.size}
        disableClearable={disableClearable}
        fullWidth={props.fullWidth}
        value={props.value || null}
        id={`autocomplete-${props.id}`}
        sx={{
            minWidth: 75,
            '& .MuiInputLabel-root': {
                right: '40px'
            },
            ...props.sx
        }}
        options={props.options}
        getOptionLabel={(option) => (option as Record<string, string>).label}
        renderInput={(params) => (
            <MUIComponents.TextFields.TextField
                {...params}
                autoFocus={false}
                required={required}
                label={props.label}
                InputProps={{ ...params.InputProps, style: { fontSize: genericFontSize } }}
                InputLabelProps={{
                    style: { fontSize: genericFontSize }
                }}
                inputProps={{
                    ...params.inputProps,
                    style: { fontSize: genericFontSize },
                    autoComplete: 'new-password' // disable autocomplete and autofill
                }}
                error={props.error}
                {...(props.error && {
                    helperText: props.errorText
                })}
            />
        )}
        onChange={
            onChange as
                | ((
                      event: React.SyntheticEvent<Element, Event>,
                      value: unknown,
                      reason: AutocompleteChangeReason,
                      details?: AutocompleteChangeDetails<unknown> | undefined
                  ) => void)
                | undefined
        }
    />
)

// Example of this component with props:
{
    /* <MUIComponents.AutoComplete
    error={bikeBrandError}
    errorText="Het selecteren van een fiets merk is verplicht."
    value={bikeBrand}
    label="Fiets merk"
    options={bikeBrands.map((brand) => ({
        value: brand.id ?? '',
        label: brand.name ?? ''
    }))}
    id="select-bike-brand"
    onChange={(e, newValue) => {
        setBikeBrand(newValue as { value: string; label: string })
        setBikeBrandError(!newValue)
        setData({
            ...props.data,
            bike_brand: newValue as { value: string; label: string }
        })

        return
    }}
/> */
}
