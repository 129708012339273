import MuiPopper, { PopperProps } from '@mui/material/Popper'

export const Popper = ({
    anchorEl,
    open,
    id = 'simple-popper',
    placement = 'bottom',
    disablePortal = true,
    ...props
}: PopperProps) => (
    <MuiPopper
        id={id}
        open={Boolean(anchorEl) ?? open}
        anchorEl={anchorEl}
        placement={placement}
        disablePortal={disablePortal}
        {...props}
    >
        {props.children}
    </MuiPopper>
)

// Example of this component with props and a button:
// STATE:
// const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
// const open = Boolean(anchorEl)
{
    /* <MUIComponents.Buttons.Button
                aria-describedby={open ? 'simple-popover' : undefined}
                type="button"
                onClick={(event) => {
                    setAnchorEl(anchorEl ? null : event.currentTarget)
                }}
            >
                Toggle Popper
            </MUIComponents.Buttons.Button>
            <MUIComponents.Popper
                id={open ? 'simple-popper' : undefined}
                open={open}
                anchorEl={anchorEl}
            >
                <MUIComponents.Styling.Box sx={{ border: 1, p: 1, bgcolor: 'background.paper' }}>
                    The content of the Popper.
                </MUIComponents.Styling.Box>
            </MUIComponents.Popper> */
}
