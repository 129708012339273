import styled from 'styled-components'
import { PaperProps as MuiPaperProps } from '@mui/material/Paper'
import { useTranslation } from 'react-i18next'
import { useRecoilState } from 'recoil'

import { TypographyProps } from '../../muiComponents/types'
import { MUIComponents } from '../../muiComponents'
import { DeviceParameters, PossibleLanguages } from '../../../types'
import { userProfileState } from '../../../store/store'
import { getDocuments } from '../../../utils/utils'

export const Copyright = ({
    isMob,
    isTablet,
    isSmallerThanMob,
    paperStyling = {},
    ...props
}: TypographyProps &
    DeviceParameters & {
        paperStyling?: MuiPaperProps['sx']
    }) => {
    const { t } = useTranslation()

    const [userProfile] = useRecoilState(userProfileState)

    let language = userProfile?.language as PossibleLanguages

    if (!language) {
        language = navigator?.languages[1] as PossibleLanguages
    }

    language = language?.toLowerCase() as PossibleLanguages

    return isMob ? (
        <Column>
            <MUIComponents.Styling.Box
                component="img"
                sx={{
                    height: '4vh',
                    display: 'block',
                    margin: '10px auto'
                }}
                src="/img/Logo-B2BIKE.png"
            />
            <Row
                {...(isSmallerThanMob && {
                    justifyContent: 'space-evenly'
                })}
            >
                <MUIComponents.Typography variant="body2" {...props}>
                    {`${t('copyRight.title')} © `}
                    {new Date().getFullYear()}
                </MUIComponents.Typography>
                <MUIComponents.Link
                    sx={{
                        ...props.sx,
                        ...(isMob || isSmallerThanMob ? { margin: 'unset' } : undefined)
                    }}
                    href="https://www.b2bike.be/"
                    target="_blank"
                >
                    B2Bike
                </MUIComponents.Link>
                <MUIComponents.Typography variant="body2" {...props}>
                    {t('copyRight.rights')}
                </MUIComponents.Typography>
            </Row>
            <Row>
                <MUIComponents.Link
                    sx={{
                        ...props.sx
                    }}
                    href={getDocuments(t, t('copyRight.privacy'), language)}
                    target="_blank"
                >
                    {t('copyRight.privacy')}
                </MUIComponents.Link>
                <MUIComponents.Link
                    sx={{
                        ...props.sx
                    }}
                    href={getDocuments(t, t('copyRight.terms'), language)}
                    target="_blank"
                >
                    {t('copyRight.terms')}
                </MUIComponents.Link>
            </Row>
        </Column>
    ) : (
        <MUIComponents.Styling.Paper
            elevation={10}
            sx={{
                display: 'flex',
                flexDirection: 'row',
                margin: '10px 50px',
                padding: '10px',
                height: '4vh',
                width: '100%',
                ...(isTablet && { marginBottom: '32px' }),
                ...paperStyling
            }}
        >
            <LeftWrapper>
                <MUIComponents.Styling.Box
                    component="img"
                    sx={{
                        height: '4vh',
                        display: 'block'
                    }}
                    src="/img/Logo-B2BIKE.png"
                />
                <MUIComponents.Typography
                    variant="body2"
                    {...props}
                    sx={{ ...props.sx, width: isTablet ? '25%' : undefined }}
                >
                    {'Copyright © '}
                    {new Date().getFullYear()}
                </MUIComponents.Typography>
                <MUIComponents.Link
                    sx={{ ...props.sx }}
                    target="_blank"
                    href="https://www.b2bike.be/"
                >
                    B2Bike
                </MUIComponents.Link>
                <MUIComponents.Typography
                    variant="body2"
                    {...props}
                    sx={{ ...props.sx, width: isTablet ? '25%' : undefined }}
                >
                    {t('copyRight.rights')}
                </MUIComponents.Typography>
            </LeftWrapper>
            <RightWrapper>
                <MUIComponents.Link
                    sx={{
                        ...props.sx
                    }}
                    href={getDocuments(t, t('copyRight.privacy'), language)}
                    target="_blank"
                >
                    {t('copyRight.privacy')}
                </MUIComponents.Link>
                <MUIComponents.Link
                    sx={{
                        ...props.sx
                    }}
                    href={getDocuments(t, t('copyRight.terms'), language)}
                    target="_blank"
                >
                    {t('copyRight.terms')}
                </MUIComponents.Link>
            </RightWrapper>
        </MUIComponents.Styling.Paper>
    )
}

const MainWrapper = styled.div`
    display: flex;
    flex-direction: row;
`

const LeftWrapper = styled(MainWrapper)`
    justify-content: flex-start;
    width: 70%;
`

const RightWrapper = styled(MainWrapper)`
    justify-content: flex-end;
    width: 30%;
`

const Row = styled.div<{ justifyContent?: string }>`
    display: flex;
    flex-direction: row;
    justify-content: ${(props) => props.justifyContent ?? 'center'};
    width: 100%;
    flex-wrap: wrap;
`

const Column = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 32px;
    margin-bottom: 32px;
`
