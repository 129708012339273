import styled, { css, CSSProperties } from 'styled-components'
import { useRecoilState } from 'recoil'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { useTranslation } from 'react-i18next'

import { MUIComponents } from '../../../muiComponents'
import { alertObjState, simulatorObjectState, userProfileState } from '../../../../store/store'
import theme from '../../../../theme/theme'
import { PDFDocument } from './PDFDocument'
import { headers, mappingCalculationFields, stringDefaulter } from '../../../../utils/utils'
import API from '../../../../API'
import { DeviceParameters, Item } from '../../../../types'
import useDeviceDetection from '../../../../hooks/useDeviceDetection'
import StyledComponents from '../../../StyledComponents'
import { Employee } from '../../../../types/GQLTypes'

export const Summary = (props: { fieldsData: Employee | undefined }) => {
    const { t } = useTranslation()

    //global states
    const [userProfile] = useRecoilState(userProfileState)
    const [_, setAlertObject] = useRecoilState(alertObjState)
    const [simulatorValues] = useRecoilState(simulatorObjectState)
    const [calcSheetData, setCalcSheetData] = useState<(Item | undefined | number)[]>([
        ...new Array(10)
    ])

    const [calcSheetDataLease, setCalcSheetDataLease] = useState<(Item | undefined | number)[]>([
        ...new Array(2)
    ])

    // state
    const [loading, setLoading] = useState(true)

    // hooks
    const [devices] = useDeviceDetection()

    const parseOptions = () => {
        const options = props.fieldsData?.company?.companyFields?.filter(cf => [
            'BIKE_DESCRIPTION',
            'BIKE_VALUE',
            'PARTS_DESCRIPTION',
            'LOOSE_PARTS_VALUE',
            'CLOTHING',
            'VALUEBIKE_NOCLOTHING',
            'CLOTHING_DESCRIPTION',
        ].includes(cf!.field_type!.code!)).map(cf => {
            let value: string | number  = ''
            if (['BIKE_VALUE', 'VALUEBIKE_NOCLOTHING'].includes(cf!.field_type!.code!)) value = simulatorValues.bikeBudget as number
            if (['LOOSE_PARTS_VALUE'].includes(cf!.field_type!.code!)) value = simulatorValues.accessoriesBudget as number
            return {
                name: cf?.name,
                value: value,
                code: cf?.field_type?.code,
                index: cf?.index
            }
        }) ?? []
        return [...options, ...simulatorValues.defaultOptions]
    }

    const calcSheetLeaseFields = ['Leasebedrag excl. BTW', 'Leasebedrag incl. BTW']

    const sheetName = `${t('simulation.summary.fileName')} - ${
        simulatorValues?.bikeBrand?.label
    } - ${simulatorValues.bikeType?.label ?? '...'}`

    useEffect(() => {
        const fetchCalculationSheetData = async () => {
            const allOptions = parseOptions()
            try {
                const result: { data: { data: { [key: string]: string | number } } } = await axios({
                    method: 'post',
                    url: API.utils.apiUrlsEnv.createCalculationSheet,
                    data: {
                        companyFolderId: simulatorValues.company?.folder_id,
                        companyCalculationSheet:
                            simulatorValues.company?.calculation_sheet ??
                            simulatorValues.company?.parentCompany?.calculation_sheet,
                        companyName: simulatorValues.company?.name,
                        employeeName: `${userProfile?.first_name} ${userProfile?.last_name}`,
                        employeeStatuteName: simulatorValues.employeeStatute?.name,
                        bikeTypeName: simulatorValues.bikeType?.label,
                        sheetName: `${sheetName}`,
                        options: allOptions
                    },
                    headers
                })

                if (result.data.data) {
                    const dataToShow = Object.entries(result.data.data).map(
                        (item: [string, string | number]) => {
                            if (mappingCalculationFields[item[0]] !== undefined) {
                                return {
                                    label: mappingCalculationFields[item[0]],
                                    value: stringDefaulter(item[1], 'currencyFormat') as string
                                }
                            }

                            return
                        }
                    )

                    setCalcSheetData(
                        dataToShow
                            .filter((item) => item)
                            .filter(
                                (item) => item?.label && !calcSheetLeaseFields.includes(item?.label)
                            )
                    )

                    setCalcSheetDataLease(
                        dataToShow
                            .filter((item) => item)
                            .filter(
                                (item) => item?.label && calcSheetLeaseFields.includes(item?.label)
                            )
                    )
                }

                setLoading(false)
            } catch (err) {
                setAlertObject({
                    message: t('simulation.summary.creationCalcSheetErr'),
                    severity: 'error'
                })
                setLoading(false)
            }
        }

        if (simulatorValues.bikeType?.label && simulatorValues.bikeBrand?.label) {
            fetchCalculationSheetData()
        } else {
            setLoading(false)
        }
    }, [])

    return (
        <MUIComponents.Styling.Paper
            elevation={5}
            style={{
                padding: 25,
                height: 'calc(100% - 50px)',
                width: 'calc(50% - 50px)',
                position: 'relative',
                ...((devices.isMob || devices.isSmallerThanMob) && {
                    padding: 'unset',
                    boxShadow: 'unset',
                    width: '100%',
                    height: 'fit-content',
                    paddingTop: '10px'
                })
            }}
        >
            <MUIComponents.Typography
                sx={{
                    textAlign: 'left',
                    mb: 3,
                    color: theme.palette.primary.main,
                    ...StyledComponents.helper.truncatedText
                }}
                variant="h5"
            >
                {loading ? t('simulation.summary.loading') : t('simulation.summary.title')}
                <MUIComponents.Buttons.IconButton
                    aria-label="toggle disclaimer visibility"
                    onClick={(e) => {
                        return
                    }}
                    onMouseDown={(e) => {
                        e.preventDefault()
                    }}
                    edge="end"
                    tooltip={{
                        content: t('simulation.summary.disclaimer')
                    }}
                    sx={{ marginRight: 'unset' }}
                >
                    <MUIComponents.Icons.HelpIcon />
                </MUIComponents.Buttons.IconButton>
                {loading && <MUIComponents.LinearLoader />}
            </MUIComponents.Typography>
            <InfoWrapper
                isWeb={devices.isWeb}
                isMob={devices.isMob}
                isSmallerThanMob={devices.isSmallerThanMob}
            >
                {calcSheetData.map((item, i) => (
                    <FieldWrapper key={i}>
                        {loading ? (
                            <MUIComponents.Skeleton
                                width="100%"
                                height={35}
                                variant="rectangular"
                            />
                        ) : (
                            <FieldRow>
                                <MUIComponents.Typography
                                    sx={{
                                        fontSize: 'small',
                                        color: 'rgba(0,0,0,0.6)',
                                        textAlign: 'left',
                                        ...StyledComponents.helper.truncatedText
                                    }}
                                    variant="body2"
                                >
                                    {(item as Item)?.label}
                                </MUIComponents.Typography>
                                <MUIComponents.Typography
                                    sx={{
                                        paddingBottom: '5px',
                                        textAlign: 'left',
                                        ...StyledComponents.helper.truncatedText
                                    }}
                                    variant="body2"
                                >
                                    {(item as Item)?.value}
                                </MUIComponents.Typography>
                            </FieldRow>
                        )}
                        {(item as Item)?.label && (
                            <MUIComponents.Styling.Divider
                                sx={{ borderColor: '#BDBDBD', marginBottom: '5px' }}
                            />
                        )}
                    </FieldWrapper>
                ))}

                {calcSheetDataLease.map((item, i) => (
                    <FieldWrapper key={i}>
                        {loading ? (
                            <>
                                {(item as Item)?.label && i === 0 && (
                                    <MUIComponents.Styling.Divider
                                        sx={{ borderColor: '#BDBDBD', marginTop: '-5px' }}
                                    />
                                )}
                                <MUIComponents.Skeleton
                                    width="100%"
                                    height={35}
                                    variant="rectangular"
                                />
                            </>
                        ) : (
                            <>
                                {(item as Item)?.label && i === 0 && (
                                    <MUIComponents.Styling.Divider
                                        sx={{ borderColor: '#BDBDBD', marginTop: '-5px' }}
                                    />
                                )}
                                <FieldRow {...(i === 0 && { margin: '10px 0 0 0' })}>
                                    <MUIComponents.Typography
                                        sx={{
                                            fontSize: 'small',
                                            color: 'rgba(0,0,0,0.6)',
                                            textAlign: 'left',
                                            fontWeight: 'bold',
                                            ...StyledComponents.helper.truncatedText
                                        }}
                                        variant="body2"
                                    >
                                        {(item as Item)?.label}
                                    </MUIComponents.Typography>
                                    <MUIComponents.Typography
                                        sx={{
                                            paddingBottom: '5px',
                                            textAlign: 'left',
                                            fontSize: 'small',
                                            fontWeight: 'bold',
                                            ...StyledComponents.helper.truncatedText
                                        }}
                                        variant="body2"
                                    >
                                        {(item as Item)?.value}
                                    </MUIComponents.Typography>
                                </FieldRow>
                            </>
                        )}
                        {(item as Item)?.label && (
                            <MUIComponents.Styling.Divider
                                sx={{ borderColor: '#BDBDBD', marginBottom: '5px' }}
                            />
                        )}
                    </FieldWrapper>
                ))}
            </InfoWrapper>

            {loading ? (
                <MUIComponents.Skeleton
                    height={40}
                    variant="rectangular"
                    {...((devices.isMob || devices.isSmallerThanMob) && {
                        width: 'calc(100% - 20px)',
                        sx: { marginLeft: '10px' }
                    })}
                    {...((devices.isWeb || devices.isTablet) && {
                        width: 'calc(100% - 50px)',
                        sx: {
                            position: 'absolute',
                            bottom: 0,
                            marginBottom: '25px'
                        }
                    })}
                />
            ) : (
                <PDFDownloadLink
                    document={
                        <PDFDocument
                            simulatorValues={{
                                ...simulatorValues
                            }}
                            calcSheetData={calcSheetData}
                            calcSheetDataLease={calcSheetDataLease}
                        />
                    }
                    fileName={sheetName}
                    // ! Is necessary for the style prop display: 'block', without this, the ellipsis is not working correctly
                    // ! The type that is foreseen in the package for display is => 'flex' | 'none'
                    // ! Why not foresee it just as a CSSProperty['display'] and then you can add all the options that are available ...
                    // ! -> anyway, that is the reason the ts-ignore is there
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    style={{
                        textDecoration: 'none',
                        position: 'absolute',
                        bottom: '30px',
                        width: 'calc(100% - 85px)',
                        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                        fontWeight: 500,
                        fontSize: '0.875rem',
                        lineHeight: '1.75',
                        letterSpacing: '0.02857em',
                        textTransform: 'uppercase',
                        padding: '6px 16px',
                        borderRadius: '4px',
                        color: 'rgb(255, 255, 255)',
                        backgroundColor: 'rgb(149, 193, 31)',
                        display: 'block',
                        marginLeft: 'auto',
                        textAlign: 'center',
                        ...StyledComponents.helper.breakupText,
                        ...((devices.isMob || devices.isSmallerThanMob) && {
                            position: 'unset',
                            height: 'fit-content',
                            width: 'unset'
                        })
                    }}
                >
                    {({ loading }) =>
                        loading
                            ? t('simulation.summary.docLoading')
                            : t('simulation.summary.saveSimulation')
                    }
                </PDFDownloadLink>
            )}
        </MUIComponents.Styling.Paper>
    )
}

const FieldWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
`

const FieldRow = styled.div<{ margin?: CSSProperties['margin'] }>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 2px 0;

    ${(props) =>
        props.margin &&
        css`
            margin: ${props.margin};
        `}
`

const InfoWrapper = styled.div<{ padding?: CSSProperties['padding'] } & DeviceParameters>`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    height: calc(100% - 110px);

    ${(props) =>
        props.isWeb &&
        css`
            overflow: auto;
        `}

    ${(props) =>
        (props.isMob || props.isSmallerThanMob) &&
        css`
            overflow: auto;
            height: calc(100% - 100px);
            padding: 10px;
        `}
`
