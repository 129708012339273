import styled from 'styled-components'

import { Maybe } from '../../../types/GQLTypes'
import { MUIComponents } from '../../muiComponents'

export const ContractMessage = () => {
    // TODO: nog echte data gaan ophalen
    return (
        <MUIComponents.Styling.Paper
            style={{ padding: '20px', height: 'calc(100% - 40px)' }}
            elevation={5}
        >
            <MUIComponents.Typography
                variant="h6"
                style={{
                    fontWeight: 'bold',
                    textAlign: 'left',
                    color: 'rgba(0, 0, 0, 0.6)'
                }}
            >
                Je lopend contract
            </MUIComponents.Typography>

            <Wrapper>
                <Label isValue={false}>Omschrijving fiets</Label>
                <Label isValue>Gravelbike Paars</Label>
            </Wrapper>
            <MUIComponents.Styling.Divider />
            <Wrapper>
                <Label isValue={false}>Einddatum</Label>
                <Label isValue>Ongekend</Label>
            </Wrapper>
            <MUIComponents.Styling.Divider />
            <Wrapper>
                <Label isValue={false}>Order status</Label>
                <Label isValue>Getekend</Label>
            </Wrapper>
            <MUIComponents.Styling.Divider />
            <Wrapper>
                <Label isValue={false}>Lease bedrag</Label>
                <Label isValue>€ 124,25</Label>
            </Wrapper>
            <MUIComponents.Styling.Divider />
            <Wrapper>
                <Label isValue={false}>Document</Label>
                <Label isValue>Getekende berekening</Label>
            </Wrapper>

            <MUIComponents.Buttons.Button
                variant="contained"
                fullWidth
                onClick={() => {
                    return
                }}
                sx={{ marginTop: '20px' }}
            >
                Contract openen
            </MUIComponents.Buttons.Button>
        </MUIComponents.Styling.Paper>
    )
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: left;
    padding: 10px 0;
`

const Label = styled.div<{ isValue: boolean }>`
    display: flex;
    align-self: left;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: ${(props) => (props.isValue ? undefined : '12px')};
    color: ${(props) => (props.isValue ? undefined : 'rgba(0, 0, 0, 0.6)')};
`

const getValue = (value: Maybe<string> | undefined) => {
    if (value) return value

    return 'Ongekend'
}
