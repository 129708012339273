import { useLazyQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import styled, { CSSProperties } from 'styled-components'
import { useRecoilState } from 'recoil'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import API from '../../../API'
import theme from '../../../theme/theme'
import { BikeData, DeviceParameters } from '../../../types'
import {
    BikeBrand,
    BikeType,
    QueryBikeBrandArgs,
    QueryBikeTypesArgs
} from '../../../types/GQLTypes'
import { MUIComponents } from '../../muiComponents'
import { simulatorObjectState, alertObjState } from '../../../store/store'
import StyledComponents from '../../StyledComponents'
import { sortArrayByName } from '../../../utils/utils'

export const LeasingSimulation = (props: DeviceParameters) => {
    const { t } = useTranslation()

    const [, setAlertObject] = useRecoilState(alertObjState)

    // global state
    const [simulatorValues, setSimulatorValues] = useRecoilState(simulatorObjectState)

    // states
    const [loading, setLoading] = useState(true)
    const [bikeBudget, setBikeBudget] = useState<number | number[]>(
        (simulatorValues.bikeBudget as number) ?? 0
    )
    const [accessoriesBudget, setAccessoriesBudget] = useState<number | number[]>(
        (simulatorValues.accessoriesBudget as number) ?? 0
    )
    const [bikeTypes, setBikeTypes] = useState<BikeType[]>([])
    const [bikeType, setBikeType] = useState<BikeData['bike_type']>(
        simulatorValues.bikeType ?? undefined
    )
    const [bikeBrands, setBikeBrands] = useState<BikeBrand[]>([])
    const [bikeBrand, setBikeBrand] = useState<BikeData['bike_brand']>(
        simulatorValues.bikeBrand ?? undefined
    )
    const [errors, setErrors] = useState<Record<string, boolean>>({
        bikeBrand: false,
        bikeType: false
    })

    // hook
    const navigate = useNavigate()

    // graphql queries
    const [getBikeTypes] = useLazyQuery<{ bikeTypes: BikeType[] }, QueryBikeTypesArgs>(
        API.Queries.BIKE_TYPES
    )
    const [getBikeBrands] = useLazyQuery<{ bikeBrands: BikeBrand[] }, QueryBikeBrandArgs>(
        API.Queries.BIKE_BRANDS
    )

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)

            try {
                const bikeTypesData = await getBikeTypes()
                const bikeBrandsData = await getBikeBrands()

                if (bikeTypesData?.data?.bikeTypes) {
                    setBikeTypes(bikeTypesData.data.bikeTypes)
                }
                if (bikeBrandsData?.data?.bikeBrands) {
                    setBikeBrands(
                        sortArrayByName({
                            arrayToSort: [...bikeBrandsData.data.bikeBrands],
                            direction: 'asc'
                        })
                    )
                }
            } catch (err) {
                console.log(err)
                setBikeTypes([])
                setBikeBrands([])
                setAlertObject({
                    message: t('home.simulation.alertBikes'),
                    severity: 'error'
                })
            }

            setLoading(false)
        }

        fetchData()
    }, [])

    return (
        <MUIComponents.Styling.Paper
            style={{ padding: '20px', height: 'calc(100% - 40px)', position: 'relative' }}
            elevation={5}
        >
            <MUIComponents.Typography
                variant="h5"
                style={{
                    textAlign: 'left',
                    color: theme.palette.primary.main,
                    ...(props.isSmallerThanMob && { ...StyledComponents.helper.breakupText })
                }}
            >
                {t('home.simulation.title')}
            </MUIComponents.Typography>

            <MUIComponents.Styling.Grid container rowSpacing={2} columnSpacing={2}>
                <MUIComponents.Styling.Grid
                    item
                    xs={12}
                    style={{
                        paddingBottom: 16,
                        display: 'flex',
                        flexDirection: props.isMob || props.isSmallerThanMob ? 'column' : 'row'
                    }}
                >
                    {loading ? (
                        <MUIComponents.Skeleton
                            sx={{ marginTop: '16px' }}
                            width="100%"
                            height={45}
                            variant="rectangular"
                        />
                    ) : (
                        <MUIComponents.AutoComplete
                            id="select-bike-brand"
                            size="small"
                            label={t('home.simulation.brand')}
                            value={bikeBrand}
                            options={bikeBrands.map((brand) => ({
                                value: brand.id ?? '',
                                label: brand.name ?? ''
                            }))}
                            required
                            onChange={(_e, value) => {
                                const newValue = value as {
                                    value: string
                                    label: string
                                }

                                if (newValue?.label === 'Alles') {
                                    setBikeBrand(newValue)
                                    return
                                }

                                setBikeBrand(newValue)

                                if (value) {
                                    setErrors((old) => ({
                                        ...old,
                                        bikeBrand: false
                                    }))
                                }
                            }}
                            sx={{ width: '100%' }}
                            error={errors.bikeBrand}
                        />
                    )}

                    {loading ? (
                        <MUIComponents.Skeleton
                            sx={{ marginTop: '16px' }}
                            width="100%"
                            height={45}
                            variant="rectangular"
                        />
                    ) : (
                        <MUIComponents.Select.SingleSelect
                            onChange={(e) => {
                                const newBikeType = {
                                    value: e.target.value as string,
                                    label:
                                        bikeTypes.find((type) => type.id === e.target.value)
                                            ?.name ?? ''
                                }

                                setBikeType(newBikeType)

                                if (e.target.value) {
                                    setErrors((old) => ({
                                        ...old,
                                        bikeType: false
                                    }))
                                }
                            }}
                            size="small"
                            labelContent={t('home.simulation.type')}
                            value={bikeType?.value}
                            menuItems={bikeTypes.map((type) => ({
                                value: type.id ?? '',
                                label: type.name ?? ''
                            }))}
                            customSelect={false}
                            formStyling={{
                                paddingLeft: props.isMob || props.isSmallerThanMob ? 'unset' : '5px'
                            }}
                            required
                            error={errors.bikeType}
                        />
                    )}
                </MUIComponents.Styling.Grid>
            </MUIComponents.Styling.Grid>

            <MUIComponents.Typography
                variant="subtitle1"
                style={{
                    ...typoGraphyStyling,
                    fontSize: '12px',
                    ...(props.isSmallerThanMob && { ...StyledComponents.helper.breakupText })
                }}
            >
                {t('home.simulation.spendingBike')}
            </MUIComponents.Typography>

            <SliderWithInput isMob={props.isMob} isSmallerThanMob={props.isSmallerThanMob}>
                <MUIComponents.Slider
                    onChange={(_e, newValue) => setBikeBudget(newValue)}
                    value={bikeBudget}
                    size="small"
                    step={100}
                    marks={(props.isMob || props.isSmallerThanMob
                        ? [0, 10000]
                        : [0, 5000, 10000]
                    ).map((amount) => ({
                        label: `€${amount}`,
                        value: amount
                    }))}
                    min={0}
                    max={10000}
                    orientation="horizontal"
                    sx={{ marginTop: '10px !important' }}
                />

                <MUIComponents.NumberField
                    autoFocus={false}
                    value={((bikeBudget as number[])[0] ?? bikeBudget).toString()}
                    size="small"
                    onChange={(e) => setBikeBudget(parseInt(e.target.value))}
                    margin="none"
                    InputProps={{
                        style: {
                            ...((props.isMob || props.isSmallerThanMob) && {
                                marginTop: '10px'
                            }),
                            ...(!(props.isMob || props.isSmallerThanMob) && {
                                sx: { marginLeft: '10px' }
                            })
                        }
                    }}
                    inputProps={{
                        step: 100,
                        min: 0,
                        max: 5000,
                        type: 'number',
                        'aria-labelledby': 'input-slider'
                    }}
                    {...(!(props.isMob || props.isSmallerThanMob) && {
                        sx: { marginLeft: '30px' }
                    })}
                />
            </SliderWithInput>

            <MUIComponents.Typography
                variant="subtitle1"
                style={{
                    ...typoGraphyStyling,
                    fontSize: '12px',
                    ...(props.isSmallerThanMob && { ...StyledComponents.helper.breakupText })
                }}
            >
                {t('home.simulation.spendingAccessories')}
            </MUIComponents.Typography>

            <SliderWithInput isMob={props.isMob} isSmallerThanMob={props.isSmallerThanMob}>
                <MUIComponents.Slider
                    onChange={(_e, newValue) => setAccessoriesBudget(newValue)}
                    value={accessoriesBudget}
                    size="small"
                    step={50}
                    marks={(props.isMob || props.isSmallerThanMob
                        ? [0, 2500]
                        : [0, 1250, 2500]
                    ).map((amount) => ({
                        label: `€${amount}`,
                        value: amount
                    }))}
                    min={0}
                    max={2500}
                    orientation="horizontal"
                    sx={{ marginTop: '10px !important' }}
                />

                <MUIComponents.NumberField
                    autoFocus={false}
                    value={((accessoriesBudget as number[])[0] ?? accessoriesBudget).toString()}
                    size="small"
                    onChange={(e) => setAccessoriesBudget(parseInt(e.target.value))}
                    margin="none"
                    InputProps={{
                        style: {
                            ...((props.isMob || props.isSmallerThanMob) && {
                                marginTop: '10px'
                            }),
                            ...(!(props.isMob || props.isSmallerThanMob) && {
                                sx: { marginLeft: '10px' }
                            })
                        }
                    }}
                    inputProps={{
                        step: 50,
                        min: 0,
                        max: 2500,
                        type: 'number',
                        'aria-labelledby': 'input-slider'
                    }}
                    {...(!(props.isMob || props.isSmallerThanMob) && {
                        sx: { marginLeft: '30px' }
                    })}
                />
            </SliderWithInput>

            <MUIComponents.Buttons.Button
                variant="contained"
                fullWidth={props.isMob || props.isSmallerThanMob ? false : true}
                onClick={() => {
                    setSimulatorValues((old) => ({
                        ...old,
                        bikeType,
                        bikeBrand,
                        accessoriesBudget,
                        bikeBudget
                    }))

                    if (!bikeBrand || !bikeType) {
                        setErrors({
                            bikeBrand: !bikeBrand?.value,
                            bikeType: !bikeType?.value
                        })

                        setAlertObject({
                            message: t('simulation.step1Container.requiredFieldsCheckErr'),
                            severity: 'error'
                        })

                        return
                    }

                    navigate('/simulator')
                }}
                sx={{
                    position: 'absolute',
                    bottom: '20px',
                    width: '90%',
                    margin: 'auto',
                    right: 0,
                    left: 0
                }}
            >
                {t('general.buttons.next')}
            </MUIComponents.Buttons.Button>
        </MUIComponents.Styling.Paper>
    )
}

const typoGraphyStyling: CSSProperties = {
    textAlign: 'left',
    color: 'rgba(0, 0, 0, 0.6)'
}

const SliderWithInput = styled.div<DeviceParameters>`
    display: flex;
    flex-direction: ${(props) => (props.isMob || props.isSmallerThanMob ? 'column' : 'row')};
    height: ${(props) => (props.isMob || props.isSmallerThanMob ? '120px' : undefined)};
`
