import { useEffect, useState } from 'react'
import styled from 'styled-components'

import { useSorting } from '../../../hooks/useSorting'
import theme from '../../../theme/theme'
import { SortDirection } from '../../../types'
import { MUIComponents } from '../../muiComponents'
import StyledComponents from '../../StyledComponents'

// TODO: downloaden van documents als PDF

const getSortingIcon = (direction: SortDirection, sortingEnabled: boolean) => {
    if (!sortingEnabled) {
        return undefined
    }

    switch (direction) {
        case 'asc':
            return <MUIComponents.Icons.KeyboardArrowUpIcon sx={{ marginTop: '8px' }} />
        case 'desc':
            return <MUIComponents.Icons.KeyboardArrowDownIcon sx={{ marginTop: '8px' }} />
        default:
            return undefined
    }
}

export const MyDocuments = () => {
    // state
    const [documentToDownload, setDocumentToDownload] = useState<string | undefined>(undefined)
    const [sorting, setSorting] = useState<{
        enabled: boolean
        key: string
        direction: SortDirection
    }>({
        enabled: true,
        key: '',
        direction: 'asc'
    })
    const [dataToSort, setDataToSort, handleSorting] = useSorting([])
    const [sortingClicked, setSortingClicked] = useState(0)

    useEffect(() => {
        // TODO: fetch the documents
        setDataToSort([
            {
                name: 'Je groene kaart',
                date: '11/13/2022',
                fileId: '1GX5YBCYSAuwRQdkjAJc2bGrSR9a0HlP3eDa7SH_9sAI',
                id: '1'
            },
            {
                name: 'E-bike certificaat',
                date: '01/09/2022',
                fileId: '1GX5YBCYSAuwRQdkjAJc2bGrSR9a0HlP3eDa7SH_9sAI',
                id: '2'
            },
            {
                name: 'Je servicepas',
                date: '02/22/2022',
                fileId: '1GX5YBCYSAuwRQdkjAJc2bGrSR9a0HlP3eDa7SH_9sAI',
                id: '3'
            },
            {
                name: 'E-bike certificaat',
                date: '01/29/2022',
                fileId: '1GX5YBCYSAuwRQdkjAJc2bGrSR9a0HlP3eDa7SH_9sAI',
                id: '4'
            },
            {
                name: 'Je servicepas',
                date: '02/22/2022',
                fileId: '1GX5YBCYSAuwRQdkjAJc2bGrSR9a0HlP3eDa7SH_9sAI',
                id: '5'
            }
        ])
    }, [])

    const handleSort = (key: string) => {
        setSortingClicked(sortingClicked + 1)

        if (sortingClicked === 2) {
            setSortingClicked(0)
            setSorting({ ...sorting, enabled: false })
        }

        if (sortingClicked !== 2) {
            setSorting({
                enabled: true,
                key,
                direction: sorting.direction === 'asc' ? 'desc' : 'asc'
            })
            handleSorting(key, sorting.direction === 'asc' ? 'desc' : 'asc')
        }
    }

    return (
        <MUIComponents.Styling.Paper style={{ padding: '20px' }} elevation={5}>
            <Wrapper>
                <MUIComponents.Typography
                    variant="h6"
                    style={{
                        fontWeight: 'bold',
                        textAlign: 'left',
                        color: 'rgba(0, 0, 0, 0.6)'
                    }}
                >
                    Mijn documenten
                </MUIComponents.Typography>

                <StyledComponents.ItemNavigation
                    onClick={() => {
                        // TODO
                        return
                    }}
                    padding={false}
                >
                    Al je documenten
                </StyledComponents.ItemNavigation>
            </Wrapper>

            <Wrapper>
                <TitleWrapper
                    onClick={() => {
                        handleSort('name')
                    }}
                >
                    <MUIComponents.Typography
                        variant="subtitle1"
                        style={{
                            textAlign: 'left',
                            color: 'rgba(0, 0, 0, 0.6)'
                        }}
                    >
                        Naam
                    </MUIComponents.Typography>
                    {sorting.key === 'name' && getSortingIcon(sorting.direction, sorting.enabled)}
                </TitleWrapper>

                <TitleWrapper
                    onClick={() => {
                        handleSort('date')
                    }}
                >
                    <MUIComponents.Typography
                        variant="subtitle1"
                        style={{
                            textAlign: 'left',
                            color: 'rgba(0, 0, 0, 0.6)'
                        }}
                    >
                        Datum
                    </MUIComponents.Typography>
                    {sorting.key === 'date' && getSortingIcon(sorting.direction, sorting.enabled)}
                </TitleWrapper>
            </Wrapper>

            <MUIComponents.Styling.Divider />

            {dataToSort.map((document, i) => (
                <div key={document.id}>
                    <RowsWrapper
                        onClick={() => {
                            setDocumentToDownload(document.id)
                            window.open(
                                `https://drive.google.com/file/d/${document?.fileId}`,
                                'blank'
                            )
                        }}
                        style={{ cursor: 'pointer' }}
                    >
                        <TitleWrapper>
                            <MUIComponents.Icons.DescriptionIcon
                                sx={{
                                    margin: 'auto',
                                    width: '20px',
                                    height: '20px',
                                    borderRadius: '50px',
                                    padding: '5px',
                                    backgroundColor:
                                        documentToDownload === document.id
                                            ? theme.palette.primary.main
                                            : 'rgb(0, 0, 0, 0.2)'
                                }}
                            />
                            <MUIComponents.Typography
                                variant="subtitle1"
                                style={{
                                    fontWeight: 'bold',
                                    textAlign: 'left',
                                    marginLeft: '10px'
                                }}
                            >
                                {document.name}
                            </MUIComponents.Typography>
                        </TitleWrapper>

                        <MUIComponents.Typography
                            variant="subtitle1"
                            style={{
                                textAlign: 'left'
                            }}
                        >
                            {document.date}
                        </MUIComponents.Typography>
                    </RowsWrapper>
                    {i !== dataToSort.length - 1 && <MUIComponents.Styling.Divider />}
                </div>
            ))}
        </MUIComponents.Styling.Paper>
    )
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: rgba(0, 0, 0, 0.6);
    padding: 10px 0;
`

const RowsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: rgba(0, 0, 0, 0.6);
    padding: 10px;

    &:hover {
        background-color: ${(props) => props.theme.palette.primary.main};
        color: white;
        border-radius: 5px;
    }
`

const TitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    cursor: pointer;
`
