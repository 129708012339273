export default {
    fields: {
        startdate: '12. Startdatum',
        durationMonths: 'DURATIONMONTHS',
        durationTime: '22. Looptijd'
    },
    orderStatuses: {
        cancelled: 'CANCELLED'
    }
}
