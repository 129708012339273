import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

import { MUIComponents } from '../../muiComponents'
import StyledComponents from '../../StyledComponents'

interface ImagesListProps {
    listItems: { [key: string]: string }[]
    padding?: boolean
    margin?: boolean
    orientation?: 'vertical' | 'horizontal'
    imageWidth?: string
    ellipsis?: {
        title?: boolean
        subTitle?: boolean
    }
}

export const ImagesList = (props: ImagesListProps) => {
    const navigate = useNavigate()

    return (
        <ListItemsWrapper padding={props.padding} orientation={props.orientation}>
            {props.listItems.map((listItem, i) => (
                <ListItemsContent
                    key={i}
                    listItems={props.listItems}
                    margin={props.margin}
                    index={i}
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                        // ! Added this to navigate to the top of the page
                        // ! (on small screens -> it's going directly to the half or bottom of the page)
                        window.scrollTo(0, 0)
                        navigate(listItem?.endpoint)
                    }}
                    orientation={props.orientation}
                >
                    <ListImage src={listItem.src} imageWidth={props?.imageWidth} />
                    <MUIComponents.Typography
                        variant="subtitle1"
                        style={{
                            fontWeight: 'bold',
                            textAlign: props.orientation === 'horizontal' ? 'left' : 'center',
                            fontSize: '12px',
                            color: '#575756',
                            ...(props.ellipsis?.title && StyledComponents.helper.truncatedText)
                        }}
                    >
                        {listItem.title}
                    </MUIComponents.Typography>

                    <MUIComponents.Typography
                        variant="body1"
                        style={{
                            textAlign: props.orientation === 'horizontal' ? 'left' : 'center',
                            fontSize: '12px',
                            color: '#7B7B7B',
                            margin: 'unset',
                            ...(props.ellipsis?.subTitle && StyledComponents.helper.truncatedText)
                        }}
                    >
                        {listItem.subTitle}
                    </MUIComponents.Typography>
                </ListItemsContent>
            ))}
        </ListItemsWrapper>
    )
}

const ListImage = styled.img<{ imageWidth?: string }>`
    width: ${(props) => props.imageWidth ?? '100%'};
    display: block;
    margin: auto;
    border-radius: 10px;
    max-width: 300px;
    max-height: 350px;
    min-width: 75px;
    min-height: 40px;
`

const ListItemsWrapper = styled.div<{ padding?: boolean; orientation?: 'vertical' | 'horizontal' }>`
    display: flex;
    flex-direction: ${(props) => (props.orientation === 'vertical' ? 'column' : 'row')};
    padding: ${(props) => (props.padding ? '20px' : undefined)};
`

const ListItemsContent = styled.div<{
    listItems: ImagesListProps['listItems']
    index: number
    margin?: boolean
    orientation?: 'vertical' | 'horizontal'
}>`
    width: ${(props) => (props.orientation === 'horizontal' ? '33%' : '100%')};
    height: calc(100% - 75px);
    margin-bottom: 10px;
    margin-right: ${(props) =>
        props.margin === false
            ? undefined
            : props.index !== props.listItems.length - 1
            ? '50px'
            : undefined};
`
