import styled from 'styled-components'

import { MUIComponents } from '../components/muiComponents'
import { TabProps } from '../components/muiComponents/types'
import StyledComponents from '../components/StyledComponents'
import * as GQLTypes from '../types/GQLTypes'
import { getRandomColor, iconStyling } from './utils'

export const tabProps = (orderLog: GQLTypes.OrderLog | TabProps, i: number) => {
    const label =
        (orderLog as GQLTypes.OrderLog)?.orderLogType?.name ?? (orderLog as TabProps)?.label
    const icon =
        getIconByFileType((orderLog as GQLTypes.OrderLog)?.orderLogType?.name) ??
        (orderLog as TabProps)?.icon
    const fileId = (orderLog as GQLTypes.OrderLog)?.file_id ?? (orderLog as TabProps)?.fileId

    return {
        label: <Label>{label}</Label>,
        id: `${orderLog.id}`,
        ariaControls: `Panel-${i}`,
        wrapped: true,
        disabled: false,
        icon,
        fileId
    }
}

export const getIconByFileType = (fileType: GQLTypes.OrderLogType['name']) => {
    switch (fileType) {
        // mockup icons + labels
        case 'Je groene kaart':
            return <MUIComponents.Icons.AddCardIcon sx={iconStyling(getRandomColor())} />
        case 'E-bike certificaat':
            return <MUIComponents.Icons.WorkspacePremiumIcon sx={iconStyling(getRandomColor())} />
        case 'Je Servicepas':
            return <MUIComponents.Icons.HandymanIcon sx={iconStyling(getRandomColor())} />
        case 'Aansluiting pechhulp':
            return <MUIComponents.Icons.HelpCenterIcon sx={iconStyling(getRandomColor())} />
        case 'Berekeningsoverzicht':
            return <MUIComponents.Icons.CalculateIcon sx={iconStyling(getRandomColor())} />
        // order log type labels + default icon
        case 'Berekening':
        case 'Getekende berekening':
        case 'Ontvangstbewijs':
        case 'Getekend ontvangstbewijs':
        case 'Offerte van dealer':
        case 'Factuur van dealer':
        case 'Overige':
        case 'COC document':
        case 'Document 705':
        case 'Digitaal ondertekende berekening':
        case 'Innamedocument':
        case 'Einde lease factuur':
            return <MUIComponents.Icons.FilePresentIcon sx={iconStyling(getRandomColor())} />
        default:
            return undefined
    }
}

const Label = styled.p`
    display: block;
    max-width: 98%;
    min-height: 15px;
    width: 100%;

    ${StyledComponents.TruncatedText}
`

// 4 -> at loading, we want to have 4 items to display in the tabs
export const loadingDocuments = ({
    loading,
    tabs = [],
    length = 4
}: {
    loading: boolean
    tabs: TabProps[] | GQLTypes.OrderLog[] | undefined
    length: number
}) =>
    loading && tabs.length === 0
        ? [...new Array(length)].map((_x, i) =>
              tabProps(
                  {
                      label: (
                          <MUIComponents.Skeleton width="100%" height={30} variant="rectangular" />
                      ),
                      icon: (
                          <MUIComponents.Skeleton
                              width="45px"
                              height="45px"
                              variant="circular"
                              sx={{ margin: 'auto' }}
                          />
                      ),
                      id: i.toString()
                  },
                  i
              )
          )
        : tabs.map((tab, i) => tabProps(tab, i))
