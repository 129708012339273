import styled from 'styled-components'
import Barcode from 'react-barcode'

import theme from '../../../theme/theme'
import { MUIComponents } from '../../muiComponents'
import * as GQLTypes from '../../../types/GQLTypes'
import { TranslatorFunction } from '../../../types'

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

const Lists = styled.div`
    display: flex;
    flex-direction: row;
`

const Alinea = styled.p`
    margin: unset;
`

const BarcodeWrapper = styled.div`
    margin: auto;
    width: fit-content;
    display: flex;
    flex-direction: row;
`

export interface TemplatesContentProps {
    servicePass?: {
        barcode: GQLTypes.Maybe<string> | undefined
        startAmount: GQLTypes.Maybe<string | number> | undefined
        currentAmount: GQLTypes.Maybe<string | number> | undefined
    }
    frameNumber?: GQLTypes.Maybe<string> | undefined
    licensePlate?: GQLTypes.Maybe<string> | undefined
    stickerNumber?: GQLTypes.Maybe<string> | undefined
    insurance?: string
    contact?: string
    telephoneNumberRoadsideAssistance?: string
    t: TranslatorFunction
}

export default (props: TemplatesContentProps) => {
    return {
        Templates: {
            Onderhoud: {
                title: props.t('templateContent.maintenanceTitle'),
                noContent: props.t('templateContent.noContent'),
                content: (
                    <>
                        <Alinea>
                            <br />
                            {props.t('templateContent.barcode')}
                            <br />
                            <br />
                            <BarcodeWrapper>
                                <Barcode value={props.servicePass?.barcode ?? '/'} />
                                <Alinea style={{ width: '50%', margin: 'auto' }}>
                                    {props.t('templateContent.remainingAmount')}:
                                    {props.servicePass?.currentAmount}
                                    <br />
                                    <br />
                                    {props.t('templateContent.initialAmount')}:
                                    {props.servicePass?.startAmount}
                                </Alinea>
                            </BarcodeWrapper>
                            <br />
                            <br />
                            {props.t('templateContent.barcodeScan')}
                            <h4>{props.t('templateContent.maintenanceNeeded')}</h4>
                            {props.t('templateContent.maintenanceNote')}
                            <h4>{props.t('templateContent.servicePassUse1')}</h4> 
                            {props.t('templateContent.servicePassUse2')}
                            <Lists>
                                <Wrapper>
                                    <MUIComponents.Icons.CheckBoxIcon
                                        sx={{
                                            color: theme.palette.primary.main,
                                            width: '50%'
                                        }}
                                    />
                                    <ul>
                                        <li>{props.t('templateContent.maintenanceTitle')}</li>
                                        <li>{props.t('templateContent.products')}</li>
                                        <ul>
                                            <li>{props.t('templateContent.productsContent')}</li>
                                        </ul>
                                        <li>{props.t('templateContent.parts')}</li>
                                        <ul>
                                            <li>{props.t('templateContent.partsContent')}</li>
                                        </ul>
                                        <li>{props.t('templateContent.damageExemption')}</li>
                                    </ul>
                                </Wrapper>

                                <Wrapper>
                                    <MUIComponents.Icons.DisabledByDefaultIcon
                                        sx={{
                                            color: 'red',
                                            width: '50%'
                                        }}
                                    />
                                    <ul>
                                        <li>{props.t('templateContent.moreExpensive')}</li>
                                        <li>{props.t('templateContent.PurchaseBike')}</li>
                                        <li>{props.t('templateContent.purchaseAcc')}</li>
                                        <li>{props.t('templateContent.purchaseRemainer')}</li>
                                    </ul>
                                </Wrapper>
                            </Lists>
                            {/* Link in text below is not yet prepared by cust. Remains in comment until further notice */}
                            {/* <b>{props.t('templateContent.saldo')}</b> <br />  */}
                            {/* {props.t('templateContent.transactions')} */}
                        </Alinea>
                    </>
                )
            },
            Verzekering: {
                title: props.t('templateContent.insuranceTitle'),
                noContent: null,
                content: (
                    <>
                        <Alinea>
                            <h4>{props.t('templateContent.damageTitle')}</h4>
                            {props.t('templateContent.damage1')}{' '}
                            <b> {props.t('templateContent.damage2')}</b>{' '}
                            {props.t('templateContent.damage3')} <br /> <br />
                            {props.t('templateContent.damage4')} <br /> <br />
                            {props.t('templateContent.damage5')}
                        </Alinea>

                        <Alinea>
                            <h4>{props.t('templateContent.theftTitle')}</h4>
                            {props.t('templateContent.theft1')}
                            <ul>
                                <li>
                                    {props.t('templateContent.theft2')}
                                    <b>
                                        {' '}
                                        {props.t('templateContent.theft3', {
                                            frame: props.frameNumber
                                        })}{' '}
                                    </b>{' '}
                                    {props.t('templateContent.theft4')}
                                </li>
                                <li>{props.t('templateContent.theft5')}</li>
                                <li>{props.t('templateContent.theft6')}</li>
                            </ul>
                        </Alinea>
                    </>
                )
            },
            Pechhulp: {
                title: props.t('templateContent.assistanceTitle'),
                noContent: props.t('templateContent.assistance1'),
                content: (
                    <Alinea>
                        {props.t('templateContent.assistance2', {
                            number: props.telephoneNumberRoadsideAssistance
                        })}
                        <br /> <br />{' '}
                        {props.t('templateContent.assistance3', {
                            frameNumber: props.frameNumber,
                            stickerNumber: props.stickerNumber
                        })}
                    </Alinea>
                )
            },
            'Garantie E-bike': {
                title: props.t('templateContent.warrantyTitle'),
                noContent: props.t('templateContent.warranty1'),
                content: <Alinea>{props.t('templateContent.warranty2')}</Alinea>
            },
            'BA-verzekering': {
                title: props.t('templateContent.BATitle'),
                noContent: null,
                content: (
                    <Alinea>
                        {props.t('templateContent.BA1', { insurance: props.insurance })}
                        <br /> <br />{' '}
                        {props.t('templateContent.BA2', {
                            contact: props.contact,
                            frameNumber: props.frameNumber,
                            licensePlate: props.licensePlate
                        })}
                    </Alinea>
                )
            }
        }
    }
}
