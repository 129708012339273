import Barcode from 'react-barcode'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import theme from '../../../theme/theme'
import { DeviceParameters } from '../../../types'
import { Employee } from '../../../types/GQLTypes'
import { MUIComponents } from '../../muiComponents'
import StyledComponents from '../../StyledComponents'

export const ProfileMessage = (
    props: {
        barcode?: Employee['employee_access_code']
        onClick?: () => void
    } & DeviceParameters
) => {
    const { t } = useTranslation()

    return (<MUIComponents.Styling.Paper
        style={{
            padding: '20px',
            backgroundColor: '#244F26',
            color: 'white',
            height: 'calc(100% - 40px)',
            position: 'relative'
        }}
        elevation={5}
    >
        <MUIComponents.Typography
            variant="h5"
            style={{
                textAlign: 'center',
                color: theme.palette.primary.main,
                ...(props.isSmallerThanMob && { ...StyledComponents.helper.breakupText })
            }}
        >
            {t('home.profile.title')}
        </MUIComponents.Typography>

        <MUIComponents.Styling.Divider />

        <MUIComponents.Typography
            variant="subtitle1"
            style={{
                textAlign: 'left',
                fontSize: '12px',
                ...(props.isSmallerThanMob && { ...StyledComponents.helper.breakupText })
            }}
        >
            {t('home.profile.changed')}
        </MUIComponents.Typography>
        <MUIComponents.Typography
            variant="subtitle1"
            style={{
                textAlign: 'left',
                margin: 'unset',
                fontSize: '12px',
                ...(props.isSmallerThanMob && { ...StyledComponents.helper.breakupText })
            }}
        >
            {t('home.profile.service')}
        </MUIComponents.Typography>

        <BarcodeWrapper>
            {props?.barcode ? (
                <Barcode value={props?.barcode} />
            ) : (
                <MUIComponents.Skeleton
                    sx={{ backgroundColor: 'rgba(255, 255, 255, 0.1)' }}
                    width={156}
                    height={142}
                    variant="rectangular"
                />
            )}
        </BarcodeWrapper>

        <MUIComponents.Buttons.Button
            variant="contained"
            fullWidth
            onClick={props?.onClick}
            sx={{
                position: 'absolute',
                bottom: '20px',
                width: '90%',
                margin: 'auto',
                right: 0,
                left: 0
            }}
        >
            {t('home.profile.open')}
        </MUIComponents.Buttons.Button>
    </MUIComponents.Styling.Paper>
)}

const BarcodeWrapper = styled.div`
    margin: auto;
    width: fit-content;
    min-width: 50px;
    margin-top: 10px;

    & svg {
        width: 100% !important;
        height: 100% !important;
    }
`
