import { MUIComponents } from '../../muiComponents'
import StyledComponents from '../../StyledComponents'
import { Item } from './Details'

export const Value = (item: Item) => (
    <MUIComponents.Typography
        sx={{ paddingBottom: '5px', textAlign: 'left', ...StyledComponents.helper.truncatedText }}
        variant="body2"
    >
        {item.value}
    </MUIComponents.Typography>
)
