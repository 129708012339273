import styled from 'styled-components'
import MuiSpeedDial, { SpeedDialProps as MuiSpeedDialProps } from '@mui/material/SpeedDial'
import MuiSpeedDialAction from '@mui/material/SpeedDialAction'

import { MUIComponents } from '.'
import theme from '../../theme/theme'

interface SpeedDialProps extends MuiSpeedDialProps {
    // you can declare custom props in here
    actions: Array<{ icon: JSX.Element; name: string; onClick: (name: string) => void }>
}

const StyledSpeedDialAction = styled(MuiSpeedDialAction)`
    background-color: ${(props) => props.theme.palette.primary.main} !important;
    color: ${(props) => props.theme.palette.primary.contrastText} !important;
`

export const SpeedDial = ({ direction = 'up', actions = [], ...props }: SpeedDialProps) => (
    <MUIComponents.Styling.Box sx={{ height: 320, transform: 'translateZ(0px)', flexGrow: 1 }}>
        <MuiSpeedDial
            sx={{ position: 'absolute', bottom: 16, right: 16 }}
            icon={<MUIComponents.Icons.SpeedDialIcon />}
            FabProps={{
                sx: {
                    bgcolor: theme.palette.primary.main,
                    '&:hover': {
                        bgcolor: theme.palette.primary.main
                    }
                }
            }}
            direction={direction}
            {...props}
        >
            {actions.map((action) => (
                <StyledSpeedDialAction
                    key={action.name}
                    icon={action.icon}
                    tooltipTitle={action.name}
                    onClick={(e) => {
                        action.onClick(action.name)
                    }}
                />
            ))}
        </MuiSpeedDial>
    </MUIComponents.Styling.Box>
)

// Example of this component with props:
{
    /* <MUIComponents.SpeedDial
                ariaLabel="SpeedDial"
                actions={[
                    {
                        icon: <MUIComponents.Icons.ArrowBackIcon />,
                        name: 'Copy',
                        onClick: (x) => {
                            console.log('Copy', x)
                        }
                    },
                    {
                        icon: <MUIComponents.Icons.SaveIcon />,
                        name: 'Save',
                        onClick: (x) => {
                            console.log('Save', x)
                        }
                    },
                    {
                        icon: <MUIComponents.Icons.DeleteIcon />,
                        name: 'Print',
                        onClick: (x) => {
                            console.log('Print', x)
                        }
                    },
                    {
                        icon: <MUIComponents.Icons.SpeedDialIcon />,
                        name: 'Share',
                        onClick: (x) => {
                            console.log('Share', x)
                        }
                    }
                ]}
            /> */
}
