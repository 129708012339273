import { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import styled from 'styled-components'
import { useLazyQuery } from '@apollo/client'
import { useTranslation, getI18n } from 'react-i18next'
import Autocomplete from '@mui/material/Autocomplete'

import {
    citiesState,
    dealerSearcherState,
    userProfileState,
    defaultCity,
    dealerZipFromHomeState
} from '../../../store/store'
import { MUIComponents } from '../../muiComponents'
import theme from '../../../theme/theme'
import StyledComponents from '../../StyledComponents'
import { City, QueryCitiesArgs, QueryEmployeeArgs, Maybe } from '../../../types/GQLTypes'
import API from '../../../API'
import { genericFontSize } from '../../../utils/utils'

const emptyStringHandler = (value: Maybe<string> | undefined) => {
    if (value === '') {
        return null
    }
    return value
}

const resolveCityName = (language: string, city?: City): string => {
    const nlFirst =
        emptyStringHandler(city?.city_name_nl) ??
        emptyStringHandler(city?.city_name_fr) ??
        emptyStringHandler(city?.city_name_de) ??
        '/'
    const frFirst =
        emptyStringHandler(city?.city_name_fr) ??
        emptyStringHandler(city?.city_name_nl) ??
        emptyStringHandler(city?.city_name_de) ??
        '/'
    const deFirst =
        emptyStringHandler(city?.city_name_de) ??
        emptyStringHandler(city?.city_name_nl) ??
        emptyStringHandler(city?.city_name_fr) ??
        '/'

    switch (language.toLowerCase()) {
        case 'en' || 'nl':
            return nlFirst
        case 'fr':
            return frFirst
        case 'de':
            return deFirst
        default:
            return nlFirst
    }
}

export const DealerSearcher = (props: { loading: boolean }) => {
    const { t } = useTranslation()
    const language = getI18n().language

    // global state
    const [userProfile] = useRecoilState(userProfileState)
    const [cities, setCities] = useRecoilState(citiesState)
    const [dealerSearcher, setDealerSearcher] = useRecoilState(dealerSearcherState)
    const [dealerZipFromHome, setDealerZipFromHome] = useRecoilState(dealerZipFromHomeState)

    // states
    const [searchTerm, setSearchTerm] = useState('') // TODO: refetching in term searches -> Needs MUI wrapper's autocomplete work
    const [options, setOptions] = useState<
        { value: string; label: string; id: string; key: string }[]
    >([])
    const [selectedOption, setSelectedOption] = useState<{
        value: string
        label: string
        id: string
        key: string
    } | null>()

    // queries
    const [collectCities, { loading: citiesCollectionLoading }] = useLazyQuery<
        { cities: City[] },
        QueryCitiesArgs & QueryEmployeeArgs
    >(API.Queries.CITIES)

    // effects
    useEffect(() => {
        const citiesToMapFrom = [...new Set(cities)]

        const newOptions = citiesToMapFrom
            .map((city, i) => ({
                value: `${city?.zip_code ?? '/'} ${resolveCityName(language, city)}`,
                label: `${city?.zip_code ?? '/'} ${resolveCityName(language, city)}`,
                id: `${city?.zip_code ?? '/'} ${resolveCityName(language, city)}`,
                key: `KEY_${i}_${city?.zip_code ?? '/'}`
            }))
            .sort((a, b) => a.value.localeCompare(b.value))

        setOptions([...newOptions])
    }, [cities])

    useEffect(() => {
        // Collect all cities as default values (TODO: refetching in term searches -> Needs MUI wrapper's autocomplete work)
        const initFetch = async () => {
            await collectCitiesHandler()
        }

        if (userProfile.employee_id) initFetch()
    }, [userProfile.employee_id])

    useEffect(() => {
        if (
            cities &&
            cities?.length > 0 &&
            dealerZipFromHome &&
            dealerZipFromHome !== '' &&
            dealerZipFromHome?.length > 3
        ) {
            // To find original city from home page
            const initOption = options.find((option) =>
                option.value.toLowerCase().includes(dealerZipFromHome)
            )

            // To move to initial point
            const foundCity = cities.find((x) =>
                (initOption as { label: string; value: string })?.label.includes(
                    resolveCityName(language, x)
                )
            )

            if (foundCity) {
                setDealerSearcher(foundCity)
                setSelectedOption(initOption)
            }
        }
    }, [cities, dealerZipFromHome, options, setSelectedOption, setDealerSearcher, resolveCityName])

    // functions
    const collectCitiesHandler = async () => {
        const res = await collectCities({
            variables: {
                id: userProfile.employee_id as string,
                term: searchTerm ?? '',
                size: 9999 // TODO: replace back to 100 or remove because of the default (see TODO above)
            }
        })

        const newCities: City[] = [defaultCity]
        newCities.push(...(res?.data?.cities ?? []))

        setCities(newCities)
    }

    return (
        <MUIComponents.Styling.Grid
            container
            sx={{
                height: 'calc(100vh - 190px)',
                marginLeft: 'unset',
                marginTop: '10px',
                width: '100%',
                boxShadow:
                    '0px 6px 6px -3px rgb(0 0 0 / 20%), 0px 10px 14px 1px rgb(0 0 0 / 14%), 0px 4px 18px 3px rgb(0 0 0 / 12%)'
            }}
            component={MUIComponents.Styling.Paper}
            columns={15}
            spacing={1}
        >
            <Wrapper>
                <MUIComponents.Typography
                    sx={{
                        color: theme.palette.primary.main,
                        paddingLeft: '8px',
                        textAlign: 'unset',
                        ...StyledComponents.helper.truncatedText
                    }}
                    variant="h5"
                >
                    {t('dealerSearch.title')}
                </MUIComponents.Typography>

                <StyledComponents.SelectsWrapper>
                    <Autocomplete
                        autoFocus={false}
                        disabled={citiesCollectionLoading}
                        loading={citiesCollectionLoading}
                        value={selectedOption || null}
                        defaultValue={selectedOption}
                        options={options}
                        getOptionLabel={(option) => option.label}
                        id="select-city"
                        onChange={(e, newValue) => {
                            const foundCity = cities.find((x) =>
                                (newValue as { label: string; value: string })?.label.includes(
                                    resolveCityName(language, x)
                                )
                            )

                            if (foundCity) {
                                setDealerSearcher(foundCity)
                            }

                            setSelectedOption(newValue)
                            return
                        }}
                        sx={{ marginLeft: '10px' }}
                        size="small"
                        fullWidth
                        filterOptions={(filterOptions, state) => {
                            const newOptions: {
                                value: string
                                label: string
                                id: string
                                key: string
                            }[] = []

                            if (state.inputValue && state.inputValue !== '') {
                                filterOptions.forEach((option) => {
                                    //! remove all accents, dialetcs from the strings: F.e.: liège => check as liege
                                    if (
                                        option.value
                                            .toLowerCase()
                                            .normalize('NFD')
                                            .replace(/[\u0300-\u036f]/g, '')
                                            .includes(state.inputValue.toLowerCase())
                                    )
                                        newOptions.push(option)
                                })

                                return newOptions
                            } else return filterOptions
                        }}
                        renderOption={(props, option) => {
                            return (
                                <li {...props} key={option.key}>
                                    {option.label}
                                </li>
                            )
                        }}
                        renderInput={(params) => (
                            <MUIComponents.TextFields.TextField
                                {...params}
                                autoFocus={false}
                                label={t('dealerSearch.citySelectLabel') as string}
                                InputProps={{
                                    ...params.InputProps,
                                    style: { fontSize: genericFontSize }
                                }}
                                InputLabelProps={{
                                    style: { fontSize: genericFontSize }
                                }}
                                colorToOverwrite="rgba(0, 0, 0, 0.6)"
                            />
                        )}
                    />
                </StyledComponents.SelectsWrapper>

                {props.loading ? (
                    <MUIComponents.Skeleton
                        width="100%"
                        height="calc(100% - 120px)"
                        sx={{ borderRadius: '20px' }}
                        variant="circular"
                    />
                ) : (
                    <MapsWrapper id="google_maps" />
                )}
            </Wrapper>
        </MUIComponents.Styling.Grid>
    )
}

const Wrapper = styled.div`
    margin: 10px 10px;
    width: calc(100% - 20px);
`

const MapsWrapper = styled.div`
    height: calc(100% - 120px);
    width: 100%;
    border-radius: 20px;
`
