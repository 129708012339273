import { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import styled, { css, CSSProperties } from 'styled-components'
import { Loader } from '@googlemaps/js-api-loader'
import { useLazyQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { alertObjState, citiesState, dealerSearcherState, dealersState } from '../../../store/store'
import { MUIComponents } from '../../muiComponents'
import theme from '../../../theme/theme'
import StyledComponents from '../../StyledComponents'
import {
    BikeBrand,
    QueryBikeBrandArgs,
    Dealer as DealerType,
    QueryDealersArgs
} from '../../../types/GQLTypes'
import { BikeData, Device, SortDirection } from '../../../types'
import { sortArrayByName } from '../../../utils/utils'
import googleMaps from './googleMaps'
import API from '../../../API'
import useDeviceDetection from '../../../hooks/useDeviceDetection'

export const DealerList = (props: { loading: boolean }) => {
    const { t } = useTranslation()

    const [dealers, setDealers] = useRecoilState(dealersState)
    const [, setAlertObject] = useRecoilState(alertObjState)
    const [dealerSearcher] = useRecoilState(dealerSearcherState)
    const [cities] = useRecoilState(citiesState)
    const [allDealersWithLongAndLat, setAllDealersWithLongAndLat] = useState<DealerType[]>([])
    const [devices] = useDeviceDetection()

    const [sortDealer, setSortDealer] = useState<SortDirection | string>('')
    const [bikeBrands, setBikeBrands] = useState<BikeBrand[]>([])
    const [bikeBrand, setBikeBrand] = useState<BikeData['bike_brand']>(undefined)

    const [getBikeBrands] = useLazyQuery<{ bikeBrands: BikeBrand[] }, QueryBikeBrandArgs>(
        API.Queries.BIKE_BRANDS
    )

    const [fetchDealers, { error }] = useLazyQuery<{ dealers: DealerType[] }, QueryDealersArgs>(
        API.Queries.DEALERS
    )

    const loader = new Loader({
        apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? '...',
        version: 'weekly'
    })

    useEffect(() => {
        const initFetch = async () => {
            const allDealersWithLongAndLat = await fetchDealers()

            if (error) {
                console.log(error.message)
                setAlertObject({
                    message: t('dealerSearch.dealerFetchErr'),
                    severity: 'error'
                })
            }

            if (allDealersWithLongAndLat?.data?.dealers) {
                setDealers(
                    allDealersWithLongAndLat?.data?.dealers.filter(
                        (dealer) => dealer.latitude && dealer.longitude
                    ) ?? []
                )
                setAllDealersWithLongAndLat(
                    allDealersWithLongAndLat?.data?.dealers.filter(
                        (dealer) => dealer.latitude && dealer.longitude
                    ) ?? []
                )
            }
        }

        if (
            bikeBrand?.label === t('dealerSearch.bikeBrandsSelectAllLabel') ||
            bikeBrand === undefined
        ) {
            initFetch()
        } else {
            setDealers(
                allDealersWithLongAndLat.filter((dealer) => {
                    const names = dealer.bikeBrands?.map((brand) => brand?.name)
                    return names?.includes(bikeBrand?.label)
                })
            )
        }
    }, [bikeBrand])

    useEffect(() => {
        if (sortDealer === 'asc' && dealers.length > 0) {
            setDealers(sortArrayByName({ arrayToSort: [...dealers] ?? [], direction: 'asc' }))
        }

        if (sortDealer === 'desc' && dealers.length > 0) {
            setDealers(sortArrayByName({ arrayToSort: [...dealers] ?? [], direction: 'desc' }))
        }
    }, [sortDealer])

    useEffect(() => {
        if (!props.loading) {
            loader
                .importLibrary('maps')
                .then(() => {
                    googleMaps.initialize(
                        { lat: dealerSearcher.lat, lng: dealerSearcher.long },
                        dealers,
                        cities
                    )
                })
                .catch((e) => {
                    setAlertObject({
                        message: t('dealerSearch.mapInitializeAlert'),
                        severity: 'error'
                    })
                    console.log(e.message)
                })
        }
    }, [props.loading, sortDealer, dealerSearcher, bikeBrand, dealers, cities])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const bikeBrandsData = await getBikeBrands()
                if (bikeBrandsData?.data?.bikeBrands) {
                    setBikeBrands(
                        sortArrayByName({
                            arrayToSort: [...bikeBrandsData.data.bikeBrands],
                            direction: 'asc'
                        })
                    )
                }
            } catch (err) {
                setBikeBrands([])
                setAlertObject({
                    message: t('dealerSearch.bikeBrandsAlert'),
                    severity: 'error'
                })
            }
        }

        fetchData()
    }, [])

    const isMob = devices.isMob || devices.isSmallerThanMob

    return (
        <MUIComponents.Styling.Grid
            container
            sx={{
                height: 'calc(100vh - 190px)',
                marginLeft: 'unset',
                marginTop: '10px',
                width: '100%',
                boxShadow:
                    '0px 6px 6px -3px rgb(0 0 0 / 20%), 0px 10px 14px 1px rgb(0 0 0 / 14%), 0px 4px 18px 3px rgb(0 0 0 / 12%)'
            }}
            component={MUIComponents.Styling.Paper}
            columns={15}
            spacing={1}
        >
            <Wrapper>
                <MUIComponents.Typography
                    sx={{
                        color: theme.palette.primary.main,
                        paddingLeft: '8px',
                        textAlign: 'unset',
                        ...StyledComponents.helper.truncatedText
                    }}
                    variant="h5"
                >
                    {t('dealerSearch.listTitle')}
                </MUIComponents.Typography>

                <StyledComponents.SelectsWrapper isSmallerThanMob={devices.isSmallerThanMob}>
                    <MUIComponents.AutoComplete
                        onChange={(_e, value) => {
                            const newValue = value as {
                                value: string
                                label: string
                            }

                            if (newValue?.label === t('dealerSearch.bikeBrandsSelectAllLabel')) {
                                setBikeBrand(newValue)
                                return
                            }

                            setBikeBrand(newValue)
                        }}
                        id="select-filter-bikeBrand"
                        size="small"
                        label={t('dealerSearch.filterBrandInput')}
                        value={bikeBrand}
                        options={[
                            { value: 'Alles', label: t('dealerSearch.bikeBrandsSelectAllLabel') }
                        ].concat(
                            bikeBrands.map((brand) => ({
                                value: brand.id ?? '',
                                label: brand.name ?? ''
                            }))
                        )}
                        sx={{ width: '100%' }}
                        disableClearable
                    />

                    <MUIComponents.Select.SingleSelect
                        labelId="sortDealer"
                        id="select-sorting"
                        name="sortDealer"
                        labelContent={t('dealerSearch.sortInput')}
                        size="small"
                        value={sortDealer}
                        menuItems={[
                            {
                                value: 'asc',
                                label: t('general.sortAsc')
                            },
                            {
                                value: 'desc',
                                label: t('general.sortDesc')
                            }
                        ]}
                        onChange={(e) => {
                            if (e.target.value) {
                                setSortDealer(e.target.value as SortDirection)
                            }
                            return
                        }}
                        formStyling={{ marginLeft: devices.isSmallerThanMob ? '0px' : '10px' }}
                        MenuProps={{
                            style: {
                                maxHeight: 500
                            }
                        }}
                    />
                </StyledComponents.SelectsWrapper>

                {props.loading ? (
                    <MUIComponents.Skeleton
                        width="100%"
                        height={
                            isMob
                                ? devices.isSmallerThanMob
                                    ? 'calc(100% - 200px)'
                                    : 'calc(100% - 150px)'
                                : '80%'
                        }
                        sx={{ borderRadius: '10px', overflow: 'auto' }}
                        variant="circular"
                    />
                ) : (
                    <MUIComponents.List
                        listItems={dealers.map((dealer) => ({
                            title: dealer?.name ?? '/',
                            subTitle: '',
                            delete: false,
                            avatar: 0,
                            item: dealer
                        }))}
                        style={{
                            height: isMob
                                ? devices.isSmallerThanMob
                                    ? 'calc(100% - 200px)'
                                    : 'calc(100% - 150px)'
                                : '80%',
                            overflow: 'auto'
                        }}
                        itemContent={(title, subTitle, index, item) => {
                            const contentTypographyStyling: CSSProperties = {
                                textAlign: 'unset',
                                fontSize: 'small',
                                ...StyledComponents.helper.truncatedText
                            }

                            const bikeBrands = (item?.bikeBrands ?? []) as BikeBrand[]

                            const canRowExpand = bikeBrands?.length > 0

                            return (
                                <MUIComponents.Card
                                    disableMenuActions
                                    id={`Dealer_item_${item?.id}`}
                                    style={{
                                        width: '100%',
                                        borderBottom:
                                            dealers.length - 1 === index
                                                ? '0'
                                                : '1px solid rgba(0, 0, 0, 0.25)',
                                        borderRadius: '0'
                                    }}
                                    {...(devices.isSmallerThen170Width && {
                                        sx: {
                                            '& .MuiCardActions-root': {
                                                flexDirection: 'column'
                                            }
                                        }
                                    })}
                                    maxWidth={'unset !important'}
                                    expanding={canRowExpand ? true : false}
                                    expandableContent={
                                        canRowExpand ? (
                                            <ExpandableContent
                                                {...(devices.isSmallerThanMob && {
                                                    style: { overflowWrap: 'break-word' }
                                                })}
                                            >
                                                {bikeBrands
                                                    .map((bikeBrand) => bikeBrand.name)
                                                    .join(' | ')}
                                            </ExpandableContent>
                                        ) : undefined
                                    }
                                    expandingTitle={
                                        canRowExpand ? (
                                            <ExpandingTitle>
                                                {t('dealerSearch.brands')}
                                            </ExpandingTitle>
                                        ) : undefined
                                    }
                                    title={
                                        <TitleWrapper>
                                            {title ?? '/'}
                                            {!isMob && (
                                                <Title isMob={isMob} id={`Card_pin_${item?.id}`}>
                                                    <MUIComponents.Icons.LocationOnIcon
                                                        sx={{
                                                            color: theme.palette.primary.main,
                                                            width: '50%'
                                                        }}
                                                    />
                                                    <DistanceShower isMob={isMob}>
                                                        12 km
                                                    </DistanceShower>
                                                </Title>
                                            )}
                                        </TitleWrapper>
                                    }
                                    titleStyle={{ fontSize: 'small' }}
                                    details={
                                        <ContentWrapper isMob={isMob}>
                                            <ContentColumnWrapper isMob={isMob}>
                                                <MUIComponents.Typography
                                                    variant="body1"
                                                    style={contentTypographyStyling}
                                                >
                                                    {item?.street as string}
                                                </MUIComponents.Typography>
                                                <MUIComponents.Typography
                                                    variant="body1"
                                                    style={contentTypographyStyling}
                                                >
                                                    {item?.zip_code as string}{' '}
                                                    {item?.city as string}
                                                </MUIComponents.Typography>
                                            </ContentColumnWrapper>
                                            <ContentColumnWrapper isMob={isMob}>
                                                <MUIComponents.Typography
                                                    variant="body1"
                                                    style={contentTypographyStyling}
                                                >
                                                    {item?.tel as string}
                                                </MUIComponents.Typography>
                                                <MUIComponents.Typography
                                                    variant="body1"
                                                    style={contentTypographyStyling}
                                                >
                                                    {item?.email as string}
                                                </MUIComponents.Typography>
                                                {isMob && (
                                                    <Title
                                                        isMob={isMob}
                                                        id={`Card_pin_${item?.id}`}
                                                    >
                                                        <MUIComponents.Icons.LocationOnIcon
                                                            sx={{
                                                                color: theme.palette.primary.main,
                                                                width: '50%'
                                                            }}
                                                        />
                                                        <DistanceShower
                                                            isMob={devices.isSmallerThanMob}
                                                        >
                                                            12 km
                                                        </DistanceShower>
                                                    </Title>
                                                )}
                                            </ContentColumnWrapper>
                                        </ContentWrapper>
                                    }
                                    loading={false}
                                    subHeaderStyle={{ padding: '2px 16px' }}
                                    cardContentStyle={{ padding: '0 16px' }}
                                />
                            )
                        }}
                    />
                )}
            </Wrapper>
        </MUIComponents.Styling.Grid>
    )
}

const Wrapper = styled.div`
    margin: 10px 10px;
    width: calc(100% - 20px);
    height: calc(100% - 30px);
`

const ExpandableContent = styled.div`
    font-size: small;
    margin: unset;
`

const TitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`

const Title = styled(TitleWrapper)<{ isMob: Device }>`
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    padding: 5px;
    cursor: pointer;
    min-width: 30px;
    max-width: 60px;

    ${(props) =>
        props.isMob &&
        css`
            margin-top: 8px;
            margin-bottom: 8px;
        `}
`

const ExpandingTitle = styled.div`
    font-size: small;
    margin-left: 8px;

    ${StyledComponents.TruncatedText}
`

const ContentWrapper = styled.div<{ isMob: Device }>`
    display: flex;
    flex-direction: ${(props) => (props.isMob ? 'column' : 'row')};
    width: 100%;
`

const ContentColumnWrapper = styled.div<{ isMob: Device }>`
    display: flex;
    flex-direction: column;
    width: ${(props) => (props.isMob ? '100%' : '50%')};
`

const DistanceShower = styled.p<{ isMob: Device }>`
    font-size: small;

    ${(props) =>
        props.isMob &&
        css`
            width: 50%;
        `}

    ${(props) =>
        !props.isMob &&
        css`
            margin: unset;
        `}
`
