import { alpha } from '@mui/material'
import { useTranslation } from 'react-i18next'

import theme from '../../../theme/theme'
import { MUIComponents } from '../../muiComponents'

export const RoadsideAssistance = () => {
    const { t } = useTranslation()

    return (
        <MUIComponents.Styling.Paper
            style={{
                color: 'white',
                backgroundColor: alpha(theme.palette.primary.main, 0.25),
                height: 'calc(100% - 10px)'
            }}
            elevation={5}
        >
            <MUIComponents.Typography
                variant="subtitle1"
                style={{
                    fontWeight: 'bold',
                    textAlign: 'center',
                    color: theme.palette.primary.main,
                    padding: '10px',
                    paddingBottom: 0
                }}
            >
                {t('roadAssistance.assistance1')}
            </MUIComponents.Typography>

            <MUIComponents.Styling.Divider />

            <MUIComponents.Typography
                variant="subtitle1"
                style={{
                    textAlign: 'center',
                    color: theme.palette.secondary.main,
                    paddingTop: '10px',
                    padding: '0 10px'
                }}
            >
                {t('roadAssistance.assistance2')}
            </MUIComponents.Typography>

            <MUIComponents.Typography
                variant="h5"
                style={{
                    fontWeight: 'bold',
                    textAlign: 'center',
                    margin: 'unset',
                    color: theme.palette.secondary.main,
                    paddingBottom: '20px'
                }}
            >
                +32 (0) 822 833
            </MUIComponents.Typography>
        </MUIComponents.Styling.Paper>
    )
}