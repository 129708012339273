import MuiSkeleton, { SkeletonProps } from '@mui/material/Skeleton'

export const Skeleton = ({
    animation = 'pulse',
    variant = 'rectangular',
    width = 200,
    height = 200,
    ...props
}: SkeletonProps) => {
    return (
        <MuiSkeleton
            animation={animation}
            variant={variant}
            width={width}
            height={height}
            {...props}
        />
    )
}

// Example of this component with props:
{
    /* <MUIComponents.Skeleton
                    sx={{ width: '50%', margin: '15px auto', display: 'flex' }}
                    width={500}
                    height={60}
                    variant="rectangular"
                /> */
}
