import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'

import theme from '../../../theme/theme'
import { MUIComponents } from '../../muiComponents'
import { stringDefaulter } from '../../../utils/utils'
import { DeviceParameters, IBasisInfo } from '../../../types'
import StyledComponents from '../../StyledComponents'

export const BasisInfo = ({
    isMob = false,
    isSmallerThanMob = false,
    isTablet = false,
    ...props
}: DeviceParameters & IBasisInfo) => {
    const { t } = useTranslation()

    const isNotBigScreen = isMob || isTablet || isSmallerThanMob

    const earliestDeliveryCondition =
        props?.sourceComponent === 'Files' &&
        props?.quotation?.earliest_delivery_date &&
        props?.quotation?.earliest_delivery_date !== null &&
        props?.quotation?.earliest_delivery_date?.length > 1

    return (
        <>
            {!isNotBigScreen && (
                <MUIComponents.Typography
                    sx={{
                        color: theme.palette.primary.main,
                        fontWeight: 'bold',
                        padding: '20px 0 10px 30px',
                        textAlign: 'left'
                    }}
                    variant="body2"
                >
                    {t('filesAndCalc.mainContainer.title')}
                </MUIComponents.Typography>
            )}

            <InfoWrapper>
                {[
                    {
                        label: t('filesAndCalc.mainContainer.date'),
                        value: stringDefaulter(props?.quotation?.quotation_date, 'dateFormat')
                    },
                    ...(props?.sourceComponent === 'Files' && earliestDeliveryCondition
                        ? [
                              {
                                  label: t('filesAndCalc.mainContainer.earliestDelivery'),
                                  value: stringDefaulter(
                                      props?.quotation?.earliest_delivery_date,
                                      'dateFormat'
                                  )
                              }
                          ]
                        : []),
                    { label: 'Dealer', value: stringDefaulter(props?.quotation?.dealer?.name) },
                    {
                        label: t('filesAndCalc.mainContainer.brand'),
                        value: stringDefaulter(props?.quotation?.bikeBrand?.name)
                    },
                    {
                        label: t('filesAndCalc.mainContainer.type'),
                        value: stringDefaulter(props?.quotation?.bikeType?.name)
                    }
                ].map((info, i) => (
                    <Wrapper key={i}>
                        <MUIComponents.Typography
                            sx={{
                                fontSize: 'small',
                                color: 'rgba(0,0,0,0.6)',
                                textAlign: 'left',
                                ...StyledComponents.helper.truncatedText
                            }}
                            variant="body2"
                        >
                            {info.label}
                        </MUIComponents.Typography>

                        {props?.quotation ? (
                            <MUIComponents.Typography
                                sx={{
                                    paddingBottom: '20px',
                                    textAlign: 'left',
                                    ...StyledComponents.helper.truncatedText
                                }}
                                variant="body2"
                            >
                                {info.value}
                            </MUIComponents.Typography>
                        ) : (
                            <MUIComponents.Skeleton
                                sx={{ margin: 0, marginTop: 1, marginBottom: 1 }}
                                width="90%"
                                height={20}
                                variant="rectangular"
                            />
                        )}
                    </Wrapper>
                ))}

                {props.button?.visible && props?.quotation && (
                    <ButtonWrapper isNotBigScreen={isNotBigScreen}>
                        <MUIComponents.Buttons.LoadingButton
                            content={props.button.label}
                            variant="outlined"
                            {...props.button}
                        />
                    </ButtonWrapper>
                )}
            </InfoWrapper>
        </>
    )
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 30px;
    width: calc(100% - 30px);
`

const InfoWrapper = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    width: 100%;
`

const ButtonWrapper = styled.div<{ isNotBigScreen: DeviceParameters['isMob'] }>`
    color: ${(props) => props.theme.palette.primary.main};
    font-weight: bold;
    width: calc(100% - 60px);
    bottom: 0;

    ${(props) =>
        props.isNotBigScreen
            ? css`
                  padding: 0 30px;
              `
            : css`
                  position: absolute;
                  padding: 30px;
              `}
`
