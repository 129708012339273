import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

import StyledComponents from '../../StyledComponents'
import { ImagesList } from '../images/ImagesList'
import { Blogpost } from '../../../types'

interface ItemProps {
    item: Blogpost
}

// TODO: Make this more generic -> Implement when we are going to use the carousel on places that are not blogposts
export const Item = ({ item }: ItemProps) => {
    const navigate = useNavigate()
    const listItems = item?.listItems ?? []

    return (
        <>
            <Image onClick={() => navigate('/blogpost/' + item?.key)} src={item.bannerImg} />

            <ItemTitleDefinition>{item?.title}</ItemTitleDefinition>
            <ItemContent>{item?.paragraphs[0]?.content}</ItemContent>

            <StyledComponents.ItemNavigation
                onClick={() => navigate('/blogpost/' + item?.key)}
                padding
            >
                Lees verder
            </StyledComponents.ItemNavigation>

            <ImagesList padding margin listItems={listItems} />
        </>
    )
}

const ItemTitleDefinition = styled.p`
    font-weight: 700;
    color: rgba(0, 0, 0, 0.6);
    padding: 0 20px;
`

const ItemContent = styled.p`
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 20px;
    font-size: 14px;
    margin-bottom: -15px;
`

const Image = styled.img`
    border-radius: 50px;
    width: calc(100% - 20px);
    height: 200px;
    padding: 10px 10px;
    object-fit: cover;
    cursor: pointer;
`
