import MuiTabs, { TabsProps as MuiTabsProps } from '@mui/material/Tabs'
import MuiTab from '@mui/material/Tab'
import styled, { CSSProperties } from 'styled-components'
import { alpha } from '@mui/material'

import { MUIComponents } from '.'
import { noop } from '../../utils/utils'
import { TabProps } from './types'

// TODO: getTabComponent is cleaned up with the props
// TODO: made tab?.label optional
// TODO: removed link at defaultProps
const getTabComponent = ({
    tab,
    component,
    onClick,
    colorToOverwrite,
    border,
    tabWidth = 'unset'
}: {
    tab: TabProps
    component: 'Label' | 'IconWithLabel' | 'Icon'
    onClick: (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        fileId?: string,
        label?: string | JSX.Element,
        id?: string
    ) => void
    colorToOverwrite?: string
    border?: boolean
    tabWidth?: CSSProperties['width']
}) => {
    const defaultProps = () => ({
        key: tab.id,
        id: `Panel-${tab.id}`,
        'aria-controls': tab.ariaControls,
        onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            onClick(
                e,
                tab.fileId,
                typeof tab.label === 'string' ? tab.label : tab.label?.props.children,
                tab.id
            )
        },
        colorToOverwrite,
        tabWidth,
        border,
        wrapped: tab.wrapped,
        disabled: tab.disabled
    })

    switch (component) {
        case 'Label':
            return <StyledTab label={tab.label} {...defaultProps()} />
        case 'Icon':
            return <StyledTab icon={tab.icon} {...defaultProps()} />
        case 'IconWithLabel':
            return <StyledTab label={tab.label} icon={tab.icon} {...defaultProps()} />
        default:
            return <StyledTab label={tab.label} {...defaultProps()} />
    }
}

const StyledTabs = styled(MuiTabs)`
    height: 100%;

    & .MuiTabs-indicator {
        background-color: ${(props) => props.color ?? props.theme.palette.primary.main};
    }

    & .MuiTabs-flexContainerVertical {
        height: 100%;
    }

    & .MuiTabs-flexContainer {
        overflow: auto;
        height: 100%;
    }
`

const StyledTab = styled(MuiTab)<{
    colorToOverwrite?: string
    border?: boolean
    tabWidth?: CSSProperties['width']
}>`
    width: ${(props) => props.tabWidth};
    border-right: ${(props) =>
        props.border ? `0.5px solid ${alpha(props.theme.palette.divider, 0.25)}` : '0'} !important;
    border-left: ${(props) =>
        props.border ? `0.5px solid ${alpha(props.theme.palette.divider, 0.25)}` : '0'} !important;

    &.MuiTab-root {
        background-color: ${(props) =>
            props.colorToOverwrite ?? props.theme.palette.secondary.main} !important;
        display: flex;
        flex-direction: column;
        text-transform: none;
    }

    &.MuiTab-root.Mui-selected {
        color: ${(props) => props.color ?? props.theme.palette.primary.main} !important;
    }
`

interface TabPanelProps {
    value: number | string
    index: number
    children: JSX.Element | string
}

const TabPanel = ({ children, value, index }: TabPanelProps) => (
    <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        style={{ height: 'calc(100% - 49px)' }}
    >
        {value === index && children}
    </div>
)

// TODO: added loading prop
// TODO: added colorToOverwrite prop
// TODO: added border prop
// TODO: updated styling
// TODO: added parentTabsBoxStyling
// TODO: added parentBoxStyling
// TODO: added tabWidth
// TODO: added noActivation
// TODO: updated TabPanel style
// TODO: updated StyledTabs height
// TODO: updated StyledTabs vertical styling
// TODO: updated StyledTabs flexContainer (overflow: auto)
// TODO: updated StyledTabs's onchange
// TODO: updated important comments at the bottom of the file
// TODO: added noActivation = false as default value
// TODO: updated onClick prop
// TODO: updated StyledTab
// TODO: updated .MuiTabs-flexContainer in StyledTabs
export const Tabs = ({
    value,
    onChange,
    allTabs = [],
    centered = false,
    variant = 'scrollable',
    orientation = 'horizontal',
    component = 'Label',
    loading = false,
    onClick = noop,
    parentTabsBoxStyling = {},
    parentBoxStyling = {},
    noActivation = false,
    ...props
}: Omit<MuiTabsProps, 'onClick'> & {
    allTabs: TabProps[]
    component?: 'Label' | 'IconWithLabel' | 'Icon'
    loading?: boolean
    onClick?: (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        fileId?: string,
        label?: string | JSX.Element,
        id?: string
    ) => void
    colorToOverwrite?: string
    border?: boolean
    parentBoxStyling?: CSSProperties
    parentTabsBoxStyling?: CSSProperties
    tabWidth?: CSSProperties['width']
    noActivation?: boolean
}) => (
    <MUIComponents.Styling.Box sx={{ width: '100%', ...parentBoxStyling }}>
        <MUIComponents.Styling.Box
            sx={{
                border: props.border ? 1 : 0,
                borderColor: 'divider',
                borderRadius: '10px',
                ...parentTabsBoxStyling
            }}
        >
            <StyledTabs
                {...(noActivation && {
                    TabIndicatorProps: {
                        style: { display: 'none' }
                    }
                })}
                value={value}
                onChange={
                    props?.disabled
                        ? () => {
                              return
                          }
                        : onChange
                }
                aria-label="tabs"
                centered={centered}
                variant={variant}
                allowScrollButtonsMobile
                scrollButtons="auto"
                orientation={orientation}
                colorToOverwrite={props.colorToOverwrite}
                border={props.border}
                {...props}
            >
                {allTabs.map((tab) =>
                    loading ? (
                        <MUIComponents.Skeleton key={tab.id} width="100%" height="40px" />
                    ) : (
                        getTabComponent({
                            component,
                            tab,
                            onClick,
                            colorToOverwrite: props.colorToOverwrite,
                            border: props.border,
                            tabWidth: props.tabWidth
                        })
                    )
                )}
            </StyledTabs>
        </MUIComponents.Styling.Box>
        {allTabs.map((tab) =>
            tab?.children ? (
                <TabPanel key={tab.id} value={value} index={Number(tab.id)}>
                    {tab.children}
                </TabPanel>
            ) : undefined
        )}
    </MUIComponents.Styling.Box>
)

// ! IMPORTANT: first item in array need to start with id = "0"
// ! The onChange is going to give the parameters event and newValue to this function and newValue is 0 when the first element is active
// ! If you want no default indicator at the start you need to give the following value to the value prop itself -> false
// ! So for example: const [value, setValue] = useState(false)
// There are 3 possible tabs: 'Label' | 'IconWithLabel' | 'Icon'
// Just change the prop component with one of the possible tabs
// EXAMPLE of this component with props
// STATE: const [value, setValue] = useState(0)
{
    /* <MUIComponents.Tabs
                onChange={(event, newValue) => {
                    setValue(newValue)
                }}
                value={value}
                allTabs={[
                    {
                        label: 'New Arrivals in the Longest Text of Nonfiction that should appear in the next line',
                        children: <div>Item One</div>,
                        id: '0',
                        ariaControls: 'Panel-0',
                        wrapped: true,
                        disabled: false,
                        icon: <MUIComponents.Icons.StarIcon />
                    },
                    {
                        label: 'Item 2',
                        children: <div>Item Two</div>,
                        id: '1',
                        ariaControls: 'Panel-1',
                        wrapped: false,
                        icon: <MUIComponents.Icons.ArrowForwardIcon />
                    },
                    {
                        label: 'Item 3',
                        children: <div>Item Three</div>,
                        id: '2',
                        ariaControls: 'Panel-2',
                        wrapped: false,
                        disabled: false,
                        icon: <MUIComponents.Icons.AccountCircleIcon />
                    }
                ]}
                component="Label"
            /> */
}
