import { useTranslation } from 'react-i18next'

import { MUIComponents } from '../../muiComponents'
import Containers from '../../containers'
import * as GQLTypes from '../../../types/GQLTypes'

export const SmallScreenContent = ({
    quotation,
    loading,
    fileTypes,
    orderId
}: {
    loading: boolean
    orderId: GQLTypes.Order['id']
    fileTypes: string[]
    quotation: GQLTypes.Maybe<GQLTypes.Quotation> | undefined
}) => {
    const { t } = useTranslation()

    return (
        <MUIComponents.Styling.Box
            sx={{
                display: 'grid',
                gridAutoFlow: 'row',
                gridTemplateColumns: 'repeat(1, 1fr)',
                gridTemplateRows: 'repeat(10, auto)',
                width: '100%',
                height: 'calc(100% - 150px)'
            }}
        >
            <MUIComponents.Styling.Box
                sx={{
                    gridColumn: '1',
                    gridRow: '1/11',
                    height: 'fit-content',
                    minWidth: 0
                }}
            >
                {loading ? (
                    <MUIComponents.Skeleton
                        sx={{
                            backgroundColor: 'rgba(211, 211, 211, 0.5)',
                            margin: '40px'
                        }}
                        width="calc(100% - 80px)"
                        height="calc(100% - 80px)"
                        variant="rectangular"
                    />
                ) : (
                    <MUIComponents.NestedList
                        sx={{ height: 'calc(100% - 10px)' }}
                        collapseStyling={{
                            height: 'calc(100% - 50px) !important',
                            overflow: 'auto',
                            overflowX: 'hidden'
                        }}
                        listItems={[
                            {
                                id: 1,
                                name: t('filesAndCalc.mainInfo'),
                                defaultOpen: true,
                                subElement: (
                                    <Containers.Info.BasisInfo
                                        quotation={quotation}
                                        isMob
                                        isSmallerThanMob
                                        isTablet
                                    />
                                )
                            },
                            {
                                id: 2,
                                name: t('filesAndCalc.calculation'),
                                defaultOpen: true,
                                subElement: (
                                    <Containers.Info.Calculation
                                        quotation={quotation}
                                        isMob
                                        isSmallerThanMob
                                        isTablet
                                    />
                                )
                            },
                            {
                                id: 3,
                                name: t('filesAndCalc.details'),
                                defaultOpen: true,
                                subElement: (
                                    <Containers.Info.Details
                                        quotation={quotation}
                                        isMob
                                        isSmallerThanMob
                                        isTablet
                                    />
                                )
                            },
                            {
                                id: 4,
                                name: t('filesAndCalc.documents'),
                                defaultOpen: true,
                                subElement: (
                                    <Containers.Info.Documents
                                        fileTypes={fileTypes}
                                        orderId={orderId}
                                        quotationId={quotation?.id}
                                        bikeType={quotation?.bikeType?.name ?? '/'}
                                        isMob
                                        isSmallerThanMob
                                        isTablet
                                    />
                                )
                            }
                        ]}
                    />
                )}
            </MUIComponents.Styling.Box>
        </MUIComponents.Styling.Box>
    )
}
