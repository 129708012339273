import styled, { CSSProperties } from 'styled-components'
import { useLazyQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import { useTranslation } from 'react-i18next'

import { MUIComponents } from '../../muiComponents'
import Containers from '../../containers'
import { Employee, Language, QueryEmployeeArgs, QueryLanguagesArgs } from '../../../types/GQLTypes'
import {
    languagesState,
    alertObjState,
    myProfileState,
    userProfileState
} from '../../../store/store'
import API from '../../../API'
import useDeviceDetection from '../../../hooks/useDeviceDetection'
import { DevicesDetection } from '../../../hooks/types'
import { Blogpost } from '../../../types'
import { getBlogPosts } from '../blogPosts/utils'
import { MyServices } from '../../containers/services/MyServices'

export const Home = () => {
    const { t } = useTranslation()

    const [, setLanguages] = useRecoilState(languagesState)
    const [, setAlertObject] = useRecoilState(alertObjState)
    const [, setOpenProfile] = useRecoilState(myProfileState)
    const [userProfile] = useRecoilState(userProfileState)

    const [posts, setPosts] = useState<Blogpost[]>([])

    // hook
    const [devices] = useDeviceDetection()

    const [employeeAccessCode, setEmployeeAccessCode] =
        useState<Employee['employee_access_code']>(undefined)

    const [fetchLanguages, { error }] = useLazyQuery<{ languages: Language[] }, QueryLanguagesArgs>(
        API.Queries.LANGUAGES
    )

    const [fetchEmployeeAccessCode, { error: EmployeeAccessCodeError }] = useLazyQuery<
        { employee: Employee },
        QueryEmployeeArgs
    >(API.Queries.EMPLOYEE_ACCESS_CODE)

    const getPosts = () => {
        let language: string = userProfile?.language as string

        if (!language) {
            language = navigator?.languages[1] as string // languagecode [1]
        }

        const posts = getBlogPosts(language)
        setPosts(posts)
    }

    useEffect(() => {
        const initFetch = async () => {
            getPosts()
            const allLanguages = await fetchLanguages()
            const employee = await fetchEmployeeAccessCode({
                variables: {
                    id: userProfile.employee_id as string
                }
            })

            if (error) {
                setAlertObject({
                    message: t('home.languagesErr'),
                    severity: 'error'
                })
            }

            if (EmployeeAccessCodeError) {
                setAlertObject({
                    message: t('home.barcodeErr'),
                    severity: 'error'
                })
            }

            if (allLanguages?.data?.languages) {
                setLanguages(
                    (allLanguages?.data?.languages ?? []).map((item) => ({ ...item, id: item.Id }))
                )
            }

            if (employee?.data?.employee) {
                setEmployeeAccessCode(employee?.data?.employee.employee_access_code)
            } else {
                setEmployeeAccessCode('/')
            }
        }

        if (userProfile.employee_id) {
            initFetch()
        }
    }, [userProfile])

    const isMob = devices.isMob || devices.isSmallerThanMob
    const isTablet = devices.isTablet

    return (
        <Main {...(isMob && { margin: '60px 20px 0 20px' })}>
            <Wrapper>
                <MUIComponents.Styling.Box
                    sx={{
                        display: 'grid',
                        gridAutoFlow: 'row',
                        gridTemplateColumns: isTablet
                            ? 'repeat(2, 1fr)'
                            : isMob
                            ? 'repeat(1, 1fr)'
                            : 'repeat(10, 1fr)',
                        gridTemplateRows: 'repeat(7, 1fr)',
                        gap: 1,
                        width: '100%',
                        height: '100%',
                        ...(devices.isWeb &&
                            devices.heightIsSmallerThan850 && {
                                minHeight: '850px'
                            })
                    }}
                >
                    <MUIComponents.Styling.Box
                        sx={{
                            gridColumn: getGrid({ devices, type: ComponentTypes.TabsDocuments })
                                ?.column,
                            gridRow: getGrid({ devices, type: ComponentTypes.TabsDocuments })?.row,
                            minWidth: 0,
                            ...((isMob || isTablet) && { minHeight: 'auto' })
                        }}
                    >
                        <MyServices isMob={isMob} isSmallerThanMob={isMob} isTablet={isMob} />
                    </MUIComponents.Styling.Box>
                    <MUIComponents.Styling.Box
                        sx={{
                            gridColumn: getGrid({ devices, type: ComponentTypes.Carousel })?.column,
                            gridRow: getGrid({ devices, type: ComponentTypes.Carousel })?.row,
                            minWidth: 0,
                            marginBottom: '10px',
                            ...(isTablet && { minHeight: '700px' }),
                            ...(isMob && { minHeight: 'auto' })
                        }}
                    >
                        {isMob ? (
                            <Containers.SmallScreenCarousel items={posts} />
                        ) : (
                            <Containers.Carousel items={posts} />
                        )}
                    </MUIComponents.Styling.Box>
                    <MUIComponents.Styling.Box
                        sx={{
                            gridColumn: getGrid({ devices, type: ComponentTypes.Dossiers })?.column,
                            gridRow: getGrid({ devices, type: ComponentTypes.Dossiers })?.row,
                            minWidth: 0,
                            ...((isMob || isTablet) && { minHeight: '350px' })
                        }}
                    >
                        <Containers.Dossiers.Dossiers />
                    </MUIComponents.Styling.Box>
                    <MUIComponents.Styling.Box
                        sx={{
                            gridColumn: getGrid({ devices, type: ComponentTypes.LeasingSimulation })
                                ?.column,
                            gridRow: getGrid({ devices, type: ComponentTypes.LeasingSimulation })
                                ?.row,
                            minWidth: 0,
                            marginBottom: '10px',
                            ...(isTablet && {
                                minHeight: '400px'
                            }),
                            ...(isMob && {
                                minHeight: '650px'
                            })
                        }}
                    >
                        <Containers.LeasingSimulation
                            isMob={devices.isMob}
                            isTablet={devices.isTablet}
                            isSmallerThanMob={devices.isSmallerThanMob}
                        />
                    </MUIComponents.Styling.Box>
                    <MUIComponents.Styling.Box
                        sx={{
                            gridColumn: getGrid({ devices, type: ComponentTypes.DealerLocator })
                                ?.column,
                            gridRow: getGrid({ devices, type: ComponentTypes.DealerLocator })?.row,
                            minWidth: 0,
                            ...((isMob || isTablet) && { minHeight: '300px' })
                        }}
                    >
                        <Containers.Locators.DealerLocator
                            isMob={devices.isMob}
                            isTablet={devices.isTablet}
                            isSmallerThanMob={devices.isSmallerThanMob}
                        />
                    </MUIComponents.Styling.Box>
                    <MUIComponents.Styling.Box
                        sx={{
                            gridColumn: getGrid({ devices, type: ComponentTypes.ProfileMessage })
                                ?.column,
                            gridRow: getGrid({ devices, type: ComponentTypes.ProfileMessage })?.row,
                            minWidth: 0,
                            marginBottom: isMob || isTablet ? undefined : '10px',
                            ...((isMob || isTablet) && {
                                minHeight: devices.isSmallerThanMob ? '450px' : '430px'
                            })
                        }}
                    >
                        <Containers.Messages.ProfileMessage
                            onClick={() => {
                                setOpenProfile(true)
                            }}
                            barcode={employeeAccessCode}
                            isSmallerThanMob={devices.isSmallerThanMob}
                        />
                    </MUIComponents.Styling.Box>

                    {(isMob || isTablet) && (
                        <MUIComponents.Styling.Box
                            sx={{
                                gridColumn: isTablet ? '1/3' : '1',
                                gridRow: '20',
                                minWidth: 0
                            }}
                        >
                            <Containers.Copyright
                                sx={{
                                    color: 'rgb(128,128,128)',
                                    margin: devices.isTablet ? 'auto 5px' : 'auto 25px'
                                }}
                                paperStyling={{
                                    margin: 'unset',
                                    width: 'calc(100% - 20px)'
                                }}
                                isMob={devices.isMob || devices.isSmallerThanMob}
                                isTablet={devices.isTablet}
                            />
                        </MUIComponents.Styling.Box>
                    )}
                </MUIComponents.Styling.Box>

                {!isMob && !isTablet && (
                    <Containers.Copyright
                        sx={{
                            color: 'rgb(128,128,128)',
                            margin: devices.isTablet ? 'auto 5px' : 'auto 25px'
                        }}
                        paperStyling={{
                            margin: 'unset',
                            width: 'calc(100% - 20px)'
                        }}
                        isMob={devices.isMob || devices.isSmallerThanMob}
                        isTablet={devices.isTablet}
                    />
                )}
            </Wrapper>
        </Main>
    )
}

const Wrapper = styled.div`
    width: 100%;
    height: calc(100vh - 150px);
    z-index: 1;
`

const Main = styled.div<{ margin?: CSSProperties['margin'] }>`
    height: calc(100vh - 150px);
    margin: ${(props) => props.margin ?? '60px'};
`

enum ComponentTypes {
    TabsDocuments = 'TabsDocuments',
    Carousel = 'Carousel',
    Dossiers = 'Dossiers',
    LeasingSimulation = 'LeasingSimulation',
    DealerLocator = 'DealerLocator',
    ProfileMessage = 'ProfileMessage'
}

// ! You can switch the ordering of the components in here by just updating the grid values
// ! IMPORTANT: The order needs to be ranked from 1 till the end, for example 1 <-> 20
// ! IMPORTANT: You still have to take the difference between the values into account, for example: 1/4 -> 3/6, another example: 5/10 -> 12/17
const getGrid = ({ type, devices }: { type: ComponentTypes; devices: DevicesDetection }) => {
    switch (type) {
        case ComponentTypes.TabsDocuments:
            if (devices.isTablet) {
                return {
                    column: '1/3',
                    row: '1/2'
                }
            } else if (devices.isMob) {
                return {
                    column: '1',
                    row: '4/5'
                }
            } else if (devices.isSmallerThanMob) {
                return {
                    column: '1',
                    row: '4/5'
                }
            } else {
                return {
                    column: '1/6',
                    row: '1/2'
                }
            }
        case ComponentTypes.Carousel:
            if (devices.isTablet) {
                return {
                    column: '1/3',
                    row: '2/8'
                }
            } else if (devices.isMob) {
                return {
                    column: '1',
                    row: '14/20'
                }
            } else if (devices.isSmallerThanMob) {
                return {
                    column: '1',
                    row: '14/20'
                }
            } else {
                return {
                    column: '1/6',
                    row: '2/8'
                }
            }
        case ComponentTypes.Dossiers:
            if (devices.isTablet) {
                return {
                    column: '1/3',
                    row: '8/11'
                }
            } else if (devices.isMob) {
                return {
                    column: '1',
                    row: '5/8'
                }
            } else if (devices.isSmallerThanMob) {
                return {
                    column: '1',
                    row: '5/8'
                }
            } else {
                return {
                    column: '6/9',
                    row: '1/4'
                }
            }
        case ComponentTypes.LeasingSimulation:
            if (devices.isTablet) {
                return {
                    column: '1/3',
                    row: '11/14'
                }
            } else if (devices.isMob) {
                return {
                    column: '1',
                    row: '8/11'
                }
            } else if (devices.isSmallerThanMob) {
                return {
                    column: '1',
                    row: '8/11'
                }
            } else {
                return {
                    column: '6/9',
                    row: '4/8'
                }
            }
        case ComponentTypes.DealerLocator:
            if (devices.isTablet) {
                return {
                    column: '1/2',
                    row: '14/17'
                }
            } else if (devices.isMob) {
                return {
                    column: '1',
                    row: '11/14'
                }
            } else if (devices.isSmallerThanMob) {
                return {
                    column: '1',
                    row: '11/14'
                }
            } else {
                return {
                    column: '9/11',
                    row: '1/4'
                }
            }
        case ComponentTypes.ProfileMessage:
            if (devices.isTablet) {
                return {
                    column: '2/3',
                    row: '14/17'
                }
            } else if (devices.isMob) {
                return {
                    column: '1',
                    row: '1/4'
                }
            } else if (devices.isSmallerThanMob) {
                return {
                    column: '1',
                    row: '1/4'
                }
            } else {
                return {
                    column: '9/11',
                    row: '4/8'
                }
            }
        default:
            return {
                column: undefined,
                row: undefined
            }
    }
}
