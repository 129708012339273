export const size = {
    mobileS: 320,
    mobileM: 375,
    mobileL: 425,
    tablet: 768,
    laptop: 1024,
    laptopL: 1440,
    desktop: 2560
}

export const sizeInPixels = {
    mobileS: `${size.mobileS}px`,
    mobileM: `${size.mobileM}px`,
    mobileL: `${size.mobileL}px`,
    tablet: `${size.tablet}px`,
    laptop: `${size.laptop}px`,
    laptopL: `${size.laptopL}px`,
    desktop: `${size.desktop}px`
}

const getMinWidth = (device: keyof typeof size) => `(min-width: ${sizeInPixels[device]})`

export const device = {
    mobileS: getMinWidth('mobileS'),
    mobileM: getMinWidth('mobileM'),
    mobileL: getMinWidth('mobileL'),
    tablet: getMinWidth('tablet'),
    laptop: getMinWidth('laptop'),
    laptopL: getMinWidth('laptopL'),
    desktop: getMinWidth('desktop'),
    desktopL: getMinWidth('desktop')
}
