import MuiBox, { BoxProps as MuiBoxProps } from '@mui/material/Box'
import MuiPaper, { PaperProps as MUIPaperProps } from '@mui/material/Paper'
import MuiGrid, { GridProps as MuiGridProps } from '@mui/material/Grid'
import MuiCssBaseline, { CssBaselineProps } from '@mui/material/CssBaseline'
import MuiContainer, { ContainerProps } from '@mui/material/Container'
import MuiStack, { StackProps } from '@mui/material/Stack'
import MuiDivider, { DividerProps } from '@mui/material/Divider'
import { ElementType } from 'react'
import styled from 'styled-components'

const StyledDivider = styled(MuiDivider)`
    color: ${(props) => props.theme.palette.primary.main} !important;
`

// added any types for these 2 interfaces -> it can contain a component, string, ...
interface GridProps extends MuiGridProps {
    component?: ElementType<any>
    elevation?: number
    square?: boolean
    align?: string
}

interface BoxProps extends MuiBoxProps {
    component?: ElementType<any>
    noValidate?: boolean
    onSubmit?: React.FormEventHandler<any> | React.UIEventHandler<any>
    src?: string
    points?: string
}

interface PaperProps extends MUIPaperProps {
    error?: boolean
}

const Grid = (props: GridProps) => <MuiGrid {...props} />

const Box = (props: BoxProps) => <MuiBox {...props} />

const Paper = (props: PaperProps) => <MuiPaper {...props} />

const Baseline = (props: CssBaselineProps) => <MuiCssBaseline {...props} />

const Container = (props: ContainerProps) => <MuiContainer {...props} />

const Stack = (props: StackProps) => <MuiStack {...props} />

const Divider = ({ children, ...props }: DividerProps) => (
    <StyledDivider {...props}>{children}</StyledDivider>
)

export default { Grid, Paper, Box, Baseline, Container, Stack, Divider }

// Examples of the Grid component with props:
{
    /* <MUIComponents.Styling.Grid
        item
        xs={false}
        sm={4}
        md={6}
        sx={{
            backgroundImage,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
                t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center'
        }}
    /> */
}

{
    /* <MUIComponents.Styling.Grid container rowSpacing={2} columnSpacing={2}>
        {...children}
</MUIComponents.Styling.Grid> */
}

// Example of the Box component with props:
{
    /* <MUIComponents.Styling.Box component="div" sx={{ mt: 5 }}>
        {...children}
        </MUIComponents.Styling.Box> */
}

// Example of the Paper component with props:
{
    /* <MUIComponents.Styling.Paper
                elevation={5}
                style={{ padding: 25, width: '75%', maxWidth: 1000, margin: 'auto', marginTop: 40 }}
            >
                {...children}
            </MUIComponents.Styling.Paper> */
}

// Example of the Baseline component with props:
{
    /* <MUIComponents.Styling.Baseline /> */
}

// Example of the Container component with props:
{
    /* <MUIComponents.Styling.Container style={{ width: 350, height: 100 }}>
            {...children}
        </MUIComponents.Styling.Container> */
}

// Example of the Stack component with props:
{
    /* <MUIComponents.Styling.Stack
                        sx={{ flexFlow: 'row wrap', margin: '16px 0', width: '75%' }}
                        direction="row"
                        spacing={1}
                    >
                        {...children}
                    </MUIComponents.Styling.Stack> */
}

// Example of the Divider component with props:
{
    /* <MUIComponents.Styling.Divider light style={{ marginTop: 15, marginBottom: 15 }} /> */
}
