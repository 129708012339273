import styled from 'styled-components'
import { useRecoilState } from 'recoil'
import { useEffect, useState } from 'react'
import fileDownload from 'js-file-download'
import { useLazyQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import theme from '../../../theme/theme'
import { MUIComponents } from '../../muiComponents'
import Containers from '../../containers'
import { TabProps } from '../../muiComponents/types'
import { DeviceParameters } from '../../../types'
import StyledComponents from '../../StyledComponents'
import * as GQLTypes from '../../../types/GQLTypes'
import { NoContent } from './NoContent'
import API from '../../../API'
import {
    alertObjState,
    loadingDownloadState,
    userObjectState,
    userProfileState
} from '../../../store/store'
import { loadingDocuments } from '../../../utils/utilsWithJSX'
import { defaultVisibleFileTypes, downloadFileAsPdf } from '../../../utils/utils'

export const Documents = ({
    isMob = false,
    isSmallerThanMob = false,
    isTablet = false,
    fileTypes = defaultVisibleFileTypes,
    ...props
}: DeviceParameters & {
    tabs?: TabProps[]
    orderId?: GQLTypes.Order['id']
    quotationId?: GQLTypes.Quotation['id']
    bikeType?: string
    fileTypes?: string[]
}) => {
    const { t } = useTranslation()

    const isNotBigScreen = isMob || isTablet || isSmallerThanMob

    // global state
    const [userObject] = useRecoilState(userObjectState)
    const [userProfile] = useRecoilState(userProfileState)
    const [, setAlertObject] = useRecoilState(alertObjState)
    const [, setLoadingDownload] = useRecoilState(loadingDownloadState)

    // local state
    const [tabs, setTabs] = useState<GQLTypes.OrderLog[]>([])
    const [loading, setLoading] = useState(true)

    // queries
    const [collectOrderLogs, { error: orderLogsError }] = useLazyQuery<
        { orderLogs: GQLTypes.OrderLog[] },
        GQLTypes.QueryOrderLogsArgs
    >(API.Queries.ORDER_LOGS_BY_ORDER)

    const [collectOrders, { error: ordersError }] = useLazyQuery<
        { employee: GQLTypes.Employee },
        GQLTypes.QueryEmployeeArgs
    >(API.Queries.ORDERS_BY_EMPLOYEE)

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            const resultOrders = await collectOrders({
                variables: {
                    id: userProfile?.employee_id as string
                }
            })
            if (!props.orderId) {
                setTabs([])
                setLoading(false)
                return
            }
            // const orderId = !props?.orderId
            //     ? resultOrders.data?.employee?.employee_quotations
            //           ?.filter((quote) => quote?.order?.orderStatus?.id === '5')
            //           ?.reduce(
            //               (a, b) =>
            //                   new Date(a?.order?.date ?? '') > new Date(b?.order?.date ?? '')
            //                       ? a
            //                       : b,
            //               {}
            //           )?.order?.id
            //     : props?.orderId

            const resultOrderLogs = props.orderId
                ? await collectOrderLogs({
                      variables: {
                          filter: {
                              order_fk: props.orderId
                          },
                          size: 100,
                          page: 0
                      }
                  })
                : { data: { orderLogs: [] } }

            if (resultOrderLogs?.data?.orderLogs && resultOrderLogs?.data?.orderLogs?.length > 0) {
                setTabs(
                    fileTypes && fileTypes?.length > 0
                        ? resultOrderLogs.data.orderLogs.filter(
                              (orderLog) =>
                                  orderLog?.orderLogType?.name &&
                                  fileTypes?.includes(orderLog?.orderLogType?.name)
                          )
                        : resultOrderLogs.data.orderLogs
                )
                setLoading(false)
            } else {
                setLoading(false)
            }

            if (orderLogsError || ordersError) {
                setAlertObject({
                    message: t('filesAndCalc.documentsContainer.loadErr'),
                    severity: 'error'
                })
                console.log(orderLogsError)
                console.log(ordersError)
                setTabs([])
                setLoading(false)
            }
        }

        setTimeout(() => {
            if (userProfile?.employee_id && isNotBigScreen) {
                fetchData()
            }
        }, 2000)
    }, [userProfile?.employee_id, props?.orderId])

    if (
        !loading &&
        !props.orderId &&
        tabs.length === 0 &&
        (props.tabs?.length === 0 || props.tabs === undefined)
    ) {
        return <NoContent />
    }

    return (
        <>
            {!isNotBigScreen && (
                <MUIComponents.Typography
                    sx={{
                        color: theme.palette.primary.main,
                        fontWeight: 'bold',
                        paddingTop: '20px'
                    }}
                    variant="body2"
                >
                    {t('filesAndCalc.documentsContainer.title')}
                </MUIComponents.Typography>
            )}

            {!isNotBigScreen ? (
                <Containers.Documents.TabsDocuments
                    parentTabsBoxStyling={{ height: '100%' }}
                    tabWidth="100%"
                    orientation="vertical"
                    noActivation
                    tabs={props.tabs}
                    orderId={props.orderId}
                    quotationId={props.quotationId}
                    bikeTypeName={props?.bikeType}
                    fileTypes={["CALCULATIONDOC", "CALCULATIONDOC_SIGNED", "QUOTATIONDOC", "INVOICEDOC", "RECEIPTDOC", "RECEIPTDOC_SIGNED"]}
                />
            ) : (
                <MUIComponents.Styling.Box
                    sx={{
                        display: 'grid',
                        gridAutoFlow: 'row',
                        gridTemplateColumns: 'repeat(2, 1fr)',
                        gridTemplateRows: `repeat(${
                            Math.round((props.tabs ?? []).length / 2) ?? 4
                        }, 1fr)`,
                        width: '100%',
                        height: '100%',
                        padding: isMob || isSmallerThanMob ? undefined : '0 25px',
                        ...((isMob || isSmallerThanMob) && {
                            border: '0.1px solid rgb(0, 0, 0, 0.25)',
                            borderRadius: '10px'
                        }),
                        ...((props.tabs ?? tabs ?? []).length <= 4 && {
                            display: 'flex',
                            flexDirection: 'row'
                        })
                    }}
                >
                    {loadingDocuments({
                        loading,
                        length:
                            (props.tabs ?? tabs ?? []).length === 0
                                ? 4
                                : (props.tabs ?? tabs ?? []).length,
                        tabs: (props.tabs ?? []).length > 0 ? props.tabs : tabs
                    })?.map((tab, i) => {
                        const isEven = i % 2 === 0

                        const getGridRow = (i: number) => {
                            if (i < 2) {
                                return '1/2'
                            } else if (i < 4) {
                                return '2/3'
                            } else if (i < 6) {
                                return '3/4'
                            } else if (i < 8) {
                                return '4/5'
                            } else {
                                return '5/6'
                            }
                        }

                        return (
                            <Wrapper
                                key={i}
                                onClick={async (_e) => {
                                    setLoadingDownload(true)

                                    try {
                                        const result = await downloadFileAsPdf({
                                            item: { fileId: tab.fileId, id: tab.id },
                                            token: userObject.accessToken as string
                                        })
                                        const fileName = tab.label.props.children === "Offerte van dealer" ? `Offerte B2Bike - ${props?.bikeType}.pdf`: `Q${props?.quotationId}-${tab.label.props.children}.pdf`
                                        fileDownload(result?.data, fileName)
                                        setLoadingDownload(false)
                                    } catch (err) {
                                        setAlertObject({
                                            message: t('filesAndCalc.documentsContainer.downloadErr'),
                                            severity: 'error'
                                        })
                                        setLoadingDownload(false)
                                    }
                                }}
                                {...((props.tabs ?? tabs ?? []).length <= 4 && {
                                    style: { width: '100%' }
                                })}
                            >
                                <MUIComponents.Styling.Box
                                    sx={{
                                        gridColumn: isEven ? '1' : '2',
                                        gridRow: getGridRow(i),
                                        minWidth: 0,
                                        textAlign: 'center',
                                        cursor: 'pointer',
                                        width: '100%',
                                        ...((isMob || isSmallerThanMob) && {
                                            borderLeft: isEven
                                                ? undefined
                                                : '0.1px solid rgb(0, 0, 0, 0.25)',
                                            borderRight: !isEven
                                                ? undefined
                                                : '0.1px solid rgb(0, 0, 0, 0.25)',
                                            borderBottom: '0.1px solid rgb(0, 0, 0, 0.25)',
                                            padding: '10px',
                                            borderWidth: 'thin'
                                        }),
                                        ...((props.tabs ?? tabs ?? []).length <= 4 && {
                                            borderLeft: 'unset',
                                            borderRight:
                                                ((props.tabs ?? tabs ?? []).length === 0
                                                    ? 4
                                                    : (props.tabs ?? tabs ?? []).length) ===
                                                i + 1
                                                    ? 'unset'
                                                    : '0.1px solid rgb(0, 0, 0, 0.25)',
                                            borderBottom: 'unset',
                                            borderWidth: 'thin'
                                        })
                                    }}
                                >
                                    {tab.icon}
                                    {tab.label}
                                </MUIComponents.Styling.Box>
                            </Wrapper>
                        )
                    })}
                </MUIComponents.Styling.Box>
            )}
        </>
    )
}

const Wrapper = styled.div`
    ${StyledComponents.TruncatedText}
    display: flex;
`
