import MuiMenuItem, { MenuItemProps as MuiMenuItemProps } from '@mui/material/MenuItem'
import { CSSProperties } from 'styled-components'

interface MenuItemProps extends MuiMenuItemProps {
    // you can declare custom props in here
    style?: CSSProperties
}

export const MenuItem = ({ style = {}, autoFocus = false, ...props }: MenuItemProps) => (
    <MuiMenuItem autoFocus={autoFocus} style={style} {...props} />
)

// Example of this component with props:
{
    /* <MUIComponents.MenuItem key={item.label} onClick={() => itemClick(item)}>
                    <MUIComponents.Typography>{item.label}</MUIComponents.Typography>
                </MUIComponents.MenuItem> */
}
