import { useState, useEffect } from 'react'
import axios from 'axios'
import {
    getAuth,
    confirmPasswordReset,
    signInWithEmailAndPassword,
    verifyPasswordResetCode
} from 'firebase/auth'
import { useRecoilState } from 'recoil'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { MUIComponents } from '../../muiComponents'
import { FormValue } from '../../../types'
import { CheckLabel } from './subComponents/CheckLabel'
import firebaseApp from '../../../firebase'
import { headers, validateEmail } from '../../../utils/utils'
import API from '../../../API'
import { alertObjState } from '../../../store/store'
import Containers from '../../containers'
import StyledComponents from '../../StyledComponents'
import theme from '../../../theme/theme'
import useDeviceDetection from '../../../hooks/useDeviceDetection'

export const PasswordReset = () => {
    const { t } = useTranslation()

    const urlParams = new URLSearchParams(window.location.search)

    const [_, setAlertObject] = useRecoilState(alertObjState)

    const [password, setPassword] = useState<FormValue>({ error: false, value: '' })
    const [confirmPassword, setConfirmPassword] = useState<FormValue>({ error: false, value: '' })
    const [loading, setLoading] = useState<boolean>(false)
    const [oobCode, setOobCode] = useState<string>('')
    const [email, setEmail] = useState<string>('')
    const [emailError, setEmailError] = useState<{ valid: boolean; error: boolean }>({
        error: false,
        valid: false
    })
    const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false)

    const auth = getAuth(firebaseApp)

    // hooks
    const navigate = useNavigate()
    const [devices] = useDeviceDetection()

    useEffect(() => {
        setLoading(true)

        verifyPasswordResetCode(auth, urlParams.get('oobCode') ?? '')
            .then((email) => {
                setOobCode(oobCode)
                setEmail(email)
            })
            .catch((err) => {
                if (['auth/expired-action-code', 'auth/invalid-action-code'].includes(err.code))
                    setAlertObject({
                        severity: 'error',
                        message: t('passwordReset.recoveryCodeErr')
                    })
                else setAlertObject({})
            })
            .finally(() => setLoading(false))
    }, [])

    const isValueValidWithRegex: (val: RegExpMatchArray | null) => boolean = (
        val: RegExpMatchArray | null
    ) => val === null

    // password checks
    const characterCheck = (password.value as string)?.match(/^.{8,}$/)
    const numberCheck = (password.value as string)?.match(/^(?=.*\d)/)
    const specialChararacterCheck =
        (password.value as string)?.match(/\W/) ||
        (password.value as string)?.match('_')
    const caseCheck = (password.value as string)?.match(/^(?=.*[A-Z])/)
    const passwordCheck =
        password.value === confirmPassword.value && (password.value as string)?.length > 0

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault()

        setLoading(true)

        confirmPasswordReset(auth, urlParams.get('oobCode') ?? oobCode, password.value as string)
            .then(() => {
                signInWithEmailAndPassword(auth, email, password.value as string)
                    .then(() => {
                        setTimeout(() => {
                            window.location.replace('/home')
                        }, 30)
                        return
                    })
                    .catch(() => {
                        setAlertObject({
                            severity: 'error',
                            message: t('passwordReset.passwordResetAlert')
                        })
                        return
                    })
            })
            .catch(() => {
                setAlertObject({
                    severity: 'error',
                    message: t('passwordReset.passwordResetAlert')
                })
                return
            })
            .finally(() => setLoading(false))
    }

    const CheckLabelCommonProps = {
        isSmallerThanMob: devices.isSmallerThanMob,
        isSmallerThen170Width: devices.isSmallerThen170Width
    }

    const mobileScreen = devices.isMob || devices.isSmallerThanMob

    return (
        <StyledComponents.Wrapper>
            <MUIComponents.Styling.Grid
                container
                sx={{
                    height: devices.isWeb ? 'calc(100vh - 20vh)' : 'calc(100vh - 10vh)',
                    marginTop: devices.isWeb ? '19vh' : '9vh'
                }}
                columns={15}
                spacing={1}
            >
                <MUIComponents.Styling.Grid item xs={devices.isWeb ? 4.5 : 1.5} />

                {devices.isWeb && (
                    <MUIComponents.Styling.Grid
                        item
                        component={MUIComponents.Styling.Paper}
                        xs={2}
                        elevation={6}
                        sx={{
                            maxHeight: '75%',
                            padding: 'unset !important',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center center',
                            backgroundImage: "url('/img/login-design.png')",
                            backgroundSize: 'cover',
                            minHeight: '550px'
                        }}
                    />
                )}

                <MUIComponents.Styling.Grid
                    item
                    xs={devices.isWeb ? 4 : 12}
                    component={MUIComponents.Styling.Paper}
                    elevation={6}
                    sx={{
                        maxHeight: '75%',
                        position: 'relative',
                        height: '100%',
                        boxShadow: mobileScreen ? 'unset' : undefined,
                        paddingRight: '8px',
                        minHeight: '550px'
                    }}
                >
                    <MUIComponents.Styling.Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            ...((devices.isTablet || devices.isWeb) && { my: 1.5, mx: 1.5 })
                        }}
                    >
                        <MUIComponents.Typography
                            sx={{ color: theme.palette.primary.main }}
                            variant="h5"
                        >
                            {t('passwordReset.setPassword')}
                        </MUIComponents.Typography>
                    </MUIComponents.Styling.Box>
                    <MUIComponents.Styling.Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            height: 'calc(100% - 80px)',
                            mx: 1.5,
                            ...(mobileScreen && { padding: '10px' })
                        }}
                        component="form"
                        noValidate
                        onSubmit={handleSubmit}
                    >
                        <MUIComponents.Styling.Stack
                            style={{
                                color: 'green',
                                boxShadow:
                                    '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
                                borderRadius: '5px',
                                width: '100%',
                                ...((devices.isMob ||
                                    devices.isTablet ||
                                    devices.isSmallerThanMob) && {
                                    maxWidth: '400px'
                                })
                            }}
                        >
                            <CheckLabel
                                error={isValueValidWithRegex(characterCheck)}
                                content={t('passwordReset.charRule')}
                                {...((devices.isMob ||
                                    devices.isSmallerThanMob ||
                                    devices.isSmallerThen170Width) && {
                                    tooltip: t('passwordReset.charRule')
                                })}
                                {...CheckLabelCommonProps}
                            />
                            <CheckLabel
                                error={isValueValidWithRegex(numberCheck)}
                                content={t('passwordReset.numberRule')}
                                {...((devices.isMob ||
                                    devices.isSmallerThanMob ||
                                    devices.isSmallerThen170Width) && {
                                    tooltip: t('passwordReset.numberRule')
                                })}
                                {...CheckLabelCommonProps}
                            />
                            <CheckLabel
                                error={isValueValidWithRegex(specialChararacterCheck)}
                                content={t('passwordReset.specialRule')}
                                {...(devices.isMob ||
                                devices.isSmallerThanMob ||
                                devices.isSmallerThen170Width
                                    ? {
                                          tooltip: t('passwordReset.specialTooltipLong')
                                      }
                                    : {
                                          tooltip: t('passwordReset.specialTooltipShort')
                                      })}
                                {...CheckLabelCommonProps}
                            />
                            <CheckLabel
                                error={isValueValidWithRegex(caseCheck)}
                                content={t('passwordReset.caseRule')}
                                {...((devices.isMob ||
                                    devices.isSmallerThanMob ||
                                    devices.isSmallerThen170Width) && {
                                    tooltip: t('passwordReset.caseRule')
                                })}
                                {...CheckLabelCommonProps}
                            />
                            <CheckLabel
                                error={!passwordCheck}
                                content={t('passwordReset.passwordEquals')}
                                {...((devices.isMob ||
                                    devices.isSmallerThanMob ||
                                    devices.isSmallerThen170Width) && {
                                    tooltip: t('passwordReset.passwordEquals')
                                })}
                                {...CheckLabelCommonProps}
                            />
                        </MUIComponents.Styling.Stack>

                        <MUIComponents.TextFields.HiddenTextField
                            id="password"
                            label={t('passwordReset.newPasswordInput')}
                            name="password"
                            type="password"
                            required
                            autoFocus={false}
                            autoComplete="current-password"
                            value={password.value}
                            error={password.error}
                            onChange={(e) => {
                                setPassword({ value: e.target.value, error: false })
                            }}
                            {...(password.error && {
                                errorText: t('passwordReset.passwordCheckkErr')
                            })}
                        />
                        <MUIComponents.TextFields.HiddenTextField
                            id="confirmPassword"
                            label={t('passwordReset.confirmPasswordInput')}
                            name="confirmPassword"
                            type="password"
                            required
                            autoFocus={false}
                            value={confirmPassword.value}
                            error={confirmPassword.error}
                            onChange={(e) => {
                                setConfirmPassword({
                                    value: e.target.value,
                                    error: e.target.value !== password.value
                                })
                            }}
                            {...(confirmPassword.error && {
                                errorText: t('passwordReset.confirmCheckErr')
                            })}
                        />
                        <MUIComponents.Buttons.LoadingButton
                            id="password reset"
                            type="submit"
                            sx={{
                                mt: 3,
                                display: 'block',
                                marginLeft: 'auto',
                                width: '100%'
                            }}
                            content={t('passwordReset.setPassword')}
                            loading={loading}
                            disabled={
                                !(
                                    !isValueValidWithRegex(characterCheck) &&
                                    !isValueValidWithRegex(numberCheck) &&
                                    !isValueValidWithRegex(specialChararacterCheck) &&
                                    !isValueValidWithRegex(caseCheck) &&
                                    passwordCheck
                                )
                            }
                        />

                        <MUIComponents.Styling.Grid
                            sx={{
                                margin: 'auto',
                                ...(mobileScreen && { marginTop: '10px' })
                            }}
                            container
                        >
                            <MUIComponents.Styling.Grid item xs>
                                <StyledComponents.ItemNavigation
                                    onClick={() => {
                                        setOpenConfirmModal(true)
                                    }}
                                    sx={{ margin: 0 }}
                                >
                                    {t('passwordReset.emailNotReceived')}
                                </StyledComponents.ItemNavigation>
                            </MUIComponents.Styling.Grid>
                            <MUIComponents.Styling.Grid
                                item
                                {...(devices.isSmallerThanMob && { sx: { marginTop: '10px' } })}
                            >
                                <StyledComponents.ItemNavigation
                                    onClick={() => {
                                        navigate('/login')
                                    }}
                                    sx={{ margin: 0 }}
                                >
                                    {t('passwordReset.loginBtn')}
                                </StyledComponents.ItemNavigation>
                            </MUIComponents.Styling.Grid>
                            {mobileScreen && (
                                <Containers.Copyright
                                    isMob={mobileScreen}
                                    isSmallerThanMob={devices.isSmallerThanMob}
                                    sx={{
                                        color: 'rgb(128,128,128)',
                                        margin: 'auto 5px',
                                        fontSize: 'small'
                                    }}
                                />
                            )}
                        </MUIComponents.Styling.Grid>
                    </MUIComponents.Styling.Box>
                </MUIComponents.Styling.Grid>

                <MUIComponents.Styling.Grid item xs={3} elevation={6} />

                {(devices.isTablet || devices.isWeb) && (
                    <MUIComponents.Styling.Grid
                        item
                        xs={20}
                        elevation={6}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                            marginTop: 'auto'
                        }}
                        id="copyright"
                    >
                        <Containers.Copyright
                            isMob={devices.isMob}
                            isTablet={devices.isTablet}
                            sx={{
                                color: 'rgb(128,128,128)',
                                margin: devices.isTablet ? 'auto 5px' : 'auto 25px'
                            }}
                        />
                    </MUIComponents.Styling.Grid>
                )}
            </MUIComponents.Styling.Grid>
            <MUIComponents.Dialog
                open={openConfirmModal}
                onClose={() => {
                    setOpenConfirmModal(false)
                }}
                cancelClick={() => {
                    setOpenConfirmModal(false)
                }}
                confirmClick={async () => {
                    try {
                        const result = await axios({
                            method: 'post',
                            url: API.utils.apiUrlsEnv.handlePasswordReset,
                            data: {
                                email,
                                type: 'reset',
                                originUrl: window.location.origin
                            },
                            headers
                        })

                        console.log('result is', result)

                        setLoading(false)
                    } catch (err) {
                        setAlertObject({
                            severity: 'error',
                            message: t('passwordReset.handlePasswordResetAlert')
                        })
                        setLoading(false)
                    }

                    setOpenConfirmModal(false)
                }}
                title={t('passwordReset.resendDialogTitle')}
                cancelButtonText={t('general.buttons.cancel')}
                confirmButtonText={t('general.buttons.confirm')}
                contentComponent={
                    <MUIComponents.TextFields.TextField
                        id="email"
                        label={t('passwordReset.email')}
                        name="email"
                        size="small"
                        autoFocus={false}
                        onBlur={(e) => {
                            const isEmailValid = validateEmail(e.target.value)

                            if (!isEmailValid) {
                                setEmailError({ error: true, valid: isEmailValid })
                            } else {
                                setEmailError({
                                    error: false,
                                    valid: isEmailValid
                                })
                                setEmail(e.target.value)
                            }
                        }}
                        error={emailError.error}
                        {...(emailError.error && {
                            helperText: !emailError.valid
                                ? t('login.mailCheckError')
                                : t('login.mailRequiredError')
                        })}
                    />
                }
                fullScreen
            />
        </StyledComponents.Wrapper>
    )
}
