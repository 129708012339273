import { useState, useLayoutEffect, RefObject } from 'react'

import { RefType } from '../types'

export const useIsOverflow = (ref: RefObject<RefType>, callback: (value: boolean) => void) => {
    const [isOverflow, setIsOverflow] = useState<boolean | undefined>(undefined)

    useLayoutEffect(() => {
        const { current } = ref

        const trigger = () => {
            if (
                (current as unknown as HTMLInputElement)?.scrollHeight &&
                (current as unknown as HTMLInputElement)?.clientHeight
            ) {
                const hasOverflow =
                    (current as unknown as HTMLInputElement)?.scrollHeight >
                    (current as unknown as HTMLInputElement)?.clientHeight

                setIsOverflow(hasOverflow)

                if (callback) callback(hasOverflow)
            }
        }

        if (current) {
            if ('ResizeObserver' in window) {
                new ResizeObserver(trigger).observe(current as unknown as Element)
            }

            trigger()
        }
    }, [callback, ref])

    return isOverflow
}
