import styled from 'styled-components'
import { useRecoilState } from 'recoil'
import { useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { MUIComponents } from '../../muiComponents'
import { DealerSearcher } from './DealerSearcher'
import { DealerList } from './DealerList'
import Containers from '../../containers'
import { alertObjState, dealersState } from '../../../store/store'
import { QueryDealersArgs, Dealer as DealerType } from '../../../types/GQLTypes'
import API from '../../../API'
import useDeviceDetection from '../../../hooks/useDeviceDetection'

export const Dealer = () => {
    const { t } = useTranslation()

    // global state
    const [, setDealers] = useRecoilState(dealersState)
    const [, setAlertObject] = useRecoilState(alertObjState)
    const [devices] = useDeviceDetection()

    // local state
    const [loading, setLoading] = useState(true)

    const [fetchDealers, { error }] = useLazyQuery<{ dealers: DealerType[] }, QueryDealersArgs>(
        API.Queries.DEALERS
    )

    useEffect(() => {
        const initFetch = async () => {
            const allDealers = await fetchDealers()

            if (error) {
                console.log(error.message)
                setAlertObject({
                    message: t('dealerSearch.dealerFetchErr'),
                    severity: 'error'
                })
                setLoading(false)
            }

            if (allDealers?.data?.dealers) {
                setDealers(
                    allDealers?.data?.dealers.filter(
                        (dealer) => dealer.latitude && dealer.longitude
                    ) ?? []
                )
                setLoading(false)
            }
        }

        initFetch()
    }, [])

    return (
        <Main>
            <Wrapper>
                <MUIComponents.Styling.Box
                    sx={{
                        display: 'grid',
                        gridAutoFlow: 'row',
                        gridTemplateColumns: 'repeat(10, 1fr)',
                        gridTemplateRows: 'repeat(10, auto)',
                        gap: 1,
                        width: '100%',
                        height: '100%'
                    }}
                >
                    <MUIComponents.Styling.Box
                        sx={{
                            gridColumn:
                                devices.isMob || devices.isSmallerThanMob || devices.isTablet
                                    ? '1/11'
                                    : '1/7',
                            gridRow: '1/8',
                            minWidth: 0
                        }}
                    >
                        <DealerSearcher loading={loading} />
                    </MUIComponents.Styling.Box>
                    <MUIComponents.Styling.Box
                        sx={{
                            gridColumn:
                                devices.isMob || devices.isSmallerThanMob || devices.isTablet
                                    ? '1/11'
                                    : '7/11',
                            gridRow:
                                devices.isMob || devices.isSmallerThanMob || devices.isTablet
                                    ? '8/15'
                                    : '1/8',
                            minWidth: 0
                        }}
                    >
                        <DealerList loading={loading} />
                    </MUIComponents.Styling.Box>

                    <MUIComponents.Styling.Box
                        sx={{
                            gridColumn: '1/11',
                            gridRow:
                                devices.isMob || devices.isSmallerThanMob || devices.isTablet
                                    ? '15/16'
                                    : '8/9',
                            minWidth: 0
                        }}
                    >
                        <Containers.Copyright
                            paperStyling={{
                                margin: 'auto',
                                marginTop: '10px',
                                width: 'calc(100% - 20px)'
                            }}
                            sx={{ color: 'rgb(128,128,128)', margin: 'auto 25px' }}
                            isMob={devices.isMob || devices.isSmallerThanMob}
                            isSmallerThanMob={devices.isSmallerThanMob}
                            isTablet={devices.isTablet}
                        />
                    </MUIComponents.Styling.Box>
                </MUIComponents.Styling.Box>
            </Wrapper>
        </Main>
    )
}

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    z-index: 1;
`

const Main = styled.div`
    margin: 20px;
    margin-top: 60px;
`
