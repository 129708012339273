import { useEffect, useState } from 'react'
import {
    isBrowser,
    isMobile,
    isDesktop,
    isTablet,
    isSmartTV,
    isWindows,
    isMacOs
} from 'react-device-detect' // https://www.npmjs.com/package/react-device-detect

import { size } from '../components/StyledComponents/devices'

const isBetweenTheSizes = (firstSize: number, secondSize: number) =>
    window.innerWidth >= firstSize && window.innerWidth <= secondSize

const getDevice = () => {
    if (window.innerWidth <= size.mobileS) {
        return 'mobileXS'
    } else if (isBetweenTheSizes(size.mobileS, size.mobileM)) {
        return 'mobileS'
    } else if (isBetweenTheSizes(size.mobileM, size.mobileL)) {
        return 'mobileM'
    } else if (isBetweenTheSizes(size.mobileL, size.tablet)) {
        return 'mobileL'
    } else if (isBetweenTheSizes(size.tablet, size.laptop)) {
        return 'tablet'
    } else if (isBetweenTheSizes(size.laptop, size.laptopL)) {
        return 'laptop'
    } else if (isBetweenTheSizes(size.laptopL, size.desktop)) {
        return 'laptopL'
    } else if (window.innerWidth > size.desktop) {
        return 'desktop'
    } else {
        return undefined
    }
}

const useDeviceDetection = () => {
    const [devices, setDevices] = useState({
        isBrowser,
        isMobile,
        isDesktop,
        isTablet,
        isSmartTV,
        isWindows,
        isMacOs
    })
    const [deviceWithWindowChange, setDeviceWithWindowChange] = useState(getDevice())

    useEffect(() => {
        const handlePageResized = () => {
            setDevices({
                isBrowser,
                isMobile,
                isDesktop,
                isTablet,
                isSmartTV,
                isWindows,
                isMacOs
            })
            setDeviceWithWindowChange(getDevice())
        }

        if (typeof window !== 'undefined') {
            window.addEventListener('resize', handlePageResized)
            window.addEventListener('orientationchange', handlePageResized)
            window.addEventListener('load', handlePageResized)
            window.addEventListener('reload', handlePageResized)
        }

        return () => {
            if (typeof window !== 'undefined') {
                window.removeEventListener('resize', handlePageResized)
                window.removeEventListener('orientationchange', handlePageResized)
                window.removeEventListener('load', handlePageResized)
                window.removeEventListener('reload', handlePageResized)
            }
        }
    }, [window.innerWidth])

    return [
        {
            devices,
            deviceWithWindowChange,
            isWeb:
                deviceWithWindowChange &&
                ['laptop', 'laptopL', 'desktop'].includes(deviceWithWindowChange),
            isMob:
                deviceWithWindowChange &&
                ['mobileS', 'mobileM', 'mobileL'].includes(deviceWithWindowChange),
            isTablet: deviceWithWindowChange && ['tablet'].includes(deviceWithWindowChange),
            isSmallerThanMob:
                deviceWithWindowChange && ['mobileXS'].includes(deviceWithWindowChange),
            heightIsSmallerThan850: window.innerHeight < 850,
            // ! Added a new device detection for very small screens (smaller than 170)
            isSmallerThen170Width: window.innerWidth < 170
        }
    ] as const
}

export default useDeviceDetection
