import APIUrls from './apiUrls'

const getGatewayGraphqlUrl = () =>
    process.env.REACT_APP_GATEWAY_URL && process.env.REACT_APP_GATEWAY_URL !== ''
        ? `${process.env.REACT_APP_GATEWAY_URL}/graphql`
        : APIUrls.development.graphql

const getApiUrlsEnv = () => {
    switch (process.env.REACT_APP_ENV) {
        case 'development':
            return APIUrls.development
        case 'staging':
            return APIUrls.staging
        case 'production':
            return APIUrls.production
        default:
            return APIUrls.development
    }
}

const getWorkflowNameEnv = (workflowName: string) => {
    switch (process.env.REACT_APP_ENV) {
        case 'development':
            return APIUrls.workflows[workflowName].development
        case 'staging':
            return APIUrls.workflows[workflowName].staging
        case 'production':
            return APIUrls.workflows[workflowName].production
        default:
            return APIUrls.workflows[workflowName].development
    }
}

export default {
    apiUrlsEnv: getApiUrlsEnv(),
    gateway: getGatewayGraphqlUrl(),
    workflowEnv: (name: string) => getWorkflowNameEnv(name),
    userType: 'employee'
}
