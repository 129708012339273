import { useLazyQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import API from '../../../API'
import { userProfileState } from '../../../store/store'
import theme from '../../../theme/theme'
import {
    Employee,
    Maybe,
    QueryEmployeeArgs,
    QueryQuotationsArgs,
    Quotation
} from '../../../types/GQLTypes'
import { MUIComponents } from '../../muiComponents'
import { Content } from './Content'
import Containers from '../../containers'
import { TabProps } from '../../muiComponents/types'
import {
    collectSkeletonTabs,
    ConditionalWrapper,
    statusesThatAreNotAContract
} from '../../../utils/utils'
import useDeviceDetection from '../../../hooks/useDeviceDetection'
import { SmallScreenContent } from './SmallScreenContent'

export const Files = () => {
    const { t } = useTranslation()

    const urlParams = new URLSearchParams(window.location.search)
    const id = urlParams.get('id')

    // hook
    const [devices] = useDeviceDetection()
    const navigate = useNavigate()

    //global states
    const [userProfile] = useRecoilState(userProfileState)

    //Default state values
    const skeletonTabs = collectSkeletonTabs(
        <MUIComponents.Skeleton
            sx={{ margin: 'auto' }}
            width={'100%'}
            height={'100%'}
            variant="rectangular"
        />,
        <Content orderId={null} quotation={null} />
    )

    //states
    const [allEmployeeQuotations, setAllEmployeeQuotations] = useState<
        Maybe<Quotation>[] | undefined
    >(undefined)
    const [tabs, setTabs] = useState<TabProps[]>(skeletonTabs)
    const [value, setValue] = useState(0)
    const [selectedQuotation, setSelectedQuotation] = useState<string | undefined>(undefined)
    const [isLoading, setIsLoading] = useState(true)

    // query
    const [collectEmployeeQuotations, { loading }] = useLazyQuery<
        { employee: Employee },
        QueryQuotationsArgs & QueryEmployeeArgs
    >(API.Queries.EMPLOYEE_QUOTATIONS_AND_ORDER)

    useEffect(() => {
        const initFetch = async () => {
            const allEmployeeQuotations = await collectEmployeeQuotations({
                variables: {
                    id: userProfile.employee_id as string
                }
            })

            setAllEmployeeQuotations(
                allEmployeeQuotations?.data?.employee?.employee_quotations
                    ? allEmployeeQuotations?.data?.employee?.employee_quotations?.filter(
                          (quotation) =>
                              quotation?.order !== null &&
                              quotation?.order?.order_status_fk &&
                              !statusesThatAreNotAContract.includes(
                                  quotation?.order?.order_status_fk
                              )
                      )
                    : []
            )

            if (!loading) {
                setIsLoading(false)
            }
        }

        if (userProfile.employee_id) initFetch()
    }, [userProfile.employee_id])

    const isSmallScreen = devices.isMob || devices.isSmallerThanMob || devices.isTablet

    useEffect(() => {
        const tabs: TabProps[] = []

        if (allEmployeeQuotations && allEmployeeQuotations?.length > 0) {
            for (const [index, quotation] of allEmployeeQuotations.entries()) {
                tabs.push({
                    label: quotation?.quotation_number ?? '',
                    children: isSmallScreen ? (
                        <SmallScreenContent
                            orderId={quotation?.order?.id}
                            loading={!quotation}
                            quotation={quotation}
                            sourceComponent="Files"
                        />
                    ) : (
                        <Content orderId={quotation?.order?.id} quotation={quotation}/>
                    ),
                    id: `${index}`,
                    ariaControls: `Panel-${index ?? ''}`,
                    wrapped: false,
                    disabled: false
                })

                if (id && quotation?.quotation_number === id) {
                    setValue(index)
                    setSelectedQuotation(id)
                }
            }

            setTabs(tabs)
        }

        if (!isLoading && allEmployeeQuotations && allEmployeeQuotations?.length === 0) {
            setTabs([])
        }
    }, [allEmployeeQuotations, isSmallScreen])

    return (
        <>
            <MUIComponents.Styling.Paper
                elevation={5}
                sx={{
                    width: 'calc(100% - 50px)',
                    margin: '75px 25px auto',
                    overflow: 'unset',
                    height: isSmallScreen ? 'unset' : 'calc(100vh - 180px)'
                }}
            >
                <Wrapper>
                    <MUIComponents.Typography
                        sx={{ color: theme.palette.primary.main, display: 'flex', padding: '20px' }}
                        variant="h5"
                    >
                        {t('filesAndCalc.files.title')}
                    </MUIComponents.Typography>
                    <MUIComponents.Icons.FilePresentIcon
                        sx={{
                            color: '#BDBDBD',
                            width: '40px',
                            height: '40px',
                            display: 'flex',
                            alignItems: 'center',
                            paddingRight: '20px'
                        }}
                    />
                </Wrapper>

                {isSmallScreen ? (
                    <>
                        <MUIComponents.Select.SingleSelect
                            onChange={(e) => {
                                if (e.target.value) {
                                    setSelectedQuotation(e.target.value as string)
                                    navigate(`/dossiers?id=${e.target.value}`)
                                }
                            }}
                            labelId="selectDossier"
                            id="select-dossier"
                            name="selectDossier"
                            size="small"
                            labelContent={t('filesAndCalc.files.mobileSelect')}
                            value={selectedQuotation}
                            menuItems={allEmployeeQuotations?.map((quotation) => ({
                                value: quotation?.quotation_number ?? '',
                                label: quotation?.quotation_number ?? ''
                            }))}
                            customSelect={false}
                            formStyling={{
                                paddingRight: '5px',
                                margin: '20px',
                                width: 'calc(100% - 50px)'
                            }}
                            MenuProps={{
                                style: {
                                    maxHeight: 500
                                }
                            }}
                        />

                        {selectedQuotation && (
                            <SmallScreenContent
                                quotation={allEmployeeQuotations?.find(
                                    (quote) => quote?.quotation_number === selectedQuotation
                                )}
                                loading={!selectedQuotation && !(id === null)}
                                orderId={
                                    allEmployeeQuotations?.find(
                                        (quote) => quote?.quotation_number === selectedQuotation
                                    )?.order?.id
                                }
                                sourceComponent="Files"
                            />
                        )}
                    </>
                ) : (
                    <MUIComponents.Tabs
                        onChange={(event, newValue) => {
                            setValue(newValue)
                            navigate(`/dossiers?id=${event.currentTarget.textContent}`)
                        }}
                        value={value}
                        color={theme.palette.primary.main}
                        colorToOverwrite="white"
                        allTabs={tabs}
                        component="Label"
                        sx={{ borderBottom: '1px solid #D8D8D8' }}
                        parentBoxStyling={{ height: 'calc(100% - 72px)' }}
                        disabled={!allEmployeeQuotations}
                    />
                )}
            </MUIComponents.Styling.Paper>

            {ConditionalWrapper({
                condition: !(devices.isMob || devices.isSmallerThanMob),
                wrapper: (wrappedChildren) => (
                    <MUIComponents.Styling.Paper
                        style={{
                            width: 'calc(100% - 50px)',
                            margin: '10px 25px auto'
                        }}
                    >
                        {wrappedChildren}
                    </MUIComponents.Styling.Paper>
                ),
                children: (
                    <Containers.Copyright
                        sx={{
                            color: 'rgb(128,128,128)',
                            margin: 'auto 5px',
                            fontSize: 'small'
                        }}
                        paperStyling={{ margin: '10px 0px', width: 'calc(100% - 20px)' }}
                        isMob={devices.isMob || devices.isSmallerThanMob}
                        isTablet={devices.isTablet}
                    />
                )
            })}
        </>
    )
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`
