import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import { CSSProperties } from 'styled-components'

interface AppBarProps extends MuiAppBarProps {
    // you can declare custom props in here
    style?: CSSProperties
}

export const Appbar = ({
    position = 'fixed',
    style = { backgroundColor: 'white' },
    ...props
}: AppBarProps) => <MuiAppBar position={position} style={style} {...props} />

// Example of this component with props:
// <MUIComponents.AppBar position="static">hier komt dan alles van custom fields</MUIComponents.AppBar>
