import styled, { css } from 'styled-components'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import theme from '../../../theme/theme'
import { MUIComponents } from '../../muiComponents'
import { Label } from './Label'
import { Value as NormalValue } from './Value'
import { Quotation, Maybe } from '../../../types/GQLTypes'
import { DeviceParameters } from '../../../types'

type ComponentTypes = 'label' | 'labelSummary' | 'stars'

export interface Item {
    label: string
    value: string
    component?: ComponentTypes
}

const getStarsValue = (value: string) => {
    switch (value?.toLowerCase()?.replace(/\s/g, '')) {
        case '1star':
            return 1
        case '2star':
            return 2
        case '3star':
            return 3
        case '4star':
            return 4
        case '5star':
            return 5
        case '0star':
        default:
            return 0
    }
}

const getComponent = (
    props: DeviceParameters & {
        item: Item
    }
) => {
    const isMobile = props.isMob || props.isSmallerThanMob

    switch (props.item.component) {
        case 'labelSummary':
            return isMobile ? (
                <Column isMob={props.isMob} isSmallerThanMob={props.isSmallerThanMob}>
                    {Label(props.item)}

                    {NormalValue(props.item)}
                </Column>
            ) : (
                <Row isMob={props.isMob} isSmallerThanMob={props.isSmallerThanMob}>
                    {Label(props.item)}

                    {NormalValue(props.item)}
                </Row>
            )
        case 'stars':
            return isMobile ? (
                <Column isMob={props.isMob} isSmallerThanMob={props.isSmallerThanMob}>
                    {Label(props.item)}

                    <MUIComponents.Rating disabled value={getStarsValue(props.item.value)} />
                </Column>
            ) : (
                <Row isMob={props.isMob} isSmallerThanMob={props.isSmallerThanMob}>
                    {Label(props.item)}

                    <MUIComponents.Rating disabled value={getStarsValue(props.item.value)} />
                </Row>
            )
        case 'label':
        default:
            return isMobile ? (
                <Column isMob={props.isMob} isSmallerThanMob={props.isSmallerThanMob}>
                    {Label(props.item)}

                    {NormalValue(props.item)}
                </Column>
            ) : (
                <Row isMob={props.isMob} isSmallerThanMob={props.isSmallerThanMob}>
                    {Label(props.item)}

                    {NormalValue(props.item)}
                </Row>
            )
    }
}

const resolveComponentType = (componentValue: string, componentName: string): ComponentTypes => {
    if (componentValue?.length > 15) return 'labelSummary'

    if (componentValue?.toLocaleLowerCase()?.includes('star')) return 'label'

    return 'label'
}

export const Details = ({
    isMob = false,
    isSmallerThanMob = false,
    isTablet = false,
    ...props
}: DeviceParameters & {
    quotation?: Maybe<Quotation>
}) => {
    const { t } = useTranslation()

    const isNotBigScreen = isMob || isTablet || isSmallerThanMob
    const [fields, setFields] = useState<Item[]>([])

    useEffect(() => {
        const fieldsArr: Item[] = []

        props?.quotation?.quotation_fields?.map((qField) => {
            fieldsArr.push({
                label: qField?.field?.name ?? '/',
                value: qField?.value ?? '/',
                component: resolveComponentType(qField?.value ?? '/', qField?.field?.name ?? '/')
            })
        })

        setFields(fieldsArr)
    }, [props?.quotation?.quotation_fields])

    return (
        <>
            {!isNotBigScreen && (
                <MUIComponents.Typography
                    sx={{
                        color: theme.palette.primary.main,
                        fontWeight: 'bold',
                        padding: '30px',
                        textAlign: 'left'
                    }}
                    variant="body2"
                >
                    {t('filesAndCalc.detailsContainer.title')}
                </MUIComponents.Typography>
            )}

            <InfoWrapper>
                {props?.quotation
                    ? fields.map((item: Item, i) => (
                          <Wrapper key={i}>
                              {getComponent({ item, isMob, isSmallerThanMob })}

                              <MUIComponents.Styling.Divider
                                  sx={{
                                      borderColor: '#BDBDBD',
                                      marginBottom: '5px',
                                      width: '100%'
                                  }}
                              />
                          </Wrapper>
                      ))
                    : [...Array(7)].map((_, i) => (
                          <MUIComponents.Skeleton
                              sx={{ margin: 0, marginBottom: 2 }}
                              width={'100%'}
                              height={20}
                              key={i}
                              variant="rectangular"
                          />
                      ))}
            </InfoWrapper>
        </>
    )
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
`

const Row = styled.div<DeviceParameters>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 2px 0;

    ${(props) =>
        (props.isMob || props.isSmallerThanMob) &&
        css`
            width: calc(100% - 30px);
        `}
`

const Column = styled.div<DeviceParameters>`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    padding: 2px 0;

    ${(props) =>
        (props.isMob || props.isSmallerThanMob) &&
        css`
            width: calc(100% - 30px);
        `}
`

const InfoWrapper = styled.div<DeviceParameters>`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    padding: 0 30px;
    width: calc(100% - 60px);

    ${(props) =>
        (props.isMob || props.isSmallerThanMob) &&
        css`
            padding-left: 30px;
            width: calc(100% - 40px);
        `};
`
