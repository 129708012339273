import { useEffect, useState } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import { useRecoilState } from 'recoil'
import { useTranslation } from 'react-i18next'

import { MUIComponents } from '../../muiComponents'
import Templates from './templatesContent'
import * as GQLTypes from '../../../types/GQLTypes'
import { getAmountByTwoDecimals, headers, stringDefaulter } from '../../../utils/utils'
import { alertObjState, userObjectState, userProfileState } from '../../../store/store'
import API from '../../../API'

interface ITemplate {
    title?: string
    noContent?: string | null
    content?: JSX.Element
}

export interface IService {
    button: {
        action: string
        visible: boolean
    }
    contact: {
        email?: string | string[]
        tel?: string
    }
    description: string
    type: 'Onderhoud' | 'Pechhulp' | 'Garantie E-bike' | 'Verzekering' | 'BA-verzekering'
}

export interface TemplateProps {
    activeTemplate: 'Onderhoud' | 'Pechhulp' | 'Garantie E-bike' | 'Verzekering' | 'BA-verzekering'
    services: IService[]
    bikeData: GQLTypes.Maybe<GQLTypes.Bike> | undefined
    orderData: GQLTypes.Maybe<GQLTypes.Order> | undefined
    companyData: GQLTypes.Maybe<GQLTypes.Company> | undefined
    endDate: string | Date
    startDate: string | Date
}

export const Template = (props: TemplateProps) => {
    const { t } = useTranslation()

    const [userProfile] = useRecoilState(userProfileState)

    const [loading, setLoading] = useState(true)
    const [currentTemplate, setCurrentTemplate] = useState<ITemplate>({})

    useEffect(() => {
        (async () => {
            const templateValues = props.services.find(
                (service) => service.type === props.activeTemplate
            )

            const balanceFactor = Number(
                props.bikeData?.service_passes?.[0]?.cardInformation?.BalanceFactor?.[0] ?? '1'
            )
            const start = getAmountByTwoDecimals(
                Number(props.bikeData?.service_passes?.[0]?.cardActivationDetails?.Value?.[0]) /
                    balanceFactor
            )
            const current = getAmountByTwoDecimals(
                Number(props.bikeData?.service_passes?.[0]?.cardInformation?.Balance?.[0]) /
                    balanceFactor
            )

            const description = templateValues?.description?.split(' ')
            const wordIndex = description?.findIndex((word) => word === 'via')
            const insurance = wordIndex ? description?.[wordIndex + 1] : undefined

            setCurrentTemplate(
                Templates({
                    t: t,
                    frameNumber: stringDefaulter(props.bikeData?.frame_number) as string,
                    licensePlate: stringDefaulter(props.bikeData?.license_number) as string,
                    stickerNumber: stringDefaulter(props.bikeData?.sticker_number) as string,
                    servicePass: {
                        barcode: props.bikeData?.service_passes?.[0]?.barcode,
                        startAmount: stringDefaulter(Number(start), 'currencyFormat'),
                        currentAmount: stringDefaulter(Number(current), 'currencyFormat')
                    },
                    insurance: stringDefaulter(insurance) as string,
                    contact: Array.isArray(templateValues?.contact?.email)
                        ? (templateValues?.contact?.email ?? []).length > 2
                            ? templateValues?.contact?.email.join(', ')
                            : templateValues?.contact?.email.join(' / ')
                        : templateValues?.contact?.email ?? undefined,
                    telephoneNumberRoadsideAssistance: stringDefaulter(
                        templateValues?.contact?.tel
                    ) as string
                }).Templates[props.activeTemplate]
            )

            let language: string = userProfile?.language as string

            if (!language) {
                language = navigator?.languages[1] as string // languagecode [1]
            }

            const fileToDownload = await axios({
                method: 'post',
                url: API.utils.apiUrlsEnv.generateMyServiceDocuments,
                data: {
                    employees: [
                        {
                            orderId: props?.orderData?.id,
                            data: 'test data',
                            startDate: props?.startDate,
                            endDate: props?.endDate,
                            name: `${userProfile?.first_name} ${userProfile?.last_name}`,
                            email: userProfile?.email,
                            language: {
                                code: language.toUpperCase()
                            },
                            bike: {
                                brand: props?.bikeData?.bikeBrand?.name,
                                frameNumber: props?.bikeData?.frame_number
                            },
                            company: {
                                name: props?.companyData?.name
                            }
                        }
                    ]
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    ['x-forwarded-authorization']: `Bearer ${localStorage.getItem('token')}`,
                    ...headers
                }
            })

            const linkSource = `data:application/pdf;base64,${fileToDownload?.data.attachment.content}`
            const pdfBlob = dataURItoBlob(linkSource)
            const url = window.URL.createObjectURL(pdfBlob)
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `${fileToDownload?.data.attachment.fileName}`)
            document.body.appendChild(link)
            link.click()
            link.remove()

            setLoading(false)
        })()
    }, [props.activeTemplate])

    return loading ? (
        <MUIComponents.Loader />
    ) : (
        <Wrapper>
            <MUIComponents.Typography
                variant="h6"
                style={{
                    fontWeight: 'bold',
                    textAlign: 'left',
                    color: 'rgba(0, 0, 0, 0.6)'
                }}
            >
                {currentTemplate?.title}
            </MUIComponents.Typography>
            {currentTemplate?.content}
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    color: rgba(0, 0, 0, 0.6);
    padding: 10px 0;
`

const dataURItoBlob = (dataURI: string) => {
    const byteString = atob(dataURI.split(',')[1])
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

    // write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(byteString.length)
    const ia = new Uint8Array(ab)
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i)
    }

    // write the ArrayBuffer to a blob
    const bb = new Blob([ab], { type: mimeString })
    return bb
}
