import { MUIComponents } from '../../muiComponents'

export const LayoutImage = ({ backgroundImage }: { backgroundImage: string }) => (
    <MUIComponents.Styling.Grid
        item
        xs={false}
        sm={4}
        md={6}
        sx={{
            backgroundImage,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
                t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center'
        }}
    />
)
