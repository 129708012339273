import PageComponentsOrdering from './pageComponentsOrdering.json'

// ! Requirements for this:
// ! Toevoegen aan de pageComponentsOrdering.json (er zijn al genoeg voorbeelden voorzien)
// ! Wanneer je deze functie aanroept moet je de components en de page meegeven,
// ! de ordering word dan voor u afgehandeld en het geeft de correcte weergave terug
// ! PS: niet vergeten om bij de array van componenten dat je meegeeft een key te voorzien, aangezien het in een array zit!
export const getComponentsOrdering = (components: JSX.Element[], page: string) => {
    const ordering = (PageComponentsOrdering.page as { [key: string]: { [key: string]: string } })[
        page
    ]

    return Object.values(ordering).map((id) =>
        components.find((component) => component.props.id === id)
    )
}
